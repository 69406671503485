import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import api from '~/api';

export const getGeneralList = createAsyncThunk('customer/getList', async ({ t }, thunkAPI) => {
    try {
        const response = await api.get('api/v1/user/General');
        if (response.status < 400) {
            return response.data.result;
        }
        message.error({
            content: t('cant_get_customer_list'),
        });

        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        message.error({
            content: t('cant_get_customer_list'),
        });
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const uploadCustomer = createAsyncThunk('customer/create', async ({ request, t }, thunkAPI) => {
    try {
        const response = await api.post('api/v1/user/General', request);
        if (response.status < 400) {
            message.success({
                content: `${t('success')}!`,
                duration: 1.5,
            });
            return response.data.result;
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const updateCustomer = createAsyncThunk('customer/update', async ({ id, data, t }, thunkAPI) => {
    try {
        const response = await api.put(`api/v1/user/General/${id}`, data);
        if (response.status < 400) {
            message.success({
                content: `${t('success')}!`,
                duration: 1.5,
            });

            return response.data.result;
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const deleteCustomer = createAsyncThunk('customer/delete', async ({ id, type, t }, thunkAPI) => {
    try {
        const response = await api.delete(`api/v1/user/General/${id}`);
        if (response.status < 400) {
            message.success({
                content: `${t('success')}!`,
                duration: 1.5,
            });
            return { id, type };
        }
        message.error({
            content: t('error'),
            duration: 1.5,
        });
        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        message.error({
            content: t('error'),
            duration: 1.5,
        });
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

/* eslint-disable react/jsx-no-useless-fragment */

import styles from './LoginMaster.module.scss';
import { Alert, Button, Form, Input, message } from 'antd';
import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

const styleEyeIcon = {
    position: 'absolute',
    right: '5%',
    bottom: '34.5%',
    cursor: 'pointer',
};

export default function RegisterComponent({ submitForm, submitFailed, isLoading, errorMessage }) {
    const [showPassWord, setShowPassWord] = useState(false);
    const [showRePassWord, setShowRePassWord] = useState(false);
    const [hidePass, setHidePass] = useState(true);
    const [hideRePass, setHideRePass] = useState(true);

    const { t } = useTranslation();

    const onFinish = (values) => {
        if (values !== '') {
            submitForm(values);
        } else {
            message.warning({
                content: `${t('invalid_username')}`,
                duration: 0.5,
            });
        }
    };
    const onFinishFailed = (errorInfo) => {
        submitFailed(errorInfo);
    };

    const confirmPassword = ({ getFieldValue }) => ({
        validator(rule, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(t('password_do_not_match_message'));
        },
    });

    const handleHideIcon = (e, setState) => {
        if (e.target.value === '') {
            setState(true);
        } else {
            setState(false);
        }
    };

    return (
        <div>
            <div className={cx('title-form')}>
                <span>{t('sign_up')}</span>
            </div>
            <div className={cx('message')}>
                {errorMessage && <Alert message={t(errorMessage)} type="error" showIcon />}
            </div>

            <div>
                <Form
                    className={cx('login-form-custom')}
                    layout="vertical"
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        autoComplete="none"
                        label={t('full_name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('input_your_name_message'),
                            },
                        ]}
                    >
                        <Input className={cx('input-custom')} placeholder={t('full_name')} />
                    </Form.Item>
                    <Form.Item
                        autoComplete="none"
                        label={t('email')}
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: t('not_valid_email_message'),
                            },
                            {
                                required: true,
                                message: t('input_your_email_message'),
                            },
                        ]}
                    >
                        <Input className={cx('input-custom')} placeholder="email@website.com" />
                    </Form.Item>

                    <Form.Item
                        autoComplete="none"
                        label={t('password')}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: t('input_your_password_message'),
                            },
                        ]}
                    >
                        <div style={{ position: 'relative' }}>
                            <Input
                                type={showPassWord ? 'text' : 'password'}
                                className={cx('input-custom')}
                                placeholder={t('password')}
                                onChange={(e) => handleHideIcon(e, setHidePass)}
                            />
                            {hidePass ? (
                                <></>
                            ) : (
                                <i
                                    className={cx(showPassWord ? 'fa-sharp fa-solid fa-eye-slash' : 'fa-solid fa-eye')}
                                    style={styleEyeIcon}
                                    onClick={() => setShowPassWord(!showPassWord)}
                                />
                            )}
                        </div>
                    </Form.Item>

                    <Form.Item
                        autoComplete="none"
                        label={t('confirm_password')}
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: t('confirm_password_message'),
                            },
                            confirmPassword,
                        ]}
                    >
                        <div style={{ position: 'relative' }}>
                            <Input
                                type={showRePassWord ? 'text' : 'password'}
                                className={cx('input-custom')}
                                placeholder={t('confirm_password')}
                                onChange={(e) => handleHideIcon(e, setHideRePass)}
                            />
                            {hideRePass ? (
                                <></>
                            ) : (
                                <i
                                    className={cx(
                                        showRePassWord ? 'fa-sharp fa-solid fa-eye-slash' : 'fa-solid fa-eye',
                                    )}
                                    style={styleEyeIcon}
                                    onClick={() => setShowRePassWord(!showRePassWord)}
                                />
                            )}
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={isLoading}
                            className="btn-custom btn-custom-large btn-custom_full-width mb-25"
                            type="primary"
                            size="large"
                            htmlType="submit"
                        >
                            {t('sign_up')}
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <p>
                            <span className={cx('text-question')}>{t('already_have_an_account')} </span>
                            <span className={cx('text-underline')}>
                                <Link to="/login" className={cx('text-underline_color')}>
                                    {t('login')}
                                </Link>
                            </span>
                        </p>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

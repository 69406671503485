/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import FormItemBaseFields from './FormItemBaseFields';
import FormItemOptionalFields from './FormItemOptionalFields';
import NewFieldForm from './NewFieldForm';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row } from 'antd';
import classNames from 'classnames/bind';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AppLoading from '~/components/AppLoading';
import { observeShouldAlarmSystemBeInstalledQuestion } from '~/helper/ForgeViewer/connected-line/common';
import { RoomsStatic } from '~/helper/ForgeViewer/speam/rooms/roomsStatic';
import { filesList } from '~/store/files/filesSlice';
import { clearModalStateModalAction } from '~/store/modal/ModalSlice';
import { doCallPostDataToolAction, selectToolState } from '~/store/tool/toolSlice';
import {
    makeViewerGuardAutoSaveFileStatusOfFileSliceSelector,
    makeViewerIsApplyRoomSettingSuccessSelector,
    makeViewerObserveSaveFileStatusOfFileSliceSelector,
} from '~/store/viewer/viewer.selectors';
import {
    setIsApplyRoomSettingSuccess,
    setObserveSaveFileStatusOfFileSliceViewerAction,
    setShouldAlarmSystemBeInstalledViewerAction,
    setShouldApplySettingToAllRoomsViewerAction,
    setShouldDrawingCenteredBMZViewerAction,
    updateRoomSettingByRoomIdViewerAction,
    updateRoomSettingForEachRoomViewerAction,
} from '~/store/viewer/viewer.slice';

// set 'cx' as Css class names
const cx = classNames.bind(this);

/**
 * Helper that processes input of form's fields to result an desired output
 * @param {*} fieldValues
 * @param {*} room
 * @returns
 */
const processFieldForm = (fieldValues, room) => {
    const copiedFields = fieldValues || {};
    const newFieldCollection = {};

    Object.keys(copiedFields).forEach((k) => {
        // make a comparison between default fields and changed fields
        // if has new field (set flag = 1), put it to a collection
        const hasNewField = fields(room)[k] ?? 1;
        if (hasNewField === 1) {
            // add key-value of newField to newFieldCollection
            newFieldCollection[k] = fieldValues[k] || '';
            // remove this new field form copiedFields
            delete copiedFields[k];
        }
    });
    // stringify value of newFieldCollection
    copiedFields['newFieldCollection'] = newFieldCollection;

    // Data of Room Measurement
    const roomMeasurementFields = {
        roomWidth: copiedFields?.roomWidth,
        roomLength: copiedFields?.roomLength,
        roomHeight: copiedFields?.roomHeight,
    };

    // Remained data except data of Room Measurement
    const remainedFields = { ...copiedFields };

    delete remainedFields?.roomWidth;
    delete remainedFields?.roomLength;
    delete remainedFields?.roomHeight;

    return {
        roomMeasurementFields,
        remainedFields,
    };
};

/**
 * Support for rendering default fields of form's items
 * @param {*} room
 */
const fields = (room) => ({
    roomWidth: {
        name: ['roomWidth'],
        value: room?.width ?? 0,
    },
    roomLength: {
        name: ['roomLength'],
        value: room?.height ?? 0,
    },
    roomHeight: {
        name: ['roomHeight'],
        value: room?.roomSetting?.roomHeight || 0,
    },
    qFalseCeilings: {
        name: ['qFalseCeilings'],
        value: room?.roomSetting?.qFalseCeilings || 0,
    },
    qIntermediateFloors: {
        name: ['qIntermediateFloors'],
        value: room?.roomSetting?.qIntermediateFloors || 0,
    },
    qItServerRoom: {
        name: ['qItServerRoom'],
        value: room?.roomSetting?.qItServerRoom || 0,
    },
    qDualDetectorDependency: {
        name: ['qDualDetectorDependency'],
        value: room?.roomSetting?.qDualDetectorDependency || 0,
    },
    qExtinguishingSystem: {
        name: ['qExtinguishingSystem'],
        value: room?.roomSetting?.qExtinguishingSystem || 0,
    },
    qShouldFireAlarmSystemBeInstalled: {
        name: ['qShouldFireAlarmSystemBeInstalled'],
        value: room?.roomSetting?.qShouldFireAlarmSystemBeInstalled || 0,
    },
    qFireLoads: {
        name: ['qFireLoads'],
        value: room?.roomSetting?.qFireLoads || 0,
    },
    qBearers: {
        name: ['qBearers'],
        value: room?.roomSetting?.qBearers || 0,
    },
    bearerMeasurementWidth1: {
        name: ['bearerMeasurementWidth1'],
        value: room?.roomSetting?.bearerMeasurementWidth1 || undefined,
    },
    bearerMeasurementHeight1: {
        name: ['bearerMeasurementHeight1'],
        value: room?.roomSetting?.bearerMeasurementHeight1 || undefined,
    },
    bearerMeasurementWidth2: {
        name: ['bearerMeasurementWidth2'],
        value: room?.roomSetting?.bearerMeasurementWidth2 || undefined,
    },
    bearerMeasurementHeight2: {
        name: ['bearerMeasurementHeight2'],
        value: room?.roomSetting?.bearerMeasurementHeight2 || undefined,
    },
    bearerMeasurementWidth3: {
        name: ['bearerMeasurementWidth3'],
        value: room?.roomSetting?.bearerMeasurementWidth3 || undefined,
    },
    bearerMeasurementHeight3: {
        name: ['bearerMeasurementHeight3'],
        value: room?.roomSetting?.bearerMeasurementHeight3 || undefined,
    },
    qSlopingCeilings: {
        name: ['qSlopingCeilings'],
        value: room?.roomSetting?.qSlopingCeilings || 0,
    },
    slopingCeilingsLength: {
        name: ['slopingCeilingsLength'],
        value: room?.roomSetting?.slopingCeilingsLength || undefined,
    },
    slopingCeilingsWidth: {
        name: ['slopingCeilingsWidth'],
        value: room?.roomSetting?.slopingCeilingsWidth || undefined,
    },
    qVaults: {
        name: ['qVaults'],
        value: room?.roomSetting?.qVaults || 0,
    },
    vaultsLength: {
        name: ['vaultsLength'],
        value: room?.roomSetting?.vaultsLength || undefined,
    },
    vaultsWidth: {
        name: ['vaultsWidth'],
        value: room?.roomSetting?.vaultsWidth || undefined,
    },
    qDisturbances: {
        name: ['qDisturbances'],
        value: room?.roomSetting?.qDisturbances || 0,
    },
    qHindrances: {
        name: ['qHindrances'],
        value: room?.roomSetting?.qHindrances || 0,
    },
    qExplosive: {
        name: ['qExplosive'],
        value: room?.roomSetting?.qExplosive || 0,
    },
    detectorType: {
        name: ['detectorType'],
        value: room?.roomSetting?.detectorType || '',
    },
    newFieldCollection: {
        name: ['newFieldCollection'],
        value: room?.roomSetting?.newFieldCollection || {},
    },
});

/**
 * Base component that renders the room setting modal form
 */
export default function RoomItemSettingForm({ currentRoom, modalOpen, modalData }) {
    // base fields of form
    const [fieldsOfForm, setFieldsOfForm] = useState(Object.values(fields(currentRoom)));

    // helper states that controls the new field input and NewFieldForm
    const [open, setOpen] = useState(false);
    const [msgHasSameField, setMsgHasSameField] = useState('');

    // state for buttons:
    // - apply to this room
    // - apply to all rooms
    const [shouldApplyAllRooms, setShouldApplyAllRoom] = useState(false);

    // helper for checking rules's error of this Room Setting Form
    const [errorMessages, setErrorMessages] = useState({});

    // helper for Closing RoomItemSettingForm
    const { isFetchingSaveFile } = useSelector(filesList);
    const { doCallPostData } = useSelector(selectToolState);
    const guardAutoSaveFileStatusOfFileSliceSelector = useSelector(
        makeViewerGuardAutoSaveFileStatusOfFileSliceSelector(),
    );
    const observeSaveFileStatusOfFileSliceSelector = useSelector(makeViewerObserveSaveFileStatusOfFileSliceSelector());
    const IsApplyRoomSettingSuccessSelector = useSelector(makeViewerIsApplyRoomSettingSuccessSelector());

    // helper for translation
    const { t } = useTranslation();
    // dispatch action to redux store
    const dispatch = useDispatch();

    // useFrom of antd
    const [form] = Form.useForm();

    // methods handling showing or hiding of NewFieldForm
    const showModalNewFieldForm = () => {
        setOpen(true);
    };
    const hideModalNewFieldForm = () => {
        setOpen(false);
    };

    /**
     * Helper support for getting all fields when user click submit button
     * @param {*} fieldValues - are got when user click Submit button
     */
    const onFinish = useCallback(
        async (fieldValues) => {
            // clear all fields error messages if any of them is exist
            setErrorMessages({});
            // processing to get the desired output
            // TODO: do something with roomMeasurementFields
            const { remainedFields, roomMeasurementFields } = processFieldForm(fieldValues, currentRoom);

            const roomHeight = Number(roomMeasurementFields.roomHeight);

            let dataForSending = {};

            if (roomHeight) {
                dataForSending = { ...remainedFields, roomHeight };
            } else {
                dataForSending = { ...remainedFields };
            }

            // ----------------------------------------------------------
            // Helper for place BMZ point at the center of the room
            // ----------------------------------------------------------
            const answer = observeShouldAlarmSystemBeInstalledQuestion(dataForSending);
            dispatch(setShouldAlarmSystemBeInstalledViewerAction(answer));
            dispatch(setShouldDrawingCenteredBMZViewerAction(answer));

            // -----------------------------------------------------------------
            // Determine should apply setting for All Rooms or the Current Room
            // -----------------------------------------------------------------
            if (shouldApplyAllRooms) {
                // update roomSetting of each room in viewer slice
                await dispatch(updateRoomSettingForEachRoomViewerAction(dataForSending));
                // update roomSetting of each room in RoomsStatic
                await RoomsStatic.updateRoomSettingForAllRooms(dataForSending);
            } else {
                const payloadRoomSettingById = {
                    roomSetting: dataForSending,
                    roomId: currentRoom?.roomId,
                };
                await dispatch(updateRoomSettingByRoomIdViewerAction(payloadRoomSettingById));
                RoomsStatic.updateRoomSettingByRoomId(payloadRoomSettingById);
            }

            // --------------------------------------------------------------
            // Call POST Save api (Observe saveDataFile of FileSlice)
            // ---------------------------------------------------------------
            dispatch(doCallPostDataToolAction(true));
        },
        [shouldApplyAllRooms],
    );

    /**
     * The main purpose if it is to checking form's fields's validation
     * @param {*} error
     */
    const onFinishFailed = (error) => {
        // convert error message array to object with
        // key - name of field
        // value - error message
        const errorFields = error?.errorFields;

        const errorMessagesObj = {};

        errorFields.forEach((err) => {
            errorMessagesObj[err.name] = err.errors[0];
        });

        console.log(errorMessagesObj);

        setErrorMessages({ ...errorMessagesObj });
    };

    /**
     * Helper that clears errors message from optional new fields
     * @param {*} fieldName
     * @param {*} msg
     */
    const handleClearErrorMessageFromRSForm = (fieldName, emptyMsg) => {
        setErrorMessages((prev) => {
            prev[fieldName] = emptyMsg;
            return { ...prev };
        });
    };

    /**
     * Helper method for handle new field from NewFieldForm.
     * using in Form.Provider when NewFieldForm is submitted
     */
    const handleOnFormFinish = (name, { values, forms }) => {
        if (name !== 'newFieldForm') {
            return;
        }

        // get newFieldsArr from 'roomSetting' form
        // if it does'n exist, create one
        const newFieldsArr = form.getFieldValue('newFields') ?? [];

        // -------------------------------------------------------------
        // Not allow add New fields if they have the same keys with
        // - those are got from api.
        // - those have been added.
        // -------------------------------------------------------------

        let hasFieldInCollection = false;
        // loop and check
        fieldsOfForm.forEach((v) => {
            const hasCurrentFieldInNewFieldCollection = Object.hasOwn(
                fields(currentRoom)['newFieldCollection']?.value,
                values.nf_new_field,
            );

            const hasCurrentFieldBeenAdded = v.name[0] === values.nf_new_field;

            if (hasCurrentFieldBeenAdded || hasCurrentFieldInNewFieldCollection) {
                hasFieldInCollection = true;
            }
        });

        // check whether this array has a field that exists, if have, find the index
        const index = newFieldsArr?.findLastIndex((field) => field.nf_new_field === values.nf_new_field);
        // set error message
        if (index !== -1 || hasFieldInCollection) {
            setMsgHasSameField(`${t('this_field_already_exists')}!`);
            return;
        }

        // name of roomSetting form
        const { roomSetting } = forms;
        // set newFields array to roomSetting
        roomSetting.setFieldsValue({ newFields: [...newFieldsArr, values] });
        // reset state of hasSaneFieldMsg
        setMsgHasSameField('');
        // close NewFieldForm
        setOpen(false);
    };

    /**
     * Helper for resetting state of hasSameFieldMsg, that support for rendering NewFieldForm
     */
    useEffect(() => {
        if (msgHasSameField.length > 0) {
            setMsgHasSameField('');
        }
    }, [msgHasSameField]);

    /**
     * Helper for summit room setting form
     * ------------------------------------------------------
     * Note:
     *   all fields's value will be send to 'onFinish' function
     * ------------------------------------------------------
     */
    const [haveErrorFromOptionalField, setHaveErrorFromOptionalField] = useState(false)

    const handleOnOkModal = useCallback(() => {
        if (haveErrorFromOptionalField) {
            return;
        }

        setTimeout(() => {
            form.submit();
        });
    }, [haveErrorFromOptionalField]);

    /**
     * Render Modal Footer with Buttons
     */
    const renderModalFooter = () => {
        return (
            <Form.Item key="rs-modal-footer-fi" style={{ width: '100%', marginTop: '8px' }}>
                <Row key="rs-modal-footer-fi-r">
                    <Col key="rs-modal-footer-fi-r-c1" flex="20%" style={{ textAlign: 'left' }}>
                        <Button
                            key="rs-modal-footer-fi-r-c1-btn-add-field"
                            htmlType="button"
                            className={cx('btn-custom-ghost')}
                            onClick={() => showModalNewFieldForm()}
                            icon={<PlusOutlined />}
                        >
                            {t('new_field')}
                        </Button>
                    </Col>
                    <Col key="rs-modal-footer-fi-r-c2" flex="80%">
                        <Button
                            key="rs-modal-footer-fi-r-c1-btn-cancel"
                            htmlType="button"
                            className={cx('btn-custom-ghost')}
                            onClick={() => {
                                setShouldApplyAllRoom(false);
                                dispatch(setShouldApplySettingToAllRoomsViewerAction(false));
                                dispatch(clearModalStateModalAction());
                                // setTimeout(() => {
                                //     dispatch(doCallPostDataToolAction(true));
                                // });
                            }}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            key="rs-modal-footer-fi-r-c1-btn-apply-to-all-rooms"
                            htmlType="submit"
                            className={cx('btn-custom-ghost')}
                            onClick={() => {
                                handleOnOkModal();
                                setShouldApplyAllRoom(true);
                                dispatch(setShouldApplySettingToAllRoomsViewerAction(true));
                            }}
                        >
                            {t('apply_to_all_rooms')}
                        </Button>
                        <Button
                            key="rs-modal-footer-fi-r-c1-btn-apply-to-this-room"
                            type="primary"
                            htmlType="submit"
                            className={cx('btn-custom')}
                            onClick={() => {
                                handleOnOkModal();
                                setShouldApplyAllRoom(false);
                                dispatch(setShouldApplySettingToAllRoomsViewerAction(false));
                            }}
                        >
                            {t('apply_to_this_room')}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        );
    };

    // ==================================================================================
    // Close the Modal RoomItemSettingForm
    // when click button Send, should call api, and when finish, should, close the modal
    // ==================================================================================
    useEffect(() => {
        if (
            observeSaveFileStatusOfFileSliceSelector &&
            guardAutoSaveFileStatusOfFileSliceSelector === false &&
            IsApplyRoomSettingSuccessSelector
        ) {
            dispatch(setObserveSaveFileStatusOfFileSliceViewerAction(false));
            dispatch(setIsApplyRoomSettingSuccess(false));
            dispatch(clearModalStateModalAction());
        }

        if (
            observeSaveFileStatusOfFileSliceSelector &&
            guardAutoSaveFileStatusOfFileSliceSelector === true &&
            doCallPostData
        ) {
            dispatch(setObserveSaveFileStatusOfFileSliceViewerAction(false));
            dispatch(doCallPostDataToolAction(false));
            dispatch(clearModalStateModalAction());
        }
    }, [observeSaveFileStatusOfFileSliceSelector, guardAutoSaveFileStatusOfFileSliceSelector, doCallPostData]);

    // ==================================================================================
    // Render RoomItemSettingForm
    // ==================================================================================
    const bodyStyle = useMemo(() => {
        const newFieldCollectionSize = Object.values(
            Object.values(fields(currentRoom)['newFieldCollection'])[1],
        ).length;

        return {
            padding: '10px 20px 0px 20px',
            height: newFieldCollectionSize > 0 ? '620px' : '550px',
            overflow: 'auto',
        };
    }, []);

    return (
        <div>
            <Modal
                key="room-setting-modal"
                title={`${t(`room`)} ${currentRoom?.name}`}
                open={modalOpen && modalData.title === 'room-setting'}
                onCancel={() => {
                    setShouldApplyAllRoom(false);
                    dispatch(setShouldApplySettingToAllRoomsViewerAction(false));
                    dispatch(clearModalStateModalAction());
                    // setTimeout(() => {
                    //     dispatch(doCallPostDataToolAction(true));
                    // });
                }}
                onOk={() => {
                    handleOnOkModal();
                }}
                footer={[<div key="rs-modal-footer">{renderModalFooter()}</div>]}
                // maskClosable={false}
                bodyStyle={bodyStyle}
                width="800px"
            >
                {/* Show app loading after click Send button */}
                {isFetchingSaveFile && <AppLoading menuTool />}

                {/* Render Main Form data here */}
                <Form.Provider
                    onFormFinish={(name, { values, forms }) => {
                        handleOnFormFinish(name, { values, forms });
                    }}
                >
                    <Form
                        name="roomSetting"
                        key="room-setting-form"
                        form={form}
                        style={{ textTransform: 'none' }}
                        layout="horizontal"
                        onFinish={async (values) => {
                            await onFinish(values);
                        }}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        preserve={false}
                        fields={fieldsOfForm}
                        onFieldsChange={async (_, allFields) => {
                            await setFieldsOfForm(allFields);
                        }}
                    >
                        <Row>
                            <Col>
                                <FormItemBaseFields fields={fields(currentRoom)} errorMessages={errorMessages} />
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: '16px' }}>
                            <Col style={{ width: '100%' }}>
                                <FormItemOptionalFields
                                    fields={fields(currentRoom)}
                                    errorMessages={errorMessages}
                                    handleClearErrorMessageFromRSForm={handleClearErrorMessageFromRSForm}
                                    hasErrorInFormItemOptionalFields={(value) => setHaveErrorFromOptionalField(value)}
                                />
                            </Col>
                        </Row>
                    </Form>

                    <NewFieldForm open={open} onCancel={hideModalNewFieldForm} msgHasSameField={msgHasSameField} />
                </Form.Provider>
            </Modal>
        </div>
    );
}

import { createSlice } from '@reduxjs/toolkit';
import { releaseProtectedData, removeProtectedData, saveProtectedData } from '~/helper/common';

/**
 * Default state of symbol slice
 */
export const initialState = {
    symbolList: [],
    protectedSymbolsData: [],
};

/**
 * ***************************************************************************
 * Create a symbol slice
 * ***************************************************************************
 */
const symbolSlice = createSlice({
    name: 'symbol',
    initialState,
    reducers: {
        /**
         * ACTION: add a symbol object to symbolList
         * @param {*} state   => base state
         * @param {*} payload  => symbol object
         */
        setSymbolList: (state, { payload }) => {
            state.symbolList = payload;
        },

        addSymbolSymbolAction: (state, { payload }) => {
            state.symbolList.unshift(payload);
        },

        /**
         * ACTION: delete a symbol with specified symbol's id in symbolList
         * @param {*} state   => base state
         * @param {*} payload  => symbol's id
         */
        removeSymbolByIdSymbolAction: (state, { payload }) => {
            state.symbolList = state.symbolList.filter((symbol) => symbol.id !== payload);
        },

        /**
         * ACTION: clear state of symbolList
         * @param {*} state   => base state
         */
        clearStateSymbolAction: (state) => {
            state.symbolList = [];
        },

        /**
         * ACTION: set state of protectedSymbolsData
         * protectedSymbolsData is an array that holds symbols data of each opened file,
         * useful when switch file between tabs in 'tool' page
         * @param {*} state   => base state
         * @param {*} payload  => the symbols data
         */
        saveProtectedSymbolsDataSymbolAction: (state, { payload }) => {
            state.protectedSymbolsData = saveProtectedData(state.protectedSymbolsData, payload);
        },

        /**
         * ACTION: release the data from protectedSymbolsData
         * the data of corresponding opened file.
         * @param {*} state   => base state
         * @param {*} payload  => the opened file's Id
         */
        releaseProtectedSymbolsDataSymbolAction: (state, { payload }) => {
            const result = releaseProtectedData(state.protectedSymbolsData, payload);
            state.symbolList = result?.symbolList || [];
        },

        /**
         * ACTION: delete the data of symbols of corresponding file's Id of protectedSymbolsData
         * @param {*} state   => base state
         * @param {*} payload  => the opened file's Id
         */
        removeProtectedSymbolsDataSymbolAction: (state, { payload }) => {
            state.protectedSymbolsData = removeProtectedData(state.protectedSymbolsData, payload);
        },

        /**
         * ACTION: set the state of protectedSymbolsData to default
         * @param {*} state   => base state
         */
        clearProtectSymbolsDataSymbolAction: (state) => {
            state.protectedSymbolsData = [];
        },
    },
});

/**
 * SELECTOR: current state of symbol slice
 * @param {*} state
 * @returns
 */
export const selectSymbols = (state) => state.symbol;

// ============================================================================
// Export Actions
// ============================================================================
export const {
    setSymbolList,
    addSymbolSymbolAction,
    removeSymbolByIdSymbolAction,
    clearStateSymbolAction,
    saveProtectedSymbolsDataSymbolAction,
    releaseProtectedSymbolsDataSymbolAction,
    removeProtectedSymbolsDataSymbolAction,
    clearProtectSymbolsDataSymbolAction,
} = symbolSlice.actions;

// ============================================================================
// Export Reducer
// ============================================================================
export default symbolSlice.reducer;

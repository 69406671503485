import { CommandViewer } from '../commandViewer';

export class ToggleRoomsInViewer extends CommandViewer {
    constructor(rooms, isCheckAll) {
        super();
        this.rooms = rooms;
        this.isNewOpenAll = isCheckAll;
        this.isOldOpenAll = !isCheckAll;
    }

    undo() {
        // this.rooms.setExtraOpenAll(this.isOldOpenAll)
        this.rooms.toggleRoomsInViewer(this.isOldOpenAll);
    }

    redo() {
        this.rooms.toggleRoomsInViewer(this.isNewOpenAll);
    }
}

export class AddRoomInViewer extends CommandViewer {
    constructor(rooms, newPolygon) {
        super();
        this.rooms = rooms;
        this.newPolygon = newPolygon;
        this.newPolygonId = newPolygon.id;
    }

    undo() {
        this.rooms.deleteRoomInViewer(this.newPolygonId);
    }

    redo() {
        this.rooms.addRoomInViewer(this.newPolygon);
    }
}

export class DeleteRoomInViewer extends CommandViewer {
    constructor(rooms, roomId) {
        super();
        this.rooms = rooms;
        this.roomId = roomId;
        this.oldRoom = this.rooms.getRoomById(roomId);
    }

    undo() {
        this.rooms.addRoomInViewer(this.oldRoom);
    }

    redo() {
        this.rooms.deleteRoomInViewer(this.roomId);
    }
}

export class ChangeRoomPointsInViewer extends CommandViewer {
    constructor(rooms, changedRoom) {
        super();
        this.rooms = rooms;

        this.changedRoom = changedRoom;
        this.oldRoom = this.rooms.getRoomById(changedRoom.id);
    }

    undo() {
        this.rooms.changeRoomPointsInViewer(this.oldRoom);
    }

    redo() {
        this.rooms.changeRoomPointsInViewer(this.changedRoom);
    }
}

/* eslint max-classes-per-file:0 */

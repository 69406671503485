import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '~/api';

/**
 * ACTION: call api to get all files that should be deleted in recycle bin
 */
export const getBinFile = createAsyncThunk('file/getBin', async (payload, thunkAPI) => {
    try {
        const response = await api.get('api/v1/user/File', {
            params: {
                IsDeleted: true,
            },
        });
        if (response.status < 400) {
            return response.data;
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';
import { deleteCustomer, getGeneralList, updateCustomer, uploadCustomer } from './generalAction';

const generalSlice = createSlice({
    name: 'general',
    initialState: {
        customerList: null,
        operatorList: null,
        locationsList: null,
        isFetching: false,
        isSuccessUpdateCustomer: false,
    },
    reducers: {
        setCustomerList: (state, { payload }) => {
            state.customerList = payload;
        },
        controlFetchingGeneral: (state, { payload }) => {
            state.isFetching = payload;
        },
    },
    extraReducers: {
        [getGeneralList.pending]: (state) => {
            state.isFetching = true;
        },
        [getGeneralList.fulfilled]: (state, { payload }) => {
            state.customerList = [];
            state.operatorList = [];
            state.locationsList = [];
            payload.forEach((item) => {
                if (item.type === 0) {
                    state.customerList.push({ ...item, key: item.id });
                }
                if (item.type === 1) {
                    state.locationsList.push({ ...item, key: item.id });
                }
                if (item.type === 2) {
                    state.operatorList.push({ ...item, key: item.id });
                }
            });
            state.isFetching = false;
        },
        [getGeneralList.rejected]: (state) => {
            state.customerList = [];
            state.operatorList = [];
            state.locationsList = [];
            state.isFetching = false;
        },
        [uploadCustomer.pending]: (state) => {
            state.isFetching = true;
        },
        [uploadCustomer.fulfilled]: (state, { payload }) => {
            if (payload.type === 0) {
                state.customerList.push({
                    key: payload.id,
                    ...payload,
                });
            }
            if (payload.type === 1) {
                state.locationsList.push({
                    key: payload.id,
                    ...payload,
                });
            }
            if (payload.type === 2) {
                state.operatorList.push({
                    key: payload.id,
                    ...payload,
                });
            }
            state.isFetching = false;
        },
        [uploadCustomer.rejected]: (state) => {
            state.isFetching = false;
        },
        [updateCustomer.pending]: (state) => {
            state.isFetching = true;
            state.isSuccessUpdateCustomer = false;
        },
        [updateCustomer.fulfilled]: (state, { payload }) => {
            let index;
            const uuid = payload?.id;

            state.isFetching = false;
            state.isSuccessUpdateCustomer = true;

            // Guard when customer list is null
            if (!state.customerList) {
                return;
            }

            switch (payload.type) {
                case 0:
                    index = state.customerList.findIndex((item) => item.id === uuid);
                    state.customerList.splice(index, 1, {
                        key: uuid,
                        ...payload,
                    });
                    return;
                case 1:
                    index = state.locationsList.findIndex((item) => item.id === uuid);
                    state.locationsList.splice(index, 1, {
                        key: uuid,
                        ...payload,
                    });
                    return;
                case 2:
                    index = state.operatorList.findIndex((item) => item.id === uuid);
                    state.operatorList.splice(index, 1, {
                        key: uuid,
                        ...payload,
                    });
                    return;
                default:
                    return;
            }
        },
        [updateCustomer.rejected]: (state) => {
            state.isFetching = false;
            state.isSuccessUpdateCustomer = false;
        },
        [deleteCustomer.pending]: (state) => {
            state.isFetching = true;
        },
        [deleteCustomer.fulfilled]: (state, { payload }) => {
            if (payload.type === 0) {
                const index = state.customerList.findIndex((item) => item.id === payload.id);
                state.customerList.splice(index, 1);
            }
            if (payload.type === 1) {
                const index = state.locationsList.findIndex((item) => item.id === payload.id);
                state.locationsList.splice(index, 1);
            }
            if (payload.type === 2) {
                const index = state.operatorList.findIndex((item) => item.id === payload.id);
                state.operatorList.splice(index, 1);
            }
            state.isFetching = false;
        },
        [deleteCustomer.rejected]: (state) => {
            state.isFetching = false;
        },
    },
});

export const generalSelector = (state) => state.general;
const { actions, reducer } = generalSlice;
export const { setCustomerList, controlFetchingGeneral } = actions;
export default reducer;

/* eslint-disable no-extra-boolean-cast */
/* eslint-disable require-yield */
/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit';

import { releaseProtectedData, removeProtectedData, saveProtectedData } from '~/helper/common';
import { getMinMaxOfCoordinates, mapCoordinates, transformPolygonToRoom } from '~/helper/ForgeViewer/forge-viewer-tool';
// import { fetchFloorBoundingBox } from './viewer.action';

/**
 * Default state of viewer slice
 */
export const initialState = {
    rooms: null,
    scaleModel: 1,
    countRoom: 0,
    haveViewer: false,
    isFetching: false,
    isSuccess: false,
    protectDataRooms: [],
    activeEditRoom: false,
    hasReleasedProtectData: false,
    observeSaveFileStatusOfFileSlice: false,
    shouldAlarmSystemBeInstalled: false,
    shouldDrawingCenteredBMZ: false,
    shouldApplySettingToAllRooms: false,
    currentRoomSettingId: '',
    guardAutoSaveFileStatusOfFileSlice: false,
    floorSettingsToolData: null,
    isBackToMenuTool: false,
    IsApplyRoomSettingSuccess: false,
    floorBoundingBox: null,
};

/**
 * ***************************************************************************
 * Create a viewer slice
 * ***************************************************************************
 */
const viewerSlice = createSlice({
    name: 'viewer',
    initialState,
    reducers: {
        /**
         * ACTION: set state of floorBoundingBox
         * @param {*} state  => base state
         * @param {*} payload => Array of min, max points
         */
        setFloorBoundingBox: (state, { payload }) => {
            const layerBoxPoints = payload?.map((point) => mapCoordinates(point));
            const {
                minX,
                minY,
                maxX,
                maxY,
            } = getMinMaxOfCoordinates(layerBoxPoints);

            const minP = {
                x: minX,
                y: minY
            }
            const maxP = {
                x: maxX,
                y: maxY,
            }

            state.floorBoundingBox = { minP, maxP }
        },
        /**
         * ACTION: set state of IsApplyRoomSettingSuccess
         * @param {*} state  => base state
         * @param {*} payload => true of false
         */
        setIsApplyRoomSettingSuccess: (state, { payload }) => {
            state.IsApplyRoomSettingSuccess = payload;
        },

        /**
         * ACTION: set state of isBackToMenuTool
         * @param {*} state  => base state
         * @param {*} payload => true of false
         */
        setIsBackToMenuToolViewerAction: (state, { payload }) => {
            state.isBackToMenuTool = payload;
        },

        /**
         * ACTION: set state of floorSettingsToolData
         * @param {*} state  => base state
         * @param {*} payload => floorSettingsToolData
         */
        setFloorSettingsToolDataViewerAction: (state, { payload }) => {
            state.floorSettingsToolData = payload;
        },

        /**
         * ACTION: set state of currentRoomSettingId
         * @param {*} state  => base state
         * @param {*} payload => roomId
         */
        setCurrentRoomSettingIdViewerAction: (state, { payload }) => {
            state.currentRoomSettingId = payload;
        },

        /**
         * ACTION: set state of shouldApplySettingToAllRooms
         * @param {*} state  => base state
         * @param {*} payload => true or false
         */
        setShouldApplySettingToAllRoomsViewerAction: (state, { payload }) => {
            state.shouldApplySettingToAllRooms = payload;
        },

        /**
         * ACTION: set state of shouldDrawingCenteredBMZ
         * @param {*} state  => base state
         * @param {*} payload => true or false
         */
        setShouldDrawingCenteredBMZViewerAction: (state, { payload }) => {
            state.shouldDrawingCenteredBMZ = payload;
        },

        /**
         * ACTION: set state of shouldAlarmSystemBeInstalled
         * @param {*} state  => base state
         * @param {*} payload => true or false
         */
        setShouldAlarmSystemBeInstalledViewerAction: (state, { payload }) => {
            state.shouldAlarmSystemBeInstalled = payload;
        },

        /**
         * ACTION: set state of observeSaveFileStatusOfFileSlice
         * @param {*} state  => base state
         * @param {*} payload => true or false
         */
        setObserveSaveFileStatusOfFileSliceViewerAction: (state, { payload }) => {
            state.observeSaveFileStatusOfFileSlice = payload;
        },

        /**
         * ACTION: set state of guardAutoSaveFileStatusOfFileSlice
         * @param {*} state  => base state
         * @param {*} payload => true or false
         */
        setGuardAutoSaveFileStatusOfFileSliceViewerAction: (state, { payload }) => {
            state.guardAutoSaveFileStatusOfFileSlice = payload;
        },

        /**
         * ACTION: set state of activeEditRoom
         * @param {*} state  => base state
         * @param {*} payload => true or false
         */
        setActiveEditRoomViewerAction: (state, { payload }) => {
            state.activeEditRoom = payload;
        },

        /**
         * ACTION: set state of haveViewer
         * @param {*} state => base state
         * @param {*} payload => true or false
         */
        setViewerLoadSuccessViewerAction: (state, { payload }) => {
            state.haveViewer = payload;
        },

        /**
         * ACTION: set state of rooms and countRoom
         * @param {*} state  => base state
         * @param {*} payload => an object that includes rooms and number of room
         */
        setEditedRoomsViewerAction: (state, { payload }) => {
            state.rooms = payload.rooms;
            state.countRoom = payload.length;
        },

        /**
         * ACTION: set state of rooms data (from api call)
         * @param {*} state  => base state
         * @param {*} payload => an object that includes rooms and number of room
         */
        initListRoomViewerAction: (state, { payload }) => {
            if (!!payload) {
                state.countRoom = 0;
                const arr = payload.map((room, index) => {
                    let roomName = room.name;

                    if (!roomName) {
                        roomName = `Id: ${index + 1}`;
                    }

                    const roomBefore = {
                        name: roomName,
                        id: room.roomId,
                        roomId: room.roomId,
                        polyId: room.roomId,
                        isOpen: false,
                        isLayer: true,
                        scale: room.scale,
                        currentPoints: room.currentPoints,
                        boxPoints: room.boxPoints,
                        roomSetting: room?.roomSetting || {},
                    };

                    const roomAfter = transformPolygonToRoom(roomBefore);

                    return {
                        id: roomAfter.roomId,
                        roomId: roomAfter.roomId,
                        currentPoints: roomAfter.currentPoints,
                        boxPoints: roomAfter.boxPoints,
                        scale: roomAfter.scale,
                        layerCurrentPoints: roomAfter.layerCurrentPoints,
                        layerBoxPoints: roomAfter.layerBoxPoints,
                        polyId: roomAfter.polyId,
                        name: roomAfter.name,
                        width: roomAfter.width,
                        widthLayer: roomAfter.widthLayer,
                        height: roomAfter.height,
                        heightLayer: roomAfter.heightLayer,
                        minX: roomAfter.minX,
                        minY: roomAfter.minY,
                        maxX: roomAfter.maxX,
                        maxY: roomAfter.maxY,
                        scaleLength: roomAfter.scaleLength,
                        isLayer: roomAfter.isLayer,
                        isOpen: roomAfter.isOpen,
                        roomSetting: roomAfter.roomSetting,
                    };
                });
                if (arr.length > 0) {
                    state.scaleModel = Math.max.apply(
                        Math,
                        arr.map((o) => o?.scale),
                    );
                }

                // set data to state
                state.rooms = arr;
                state.countRoom = arr.length;

                // Set get rooms data success
                state.isSuccess = true;
            }
        },

        /**
         * ACTION: update room setting for each room
         * @param {*} state  => base state
         * @param {*} payload => roomSetting object data
         */
        updateRoomSettingForEachRoomViewerAction: (state, { payload }) => {
            if (state.rooms && state.rooms.length > 0) {
                state.rooms = [...state.rooms].map((room) => {
                    room.roomSetting = payload;
                    return { ...room };
                });
            }
        },

        /**
         * ACTION: update room setting by roomId
         * @param {*} state  => base state
         * @param {*} payload => roomSetting with specify id
         */
        updateRoomSettingByRoomIdViewerAction: (state, { payload }) => {
            const { roomSetting, roomId } = payload;

            state.rooms = [...state.rooms].map((room) => {
                if (room.roomId === roomId) {
                    room.roomSetting = roomSetting;
                    return { ...room };
                }
                return { ...room };
            });
        },

        /**
         * ACTION: set state of isSuccess after get list room successfully to default
         * @param {*} state  => base state
         */
        resetIsSuccessGetListRoomViewerAction: (state) => {
            state.isSuccess = false;
        },

        /**
         * ACTION: set state of viewer slice to default
         * @param {*} state  => base state
         */
        clearStateViewerAction: (state) => {
            state.rooms = null;
            state.scaleModel = 1;
            state.countRoom = 0;
            state.haveViewer = false;
            state.isFetching = false;
            state.isSuccess = false;

            state.activeEditRoom = false;
            state.observeSaveFileStatusOfFileSlice = false;
            state.shouldAlarmSystemBeInstalled = false;
            state.shouldDrawingCenteredBMZ = false;
            state.shouldApplySettingToAllRooms = false;
            state.IsApplyRoomSettingSuccess = false;

            state.floorSettingsToolData = null;
            state.isBackToMenuTool = false;

            state.floorBoundingBox = null;
        },

        clearProtectData: (state) => {
            state.protectDataRooms = null;
        },

        /**
         * ACTION: set state of protectDataRooms
         * protectDataRoom is an array that holds rooms data of each opened file,
         * useful when switch file between tabs in 'tool' page
         * @param {*} state   => base state
         * @param {*} payload  => the rooms data
         */
        saveProtectDataRoomsViewerAction: (state, { payload }) => {
            state.protectDataRooms = saveProtectedData(state.protectDataRooms, payload);
        },

        /**
         * ACTION: release the data from protectDataRoom,
         * the data of corresponding opened file.
         * @param {*} state   => base state
         * @param {*} payload  => the opened file's Id
         */
        releaseProtectDataRoomsByFileIdViewerAction: (state, { payload }) => {
            if (state.protectDataRooms) {
                const result = releaseProtectedData(state.protectDataRooms, payload);
                if (result) {
                    state.rooms = result?.rooms ?? null;
                    state.scaleModel = result?.scaleModel ?? 1;
                    state.countRoom = result?.countRoom ?? 0;
                    state.haveViewer = result?.haveViewer ?? false;
                    state.floorBoundingBox = result?.floorBoundingBox ?? null;
                }
            }
        },

        /**
         * ACTION: helper for checking has protectDataRoom been released
         * @param {*} state   => base state
         * @param {*} payload  => true or false
         */
        setHasReleasedProtectDataRoomsViewerAction: (state, { payload }) => {
            state.hasReleasedProtectData = payload;
        },

        /**
         * ACTION: delete the data of rooms of corresponding file's Id of protectDataRoom
         * @param {*} state   => base state
         * @param {*} payload  => the opened file's Id
         */
        removeProtectDataRoomByFileIdViewerAction: (state, { payload }) => {
            state.protectDataRooms = removeProtectedData(state.protectDataRooms, payload);
        },

        /**
         * ACTION: set the state of protectDataRoom to default
         * @param {*} state   => base state
         */
        clearProtectDataRoomsViewerAction: (state) => {
            state.protectDataRooms = [];
        },

        /**
         * ACTION: set the state of isFetching (during api call to get rooms data)
         * @param {*} state   => base state
         * @param {*} payload  => true of false
         */
        controlIsFetchingViewerAction: (state, { payload }) => {
            state.isFetching = payload;
        },
    }
});

// ============================================================================
// Export Actions
// ============================================================================
export const {
    setEditedRoomsViewerAction,
    initListRoomViewerAction,
    setViewerLoadSuccessViewerAction,
    clearStateViewerAction,
    clearProtectData,
    saveProtectDataRoomsViewerAction,
    releaseProtectDataRoomsByFileIdViewerAction,
    removeProtectDataRoomByFileIdViewerAction,
    clearProtectDataRoomsViewerAction,
    controlIsFetchingViewerAction,
    updateRoomSettingForEachRoomViewerAction,
    updateRoomSettingByRoomIdViewerAction,
    resetIsSuccessGetListRoomViewerAction,
    setActiveEditRoomViewerAction,
    setHasReleasedProtectDataRoomsViewerAction,
    setObserveSaveFileStatusOfFileSliceViewerAction,
    setShouldAlarmSystemBeInstalledViewerAction,
    setShouldDrawingCenteredBMZViewerAction,
    setShouldApplySettingToAllRoomsViewerAction,
    setCurrentRoomSettingIdViewerAction,
    setGuardAutoSaveFileStatusOfFileSliceViewerAction,
    setFloorSettingsToolDataViewerAction,
    setIsBackToMenuToolViewerAction,
    setIsApplyRoomSettingSuccess,
    setFloorBoundingBox,
} = viewerSlice.actions;

// ============================================================================
// Export Reducer
// ============================================================================
export default viewerSlice.reducer;

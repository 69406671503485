/* eslint-disable no-restricted-syntax */
/* eslint-disable no-else-return */
/* eslint-disable camelcase */
/* eslint-disable dot-notation */
/* eslint-disable no-prototype-builtins */
/* eslint-disable eqeqeq */
/* eslint-disable no-new */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */

import styles from '../../ToolViewer.module.scss';
import AlarmDeviceList from './AlarmDeviceList';
import { Button, Checkbox, Divider, Empty, message, notification, Popconfirm, Result } from 'antd';
import classNames from 'classnames/bind';
import jcc from 'json-case-convertor';
import PropsTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import AppLoading from '~/components/AppLoading';
import { customMemo } from '~/helper/common';
import { toggleWiringAlarm } from '~/helper/ForgeViewer/connected-line/common';
import {
    draw_symbol_and_circle,
    getCenterPointOfPolygon,
    getLayerLength,
    getLengthFromAPI,
    getUnitsNumber,
    insertDeviceSymbol,
    insidePoly,
    mapCoordinates,
    mapCoordinatesToModel,
    removeDevice,
} from '~/helper/ForgeViewer/forge-viewer-tool';
import { handleRadiusErase } from '~/helper/ForgeViewer/radius-erase';
import ListAlarmDevice from '~/helper/ForgeViewer/speam/alarmDevices/alarmDevice';
import { getAlarm } from '~/store/alarm-device/alarmAction';
import {
    controlIsFetching,
    controlIsSuccess,
    deleteDeviceAction,
    selectAlarmDevice,
    setAlarmInfos,
    setDevices,
    setOutSideRoom,
} from '~/store/alarm-device/alarmDeviceSlice';
import { selectToolState } from '~/store/tool/toolSlice';
import { makeViewerRoomsSelector } from '~/store/viewer/viewer.selectors';
import { algorithmSupport, fetchSortedPointsWireAlarmAction } from '~/store/wire-alarm/wireAlarm.actions';
import {
    makeHashOfBMZWASelector,
    makeIsFetchingWASelector,
    makeIsShowWireWASelector,
    makeSortedWireAlarmPointsWASelector,
} from '~/store/wire-alarm/wireAlarm.selectors';
import {
    resetSortedPointsFromApiWireAlarmAction,
    setIsShowWireWireAlarmAction,
    setWirePerimeter,
} from '~/store/wire-alarm/wireAlarm.slice';

const cx = classNames.bind(styles);

function AlarmDeviceTool({ changeOpenSubTool, isOpen }) {
    // Management render state
    const [emitter, setEmitter] = useState(0);
    const [isNoRoom, setIsNoRoom] = useState(true);
    const [dataRoom, setDataRoom] = useState([]);
    const [dataAlarm, setDataAlarm] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [deviceOutSide, setDeviceOutSide] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [showListDevice, setShowListDevice] = useState(false);
    const [checkAll, setCheckAll] = useState(true);
    const [isReloadData, setIsReloadData] = useState(false);

    const { alarmInfos, devices, unit, isSuccess, isFetching, deleteDeviceByKeyPress, activeEditDevice, outSideList } =
        useSelector(selectAlarmDevice);
    const { wrongUnit } = useSelector(selectAlarmDevice);

    const [checked, setChecked] = useState(outSideList.length > 0 ?? true);

    // ===========================================
    const { toggleAllTools, data } = useSelector(selectToolState);
    // ===========================================

    // From viewer selectors
    const roomsSelector = useSelector(makeViewerRoomsSelector());
    // wire alarm state
    const hashOfBMZWASelector = useSelector(makeHashOfBMZWASelector());
    const isShowWireAlarmSelector = useSelector(makeIsShowWireWASelector());
    const sortedWireAlarmPointsSelector = useSelector(makeSortedWireAlarmPointsWASelector());
    const isFetchingWireAlarmSelector = useSelector(makeIsFetchingWASelector());

    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language || 'en');


    const dispatch = useDispatch();

    useEffect(() => {
        const notCheck = [...deviceList, ...deviceOutSide].filter((device) => device.isOpen === false);

        // console.log('--------------notChecK-------------', notCheck)
        if (!notCheck || notCheck.length < 1) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }

        if (deviceOutSide.length === 0) {
            setDisabled(true);
            setChecked(false);
        } else {
            setDisabled(false);
        }

        dispatch(setOutSideRoom(deviceOutSide));
        dispatch(setDevices(deviceList));
    }, [deviceOutSide, deviceList, dataRoom, checkAll]);

    useEffect(() => {
        if (roomsSelector?.length > 0) {
            setIsNoRoom(false);
            const newRoomsData = roomsSelector.map((room) => ({ ...room, isActive: true }));
            setDataRoom(newRoomsData);
        } else {
            setIsNoRoom(true);
        }
    }, [roomsSelector]);

    useEffect(() => {
        if (deleteDeviceByKeyPress !== null) {
            confirmDeleteOutSide(deleteDeviceByKeyPress);
            confirmDelete(deleteDeviceByKeyPress);
            dispatch(deleteDeviceAction(null));
        }
    }, [deleteDeviceByKeyPress]);

    useEffect(() => {
        window.addEventListener('endDragCircle', logicDragDevice);

        return () => {
            window.removeEventListener('endDragCircle', logicDragDevice);
        };
    }, [deviceOutSide, dataRoom, deviceList, activeEditDevice, window?.tools?.moveTool?.shapes, isOpen]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: t('Successful!'),
            });
            setShowListDevice(false);

            deviceList.forEach((device) => {
                removeDevice(device.deviceId || device.id);
            });

            const camelAlarmInfoList = jcc.camelCaseKeys(alarmInfos);

            const newAlarmData = camelAlarmInfoList.map((value, index) => {
                let scaleLength = dataRoom[index]?.scaleLength || 1;

                const unitsNumberAfter = getUnitsNumber(unit);

                scaleLength /= unitsNumberAfter;

                const distanceSmokeAlarmXLayer = getLengthFromAPI(value.distanceSmokeAlarmX, scaleLength);
                const distanceSmokeAlarmYLayer = getLengthFromAPI(value.distanceSmokeAlarmY, scaleLength);
                const distanceWallXLayer = getLengthFromAPI(value.distanceWallX, scaleLength);
                const distanceWallYLayer = getLengthFromAPI(value.distanceWallY, scaleLength);
                const radiusLayer = getLengthFromAPI(value.radius, scaleLength);
                return {
                    ...value,
                    roomId: value.guid,
                    width: Number(value.width),
                    length: Number(value.length),
                    distanceSmokeAlarmXLayer,
                    distanceSmokeAlarmYLayer,
                    distanceWallXLayer,
                    distanceWallYLayer,
                    radiusLayer,
                };
            });
            // Set

            setDataAlarm([...newAlarmData]);
            dispatch(controlIsSuccess(false));
        }
    }, [isSuccess, data, unit]);

    /**
     * Helper for showing or hiding wire that connects alarm devices
     * - 'emitter' is a number, that is set to update each time 'deviceList' is update
     * - 'sortedWireAlarmPointsSelector' is the result of dispatch action fetchSortedPointsWireAlarmAction successfully
     */
    useEffect(() => {
        if (emitter > 3) {
            setEmitter(1);
        }
        const wireAlarmPoints = [...sortedWireAlarmPointsSelector];

        if (wireAlarmPoints && wireAlarmPoints.length > 0) {
            // State of isShowWireAlarmSelector change when user click 'Show/Hide wire' button
            if (isShowWireAlarmSelector) {
                // remove wire that is drawn
                toggleWiringAlarm(wireAlarmPoints, false);
                // draw new wire
                const perimeter = toggleWiringAlarm(wireAlarmPoints, true);
                // set perimeter of wire to store
                dispatch(setWirePerimeter(perimeter));
            } else {
                // remove wire that is drawn
                toggleWiringAlarm(wireAlarmPoints, false);
                // set state to default
                dispatch(resetSortedPointsFromApiWireAlarmAction());
                // reset wirePerimeter
                dispatch(setWirePerimeter(0));
            }
        }
    }, [emitter, sortedWireAlarmPointsSelector, isShowWireAlarmSelector]);

    //* Helper for calling wiring alarm algorithm from api
    useEffect(() => {
        if (dataRoom.length < 1 || deviceList.length < 1) {
            return;
        }
        if (!isShowWireAlarmSelector) {
            return;
        }

        // Preparing data that is needed for calling api
        const dataForWiringAlarm = dataRoom.map((a) => {
            const RoomId = a.roomId;
            const RoomPoints = a.layerCurrentPoints.map((r) => ({ X: r.x, Y: r.y, Z: 0 }));
            const cp = getCenterPointOfPolygon(a.layerCurrentPoints);
            const Center = { X: cp.x, Y: cp.y, Z: 0 };

            let listAlarm = deviceList.filter((alarm) => alarm.roomId === a.roomId);

            // the current room needs the data of BMZ symbol
            const bmzSymbols = Object.values(hashOfBMZWASelector).filter((v) => v.roomId === a.roomId);

            // add the bmzSymbol to the listAlarm
            if (bmzSymbols && bmzSymbols.length > 0) {
                listAlarm = [...listAlarm, ...bmzSymbols];
            }

            const DevicePoints = listAlarm.map((d) => ({ X: d.x, Y: d.y, Z: 0 }));

            return { RoomId, Center, RoomPoints, DevicePoints };
        });
        if (dataForWiringAlarm.length > 0) {
            const dataJson = {
                data: JSON.stringify(dataForWiringAlarm),
                type: '1' /* is an enum to execute 'Wiring Alarm Algorithm' */,
            };

            if (window.hitShape || isFetchingWireAlarmSelector) {
                handleEndDragDevice({ detail: window.hitShape });
            }

            // Calling api to fetch sorted wire alarm points
            dispatch(fetchSortedPointsWireAlarmAction({ data: dataJson, t }));
        }
    }, [dataRoom, deviceList, isShowWireAlarmSelector, hashOfBMZWASelector]);

    const logicDragDevice = (polygonToolEvent) => {
        const d = polygonToolEvent?.detail;
        const findRoom = handleEndDragDevice(d);
        if (d.isButtonDown && findRoom) {
            d.callback();
        }
    };

    const handleEndDragDevice = (d) => {
        let findRoom;
        if (d && (d?.devcieId || d?.id) && (d.type === 'device' || d.isAlarmDevice) && !isFetchingWireAlarmSelector) {
            const planeMesh = ListAlarmDevice.listPlaneMesh.find((p) => p.deviceId === d.deviceId);
            if (planeMesh) {
                if (planeMesh.isCircle) {
                    dataRoom?.map((room) => {
                        const isInsideRoom = insidePoly(room.layerCurrentPoints, planeMesh.position);
                        if (isInsideRoom) findRoom = room;
                    });

                    const device = deviceList.find((dv) => dv.id === planeMesh.deviceId);

                    if (findRoom) {
                        const oldShape = planeMesh.polygon;
                        const arr = [
                            {
                                ...planeMesh.position,
                                radius: planeMesh.radius,
                                deviceId: planeMesh.deviceId,
                                roomId: findRoom.roomId,
                                planeMesh,
                            },
                        ];

                        const result = handleRadiusErase(findRoom.layerCurrentPoints, arr, planeMesh);

                        if (device) {
                            const newDevice = {
                                ...device,
                                x: planeMesh.position.x,
                                y: planeMesh.position.y,
                                roomId: findRoom.roomId,
                                planeMesh: d.planeMesh,
                            };

                            new Promise(() => {
                                setDeviceOutSide(() =>
                                    deviceOutSide.filter((item) => item.deviceId !== device.deviceId),
                                );
                                setDeviceList((prev) => [
                                    ...prev.filter((dv) => dv.deviceId !== device.deviceId),
                                    newDevice,
                                ]);

                                // support for render this component the right way
                                setEmitter((prev) => prev + 1);
                            });
                        } else {
                            const insideRoom = deviceOutSide.find((item) => item.deviceId === planeMesh.deviceId);
                            const newItem = {
                                ...insideRoom,
                                x: planeMesh.position.x,
                                y: planeMesh.position.y,
                                roomId: findRoom.roomId,
                                planeMesh: d.planeMesh,
                            };
                            new Promise(() => {
                                setDeviceOutSide(() => deviceOutSide.filter((item) => item.id !== planeMesh.deviceId));
                                setDeviceList([newItem, ...deviceList]);
                                // support for render this component the right way
                                setEmitter((prev) => prev + 1);
                            });
                        }

                        if (result.length === 0) {
                            window.layer.removeShape(oldShape);
                            planeMesh.isCircle = false;
                            window.layer.update();
                        }
                    } else {
                        if (device) {
                            new Promise(() => {
                                setDeviceList(() =>
                                    deviceList.filter((item) => (item.id || item.deviceId) !== device.deviceId),
                                );

                                setDeviceOutSide([
                                    {
                                        x: planeMesh.position.x,
                                        y: planeMesh.position.y,
                                        isOpen: true,
                                        isAlarmDevice: true,
                                        coordId: device.coordId,
                                        deviceId: device.deviceId,
                                        id: device.deviceId,
                                        radius: device.radius,
                                        roomId: 0,
                                        planeMesh,
                                    },
                                    ...deviceOutSide,
                                ]);
                                // support for render this component the right way
                                setEmitter((prev) => prev + 1);
                            });
                        } else {
                            const deviceChange = deviceOutSide.find((item) => item.deviceId === planeMesh.deviceId);
                            const newDeviceOutSide = deviceOutSide.filter(
                                (item) => item.deviceId !== planeMesh.deviceId,
                            );

                            setDeviceOutSide([
                                {
                                    x: planeMesh.position.x,
                                    y: planeMesh.position.y,
                                    roomId: 0,
                                    radius: d.radius,
                                    id: planeMesh.deviceId,
                                    isOpen: true,
                                    ...deviceChange,
                                },
                                ...newDeviceOutSide,
                            ]);
                        }
                        setChecked(true);
                    }
                }
            }
        }
        return findRoom;
    };

    const handleDrawAll = (rD, dL, isFirst = false) => {
        if (isFirst) {
            rD.map((room) => {
                const listAlarm = dL.filter((alarm) => String(alarm.roomId) === String(room.roomId));
                const listCircleError = handleRadiusErase(room.layerCurrentPoints, listAlarm);
                listCircleError.map(async (circle) => {
                    insertDeviceSymbol(
                        { x: circle.x, y: circle.y },
                        circle.radius,
                        circle.deviceId,
                        String(circle.roomId),
                        null,
                        true,
                    );
                });
            });
        } else {
            new Promise(() => {
                dL.forEach((device) => {
                    const planeMesh = ListAlarmDevice.getPlane(device.deviceId);
                    if (planeMesh) {
                        let room = rD.find((r) => String(r.roomId) === String(device.roomId));
                        if (room) {
                            const isInsideOldRoom = insidePoly(room.layerCurrentPoints, planeMesh.position);
                            if (isInsideOldRoom) {
                                planeMesh.isRerender = true;
                                handleRadiusErase(room.layerCurrentPoints, [{ ...device }], planeMesh);
                                return;
                            }

                            room = null;
                            // eslint-disable-next-line no-restricted-syntax
                            for (const r of rD) {
                                const insideRoom = insidePoly(r.layerCurrentPoints, planeMesh.position);
                                if (insideRoom) {
                                    room = r;
                                    break;
                                }
                            }
                            if (room) {
                                planeMesh.isRerender = true;
                                handleRadiusErase(room.layerCurrentPoints, [{ ...device }], planeMesh);
                                // device.roomId = room.roomId;
                            } else {
                                // draw circle
                                draw_symbol_and_circle(planeMesh, true);
                                // device.roomId = null;
                            }
                        } else {
                            // draw circle

                            draw_symbol_and_circle(planeMesh, true);
                            // device.roomId = null;
                        }
                    }
                });
            });
        }
    };

    const getAlarmAction = useCallback(async () => {
        if (deviceList && deviceList.length > 0) {
            dispatch(setDevices([]));
            deviceOutSide.forEach((device) => removeDevice(device.deviceId || device.id));
            deviceList?.forEach((device) => removeDevice(device.deviceId || device.id));
            setDeviceOutSide([]);
            setDeviceList([]);
            ListAlarmDevice.cleanup();
        }

        const unitsNumber = getUnitsNumber(unit);

        const newData = roomsSelector.map((d) => ({
            GUID: d.roomId,
            width: d.width / unitsNumber,
            length: d.height / unitsNumber,
        }));

        // Will not show wire-alarm when click reload data
        dispatch(setIsShowWireWireAlarmAction(false));

        dispatch(
            getAlarm({
                rooms: newData,
            }),
        );
        setIsReloadData(true);
    }, [roomsSelector, deviceList, deviceOutSide, ListAlarmDevice, unit]);

    const getAlarmDevicesList = useCallback(() => {
        if (deviceList && deviceList.length > 0) {
            dispatch(setDevices([]));
            deviceList?.forEach((device) => removeDevice(device.deviceId || device.id));
            deviceOutSide.forEach((device) => removeDevice(device.deviceId || device.id));
            ListAlarmDevice.cleanup();
        }

        const parseData = jcc.camelCaseKeys(JSON.parse(data?.fileData));
        if (parseData.alarms && parseData.alarms.length > 0 && parseData.units) {
            dispatch(setAlarmInfos(parseData.alarms));
            dispatch(controlIsSuccess(true));
        } else {
            if (roomsSelector.length > 0) {
                getAlarmAction();
            }
        }
    }, [roomsSelector, data]);

    const autoSetAlarm = useCallback(async () => {
        deviceList?.forEach((device) => removeDevice(device.deviceId || device.id));
        deviceOutSide.forEach((device) => removeDevice(device.deviceId || device.id));
        ListAlarmDevice.cleanup();
        dispatch(controlIsFetching(true));

        const rooms = roomsSelector.map((room) => ({ ...room, isActive: true }));
        setDataRoom(rooms);

        const parseData = jcc.camelCaseKeys(JSON.parse(data?.fileData));

        if (parseData?.outSideList?.length > 0) {
            setDeviceOutSide(parseData.outSideList);
            draw_symbol_and_circle(parseData.outSideList.planeMesh, true);
        }

        if (parseData.devices && parseData.devices.length > 0 && !isReloadData) {
            setDeviceList([...parseData.devices]);
            // support for render this component the right way
            setEmitter((prev) => prev + 1);
            handleDrawAll(dataRoom, parseData.devices, true);
            setShowListDevice(true);
        } else {
            // TODO: call api for show alarm device
            // const result = showDataAlarm(dataAlarm, dataRoom);
            const result = await setDevicesAutomatically(dataRoom, dataAlarm);

            if (result.length === 0) {
                notification.warning({
                    message: 'Failed to show device! Check your draw unit in list rooms and reload!',
                    duration: 2,
                    placement: 'topRight',
                });
            }
            // setDeviceList(() => [...result.map((device) => ({ ...device, isOpen: true }))]);
            const resultAllOpen = result.map((device) => { return { ...device, isOpen: true }})

            setDeviceList([...resultAllOpen]);
            // support for render this component the right way
            setEmitter((prev) => prev + 1);

            handleDrawAll(dataRoom, resultAllOpen, true);
            setShowListDevice(true);
        }

        dispatch(controlIsFetching(false));
        setCheckAll(true);
        setIsReloadData(false);
    }, [roomsSelector, dataRoom, deviceList, showListDevice, dataAlarm, data, isReloadData]);

    const handleOpen = useCallback(
        (id, e) => {
            const newList = [...deviceList].map((device) => {
                const newDevice = { ...device };
                if (newDevice.roomId === id) {
                    newDevice.isOpen = e.target.checked;
                    handleShowDevice(newDevice, e.target.checked);
                }
                return { ...newDevice };
            });

            // support for render this component the right way
            setEmitter((prev) => prev + 1);

            const result = dataRoom.map((room) => {
                if (room?.roomId === id) {
                    room.isActive = !room.isActive;
                }
                return { ...room };
            });

            setDeviceList(newList);
            setDataRoom(result);
        },
        [deviceList, dataRoom],
    );

    const handleCheck = useCallback(
        (id, status) => {
            setDeviceList((prev) =>
                prev.map((item) => {
                    const newItem = { ...item };
                    if (newItem.id === id) {
                        newItem.isOpen = status;
                        handleShowDevice(newItem, status);
                    }
                    return { ...newItem };
                }),
            );
            // support for render this component the right way
            setEmitter((prev) => prev + 1);
        },
        [deviceList],
    );

    const handleShowDevice = (device, status) => {
        new Promise(() => {
            const planeMesh = ListAlarmDevice.getPlane(device.deviceId || device.id);
            if (planeMesh) {
                if (status) {
                    let room = dataRoom.find((r) => r.roomId === device.roomId);
                    if (room) {
                        const isInsideOldRoom = insidePoly(room.layerCurrentPoints, planeMesh.position);
                        if (isInsideOldRoom) {
                            planeMesh.isRerender = true;
                            handleRadiusErase(room.layerCurrentPoints, [{ ...device }], planeMesh);
                            return;
                        }

                        /// remove after check roomId when end drag
                        room = null;

                        for (const r of dataRoom) {
                            const insideRoom = insidePoly(r.layerCurrentPoints, planeMesh.position);
                            if (insideRoom) {
                                room = r;
                                break;
                            }
                        }
                        if (room) {
                            planeMesh.isRerender = true;
                            handleRadiusErase(room.layerCurrentPoints, [{ ...device }], planeMesh);
                        } else {
                            // draw circle
                            draw_symbol_and_circle(planeMesh, true);
                        }
                        /// remove after check roomId when end drag
                    } else {
                        // draw circle
                        draw_symbol_and_circle(planeMesh, true);
                    }
                } else {
                    planeMesh.isRerender = false;
                    removeDevice(device.deviceId || device.id);
                }
            }
            window.layer.update();
        });
    };

    const handleCheckAllChange = useCallback(async () => {
        const oldCheckAll = checkAll;
        dataRoom.forEach((room) => (room.isActive = !oldCheckAll));
        const newList = await deviceList.map((device) => {
            const newDevice = { ...device };
            if (!oldCheckAll) {
                newDevice.isOpen = true;
            } else {
                newDevice.isOpen = false;
            }
            return { ...newDevice };
        });

        if (!oldCheckAll) {
            await deviceOutSide.forEach((item) => removeDevice(item.deviceId));
            handleDrawAll(dataRoom, deviceList);
            const newList = await [...deviceOutSide].map((item) => {
                const newItem = { ...item };
                newItem.isOpen = !oldCheckAll;
                return { ...newItem };
            });

            newList.forEach(async (item) => {
                const planeMeshOld = ListAlarmDevice.getPlane(item.deviceId || item.id);
                if (!oldCheckAll) {
                    const planeMesh = await insertDeviceSymbol(
                        { x: planeMeshOld.position.x, y: planeMeshOld.position.y },
                        item.radius,
                        item.deviceId,
                        item.roomId,
                        null,
                        true,
                    );
                    if (planeMesh) {
                        draw_symbol_and_circle(planeMesh, false);
                    }
                } else {
                    removeDevice(item.deviceId);
                }
            });
            setDeviceOutSide(newList);
            setChecked(true);
        } else {
            await newList.forEach((device) => removeDevice(device.id || device.deviceId));
            await deviceOutSide.forEach((device) => removeDevice(device.deviceId || device.id));
            setChecked(false);
        }
        setCheckAll(!oldCheckAll);
        setChecked(!oldCheckAll);
        setDeviceList(newList);
    });

    // --- Control turn off All select when CHANGE LANGUAGE ---
    useEffect(() => {
        if (checkAll === true && currentLanguage !== i18n.language) {
            handleCheckAllChange().then(() => {
                setCurrentLanguage(i18n.language)

                if (isShowWireAlarmSelector) {
                    // disable show wire when change language
                    dispatch(setIsShowWireWireAlarmAction(false));
                }

            })
        }
    }, [currentLanguage, checkAll, i18n.language, isShowWireAlarmSelector, dispatch]);


    const confirmDelete = async (id) => {
        const newList = await deviceList.filter((device) => device.deviceId !== id);
        removeDevice(id);
        dispatch(setDevices(newList));
        setDeviceList(newList);
    };

    const handleCheckOutSideList = () => {
        const oldCheck = checked;
        setChecked(!oldCheck);
        const newList = deviceOutSide.map((item) => {
            const newItem = { ...item };

            newItem.isOpen = !oldCheck;
            handlCheckOutSideItem(item, !oldCheck);
            return { ...newItem };
        });

        setDeviceOutSide(newList);
    };

    const handlCheckOutSideItem = (device, status) => {
        const newList = [...deviceOutSide].map((item) => {
            const newItem = { ...item };
            if (newItem.deviceId === device.deviceId) {
                newItem.isOpen = status;
            }
            return { ...newItem };
        });

        newList.forEach(async (item) => {
            if (item.deviceId === device.deviceId) {
                const planeMeshOld = ListAlarmDevice.getPlane(device.deviceId || device.id);
                if (status) {
                    const planeMesh = await insertDeviceSymbol(
                        { x: planeMeshOld.position.x, y: planeMeshOld.position.y },
                        item.radius,
                        item.deviceId,
                        item.roomId,
                        null,
                        true,
                    );
                    if (planeMesh) {
                        draw_symbol_and_circle(planeMesh, false);
                    }
                } else {
                    removeDevice(item.deviceId);
                }
            }
        });
        setDeviceOutSide(newList);
    };

    const confirmDeleteOutSide = async (id) => {
        const newList = await deviceOutSide.filter((device) => device.deviceId !== id);
        removeDevice(id);
        dispatch(setOutSideRoom(newList));
        dispatch(setOutSideRoom(deviceOutSide));
        setDeviceOutSide(newList);
    };

    const showHideWire = useCallback(() => {
        dispatch(setIsShowWireWireAlarmAction(!isShowWireAlarmSelector));
    }, [isShowWireAlarmSelector]);

    const getListRoomData = (dataRoom = [], deviceList = [], bmz = undefined) => {
        // Preparing data that is needed for calling api
        return dataRoom.map((a) => {
            const RoomId = a.roomId;
            const RoomPoints = a.layerCurrentPoints.map((r) => ({ X: r.x, Y: r.y, Z: 0 }));
            const cp = getCenterPointOfPolygon(a.layerCurrentPoints);
            const Center = { X: cp.x, Y: cp.y, Z: 0 };

            if (deviceList?.length > 0 && bmz) {
                let listAlarm = deviceList.filter((alarm) => alarm.roomId === a.roomId);
                // the current room needs the data of BMZ symbol
                const bmzSymbols = Object.values(bmz).filter((v) => v.roomId === a.roomId);

                // add the bmzSymbol to the listAlarm
                if (bmzSymbols && bmzSymbols.length > 0) {
                    listAlarm = [...listAlarm, ...bmzSymbols];
                }

                const DevicePoints = listAlarm.map((d) => ({ X: d.x, Y: d.y, Z: 0 }));
                return { RoomId, Center, RoomPoints, DevicePoints };
            }

            return { RoomId, Center, RoomPoints, DevicePoints: [] };
        });
    };

    const setDevicesAutomatically = async (dataRoom = [], dataAlarm = []) => {
        if (dataAlarm) {
            const oldList = getListRoomData(dataRoom);

            const roomList = oldList.map((value) => {
                const center = mapCoordinatesToModel({ x: value.Center.X, y: value.Center.Y, z: value.Center.Z });
                return {
                    ...value,
                    Center: { X: center.x, Y: center.y, Z: 0 },
                    RoomPoints: value.RoomPoints.map(({ X, Y, Z }) => {
                        const newPoint = mapCoordinatesToModel({ x: X, y: Y, z: Z });
                        return { X: newPoint.x, Y: newPoint.y, Z: 0 };
                    }),
                };
            });

            const newDataAlarm = dataAlarm.map((value, index) => {
                const roomLayer = oldList[index];
                const roomModel = roomList[index];

                const point1Layer = roomLayer.RoomPoints[0];
                const point1Model = roomModel.RoomPoints[0];

                const lengthLayer = getLayerLength(
                    { x: roomLayer.Center.X, y: roomLayer.Center.Y },
                    { x: point1Layer.X, y: point1Layer.Y },
                );

                const lengthModel = getLayerLength(
                    { x: roomModel.Center.X, y: roomModel.Center.Y },
                    { x: point1Model.X, y: point1Model.Y },
                );

                const distanceSmokeAlarmX = (value.distanceSmokeAlarmXLayer * lengthModel) / lengthLayer;
                const distanceSmokeAlarmY = (value.distanceSmokeAlarmYLayer * lengthModel) / lengthLayer;
                const distanceWallX = (value.distanceWallXLayer * lengthModel) / lengthLayer;
                const distanceWallY = (value.distanceWallYLayer * lengthModel) / lengthLayer;
                const radius = (value.radiusLayer * lengthModel) / lengthLayer;

                return {
                    id: uuid(),
                    roomId: value.roomId,
                    width: value.width,
                    length: value.length,
                    amountSmokeAlarm: value.amountSmokeAlarm,
                    radius,
                    distanceWallY,
                    distanceWallX,
                    distanceSmokeAlarmY,
                    distanceSmokeAlarmX,
                };
            });

            const response = await algorithmSupport({
                data: {
                    dataRoom: JSON.stringify(roomList),
                    dataDevice: JSON.stringify(newDataAlarm),
                },
                type: 0,
            });

            if (response.status === 200) {
                const resultList = [];
                response?.data?.result.map((room, index) => {
                    const alarmInfo = dataAlarm[index];
                    room?.devicePoints.map((device) => {
                        const newDevice = mapCoordinates(device);
                        const id = uuid();
                        newDevice.id = id;
                        newDevice.deviceId = id;
                        newDevice.radius = alarmInfo?.radiusLayer || alarmInfo?.radius;
                        newDevice.roomId = alarmInfo.roomId;
                        newDevice.isActive = false;
                        resultList.push(newDevice);
                    });
                });

                return resultList;
            } else {
                dispatch(controlIsFetching(false));
                message.error({
                    content: response.data.errors[0],
                    duration: 2,
                });
            }

            return undefined;
        }
        return undefined;
    };

    // Render AlarmDeviceTool
    return (
        <div style={{ display: toggleAllTools ? 'block' : 'none' }}>
            <div className={cx('sub-tools')} style={{ display: !isOpen ? 'none' : 'block' }}>
                <div
                    className={cx('sub-tools-back')}
                    onClick={() => changeOpenSubTool('listAlarmDevice')}
                    aria-hidden="true"
                >
                    <i className="fa-solid fa-arrow-left" /> {t('back')}
                </div>

                {isFetching && <AppLoading menuTool />}

                <div className={cx('sub-tools-content')}>
                    <div className={cx('sub-tools-content__title')}>
                        <h2 style={{ margin: '5px 0 10px' }}>{t('list_alarm_device')} </h2>
                        {deviceList.length > 0 && (
                            <span>
                                ({deviceList.length || 0} {deviceList.length > 1 ? t('devices') : t('device')})
                            </span>
                        )}
                    </div>

                    <div className={cx('sub-tools-content__action')}>
                        <Button
                            size="small"
                            onClick={getAlarmAction}
                            className="btn-custom btn-custom-small"
                            disabled={dataAlarm?.length < 1}
                        >
                            <span>
                                <i className="fa-solid fa-rotate-right" /> {t('reload_data')}
                            </span>
                        </Button>
                        <Button
                            onClick={showHideWire}
                            className={cx('btn-custom btn-custom-small')}
                            disabled={devices === undefined || devices === null || devices?.length < 1}
                            loading={isFetchingWireAlarmSelector}
                        >
                            <span>{!isShowWireAlarmSelector ? `${t('show_wire')}` : `${t('hide_wire')}`}</span>
                        </Button>
                    </div>

                    {isNoRoom ? (
                        <div className={cx('sub-tools-content__nodata')}>
                            <Empty description={t('no_data')} />
                            <Divider />
                            {t('please_select_room')} !!!
                        </div>
                    ) : !dataAlarm || dataAlarm?.length < 1 ? (
                        <div className={cx('sub-tools-content__nodata', 'margin-top')}>
                            <Button onClick={getAlarmDevicesList} className="btn-custom btn-custom-large">
                                {t('get_list_device')}
                            </Button>
                        </div>
                    ) : (
                        <div className={cx('sub-tools-content__nodata')}>
                            {showListDevice ? (
                                <>
                                    <div className={cx('check-all-btn')}>
                                        <h4>{t('show_all')}</h4>
                                        <span>
                                            <Checkbox onChange={handleCheckAllChange} checked={checkAll} />
                                        </span>
                                    </div>
                                    <div key="other" className={cx('sub-tools-room')}>
                                        <div className={cx('sub-tools-room__sort-button')} type="button">
                                            <Checkbox
                                                checked={checked}
                                                onClick={handleCheckOutSideList}
                                                disabled={disabled}
                                            />
                                            <h4 className={cx('title')} aria-hidden="true">
                                                {t('out_side')}
                                            </h4>
                                        </div>

                                        {deviceOutSide.map((device) => (
                                            <div
                                                key={device.deviceId || device.id}
                                                className={cx('device-item', checked && 'open')}
                                            >
                                                <div className={cx('device-head')}>
                                                    <Checkbox
                                                        onClick={(e) => handlCheckOutSideItem(device, e.target.checked)}
                                                        checked={device.isOpen}
                                                    />
                                                    <h5 className={cx('device-title')}>
                                                        {t('device')}: {device.id || device.deviceId}
                                                    </h5>

                                                    <Popconfirm
                                                        title={t('do_you_want_to_delete_this_device')}
                                                        onConfirm={() =>
                                                            confirmDeleteOutSide(device.id || device.deviceId)
                                                        }
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <i
                                                            className="fa-solid fa-trash"
                                                            style={{
                                                                cursor: 'pointer',
                                                                padding: '4px',
                                                            }}
                                                        />
                                                    </Popconfirm>
                                                </div>
                                                <p className={cx('device-radius')}>
                                                    {t('radius')}: {device.radius.toFixed(2)}m
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    {dataRoom.map((room) => (
                                        <AlarmDeviceList
                                            key={room.roomId}
                                            room={room}
                                            deviceList={deviceList.filter((device) => device.roomId === room.roomId)}
                                            handleOpen={handleOpen}
                                            handleCheck={handleCheck}
                                            confirmDelete={confirmDelete}
                                        />
                                    ))}
                                </>
                            ) : (
                                <>
                                    <Result status="success" title={t('there_was_an_device_information')} />
                                    <Button
                                        disabled={wrongUnit}
                                        onClick={async() => {await autoSetAlarm()}}
                                        className="btn-custom btn-custom-large"
                                    >
                                        {t('set_the_device_automatically')}
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

// =====================================================================
// Props
// =====================================================================
AlarmDeviceTool.propTypes = {
    changeOpenSubTool: PropsTypes.func.isRequired,
    isOpen: PropsTypes.bool.isRequired,
};

export default customMemo(AlarmDeviceTool);

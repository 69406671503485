/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
import { Row, Col, Modal } from 'antd';
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';

import styles from './Home.module.scss';
import Sidebar from '~/pages/Home/Sidebar';
import MainHome from './MainHome';

const cx = classNames.bind(styles);

export default function Home({ connStatus }) {
    useEffect(() => {
        if (connStatus === 1) {
            Modal.error({
                title: 'Connection errors!',
                content: 'Connection to Webhook failed. Please try again!',
                onOk() {},
            });
        }
    }, []);

    return (
        <div className={cx('home-container')}>
            <Row className={cx('home-container')}>
                <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4}>
                    <Sidebar />
                </Col>

                <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
                    <MainHome />
                </Col>
            </Row>
        </div>
    );
}

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */

import styles from '../ModalControll.module.scss';
import { Image } from 'antd';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { image } from '~/assets';
import { generalSelector } from '~/store/generalData/generalSlice';

const cx = classNames.bind(styles);

function InformationForm({ projectOpen }) {
    const [parseProject, setParseProject] = useState(null);

    const { t } = useTranslation();
    const { customerList, operatorList } = useSelector(generalSelector);

    useEffect(() => {
        const parse = {
            customer: null,
            operator: null,
            dynamicFields: null,
            alerts: null,
            plans: null,
            protected: 0,
            tab: null,
        };

        if (projectOpen) {
            if (projectOpen.customerId) {
                parse.customer = customerList.find((item) => item.id === projectOpen.customerId);
            }
            if (projectOpen.operatorId) {
                parse.operator = operatorList.find((item) => item.id === projectOpen.operatorId);
            }

            if (projectOpen.plans[0] !== null) {
                const parsePlans = JSON.parse(projectOpen.plans);
                parse.plans = parsePlans;
            }

            if (projectOpen.alerts[0] !== null) {
                const parseAlerts = JSON.parse(projectOpen.alerts);
                parse.alerts = parseAlerts;
            }

            if (projectOpen.dynamicFields !== '') {
                const parseynamicFields = JSON.parse(projectOpen.dynamicFields);
                parse.dynamicFields = parseynamicFields;
            }

            if (projectOpen.protected === 1) {
                parse.protected = { id: 'fullProtection', name: 'full_protection' };
            }
            if (projectOpen.protected === 2) {
                parse.protected = { id: 'partialProtection', name: 'partial_protection' };
            }
            if (projectOpen.tab !== '') {
                parse.tab = JSON.parse(projectOpen.tab);
            }
        }

        setParseProject(parse);
    }, [projectOpen]);

    return (
        <div className={cx('information-container')}>
            <div className={cx('fixed-information')}>
                <div className={cx('information')}>
                    <h4>{t('project_name')}: </h4>
                    <span>{projectOpen?.name || '...'}</span>
                </div>

                <div className={cx('information')}>
                    <h4>{t('customer')}: </h4>
                    <span>{parseProject?.customer?.name || '...'}</span>
                </div>
                <div className={cx('information')}>
                    <h4>{t('operator')}: </h4>
                    <span>{parseProject?.operator?.name || '...'}</span>
                </div>
                <div className={cx('information')}>
                    <h4>{t('object_type')}: </h4>
                    <span>{projectOpen?.objectType || '...'}</span>
                </div>

                {!!parseProject?.dynamicFields &&
                    parseProject?.dynamicFields.map((field) => (
                        <div className={cx('information')} key={field.id}>
                            <h4>{field.name}:</h4>
                            {(!field.hide && <span>{field.value || '...'}</span>) || <span>*************</span>}
                        </div>
                    ))}
            </div>

            {!!parseProject?.alerts && parseProject?.alerts.length > 0 && (
                <div className={cx('information')}>
                    <h4>* {t('alerts')}:</h4>
                    {parseProject?.alerts.map((field) => {
                        if (field.check)
                            return (
                                <p key={field.id} className={cx('info')}>
                                    <i className="fa-solid fa-circle-small" /> {t(field.name)}
                                </p>
                            );
                    })}
                </div>
            )}

            {parseProject?.protected !== 0 && (
                <div className={cx('information')}>
                    <h4>* {t('protected')}: </h4>

                    <p key={parseProject?.protected.id} className={cx('info')}>
                        <i className="fa-solid fa-circle-small" /> {t(parseProject?.protected.name)}
                    </p>
                </div>
            )}

            {!!parseProject?.plans && parseProject?.plans.length > 0 && (
                <div className={cx('information')}>
                    <h4>* {t('plans')}:</h4>
                    {parseProject?.plans.map((field) => {
                        if (field.check)
                            return (
                                <p key={field.id} className={cx('info')}>
                                    <i className="fa-solid fa-circle-small" /> {field.name}
                                </p>
                            );
                    })}
                </div>
            )}
            {!!parseProject?.tab && (
                <div className={cx('tabs-list-selected__item', 'project-tab')}>
                    <h4>* {t('tabs')}:</h4>
                    <Image preview={false} style={{ marginLeft: 20 }} src={image.pdf} width="34px" height="100%" />
                    <a
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginLeft: 34 }}
                        className={cx('name')}
                        href={parseProject?.tab.url}
                    >
                        {parseProject?.tab.name}
                    </a>
                </div>
            )}
            {projectOpen?.saveTime && (
                <div className={cx('information')}>
                    <h4>* {t('save_time')}:</h4>{' '}
                    <span>
                        {projectOpen.saveTime} {t('minutes')}
                    </span>
                </div>
            )}
        </div>
    );
}

export default InformationForm;

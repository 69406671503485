/* eslint-disable react/jsx-filename-extension */
/* eslint-disable global-require */

export function FolderSpeam({ width, height, padding }) {
    return (
        <div style={{ padding }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={width} height={height}>
                <path d="M438.6 102.6l-93.08-93.26C339.6 3.371 331.5 0 322.1 0H192.5C157.2 0 128.6 28.65 128.6 64v288c0 35.35 28.6 64 63.88 64h191.6C419.3 416 448 387.3 448 352V125.3C448 116.8 444.6 108.6 438.6 102.6zM336.2 22.62L425.4 112h-81.22c-4.398 0-7.985-3.594-7.985-8V22.62zM432 352c0 26.47-21.49 48-47.91 48H192.5c-26.42 0-47.91-21.53-47.91-48V64c0-26.47 21.49-48 47.91-48h127.8v88c0 13.22 10.76 24 23.95 24h87.83V352zM310.8 448c-3.944 0-7.279 2.898-7.837 6.805C299.6 478.1 279.6 496 255.5 496H63.88c-26.46 0-47.91-21.49-47.91-48V160c0-26.51 21.45-48 47.91-48h23.95c4.412 0 7.985-3.582 7.985-8S92.25 96 87.83 96H63.88C28.6 96 0 124.7 0 160v288c0 35.34 28.69 64 63.97 64h191.5c32.2 0 58.83-23.87 63.24-54.91C319.4 452.3 315.6 448 310.8 448z" />
            </svg>
        </div>
    );
}

export function ImagesThin({ width, height, padding, className }) {
    return (
        <div style={{ padding, width, height }} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={width} height={height}>
                <path d="M224 88c-22.06 0-40 17.94-40 40s17.94 40 40 40s40-17.94 40-40S246.1 88 224 88zM224 152c-13.22 0-24-10.77-24-24s10.78-24 24-24s24 10.77 24 24S237.2 152 224 152zM472 416c-4.422 0-8 3.594-8 8c0 22.06-17.94 40-40 40h-352c-30.88 0-56-25.12-56-56v-256c0-22.06 17.94-40 40-40C60.42 112 64 108.4 64 104S60.42 96 56 96C25.13 96 0 121.1 0 152v256C0 447.7 32.3 480 72 480h352c30.88 0 56-25.12 56-56C480 419.6 476.4 416 472 416zM527.1 32H144c-26.5 0-48 21.5-48 48v256c0 26.5 21.5 48 48 48h383.1C554.5 384 576 362.5 576 336v-256C576 53.5 554.5 32 527.1 32zM144 368c-17.64 0-32-14.36-32-32V316.7l90.34-90.34c3.125-3.125 8.188-3.125 11.31 0l79.03 79.03L230.1 368H144zM560 336c0 17.64-14.36 32-32 32H252.7l205.7-205.7c3.125-3.125 8.188-3.125 11.31 0L560 252.7V336zM560 230.1l-79.03-79.03c-9.375-9.344-24.56-9.344-33.94 0L304 294.1L224.1 215c-9.375-9.344-24.56-9.344-33.94 0L112 294.1V80c0-17.64 14.36-32 32-32h384c17.64 0 32 14.36 32 32V230.1z" />
            </svg>
        </div>
    );
}

import Dll from '~/pages/Dll';
import GeneralData from '~/pages/GeneralData/GeneralData';
import Home from '~/pages/Home';
import Login from '~/pages/Login';
import Logout from '~/pages/Logout';
import NotFound from '~/pages/NotFound';
import Register from '~/pages/Register';
import Tool from '~/pages/Tool';

const publicRoutes = [
    { path: '/login', component: Login, layout: null },
    { path: '/login/:email', component: Login, layout: null },
    { path: '/register', component: Register, layout: null },
    { path: '/not-found-404', component: NotFound, layout: null },
];

const privateRoutes = [
    { path: '/logout', component: Logout, layout: null },
    { path: '/', component: Home },
    { path: '/:id', component: Home },
    { path: '/project/:id', component: Home },
    { path: '/tool', component: Tool },
    { path: '/tool/:id', component: Tool },
    { path: '/dll', component: Dll },
    { path: '/general/:general', component: GeneralData },
];

export { publicRoutes, privateRoutes };

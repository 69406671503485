/* eslint-disable react/jsx-no-useless-fragment */

import { renderFileStatus } from '../handleFileUI/renderFileStatus';
import MenuControll from '../Menu/MenuControll';
import styles from './ListFile.module.scss';
import { Checkbox, Dropdown, Empty, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import classNames from 'classnames/bind';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import AppLoading from '~/components/AppLoading';
import { selectBinList } from '~/store/bin-list/binListSlice';
import { filesList } from '~/store/files/filesSlice';
import { getOneProject } from '~/store/project/projectAction';
import { projectSelector } from '~/store/project/projectSlice';

const cx = classNames.bind(styles);

function ListFile({ dataFiles, onCheck, onSetFileModal }) {
    const [data, setData] = useState([]);

    const params = useParams();
    const { t } = useTranslation();

    const { isFetchingGetOne } = useSelector(projectSelector);
    const { deleteFiles, isErrorFile } = useSelector(filesList);
    const { onBinScreen, isErrorBin } = useSelector(selectBinList);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getOneProject({
                id: params.id,
                isDeleted: onBinScreen,
                dispatch,
                t,
            }),
        );
    }, [params.id]);

    useEffect(() => {
        if (dataFiles) {
            setData(dataFiles);
        }
    }, [dataFiles]);

    useEffect(() => {
        if (isErrorBin || isErrorFile) {
            Modal.error({
                title: 'Connection errors!',
                content: "Can't connect to the server. Please try again!",
                onOk() {
                    navigate('/not-found-404');
                },
            });
        }
    }, [isErrorFile, isErrorBin]);

    const handleToView = useCallback(
        (item) => {
            if (item.status === 0 && !onBinScreen && !item.thumbnailLoading) {
                navigate(`/tool/${item.id}`);
            }
        },
        [onBinScreen],
    );

    return (
        <div className={cx('list-file-container')}>
            <div className={cx('list-file-group')}>
                {isFetchingGetOne ? (
                    <AppLoading listFile />
                ) : (
                    <Row>
                        {(data.length === 0 && (
                            <Empty
                                style={{ width: '100%', marginTop: '10%' }}
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                description={<span>No file</span>}
                            />
                        )) ||
                            data.map((item) => (
                                <div className={cx('list-file-group__item')} key={item.id || item.key}>
                                    <div className={cx('list-file-group__item__check-box', item.isCheck && 'active')}>
                                        <Checkbox
                                            id={item.id}
                                            key={item.id}
                                            onClick={(e) =>
                                                onCheck(item.id, e.target.checked, data, deleteFiles, 'files')
                                            }
                                            checked={item.isCheck}
                                        />
                                    </div>

                                    <Dropdown
                                        key={item.id}
                                        overlay={<MenuControll handleToView={handleToView} type="file" />}
                                        overlayClassName="dropdown-menu"
                                        trigger={['click']}
                                        loading={item.status === 2 || item.status === 0 || false}
                                        onOpenChange={(e) => onSetFileModal(e, item)}
                                    >
                                        <button className={cx('list-file-group__item__button')}>
                                            <i className="fa-solid fa-ellipsis" />
                                        </button>
                                    </Dropdown>
                                    <div className={cx('thumbnail-group')} onClick={() => handleToView(item)}>
                                        {renderFileStatus(item, onBinScreen, t)}
                                    </div>

                                    <span className={cx('item-name')}>{item.name || ''}</span>
                                </div>
                            ))}
                    </Row>
                )}
            </div>
        </div>
    );
}

export default memo(ListFile);

import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import LoginMaster from '~/components/LoginMaster';
import LoginComponent from '~/components/LoginMaster/Login';
import { loginAuthAction } from '~/store/auth/authAction';
import { clearStateAuthAction, selectAuth } from '~/store/auth/authSlice';

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [errMess, setErrMess] = useState(null);
    const { isSuccess, isError, errorMessage } = useSelector(selectAuth);

    const { email } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('login');
    }, [t]);

    useEffect(() => {
        if (isError) {
            setErrMess(errorMessage);
            dispatch(clearStateAuthAction);
            setIsLoading(false);
        }
        if (isSuccess) {
            setIsLoading(false);
            navigate('/');
        }
    }, [isSuccess, isError]);

    const submitForm = (values) => {
        setIsLoading(true);
        dispatch(loginAuthAction(values))
            .then(unwrapResult)
            .catch((rejectedValueOrSerializedError) => {
                setIsLoading(false);

                let message = rejectedValueOrSerializedError;

                if (message === 'Username or password is incorrect or account has not been activated') {
                    message = `${t('username_or_password_is_incorrect_or_account_has_not_been_activated!')}`;
                }

                setErrMess(message);
            });
    };

    const submitFailed = (errorInfo) => {
        console.log('submitFailed:', errorInfo);
    };

    return (
        <LoginMaster>
            <LoginComponent
                submitForm={submitForm}
                submitFailed={submitFailed}
                email={email}
                isLoading={isLoading}
                errorMessage={errMess}
            />
        </LoginMaster>
    );
}

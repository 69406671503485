/* eslint-disable arrow-body-style */

import { initialState } from './viewer.slice';
import { createSelector } from '@reduxjs/toolkit';

/**
 * SELECTOR: current state of viewer slice
 * @param {*} state
 * @returns
 */
export const selectViewerDomain = (state) => {
    return state.viewer || initialState;
};

// ==========================================================================
/**
 * SELECTOR: state of IsApplyRoomSettingSuccess
 * @returns
 */
export const makeViewerIsApplyRoomSettingSuccessSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.IsApplyRoomSettingSuccess);

/**
 * SELECTOR: state of isBackToMenuTool
 * @returns
 */
export const makeViewerIsBackToMenuToolSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.isBackToMenuTool);

/**
 * SELECTOR: state of floorSettingsToolData
 * @returns
 */
export const makeViewerFloorSettingsToolDataSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.floorSettingsToolData);

/**
 * SELECTOR: state of currentRoomSettingId
 * @returns
 */
export const makeViewerCurrentRoomSettingIdSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.currentRoomSettingId);

/**
 * SELECTOR: state of shouldApplySettingToAllRooms
 * @returns
 */
export const makeViewerShouldApplySettingToAllRoomsSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.shouldApplySettingToAllRooms);

/**
 * SELECTOR: state of shouldDrawingCenteredBMZ
 * @returns
 */
export const makeViewerShouldDrawingCenteredBMZSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.shouldDrawingCenteredBMZ);

/**
 * SELECTOR: state of shouldAlarmSystemBeInstalled
 * @returns
 */
export const makeViewerShouldAlarmSystemBeInstalledSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.shouldAlarmSystemBeInstalled);

/**
 * SELECTOR: state of guardAutoSaveFileStatusOfFileSlice
 * @returns
 */
export const makeViewerGuardAutoSaveFileStatusOfFileSliceSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.guardAutoSaveFileStatusOfFileSlice);

/**
 * SELECTOR: state of observeSaveFileStatusOfFileSlice
 * @returns
 */
export const makeViewerObserveSaveFileStatusOfFileSliceSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.observeSaveFileStatusOfFileSlice);

/**
 * SELECTOR: state of rooms
 * @returns
 */
export const makeViewerRoomsSelector = () => createSelector([selectViewerDomain], (viewer) => viewer.rooms);

/**
 * SELECTOR: state of haveViewer
 * @returns
 */
export const makeViewerHaveViewerSelector = () => createSelector([selectViewerDomain], (viewer) => viewer.haveViewer);

/**
 * SELECTOR: state of scaleModel
 * @returns
 */
export const makeViewerScaleModelSelector = () => createSelector([selectViewerDomain], (viewer) => viewer.scaleModel);

/**
 * SELECTOR: state of countRoom
 * @returns
 */
export const makeViewerCountRoomSelector = () => createSelector([selectViewerDomain], (viewer) => viewer.countRoom);

/**
 * SELECTOR: state of isFetching
 * @returns
 */
export const makeViewerIsFetchingSelector = () => createSelector([selectViewerDomain], (viewer) => viewer.isFetching);

/**
 * SELECTOR: state of isSuccess
 * @returns
 */
export const makeViewerIsSuccessSelector = () => createSelector([selectViewerDomain], (viewer) => viewer.isSuccess);

/**
 * SELECTOR: state of activeEditRoom
 * @returns
 */
export const makeActiveEditRoomSelector = () => createSelector([selectViewerDomain], (viewer) => viewer.activeEditRoom);

/**
 * SELECTOR: state of hasReleasedProtectData
 * @returns
 */
export const makeHasReleasedProtectDataRoomSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.hasReleasedProtectData);

export const protectDataRoomsSelector = () => createSelector([selectViewerDomain], (viewer) => viewer.protectDataRooms);

/**
 * SELECTOR: state of floorBoundingBox
 * @returns
 */
export const makeViewerFloorBoundingBoxSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.floorBoundingBox);

/* eslint-disable react/jsx-no-useless-fragment */
import { Tooltip, Image } from 'antd';
import classNames from 'classnames/bind';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { image } from '~/assets';
import { selectAlarmDevice, setOnDeviceMode } from '~/store/alarm-device/alarmDeviceSlice';

import styles from './ToolButton.module.scss';

const cx = classNames.bind(styles);

function ToolButton({ data, parent, onSelect, tooltip = false, horizontal = false, bigSize = false }) {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { onAddSymbolMode, symbol } = useSelector(selectAlarmDevice);

    const handleSetDevice = () => {
        if (!!parent && !!data.image) {
            onSelect('select');
            dispatch(
                setOnDeviceMode({
                    parent,
                    code: data.image,
                    ratio: data?.ratio ?? null,
                }),
            );
        }
    };

    useEffect(() => {
        if (!onAddSymbolMode && !!data.code) {
            data.isActive = false;
        }
    }, [onAddSymbolMode, symbol]);

    const children = (
        <div
            key={data.code}
            className={cx(
                'item-button',
                data.isActive && 'item-button__active',
                horizontal && 'horizontal',
                bigSize && 'big-size',
            )}
            onClick={handleSetDevice}
        >
            {(data.icon && <i className={data.icon} />) || (
                <Image
                    src={`${window.location.origin}/assets/img/Symbol/${parent}/${data.image}`}
                    alt=""
                    style={{
                        maxWidth: '50px',
                        maxHeight: '40px',
                        padding: '4px',
                        backgroundColor: 'transparent',
                    }}
                    fallback={image.logo}
                    preview={false}
                    loading="lazy"
                />
            )}

            <span className={cx('item-button__name', horizontal && 'horizontal_item')}>{t(data.name)}</span>
        </div>
    );

    return (
        <>
            {tooltip ? (
                <Tooltip title={t(data.name)} placement="topRight" smouseLeaveDelay={0}>
                    {children}
                </Tooltip>
            ) : (
                <> {children}</>
            )}
        </>
    );
}

export default ToolButton;

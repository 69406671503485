/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { Vector2, Vector3 } from 'three-full';

const uniqBy = (a, key) => {
    const seen = {};
    return a.filter((item) => {
        const k = key(item);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
};

// Find the coordinates of the intersection point
const getPointIntersection = (point1, point2, center_point, radius) => {
    // Get the distance from the center to the specified line segment
    const distance_center_to_line = distancePointToLine(point1, point2, center_point);
    // If the above distance is larger than the radius, the circle does not intersect the line;
    if (distance_center_to_line >= radius) {
        return null;
    }

    // Find vector through point right -> left, bottom -> top
    const vector_corner = new Vector2();
    vector_corner.subVectors(point1, point2).normalize();

    // Rotate perpendicular vector
    const newVector = rotateVector(vector_corner, -90);
    const vector_tangent = newVector.normalize();
    const tangent_point = center_point.clone().add(vector_tangent);

    // Equation of the line between 2 points
    const pt1 = pt_assign(point1, point2);

    // Equation of the line between the center and the perpendicular
    const pt2 = pt_assign(center_point, tangent_point);

    // return intersection point
    return pt_intersection(pt1, pt2);
};

// Distance between point and line segment
const distancePointToLine = (point1, point2, center_point) => {
    if ((point1.x - point2.x) * (center_point.x - point2.x) + (point1.y - point2.y) * (center_point.y - point2.y) <= 0)
        return Math.sqrt(
            (center_point.x - point2.x) * (center_point.x - point2.x) +
                (center_point.y - point2.y) * (center_point.y - point2.y),
        );

    if ((point2.x - point1.x) * (center_point.x - point1.x) + (point2.y - point1.y) * (center_point.y - point1.y) <= 0)
        return Math.sqrt(
            (center_point.x - point1.x) * (center_point.x - point1.x) +
                (center_point.y - point1.y) * (center_point.y - point1.y),
        );

    return (
        Math.abs(
            (point2.y - point1.y) * center_point.x -
                (point2.x - point1.x) * center_point.y +
                point2.x * point1.y -
                point2.y * point1.x,
        ) / Math.sqrt((point1.y - point2.y) * (point1.y - point2.y) + (point1.x - point2.x) * (point1.x - point2.x))
    );
};

// Determine the rotation angle between 2 points
const degOfRotate = (point1, point2, center_point) => {
    // Find the 90 degree rotation of the radius 1 or 2
    let deg_of_rotate = 2;
    if (point1.y >= point2.y) {
        deg_of_rotate = 1;
    }

    if (deg_of_rotate === 1 && point2.x < center_point.x) deg_of_rotate = 2;
    else if (point1.x < center_point.x) deg_of_rotate = 1;
    return deg_of_rotate;
};

// Rotate vector
const rotateVector = (vec, ang) => {
    if (!ang) ang = 90;
    ang = -ang * (Math.PI / 180);
    const cos = Math.cos(ang);
    const sin = Math.sin(ang);
    const newX = Math.round(10000 * (vec.x * cos - vec.y * sin)) / 10000;
    const newY = Math.round(10000 * (vec.x * sin + vec.y * cos)) / 10000;
    return new Vector2(newX, newY);
};

// Calculate the angle between two vectors. Return rad
const findAngle = (vec1, vec2) => {
    const vector3_1 = new Vector3(vec1.x, vec1.y, 0);
    const vector3_2 = new Vector3(vec2.x, vec2.y, 0);
    return vector3_1.angleTo(vector3_2);
};

// Equation passing through 2 points
// eslint-disable-next-line camelcase
const pt_assign = (p1, p2) => {
    const a = p1.y - p2.y;
    const b = p2.x - p1.x;
    const c = p2.x * p1.y - p1.x * p2.y;
    return { a, b, c };
};

// Find the intersection point of 2 equations of the line
const pt_intersection = (pt1, pt2) => {
    const d = pt1.a * pt2.b - pt2.a * pt1.b;
    const dx = pt1.c * pt2.b - pt2.c * pt1.b;
    const dy = pt1.a * pt2.c - pt2.a * pt1.c;
    if (d !== 0) {
        const x = dx / d;
        const y = dy / d;
        return new Vector2(x, y);
    }
    return undefined;
};

function isClockwiseF(points) {
    let sum = 0;
    for (let i = 0; i < points?.length - 1; i++) {
        const p1 = points[i];
        const p2 = points[i + 1];
        sum += (p2.x - p1.x) * (p2.y + p1.y);
    }

    const p1 = points[points?.length - 1];
    const p2 = points[0];
    sum += (p2.x - p1.x) * (p2.y + p1.y);

    return sum > 0;
}

// Reverse array points
const reversePoints = (listItems, isCw = false) => {
    const isClockwise = isClockwiseF(listItems);
    if (isClockwise) {
        if (isCw) {
            return listItems;
        }
        return listItems.slice().reverse();
    }

    if (!isCw) {
        return listItems;
    }
    return listItems.slice().reverse();
};

// Arrange points counterclockwise
const sortPoint = (points) => {
    // Sort your points by angle
    const center = getCenterPoint(points);
    const angles = getAngles(points, center);
    return angles.sort((a, b) => a.angle - b.angle);
};

// Get list angle of line
const getAngles = (points, center) =>
    points.map((item) => ({
        ...item,
        angle: (Math.atan2(item.y - center.y, item.x - center.x) * 180) / Math.PI,
    }));

// Get list center points
// Get the center (mean value) using reduce
const getCenterPoint = (points) =>
    points.reduce(
        (acc, { x, y, isOrigin }) => {
            acc.x += x / points.length;
            acc.y += y / points.length;
            return acc;
        },
        { x: 0, y: 0 },
    );

const getClockwiseAngle = (vec1, vec2) => {
    const dot = vec1.x * vec2.x + vec1.y * vec2.y; // dot product between [x1, y1] and [x2, y2]
    const det = vec1.x * vec2.y - vec1.y * vec2.x; // determinant
    return Math.atan2(det, dot); // atan2(y, x) or atan2(sin, cos)
};

export {
    uniqBy,
    sortPoint,
    getPointIntersection,
    degOfRotate,
    rotateVector,
    findAngle,
    distancePointToLine,
    reversePoints,
    pt_assign,
    getClockwiseAngle,
};

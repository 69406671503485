/* eslint-disable react/prop-types */
import { ConfigProvider } from 'antd';
import './GlobalStyles.scss';

ConfigProvider.config({
    theme: {
        primaryColor: '#f44c45',
    },
});

export default function GlobalStyles({ children }) {
    return <ConfigProvider>{children}</ConfigProvider>;
}

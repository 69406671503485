import styles from './Logout.module.scss';
import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { logoutAuthAction } from '~/store/auth/authSlice';

const cx = classNames.bind(styles);

export default function Logout() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(logoutAuthAction);
        window.location.href = '/login';
    }, []);
    return (
        <div className={cx('container')}>
            <img src={`${window.location.origin}/assets/img/logo_new.png`} alt="logo" />
            <h2>{t('logout')}...</h2>
        </div>
    );
}

import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { loginAuthAction } from './authAction';

/**
 * Default state of auth slice
 */
const initialState = {
    data: null,
    isFetching: false,
    isError: false,
    isSuccess: false,
    errorMessage: [],
};

/**
 * ***************************************************************************
 * Create a auth slice
 * ***************************************************************************
 */
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        /**
         * ACTION: log out
         * @param {*} state   => base state
         */
        logoutAuthAction(state) {
            state.data = null;

            // delete data from cookie
            Cookies.remove('access_token');
            Cookies.remove('refresh_token');
            Cookies.remove('expire');
            Cookies.remove('user');
        },

        /**
         * ACTION: set state of auth slice to default
         * @param {*} state   => base state
         */
        clearStateAuthAction: (state) => {
            state.data = null;
            state.isError = false;
            state.isFetching = false;
            state.isSuccess = false;
            state.errorMessage = [];
        },
    },
    extraReducers: {
        [loginAuthAction.pending]: (state) => {
            state.isFetching = true;
            state.isSuccess = false;
            state.isError = false;
        },
        [loginAuthAction.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = false;
            state.isSuccess = true;
            state.errorMessage = [];
            state.data = payload.result;

            // set data to cookie
            Cookies.set('access_token', payload.result.access_token);
            Cookies.set('refresh_token', payload.result.refresh_token);
            Cookies.set('expire', payload.result.expire);
            Cookies.set('user', JSON.stringify(payload.result.user));
        },
        [loginAuthAction.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = false;
            state.isError = true;
            state.errorMessage = payload;
        },
    },
});

/**
 * SELECTOR: current state of auth slice
 * @param {*} state
 * @returns
 */
export const selectAuth = (state) => state.auth;

// ============================================================================
// Export Actions
// ============================================================================
export const clearStateAuthAction = authSlice.actions.clearStateAuthAction();
export const logoutAuthAction = authSlice.actions.logoutAuthAction();

// ============================================================================
// Export Reducer
// ============================================================================
export default authSlice.reducer;

/* eslint-disable consistent-return */
import classNames from 'classnames/bind';
import { Spin, Image, Skeleton } from 'antd';
import { LoadingOutlined, FileExclamationOutlined, CloseCircleOutlined } from '@ant-design/icons';

import styles from './renderFileStatus.module.scss';
import { image } from '~/assets';
import ProgressBar from './ProgressBar/ProgressBar';

const cx = classNames.bind(styles);

export function renderFileStatus(file, onBinScreen, t) {
    if (file === undefined) return;

    switch (file?.status) {
        case 'error':
            return (
                <div className={cx('file-thumbnail')}>
                    <div className={cx('file-thumbnail__error-icon')}>
                        <Spin spin="false" style={{ color: 'rgb(243, 69, 69)' }} indicator={<CloseCircleOutlined />}>
                            <img src={image.logo} alt="err" style={{ width: '100%' }} />
                        </Spin>
                    </div>
                    <p className={cx('file-thumbnail__error-title')}>{t('network_error')}!</p>
                </div>
            );

        case 'upLoading':
            return (
                <div className={cx('file-thumbnail')}>
                    <div className={cx('file-thumbnail__uploading-icon')}>
                        <Spin indicator={<LoadingOutlined />}>
                            <img src={image.logo} alt="Uploading" style={{ width: '100%' }} />
                        </Spin>
                    </div>
                    <p className={cx('file-thumbnail__uploading-title')}>{t('uploading')}...</p>
                    <ProgressBar percent={file.percent || 0} />
                </div>
            );
        case 2 && !onBinScreen:
            return (
                <div className={cx('file-thumbnail')}>
                    <div className={cx('file-thumbnail__processing-icon')}>
                        <Spin indicator={<LoadingOutlined />}>
                            <img src={image.logo} alt="Processing" style={{ width: '100%' }} />
                        </Spin>
                    </div>
                    <span className={cx('file-thumbnail__processing-title')}>{t('processing')}...</span>
                </div>
            );

        case 2 && onBinScreen:
            return (
                <div className={cx('file-thumbnail')}>
                    <div className={cx('file-thumbnail__error-icon')}>
                        <FileExclamationOutlined />
                    </div>
                    <p className={cx('file-thumbnail__error-title')}>{t('file_error')}!</p>
                </div>
            );

        case 0:
            if (file.thumbnailLoading) {
                return <Skeleton.Avatar active shape="square" style={{ width: '100%', height: '100%' }} />;
            }
            if (!file.thumbnailLoading) {
                return (
                    <Image
                        width="100%"
                        height="100%"
                        style={{ backgroundColor: '#fff' }}
                        src={file.thumbnail}
                        fallback={image.logo}
                        preview={false}
                    />
                );
            }
            break;

        case 1:
            return (
                <div className={cx('file-thumbnail')}>
                    <div className={cx('file-thumbnail__error-icon')}>
                        <FileExclamationOutlined />
                    </div>
                    <p className={cx('file-thumbnail__error-title')}>{t('file_error')}!</p>
                </div>
            );
        default:
            return (
                <div className={cx('file-thumbnail')}>
                    <div className={cx('file-thumbnail__processing-icon')}>
                        <Spin indicator={<LoadingOutlined />} size="large">
                            <img src={image.logo} alt="Processing" style={{ width: '100%' }} />
                        </Spin>
                    </div>
                    <span className={cx('file-thumbnail__processing-title')}>{t('processing')}...</span>
                </div>
            );
    }
}

/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';
import { getBinFile } from './binListActions';

/**
 * Default state of binList slice
 */
export const initialState = {
    binList: [], // list of files object
    subList: [],
    isFetchingBin: false,
    isErrorBin: null,
    onBinScreen: false,
};

/**
 * ***************************************************************************
 * Create a binList slice
 * ***************************************************************************
 */
const binListSlice = createSlice({
    name: 'binList',
    initialState,
    reducers: {
        /**
         * ACTION: helper for show all files when click the Recycle Bin's icon
         * at My documents Sidebar
         * @param {*} state   => base state
         */
        showBinListAction(state, { payload }) {
            state.onBinScreen = payload;
        },

        /**
         * ACTION: delete a file with specified id in binList
         * @param {*} state   => base state
         * @param {*} payload  => File's id
         */
        removeFromBinListByFileIdBinListAction(state, { payload }) {
            state.binList = [...state.binList].filter((item) => item.id !== payload);
            state.isFetchingBin = false;
        },

        /**
         * ACTION: add file's id to subList
         * @param {*} state   => base state
         * @param {*} payload  => File's id
         */
        addFileIdToSubListBinListAction(state, { payload }) {
            state.subList.push(payload);
        },

        /**
         * ACTION: delete a file with specified id in binList
         * @param {*} state   => base state
         * @param {*} payload  => File's id
         */
        removeFromSubListByFileIdBinListAction(state, { payload }) {
            state.subList = [...state.subList].filter((item) => item !== payload);
        },

        /**
         * ACTION: change the state of subList by a new subList
         * @param {*} state   => base state
         * @param {*} payload  => File's id
         */
        setSubListBinListAction(state, { payload }) {
            state.subList = payload;
        },

        /**
         * ACTION: change the state of subList, and binList to empty
         * @param {*} state   => base state
         */
        setEmptyBinBinListAction(state) {
            state.binList = [];
            state.subList = [];
            state.isFetchingBin = false;
        },
    },
    extraReducers: {
        [getBinFile.pending]: (state) => {
            state.isFetchingBin = true;
        },
        [getBinFile.fulfilled]: (state, { payload }) => {
            state.isFetchingBin = false;
            state.binList = payload.result;
        },
        [getBinFile.rejected]: (state) => {
            state.isFetchingBin = false;
            state.isErrorBin = true;
        },
    },
});

/**
 * SELECTOR: current state of binList slice
 * @param {*} state
 * @returns
 */
export const selectBinList = (state) => state.binList;

// ============================================================================
// Export Actions
// ============================================================================
export const {
    showBinListAction,
    removeFromBinListByFileIdBinListAction,
    addFileIdToSubListBinListAction,
    removeFromSubListByFileIdBinListAction,
    setSubListBinListAction,
    setEmptyBinBinListAction,
} = binListSlice.actions;

// ============================================================================
// Export Reducer
// ============================================================================
export default binListSlice.reducer;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */

import styles from '../../ModalControll.module.scss';
import { InboxOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames/bind';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

const cx = classNames.bind(styles);

function UploadArea({ listUpload = [], setListUpload }) {
    const [newName, setNewName] = useState('');

    const { t } = useTranslation();
    const newFieldRef = useRef();

    const handlePushFile = useCallback(
        (e, list) => {
            e.preventDefault();
            const newList = [];
            for (let f of list) {
                const id = uuid();
                newList.push({ name: f.name, id, onEdit: false, file: f });
            }
            setListUpload([...newList, ...listUpload]);
            // }
        },
        [listUpload],
    );

    const removeFile = async (index) => {
        await listUpload.splice(index, 1);
        setListUpload([...listUpload]);
    };

    const handleEditNameFile = async (item) => {
        await setListUpload(() =>
            listUpload.map((f) => {
                if (f.id === item.id) {
                    f.onEdit = true;
                    setNewName(f.name);
                } else {
                    f.onEdit = false;
                }
                return { ...f };
            }),
        );

        newFieldRef.current.focus();
    };

    const setNameFileUpload = (item) => {
        const newList = listUpload.map((f) => {
            if (item.id === f.id) {
                f.onEdit = false;
                if (newName !== '') {
                    f.name = newName;
                }
            }
            return { ...f };
        });
        setListUpload(newList);
        setNewName('');
    };

    return (
        <div>
            <form
                id="form"
                encType="multipart/form-data"
                onDragOver={(e) => {
                    e.preventDefault();
                }}
                onDragEnd={(e) => {
                    e.preventDefault();
                }}
                onDragLeave={(e) => {
                    e.preventDefault();
                    return;
                }}
                onSubmit={(e) => {
                    e.preventDefault();
                }}
                onDrop={(e) => handlePushFile(e, e.dataTransfer.files)}
            >
                <div className="ant-upload ant-upload-drag">
                    <span
                        tabIndex="0"
                        className="ant-upload ant-upload-btn"
                        style={{ padding: '12px 0' }}
                        role="button"
                    >
                        <input
                            style={{ display: 'none' }}
                            id="upload"
                            type="file"
                            accept=".dwg, .pdf"
                            multiple
                            onChange={(e) => handlePushFile(e, e.target.files)}
                        />
                        <label id="upload" htmlFor="upload">
                            <p className="ant-upload-drag-icon" style={{ marginBottom: 0 }}>
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">{t('click_or_drag_a_file_to_this_area_to_upload')}</p>
                        </label>
                    </span>
                </div>
                <p className={cx('attention')}>*({t('you_can_click_on_the_file_to_change_name_of_file')})</p>

                <div className={cx('list-file-upload')}>
                    {listUpload.map((item, index) => (
                        <div
                            onBlur={() => setNameFileUpload(item)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setNameFileUpload(item);
                                }
                            }}
                            onClick={() => handleEditNameFile(item)}
                            className={cx('list-file-upload__item')}
                            key={item.id}
                        >
                            {item.onEdit ? (
                                <input
                                    ref={newFieldRef}
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                    className={cx('item-name')}
                                />
                            ) : (
                                <p className={cx('item-name')}>{item.name}</p>
                            )}

                            <Button className={cx('remove-btn')} onClick={() => removeFile(index)}>
                                <i className="fa-solid fa-trash-can" />
                            </Button>
                        </div>
                    ))}
                </div>
            </form>
        </div>
    );
}

export default memo(UploadArea);

/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Modal, Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { image } from '~/assets';

import styles from './HelpButton.module.scss';

const cx = classNames.bind(styles);

const dataTools = [
    {
        id: 'select',
        title: 'select',
        content: 'default',
        img: image.helpTools.select,
    },
    {
        id: 'markRoom',
        title: 'markRoom',
        content: 'draw_more_room_on_your_blueprint',
        img: image.helpTools.markRoom,
    },
    {
        id: 'editRoom',
        title: 'editRoom',
        content: 'you_can_move_edit_and_detele_room_on_the_blueprint',
        img: image.helpTools.editRoom,
    },
    {
        id: 'editDevice',
        title: 'editDevice',
        content: 'you_can_move_edit_and_detele_device_and_symbol_on_the_blueprint',
        img: image.helpTools.editDevice,
    },
    {
        id: 'listRoom',
        title: 'listRoom',
        content: 'you_can_see_your_room_list_in_there',
        img: image.helpTools.listRoom,
    },
    {
        id: 'alarmDevice',
        title: 'alarmDevice',
        content: 'you_can_see_your_alarm_device_list_in_there',
        img: image.helpTools.alarmDevice,
    },
    {
        id: 'listSymbol',
        title: 'listSymbol',
        content: 'you_can_see_your_symbol_list_in_there',
        img: image.helpTools.listSymbol,
    },
];

function HelpButton({ title = '' }) {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const content = (id) => {
        switch (id) {
            case 'tools':
                return (
                    <div className={cx('tools-container')}>
                        {dataTools.map((item) => (
                            <div key={item.id} className={cx('item')}>
                                <img src={item.img} alt={item.title} />:
                                <p className={cx('content')}>{t(item.content)}</p>
                            </div>
                        ))}
                    </div>
                );

            case 'symbols':
                return (
                    <ul>
                        <h3>{t('draw_symbol_on_the_blueprint')}</h3>
                        <li>{t('first_choose_the_symbol_you_want_to_draw')}</li>
                        <li>{t('next_draw_symbol_on_the_blueprint_by_click')}</li>
                        <li>{t('you_can_move_symbol_by_edit_device_button')}</li>
                    </ul>
                );
            case 'adjust':
                return <p style={{ textAlign: 'center' }}>{t('updating...')}</p>;
            case 'energy':
                return <p style={{ textAlign: 'center' }}>{t('updating...')}</p>;
            default:
                return <p>{t('something_wrong')}</p>;
        }
    };

    return (
        <>
            <Tooltip title={`${t('need_help')}?`} placement="right">
                <button className={cx('button-help')} onClick={() => setOpen(true)}>
                    <i className="fa-regular fa-circle-question" />
                </button>
            </Tooltip>

            <Modal
                title={
                    <h2 style={{ textAlign: 'center' }}>
                        {t(title)} {t('help')}
                    </h2>
                }
                open={open}
                onCancel={() => setOpen(false)}
                footer={
                    <Button
                        onClick={() => setOpen(false)}
                        style={{ textTransform: 'capitalize' }}
                        size="large"
                        type="danger"
                    >
                        {t('understood')}
                    </Button>
                }
            >
                {content(title)}
            </Modal>
        </>
    );
}

export default HelpButton;

export default class ListAlarmDevice {
    static listPlaneMesh = [];

    static listCircle = [];

    static listPolygon = [];

    static getPlane(deviceId) {
        return ListAlarmDevice.listPlaneMesh.find((p) => p.deviceId === deviceId);
    }

    static addPlane(plane) {
        const index = ListAlarmDevice.listPlaneMesh.findIndex((p) => p.deviceId === plane.deviceId);
        if (index < 0) {
            ListAlarmDevice.listPlaneMesh.push(plane);
        } else {
            ListAlarmDevice.listPlaneMesh.splice(index, 1, plane);
        }
    }

    static removePlane(deviceId) {
        ListAlarmDevice.listPlaneMesh = [...ListAlarmDevice.listPlaneMesh].filter((p) => p.deviceId !== deviceId);
        window?.viewer?.overlays.removeScene(`custom-scene_${deviceId}`);
        window?.viewer?.overlays.removeMesh(`custom-scene_${deviceId}`);
    }

    static addCircle(circle) {
        const index = ListAlarmDevice.listCircle.findIndex((p) => p.deviceId === circle.deviceId);
        if (index < 0) {
            ListAlarmDevice.listCircle.push(circle);
        }
    }

    static removeCircle(deviceId) {
        ListAlarmDevice.listCircle = [...ListAlarmDevice.listCircle].filter((p) => p.deviceId !== deviceId);
    }

    static cleanup() {
        ListAlarmDevice.listPlaneMesh = [];
        ListAlarmDevice.listCircle = [];
        ListAlarmDevice.listPolygon = [];
    }
}

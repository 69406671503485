/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import { changeUnitAPI, getAlarm } from './alarmAction';
import { createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';

import { releaseProtectedData, removeProtectedData, saveProtectedData } from '~/helper/common';
import { changeCursor } from '~/helper/ForgeViewer/forge-viewer-tool';

const openNotification = (type, message) => {
    notification[type]({
        message,
        placement: 'topRight',
    });
};

const initialState = {
    alarms: null,
    devices: [],
    outSideList: [],
    alarmInfos: [],
    countAlarm: 0,
    unit: null,
    wrongUnit: false,
    protectDataAlarms: [],
    selectedAlarmInLayer: null,
    deviceId: null,
    isFetching: false,
    isError: false,
    isSuccess: false,
    errorMessage: [],
    activeEditDevice: false,
    deleteDeviceByKeyPress: null,
    onAddSymbolMode: false,
    symbol: null,
    editUnitMode: false,
    isFetchingUnitMode: false,
};

const alarmDeviceSlice = createSlice({
    name: 'alarm',
    initialState,
    reducers: {
        setOutSideRoom: (state, { payload }) => {
            state.outSideList = payload;
        },
        setDevices: (state, { payload }) => {
            state.devices = payload;
        },
        changeDeviceInList: (state, { payload }) => {
            state.devices = payload;
        },
        setOnDeviceMode: (state, { payload }) => {
            if (!payload) {
                state.onAddSymbolMode = false;
                state.symbol = null;
                changeCursor(false);
            } else {
                state.onAddSymbolMode = true;
                state.symbol = payload;
                changeCursor(true);
            }
        },
        deleteDeviceAction: (state, { payload }) => {
            state.deleteDeviceByKeyPress = payload;
        },
        setActiveEditDevice: (state, { payload }) => {
            state.activeEditDevice = payload;
        },
        setDeviceId: (state, { payload }) => {
            state.deviceId = payload;
        },
        setAlarmInfos: (state, { payload }) => {
            state.alarmInfos = payload;
        },
        addRoomToDevice: (state, { payload }) => {
            state.rooms = payload;
        },
        controlIsFetching: (state, { payload }) => {
            state.isFetching = payload;
        },
        clearStateAlarmDevice: (state) => {
            state.alarmInfos = null;
            state.alarms = [];
            state.countAlarm = 0;
            state.devices = [];
            state.unit = null;

            state.isFetching = false;
            state.isError = false;
            state.isSuccess = false;
            state.errorMessage = [];
        },
        saveProtectDataAlarms: (state, { payload }) => {
            state.protectDataAlarms = saveProtectedData(state.protectDataAlarms, payload);
        },
        releaseProtectDataAlarms: (state, { payload }) => {
            const result = releaseProtectedData(state.protectDataAlarms, payload);
            if (result) {
                state.alarms = result?.alarms || [];
                state.alarmInfos = result?.alarmInfos || [];
                state.countAlarm = result?.countAlarm || 0;
                state.unit = result?.unit || null;
            }
        },
        removeProtectDataAlarm: (state, { payload }) => {
            state.protectDataAlarms = removeProtectedData(state.protectDataAlarms, payload);
        },
        clearProtectDataAlarms: (state) => {
            state.protectDataAlarms = [];
        },
        setUnit: (state, { payload }) => {
            state.unit = payload;
        },
        setSelectedAlarmInLayerAlarmAction: (state, { payload }) => {
            state.selectedAlarmInLayer = payload;
        },
        controlIsSuccess: (state, { payload }) => {
            state.isSuccess = payload;
        },
        setWrongUnit: (state, { payload }) => {
            state.wrongUnit = payload;
        },
        controlModeUnit: (state, { payload }) => {
            state.editUnitMode = payload;
        },
        controlIsFetchingUnitMode: (state, { payload }) => {
            state.isFetchingUnitMode = payload;
        },
    },
    extraReducers: {
        [getAlarm.pending]: (state) => {
            state.isFetching = true;
            state.isSuccess = false;
            state.isError = false;
        },
        [getAlarm.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = false;
            state.isSuccess = true;
            state.errorMessage = [];
            state.alarmInfos = payload.result?.rooms;
        },
        [getAlarm.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = false;
            state.isError = true;
            // state.errorMessage = payload.result;
            openNotification('error', payload.errors);
        },
        [changeUnitAPI.pending]: (state) => {
            state.isFetchingUnitMode = true;
        },
        [changeUnitAPI.rejected]: (state) => {
            state.isFetchingUnitMode = false;
        },
    },
});

export const selectAlarmDevice = (state) => state.alarm;
export const successStatus = (state) => state.alarm.isSuccess;

export const {
    clearStateAlarmDevice,
    setOnDeviceMode,
    addNewAlarm,
    addRoomToDevice,
    setAlarmInfos,
    saveProtectDataAlarms,
    releaseProtectDataAlarms,
    removeProtectDataAlarm,
    clearProtectDataAlarms,
    setUnit,
    setDevices,
    setDeviceId,
    setSelectedAlarmInLayerAlarmAction,
    setActiveEditDevice,
    deleteDeviceAction,
    controlIsFetching,
    controlIsSuccess,
    setOutSideRoom,
    setWrongUnit,
    controlModeUnit,
    controlIsFetchingUnitMode,
} = alarmDeviceSlice.actions;

export default alarmDeviceSlice.reducer;

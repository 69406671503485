export const regexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

export const regexPhoneNum = /^(?:\+49|0)[1-9]\d{8,9}$/;

export const regexNotSpecialCharacter = /^[a-zA-Z0-9]*$/;

export const regexCheckTypeNum = /^[+\d]*$/;

export const regexCheckPostCode = /^[0-9-]*$/;

export const regexNotAllowWhiteSpaceAtFirstCharacter = /^(?!\s)[\w\s-]*$/

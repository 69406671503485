/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input } from 'antd';
import { useDebounce } from 'ahooks';
import { NumericFormat } from 'react-number-format';
import { RoomStatic } from '~/helper/ForgeViewer/speam/rooms/roomStatic';
import { convertUnitToString, getUnitsNumber } from '~/helper/ForgeViewer/forge-viewer-tool';

const toMeter = (input) => {
    const unitString = convertUnitToString(RoomStatic.unit);
    const result = (Number(input) / getUnitsNumber(unitString)).toFixed(2);
    return Number(result);
}

/**
 * Render FormItemLHSRoomNumberMeasurement
 */
export default function FormItemLHSRoomNumberMeasurement({ fields }) {
    const [roomWidthInput, setRoomWidthInput] = useState(toMeter(fields['roomWidth']?.value));
    const [roomLengthInput, setRoomLengthInput] = useState(toMeter(fields['roomLength']?.value));
    const [roomHeightInput, setRoomHeightInput] = useState(toMeter(fields['roomHeight']?.value) || 0);

    const [roomArea, setRoomArea] = useState('');
    const [roomVolume, setRoomVolume] = useState('');

    // -----------------------------------------------

    const { t } = useTranslation();
    // Get an instance of form
    const form = Form.useFormInstance();

    // -----------------------------------------------
    const roomHeightInputDebounce = useDebounce(Number(roomHeightInput), {wait: 500});

    /**
     * When submit form, we can choose the desired value
     * for corresponding field name
     */
    useEffect(() => {
        // Reset room height unit and set value to form
        const baseUnit = RoomStatic.unit
        const roomHeightBaseUnit = (Number(roomHeightInputDebounce) * getUnitsNumber(baseUnit)).toFixed(2);

        form.setFieldsValue({ roomWidth: roomWidthInput });
        form.setFieldsValue({ roomLength: roomLengthInput });
        form.setFieldsValue({ roomHeight: roomHeightBaseUnit });

        // TODO: need calculate room area and room volume
        setRoomArea(roomWidthInput * roomLengthInput);
        setRoomVolume(roomWidthInput * roomLengthInput * roomHeightInputDebounce);
    }, [roomLengthInput, roomWidthInput, roomHeightInputDebounce]);

    /**
     * Helper that sets input to zero number if user clears the input
     */
    useEffect(() => {
        const checkWhetherShouldSetInputToZero = (value, callback) => {
            if (value === undefined || value === '' || Number.isNaN(value) === true || value === 0) {
                callback(0);
            }
        };
        checkWhetherShouldSetInputToZero(roomWidthInput, setRoomWidthInput);
        checkWhetherShouldSetInputToZero(roomLengthInput, setRoomLengthInput);
        checkWhetherShouldSetInputToZero(roomHeightInput, setRoomHeightInput);
    }, [roomWidthInput, roomLengthInput, roomHeightInput]);

    /**
     * Array of formItem that is needed for rendering
     */
    const formListRoomNumberMeasurement = useMemo(
        () => [
            {
                form_item_name: 'roomWidth',
                form_item_label: `${t('room')} ${t('width')}`,
                form_item_value: roomWidthInput,
                form_item_callback: setRoomWidthInput,
            },
            {
                form_item_name: 'roomLength',
                form_item_label: `${t('room')} ${t('length')}`,
                form_item_value: roomLengthInput,
                form_item_callback: setRoomLengthInput,
            },
            {
                form_item_name: 'roomHeight',
                form_item_label: `${t('room')} ${t('height')}`,
                form_item_value: roomHeightInput,
                form_item_callback: setRoomHeightInput,
            },
        ],
        [roomWidthInput, roomHeightInput, roomLengthInput],
    );

    /**
     * Array of outputValues that is needed for rendering
     */
    const outputAreaAndVolumeNumber = useMemo(
        () => [
            {
                output_label: `${t('room')} ${t('area')}`,
                output_value: roomArea,
                output_super_value_unit: '2',
            },
            {
                output_label: `${t('room')} ${t('volume')}`,
                output_value: roomVolume,
                output_super_value_unit: '3',
            },
        ],
        [roomArea, roomVolume],
    );

    /**
     * Render FormItemRoomNumberMeasurement
     */
    const renderFormItemRoomNumberMeasurement = (formItemName, formItemLabel, formItemValue, formItemCallback) => (
        <div style={{ marginBottom: '8px', width: '155px' }}>
            <Form.Item name={formItemName} valuePropName="value" noStyle>
                <Input value={formItemValue} hidden />
            </Form.Item>
            <p>{formItemLabel}:</p>
            <NumericFormat
                customInput={Input}
                suffix={' '.concat(`m`)}
                thousandSeparator={t(',')}
                decimalSeparator={t('.')}
                style={{ textTransform: 'lowercase', marginLeft: '10px', maxHeight: '27px' }}
                onValueChange={(values) => {
                    formItemCallback(values.floatValue);
                }}
                value={formItemValue}
                placeholder={formItemLabel}
            />
        </div>
    );

    /**
     * Helper for rendering placeholder if have not any value
     */
    const renderValueOrPlaceholder = (outputLabel, outputValue) => {
        switch (outputValue) {
            case String(Number.NaN):
            case String(undefined):
            case '':
            case 0:
                return <span style={{ textTransform: 'lowercase', color: '#d9d9d9' }}>{outputLabel}</span>;
            default:
                return (
                    <NumericFormat
                        thousandSeparator={t(',')}
                        decimalSeparator={t('.')}
                        displayType="text"
                        value={Number(outputValue).toFixed(2)}
                        style={{
                            textTransform: 'lowercase',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                        }}
                    />
                );
        }
    };

    /**
     * Render RoomAreaAndRoomVolumeOutput
     */
    const renderOutputReadOnly = (outputLabel, outputValue, outputSuperValueUnit) => (
        <div style={{ marginRight: '0px' }}>
            <p>{outputLabel}:</p>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    maxWidth: '160px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '5px',
                    height: '27px',
                    marginLeft: '10px',
                    marginBottom: '8px',
                    paddingLeft: '8px',
                }}
            >
                {renderValueOrPlaceholder(outputLabel, outputValue)}
                <span style={{ marginInline: '5px' }}>
                    {`m`}
                    <sup>{outputSuperValueUnit}</sup>
                </span>
            </div>
        </div>
    );

    const contentRoomNumberMeasurement = (formItem) => {
        let content = null;

        if (formItem.form_item_name === 'roomHeight') {
            content = renderFormItemRoomNumberMeasurement(
                formItem.form_item_name,
                formItem.form_item_label,
                formItem.form_item_value,
                formItem.form_item_callback,
            );
        } else {
            content = renderOutputReadOnly(formItem.form_item_label, formItem.form_item_value, '');
        }

        return content;
    };

    // ========================================================================
    // Render FormItemLHSRoomNumberMeasurement
    // ========================================================================
    return (
        <>
            <Col flex="50%">
                {formListRoomNumberMeasurement.map((formItem, index) => (
                    <div key={`${formItem.form_item_name}-${index}`}>
                        {contentRoomNumberMeasurement(formItem)}
                    </div>
                ))}
            </Col>
            <Col flex="50%">
                {outputAreaAndVolumeNumber.map((output, index) => (
                    <div key={`${output.output_label}-${index}`}>
                        {renderOutputReadOnly(output.output_label, output.output_value, output.output_super_value_unit)}
                    </div>
                ))}
            </Col>
        </>
    );
}

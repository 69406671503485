/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable array-callback-return */

import styles from '../ToolViewer.module.scss';
import { Button, Divider, Empty, Popconfirm } from 'antd';
import classNames from 'classnames/bind';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SYMBOL_BMZ } from '~/helper/ForgeViewer/connected-line/common';
import { drawSymbol, insidePoly, removeDevice } from '~/helper/ForgeViewer/forge-viewer-tool';
import { makeSymbolListSymbolSelector } from '~/store/symbol/symbol.selectors';
import { removeSymbolByIdSymbolAction, selectSymbols } from '~/store/symbol/symbolSlice';
import { selectToolState } from '~/store/tool/toolSlice';
import {
    makeViewerCurrentRoomSettingIdSelector,
    makeViewerRoomsSelector,
    makeViewerShouldAlarmSystemBeInstalledSelector,
    makeViewerShouldApplySettingToAllRoomsSelector,
} from '~/store/viewer/viewer.selectors';
import { setCurrentRoomSettingIdViewerAction } from '~/store/viewer/viewer.slice';
import { setHashOfBMZWireAlarmAction } from '~/store/wire-alarm/wireAlarm.slice';

const cx = classNames.bind(styles);

function SymbolTool({ isOpen, changeOpenSubTool }) {
    // Management render state
    const [symbolsInRoom, setSymbolsInRoom] = useState({});
    const [list, setList] = useState([]);
    const [showDrawSymbol, setShowDrawSymbol] = useState(true);

    // selectors from symbolSlice
    const symbolList = useSelector(makeSymbolListSymbolSelector());

    // selectors from viewerSlice
    const roomsSelector = useSelector(makeViewerRoomsSelector());
    const shouldAlarmSystemBeInstalledSelector = useSelector(makeViewerShouldAlarmSystemBeInstalledSelector());
    const shouldApplySettingToAllRoomsSelector = useSelector(makeViewerShouldApplySettingToAllRoomsSelector());
    const currentRoomSettingIdSelector = useSelector(makeViewerCurrentRoomSettingIdSelector());
    const { toggleAllTools } = useSelector(selectToolState);
    // ===========================================

    // translator
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id: paramId } = useParams();

    useEffect(() => {
        if (symbolList && symbolList.length > 0) {
            const symbolsFromViewer = symbolList.filter((item) => item.fileId === paramId);

            // Helper that filter the BMZ symbol at the first drawing process

            if (symbolsFromViewer && symbolsFromViewer.length > 0) {
                symbolsFromViewer.forEach((s) => {
                    const isBMZCode = s?.symbol?.code === SYMBOL_BMZ.code;
                    const isBMZParent = s?.symbol?.parent === SYMBOL_BMZ.parent;

                    if (s?.isSymbol && isBMZCode && isBMZParent) {
                        roomsSelector?.map((room) => {
                            const symbolPosition = {
                                x: s?.x,
                                y: s?.y,
                                z: 3,
                            };

                            // determine whether this symbol is put in inside the current room
                            const doesRoomHasSymbol = insidePoly(room.layerCurrentPoints, symbolPosition);

                            // if it is exist, set it to the state with the roomId of current room
                            if (doesRoomHasSymbol) {
                                const helperObject = {};
                                helperObject[s?.id] = {
                                    ...s,
                                    type: 'symbol',
                                    roomId: room.roomId,
                                };
                                setSymbolsInRoom((prev) => ({ ...prev, ...helperObject }));
                            }
                        });
                    }
                });

                // Set all symbols to the list
                setList([...symbolsFromViewer]);
            }
            return;
        }

        // Reset List of Symbol if symbolList from symbolSlice has length = 0
        setSymbolsInRoom({});
        setList([]);
    }, [paramId, symbolList, roomsSelector]);

    useEffect(() => {
        const handleEndDragSymbol = async (polygonToolEvent) => {
            const d = polygonToolEvent?.detail;
            if (d.type === 'symbol' && !!d.symbolId) {
                const index = await list.findIndex((item) => item.id === d.symbolId);
                const data = await list.find((item) => item.id === d.symbolId);
                let findRoom;

                roomsSelector?.map((room) => {
                    const isInsideRoom = insidePoly(room.layerCurrentPoints, d.planeMesh.position);
                    if (isInsideRoom) findRoom = room;
                });

                const newData = {
                    ...data,
                    ...d.planeMesh.position,
                    symbolId: data.id,
                    type: 'symbol',
                    planeMesh: d.planeMesh,
                };

                if (findRoom) {
                    newData.roomId = findRoom.roomId;
                } else {
                    newData.roomId = 0;
                }

                // preparing data for the BMZ symbol
                const s = {
                    ...data,
                    ...d.planeMesh.position,
                    symbolId: data.id,
                    type: 'symbol',
                    // planeMesh: d.planeMesh,
                };
                const isBMZCode = s?.symbol?.code === SYMBOL_BMZ.code;
                const isBMZParent = s?.symbol?.parent === SYMBOL_BMZ.parent;
                const helperObject = {};

                if (isBMZCode && isBMZParent) {
                    // if the room that is found has a BMZ symbol, assign room id to helperObject
                    if (findRoom) {
                        helperObject[s?.id] = {
                            ...s,
                            roomId: findRoom.roomId,
                        };

                        // if symbolsInRoom does not include 's.id'
                        // so add it to the hash object of symbol
                        if (symbolsInRoom[s?.id] === undefined) {
                            setSymbolsInRoom((prev) => {
                                delete prev[s?.id];
                                return {
                                    ...prev,
                                    ...helperObject,
                                };
                            });
                        }
                        // else, this symbol is lived outside of the floor
                    } else {
                        helperObject[s?.id] = {
                            ...s,
                            roomId: '',
                        };
                    }

                    // if symbolsInRoom includes this 's.id'
                    if (symbolsInRoom[s?.id]) {
                        const hasRoomId = helperObject[s?.id]?.roomId;
                        const hasTheSameRoomId = hasRoomId === symbolsInRoom[s?.id]?.roomId;

                        if ((hasRoomId && hasTheSameRoomId) || (hasRoomId && !hasTheSameRoomId)) {
                            setSymbolsInRoom((prev) => {
                                delete prev[s?.id];
                                return {
                                    ...prev,
                                    ...helperObject,
                                };
                            });
                        }
                        if (!hasRoomId) {
                            setSymbolsInRoom((prev) => {
                                delete prev[s?.id];
                                return {
                                    ...prev,
                                };
                            });
                        }
                    }
                }

                // Set edited data to the List
                list.splice(index, 1, newData);
                setList(list);
            }
        };

        window.addEventListener('endDragCircle', handleEndDragSymbol);

        return () => {
            window.removeEventListener('endDragCircle', handleEndDragSymbol);
        };
    }, [list, symbolsInRoom, paramId]);

    // Controlling Wire Alarm Drawing
    useEffect(() => {
        if (!symbolsInRoom) {
            return;
        }
        const copiedSymbolsInRoom = { ...symbolsInRoom };

        // ----------------------------------------------------------------------------
        // For RoomItemSettingForm
        // When user chooses answer NO "should alarm system be installed...",
        // centered BMZ symbol in all rooms will be deleted
        // ----------------------------------------------------------------------------
        const regexCenteredBMZ = /^centered-bmz/;

        if (!shouldAlarmSystemBeInstalledSelector) {
            if (shouldApplySettingToAllRoomsSelector) {
                Object.keys(copiedSymbolsInRoom).forEach((sKey) => {
                    const hasCenteredBMZSymbol = regexCenteredBMZ.test(sKey);

                    if (hasCenteredBMZSymbol) {
                        delete copiedSymbolsInRoom[sKey];
                        // also delete in state of symbolsInRoom
                        setSymbolsInRoom((prev) => {
                            delete prev[sKey];
                            return { ...prev };
                        });
                    }
                });
            }

            if (!shouldApplySettingToAllRoomsSelector && currentRoomSettingIdSelector) {
                const regexCurrentRoomId = new RegExp(`${currentRoomSettingIdSelector}$`);

                Object.keys(copiedSymbolsInRoom).forEach((sKey) => {
                    const hasCenteredBMZSymbol = regexCenteredBMZ.test(sKey);
                    const matchedCurrentRoomId = regexCurrentRoomId.test(sKey);
                    const condition = hasCenteredBMZSymbol && matchedCurrentRoomId;

                    if (condition) {
                        delete copiedSymbolsInRoom[sKey];
                        // also delete in state of symbolsInRoom
                        setSymbolsInRoom((prev) => {
                            delete prev[sKey];
                            return { ...prev };
                        });
                    }
                });

                // reset current room setting's Id in store
                dispatch(setCurrentRoomSettingIdViewerAction(''));
            }
        }

        // dispatch to WireAlarm store
        dispatch(setHashOfBMZWireAlarmAction(JSON.parse(JSON.stringify({ ...copiedSymbolsInRoom }))));
    }, [
        symbolsInRoom,
        currentRoomSettingIdSelector,
        shouldAlarmSystemBeInstalledSelector,
        shouldApplySettingToAllRoomsSelector,
    ]);

    const handleShowList = useCallback(() => {
        list.forEach((item) => removeDevice(item.id));
        list.forEach((item) => drawSymbol({ x: item.x, y: item.y, isRight: true }, item.id, item.symbol));
        setShowDrawSymbol(false);
    }, [list]);

    const handleDelete = (id) => {
        removeDevice(id);
        dispatch(removeSymbolByIdSymbolAction(id));
    };

    return (
        <div style={{ display: toggleAllTools ? 'block' : 'none' }}>
            <div className={cx('sub-tools')} style={{ display: !isOpen ? 'none' : 'block' }}>
                <div
                    className={cx('sub-tools-back')}
                    onClick={() => changeOpenSubTool('listSymbol')}
                    aria-hidden="true"
                >
                    <i className="fa-solid fa-arrow-left" /> {t('back')}
                </div>

                <div className={cx('sub-tools-content')}>
                    <div className={cx('sub-tools-content__action')}>
                        <h2 style={{ margin: '5px 0' }}>{t('list_symbol')}</h2>
                        {list.length > 0 && (
                            <span>
                                ({list.length} {list.length > 1 ? 'symbols' : 'symbol'})
                            </span>
                        )}
                    </div>

                    {showDrawSymbol ? (
                        <div className={cx('sub-tools-content__nodata', 'margin-top')}>
                            <Button
                                onClick={handleShowList}
                                className="btn-custom btn-custom-large"
                                style={{ textTransform: 'uppercase' }}
                            >
                                {t('show_symbol_list')}
                            </Button>
                        </div>
                    ) : (
                        <>
                            {list.length === 0 ? (
                                <div className={cx('sub-tools-content__nodata')}>
                                    <Empty description={t('no_data')} />
                                    <Divider />
                                </div>
                            ) : (
                                <div className={cx('symbol-tool-list')}>
                                    {list.map((symbol, index) => (
                                        <div className={cx('symbol-tool-group')} key={index}>
                                            <div className={cx('symbol-tool-item')}>
                                                <img
                                                    alt="symbol"
                                                    src={`${window.location.origin}/assets/img/Symbol/${symbol?.symbol?.parent}/${symbol?.symbol?.code}`}
                                                    className={cx('symbol-tool-item__img')}
                                                />
                                                <p className={cx('symbol-tool-item__name')}>
                                                    {index + 1}. Id: {symbol.id}
                                                </p>

                                                <Popconfirm
                                                    title={t('do_you_want_to_delete_this_symbol?')}
                                                    onConfirm={() => handleDelete(symbol.id)}
                                                    okText={t('yes')}
                                                    cancelText={t('no')}
                                                    className={cx('trash-can')}
                                                >
                                                    <i
                                                        className="fa-solid fa-trash"
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '4px',
                                                        }}
                                                    />
                                                </Popconfirm>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SymbolTool;

/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { customMemo } from '~/helper/common';
// eslint-disable-next-line camelcase
import {
    draw_symbol_and_circle,
    drawSymbol,
    editDeviceTool,
    handleDeviceSelect,
    handleRoomSelect,
    removeDevice,
} from '~/helper/ForgeViewer/forge-viewer-tool';
import ListAlarmDevice from '~/helper/ForgeViewer/speam/alarmDevices/alarmDevice';
import {
    deleteDeviceAction,
    selectAlarmDevice,
    setOnDeviceMode,
    setSelectedAlarmInLayerAlarmAction,
} from '~/store/alarm-device/alarmDeviceSlice';
import { clearModalStateModalAction, openModalModalAction } from '~/store/modal/ModalSlice';
import { addSymbolSymbolAction, removeSymbolByIdSymbolAction } from '~/store/symbol/symbolSlice';
import { makeActiveEditRoomSelector } from '~/store/viewer/viewer.selectors';
import { setViewerLoadSuccessViewerAction } from '~/store/viewer/viewer.slice';
import { makeIsFetchingWASelector } from '~/store/wire-alarm/wireAlarm.selectors';

function EventTool() {
    const [isSuccessLoaded, setIsSuccessLoaded] = useState(false);

    const { id } = useParams();
    const dispatch = useDispatch();

    let key = Object.keys(window?.selection?.isSelected || {});
    let selectItem = window?.selection?.isSelected[key[0]];

    /* =======================================================================
     * IMPORTANT !!!
     *
     * This make sure that, all tools those are needed to interact with
     * viewer will work correctly
     * =======================================================================
     */
    const { activeEditDevice, onAddSymbolMode, symbol } = useSelector(selectAlarmDevice);
    const activeEditRoom = useSelector(makeActiveEditRoomSelector());
    const isFetchingWire = useSelector(makeIsFetchingWASelector());

    const { editUnitMode, unit } = useSelector(selectAlarmDevice);

    useEffect(() => {
        const handleViewerLoadSuccess = () => {
            setIsSuccessLoaded(true);
            dispatch(setViewerLoadSuccessViewerAction(true));
        }

        window.addEventListener('loadExtensionEdit2DSuccess', handleViewerLoadSuccess);
        return () => {
            window.removeEventListener('loadExtensionEdit2DSuccess', handleViewerLoadSuccess);
        };
    }, []);

    useEffect(() => {
        if (isFetchingWire) {
            window?.viewer?.toolController?.deactivateTool('Edit2_MoveTool_default');
        } else if (activeEditDevice && !isFetchingWire) {
            editDeviceTool();
        }
    }, [isFetchingWire, activeEditDevice]);

    useEffect(() => {
        /**
         * Register selection for in Layer of Viewer
         * @param {*} onSelectionChanged
         */
        const handleSelectionEvent = (onSelectionChanged) => {
            const selectedShape = onSelectionChanged.target.getFirstSelected();

            if (!!selectedShape && !editUnitMode && !isFetchingWire) {
                if (activeEditDevice) {
                    handleDeviceSelect(selectedShape);

                    if (!selectedShape.isCircle) {
                        // vẽ đường tròn
                        const planeMesh = ListAlarmDevice.listPlaneMesh.find(
                            (p) => p.deviceId === selectedShape.deviceId,
                        );

                        if (planeMesh) {
                            draw_symbol_and_circle(planeMesh);
                        }
                    }

                    const serializableSelectedShape = JSON.parse(JSON.stringify(selectedShape));

                    setTimeout(() => {
                        //   Action is dispatched when any changes to shapes in edit2D mode

                        dispatch(setSelectedAlarmInLayerAlarmAction(serializableSelectedShape));
                    }, 0);
                } else if (activeEditRoom) {
                    handleRoomSelect(selectedShape);
                }
            } else if (editUnitMode) {
                window.selection.isSelected = {};
            }
        };
        if (isSuccessLoaded) {
            // ============================================================

            // Add Event Listener
            // ======================================================================

            window?.selection?.addEventListener(
                Autodesk.Edit2D.Selection.Events.SELECTION_CHANGED,
                handleSelectionEvent,
            );
        }
        return () => {
            window?.selection?.removeEventListener(
                Autodesk.Edit2D.Selection.Events.SELECTION_CHANGED,
                handleSelectionEvent,
            );
        };
    }, [isSuccessLoaded, activeEditDevice, activeEditRoom, editUnitMode, isFetchingWire]);

    useEffect(() => {
        const putSymbol = (e) => {
            const idNewSymbol = uuid();
            const viewport = window.viewer.clientToWorld(e.offsetX, e.offsetY, true);
            const point = {
                x: viewport.point.x,
                y: viewport.point.y,
                isRight: true,
            };

            if (onAddSymbolMode) {
                drawSymbol(point, idNewSymbol, symbol);

                const newSymbol = {
                    x: viewport.point.x,
                    y: viewport.point.y,
                    id: idNewSymbol,
                    fileId: id,
                    isSymbol: true,
                    symbol,
                };
                dispatch(addSymbolSymbolAction(newSymbol));
            }
        };

        window?.viewer?.canvas.addEventListener('click', putSymbol);

        return () => window?.viewer?.canvas.removeEventListener('click', putSymbol);
    }, [onAddSymbolMode, symbol, editUnitMode]);

    useEffect(() => {
        const handleSrcollOnDeviceMode = () => {
            dispatch(setOnDeviceMode(symbol));
        };

        if (onAddSymbolMode) {
            window?.viewer?.addEventListener(Autodesk?.Viewing?.CAMERA_CHANGE_EVENT, handleSrcollOnDeviceMode);
        }

        return () => {
            window?.viewer?.removeEventListener(Autodesk?.Viewing?.CAMERA_CHANGE_EVENT, handleSrcollOnDeviceMode);
        };
    }, [symbol, Autodesk?.Viewing?.CAMERA_CHANGE_EVENT]);

    useEffect(() => {
        const handleEventKeyDown = (event) => {
            if ((event?.code === 'Delete' || event.keyCode === 46) && selectItem) {
                if (selectItem.isAlarmDevice || selectItem?.deviceId) {
                    // delete device
                    dispatch(deleteDeviceAction(selectItem?.deviceId || selectItem.id));
                }
                if (selectItem?.symbolId && selectItem.type === 'symbol') {
                    // delete symbol
                    dispatch(removeSymbolByIdSymbolAction(selectItem?.symbolId));
                    removeDevice(selectItem?.symbolId);
                }
            }
            if (event.key === 'Escape') {
                // end add symbol mode
                dispatch(setOnDeviceMode(false));
            }
        };

        if (activeEditDevice || onAddSymbolMode) window.addEventListener('keyup', handleEventKeyDown);

        return () => window.removeEventListener('keyup', handleEventKeyDown);
    }, [selectItem, activeEditDevice, onAddSymbolMode, symbol]);

    useEffect(() => {
        const handlePosMouseUnit = () => {
            const pickDone = window.viewer.getExtension('Autodesk.Measure')?.measureTool.areAllPicksSet();
            const select = Object.keys(window.selection.isSelected);

            if (!pickDone || select.length > 0) {
                window.selection.isSelected = {};
                return;
            }

            const measurement = window.viewer.getExtension('Autodesk.Measure').measureTool.getMeasurement(unit, 0);
            const pointList = measurement.picks.map((item) => ({ ...item.intersection }));
            let charactor = ' ';
            if (unit === 'in') charactor = '"';
            const distance = measurement.distance.split(charactor)[0];

            dispatch(
                openModalModalAction({
                    title: 'unitChange',
                    content: {
                        pointModels: pointList,
                        pointLayers: pointList,
                        distance,
                        unit,
                    },
                }),
            );
        };

        if (editUnitMode) window?.viewer?.canvas.addEventListener('click', handlePosMouseUnit);

        return () => window?.viewer?.canvas.removeEventListener('click', handlePosMouseUnit);
    }, [editUnitMode, unit, window?.viewer?.canvas]);

    useEffect(() => {
        const closeMode = async (e) => {
            if (e.key !== 'Escape') return;
            await window?.viewer?.getExtension('Autodesk.Measure')?.measureTool.deleteMeasurements();
            dispatch(clearModalStateModalAction());
            return;
        };

        if (editUnitMode) window?.addEventListener('keydown', closeMode);
        return () => window?.removeEventListener('keydown', closeMode);
    }, [editUnitMode]);
}

export default customMemo(EventTool);

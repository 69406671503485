import { addPrefixActionType } from '../helper';

const prefix = 'viewer-pdf/';

const ViewerPdfType = {
    OBSERVE_OPEN_VIEWER_PDF: 'observeOpenViewerPdf',
    SET_PROJECT_INFO: 'setProjectInfo',
    SET_BOTTOM_RIGHT_FRAME_INFO: 'setBottomRightFrameInfo',
    SET_CURRENT_CUSTOMER: 'setCurrentCustomer',
    SET_CURRENT_FILE: 'setCurrentFile',
    SET_CURRENT_FILE_ID: 'setCurrentFileId',
    CLEAR_VIEWER_PDF_STATE: 'clearViewerPdfState',
};

export default addPrefixActionType(prefix, ViewerPdfType);

import { helperWrapText } from '../../utils';

export function draw_A4_FrameInfoBottomRight(doc, leftMost, topMost, logoConfig, texts) {
    leftMost += 127;
    topMost += 132;

    const frameWidth = 140;
    const frameHeight = 58;

    const drawingInfo = {
        left: leftMost, // mm
        top: topMost, // mm
        width: frameWidth, // mm
        height: frameHeight, // mm
    };
    doc.rect(drawingInfo.left, drawingInfo.top, drawingInfo.width, drawingInfo.height);

    // s11: Objekt:
    doc.text(`${texts?.s11}:`, leftMost + 2, topMost + 6);
    // s12: Schäfer Etiketten GmbH & Co. KG
    doc.text(`${texts?.s12}`, leftMost + 30, topMost + 6);
    // ------------ Divider ------------
    doc.line(leftMost, topMost + 8, leftMost + frameWidth, topMost + 8);

    topMost += 8;

    // s21: 'Adresse',
    doc.text(`${texts?.s21}:`, leftMost + 2, topMost + 6);
    // s22: 'Nütinger Straße 62, 72649 Wolfschlugen',
    doc.text(`${texts?.s22}`, leftMost + 30, topMost + 6);
    // ------------ Divider ------------
    doc.line(leftMost, topMost + 8, leftMost + frameWidth, topMost + 8);

    topMost += 8;

    // s31: 'Planart',
    doc.text(`${texts?.s31}:`, leftMost + 2, topMost + 6);
    // s32: 'Projektierung 2. OG',
    doc.text(`${texts?.s32}`, leftMost + 30, topMost + 6);
    // ------------ Divider ------------
    doc.line(leftMost, topMost + 8, leftMost + frameWidth, topMost + 8);

    topMost += 8;

    // s41: 'Planersteller',
    doc.text(`${texts?.s41}:`, leftMost + 2, topMost + 6);

    // ----------- KFT logo -----------
    const img = new Image();
    img.src = logoConfig?.PATH;

    const imgWidth = logoConfig?.WIDTH;

    let aspectRatio = 1;
    let imgHeight = 0;

    // if image exist, set update ratio and height
    img.onload = () => {
        aspectRatio = img.naturalWidth / img.naturalHeight;
        imgHeight = Math.floor(imgWidth / aspectRatio);
    };
    // else set height to magic number
    img.onerror = () => {
        imgHeight = 15;
    };

    doc.addImage(
        img,
        'WEBP',
        leftMost + 6,
        topMost + 6 * 2 - 4,
        imgWidth,
        imgHeight,
        undefined,
        'FAST', // compression of image
    );

    const moreLeft = 6;
    const moreTop = 6;

    // s42: 'KFT Kraus Feuerschutztechnik e. K.',
    // s43: 'Friedric-List-Straße 32',
    // s44: '70771 Leinfelden-Echterdingen',

    const line = topMost + moreTop * 2; // Line height to start text at
    const lineHeight = moreTop;
    const leftMargin = leftMost + moreLeft + 50;
    const wrapWidth = 100;
    const longString = `${texts?.s42}`;

    helperWrapText(longString, doc, line, lineHeight, leftMargin, wrapWidth);

    // ------------ Divider ------------
    topMost += moreTop * 3;

    // --- vertical line ----
    doc.line(leftMost, topMost + 8, leftMost + frameWidth, topMost + 8);

    topMost += 8;

    // s51: 'Stand',
    doc.text(`${texts?.s51}:`, leftMost + 2, topMost + 6);
    // s52: '04 / 2019',
    doc.text(`${texts?.s52}`, leftMost + 46, topMost + 6);

    // vertical line
    doc.line(leftMost + frameWidth / 2, topMost, leftMost + frameWidth / 2, topMost + 8);

    const s62 = doc.getCurrentPageInfo().pageNumber;

    // s61: 'Seite',
    doc.text(`${texts?.s61}:`, leftMost + frameWidth / 2 + 2, topMost + 6);
    // s62: '4',
    doc.text(`${s62}`, leftMost + frameWidth / 2 + 16, topMost + 6);

    // -----------------------------------

    leftMost += -5 + frameWidth / 2;

    // --- vertical line ----
    doc.line(leftMost + frameWidth / 4, topMost, leftMost + frameWidth / 4, topMost + 8);

    leftMost += frameWidth / 4;

    // s71: 'Scale',
    doc.text(`${texts?.s71}:`, leftMost + 2, topMost + 6);
    // s62: '4',
    doc.text(`1 : ${texts.drawingScale}`, leftMost + 18, topMost + 6);
}

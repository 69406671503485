import api from '~/api';

/**
 * Support call api to Design automation and the result will be received from SignalR (AppConnection)
 * eg: connection.on('get-box', (response) => { ...do something...})
 * @param {*} fileId
 * @param {*} clientId
 */
export const fetchFloorBoundingBox = async (fileId, clientId) => {
    try {
        await api.post('api/v1/user/DesignAutomation/get-box', {
            fileId,
            clientId,
        });
    } catch (error) {
        console.error(error);
    }
};

/* eslint-disable no-undef */
/* eslint-disable dot-notation */
import { Vector2 } from 'three-full';
import { mapCoordinatesToModel, turnOffSelectionInViewer } from '../forge-viewer-tool';

/**
 * The info of BMZ symbol
 *   that is located at:
 *     ~\speam_frontend\src\assets\DataSymbol\demo.json
 *     ~\speam_frontend\src\assets\DataSymbol\data.json
 */
export const SYMBOL_BMZ = {
    parent: 'Feuerwehrzeichen',
    code: 'B05-0270.png',
};

/**
 * Return an Array of Vector2 points
 * @param {*} generatorCreateVector
 * @returns
 */
function createVectorsPoints(generatorCreateVector) {
    return generatorCreateVector.next().value;
}

/**
 * Watching for create Vector2 from each point
 * @param {*} points
 */
function* watchInitializeVector2(points) {
    const pointsVector2 = [];

    const length = points.length;
    let i = 0;

    while (i < length) {
        pointsVector2.push(new Vector2(points[i].x, points[i].y));
        // increment i
        i += 1;
    }

    yield pointsVector2;
}

/**
 * Helper for calculate the perimeter of a curve from its points
 * @param {*} pointsVector
 * @returns
 */
export function calculatePerimeter(pointsVector) {
    const modelPoints = pointsVector.map((point) => mapCoordinatesToModel(point));
    const pointsVector2 = createVectorsPoints(watchInitializeVector2(modelPoints));

    const length = pointsVector2?.length;
    let i = 0;
    let totalPerimeter = 0;

    while (i < length) {
        if (pointsVector[i + 1] === undefined) {
            break;
        }

        const current = pointsVector2[i];
        const next = pointsVector2[i + 1];

        totalPerimeter += current.distanceTo(next);

        i += 1;
    }

    return totalPerimeter;
}

/**
 * Helper for showing and hiding wire-alarm
 * @param {*} extraPointsAndDevicesPoints
 * @param {*} isShow
 * @returns
 */
export function toggleWiringAlarm(extraPointsAndDevicesPoints, isShow) {
    let totalPerimeter = 0;
    const wireType = 'wire';

    const orange = 'rgb(235,100,64)';

    if (Autodesk.Edit2D !== undefined && window.layer !== undefined) {
        //
        // if it is allowed for showing
        if (isShow) {
            const firstPoint = extraPointsAndDevicesPoints[0];
            const points = [...extraPointsAndDevicesPoints, firstPoint];
            const polygons = new Autodesk.Edit2D.Polyline(points);

            // ===========================================================
            // Temporary helper for selecting room
            polygons.isRoom = true;
            polygons.type = wireType;
            // ===========================================================

            totalPerimeter = calculatePerimeter(points);
            // -----------------------------------------------------------
            // set style
            const style = polygons.style;
            style.lineColor = orange;
            // -----------------------------------------------------------

            // draw all lines on viewer
            window.layer.addShape(polygons);

            // if it is allowed for Hiding
        } else {
            removeWireAlarmOnViewer(wireType);
        }
    }

    return totalPerimeter;
}

export function removeWireAlarmOnViewer(shapeType = 'wire') {
    // Turn off all selection in viewer
    turnOffSelectionInViewer();
    if (Autodesk.Edit2D !== undefined && window.layer !== undefined) {
        const shape = window?.layer?.shapes.find((s) => s.type === shapeType);
        if (shape?.length > 0) {
            window.layer.removeShape(shape);
        }
    }
}

// ------------------------------------------------------
// For testing wire drawing feature
// ------------------------------------------------------
export function drawWiring(extraPointsAndDevicesPoints, bmzPoint) {
    let totalPerimeter = 0;

    const orange = 'rgb(235,100,64)';

    if (Autodesk.Edit2D !== undefined && window.layer !== undefined) {
        const polygons = new Autodesk.Edit2D.Polyline(extraPointsAndDevicesPoints);
        // ===========================================================
        // Temporary helper for selecting room
        polygons.isRoom = true;
        polygons.type = 'wire';
        // ===========================================================
        totalPerimeter = calculatePerimeter(extraPointsAndDevicesPoints);
        // --------------
        // set style
        const style = polygons.style;
        style.lineColor = orange;
        // --------------

        window.layer.addShape(polygons);
        // drawSymbol(bmzPoint, uuid(),SYMBOL_BMZ);
    }
    return totalPerimeter;
}

/**
 * check answer of question ShouldAlarmSystemBeInstalled, that support
 * for drawing or removing centered BMZ symbol
 * @param {*} formFields
 * @returns true or false
 */
export function observeShouldAlarmSystemBeInstalledQuestion(formFields) {
    let shouldAlarmSystemBeInstalled = false;

    // This code will check if argument = remainedFields in 'onFinish' method
    const condition = formFields['qShouldFireAlarmSystemBeInstalled'];

    switch (condition) {
        case 0:
            shouldAlarmSystemBeInstalled = false;
            break;
        case 1:
            shouldAlarmSystemBeInstalled = true;
            break;
        default:
            break;
    }

    return shouldAlarmSystemBeInstalled;
}

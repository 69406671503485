import React from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import styles from './Header.module.scss';
import ChangeLanguage from '~/components/ChangeLanguage';
import UserDropdown from '~/components/UserDropdown';
import { setDeleteFile } from '~/store/files/filesSlice';
import { controlReset, setSubProjectList } from '~/store/project/projectSlice';
import { showBinListAction } from '~/store/bin-list/binListSlice';

const cx = classNames.bind(styles);

export default function Header() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const goHome = () => {
        sessionStorage.clear();
        dispatch(setDeleteFile([]));
        dispatch(setSubProjectList([]));
        dispatch(showBinListAction(false));
        dispatch(controlReset(true));
        navigate('/');
    };

    return (
        <div className={cx('header-container')}>
            <div className="container h-100">
                <div className={cx('header-container__content')}>
                    <div className={cx('header-logo')}>
                        <div onClick={goHome} className={cx('header-logo__brand')}>
                            <img
                                src={`${window.location.origin}/assets/img/logo_new.png`}
                                height="30px"
                                alt="Speam Logo"
                            />
                        </div>
                    </div>
                    <div className={cx('header-info')}>
                        <ChangeLanguage />
                        <UserDropdown />
                    </div>
                </div>
            </div>
        </div>
    );
}

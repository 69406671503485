import { showRoom } from '../../forge-viewer-tool';

/**
 * Room class
 */
export class Room {
    constructor(
        roomId,
        currentPoints,
        boxPoints,
        scale,
        layerCurrentPoints,
        layerBoxPoints,
        polyId,
        name,
        width,
        widthLayer,
        height,
        heightLayer,
        minX,
        minY,
        maxX,
        maxY,
        scaleLength,
        isLayer,
        isOpen,
        roomSetting = {},
    ) {
        this.roomId = roomId;
        this.currentPoints = currentPoints;
        this.boxPoints = boxPoints;
        this.scale = scale;
        this.layerCurrentPoints = layerCurrentPoints;
        this.layerBoxPoints = layerBoxPoints;
        this.polyId = polyId;
        this.name = name;
        this.width = width;
        this.widthLayer = widthLayer;
        this.height = height;
        this.heightLayer = heightLayer;
        this.minX = minX;
        this.minY = minY;
        this.maxX = maxX;
        this.maxY = maxY;
        this.scaleLength = scaleLength;
        this.isLayer = isLayer;
        this.isOpen = isOpen;
        this.roomSetting = roomSetting;

        // Binding all methods to this Room class
        this.toggleRoomInViewer = this.toggleRoomInViewer.bind(this);
        this.editRoomNameInViewer = this.editRoomNameInViewer.bind(this);
        this.clearCache = this.clearCache.bind(this);
    }

    // =======================================================================
    // Room actions in viewer
    // =======================================================================

    toggleRoomInViewer(isCheck) {
        window?.selection?.onLayerCleared();

        const roomTrans = showRoom(
            isCheck,
            this.roomId,
            this.name,
            this.isLayer,
            this.layerCurrentPoints.map((p) => ({ ...p })),
        );

        this.isLayer = roomTrans.isLayer;
        this.isOpen = roomTrans.isOpen;
    }

    editRoomNameInViewer(name) {
        this.name = name;
        // detach old label
        this.toggleRoomInViewer(true);
    }

    // =======================================================================
    // Clear Cache
    // =======================================================================

    clearCache() {
        this.roomId = '';
        this.currentPoints = [];
        this.boxPoints = [];
        this.scale = 1;
        this.layerCurrentPoints = [];
        this.layerBoxPoints = [];
        this.polyId = '';
        this.name = '';
        this.width = 0;
        this.widthLayer = 0;
        this.height = 0;
        this.heightLayer = 0;
        this.minX = 0;
        this.minY = 0;
        this.maxX = 0;
        this.maxY = 0;
        this.scaleLength = 0;
        this.isLayer = false;
        this.isOpen = false;
        this.roomSetting = {}
    }
}

/* eslint-disable react/jsx-boolean-value */
import { Checkbox, Col, Input, Row } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { customMemo } from '~/helper/common';
import useDebounce from '~/helper/hooks/useDebounce';
import { makeViewerFloorSettingsToolDataSelector } from '~/store/viewer/viewer.selectors';

function CheckBoxGroupData({ onChangeCheckBoxGroupData, defaultData }) {
    // State of checkboxGroupData
    const [isFireControlChecked, setIsFireControlChecked] = useState(false);
    const [isRWAChecked, setIsRWAChecked] = useState(false);
    const [isOtherChecked, setIsOtherChecked] = useState(false);
    const [otherInput, setOtherInput] = useState(undefined);

    const otherInputDebounce = useDebounce(otherInput, 500);

    // ------------------------------------------------------------------

    const { t } = useTranslation();

    // ------------------------------------------------------------------
    useEffect(() => {
        const a = defaultData?.fireControl?.checked;
        const b = defaultData?.rwa?.checked;
        const c = defaultData?.other?.checked;
        const d = defaultData?.other?.value;

        setIsFireControlChecked(a);
        setIsRWAChecked(b);
        setIsOtherChecked(c);
        setOtherInput(d);
    }, []);

    useEffect(() => {
        const result = {
            fireControl: { label: 'Fire Control', checked: false },
            RWA: { label: 'RWA', checked: false },
            other: { label: 'Other', checked: false, value: '' },
        };

        if (isFireControlChecked) {
            result.fireControl.checked = true;
            result.fireControl.value = true;
        }
        if (isRWAChecked) {
            result.RWA.checked = true;
            result.RWA.value = true;
        }
        if (isOtherChecked) {
            result.other.checked = true;
            result.other.value = otherInputDebounce || '';
        }

        onChangeCheckBoxGroupData(result);
    }, [isFireControlChecked, isRWAChecked, isOtherChecked, otherInputDebounce]);

    // ------------------------------------------------------------------

    // ================================================================================================================
    // Render CheckBoxGroupData
    // ================================================================================================================

    return (
        <Row style={{ border: '1px solid #b4b4b4', borderRadius: '8px', padding: '8px' }}>
            <Col span={24} style={{ display: 'flex', flexFlow: 'row nowrap' }}>
                <Checkbox
                    // defaultChecked={defaultData?.fireControl?.checked}
                    checked={isFireControlChecked}
                    onChange={(e) => {
                        setIsFireControlChecked(e.target.checked);
                    }}
                >
                    {t('fire_control')}
                </Checkbox>
                <Checkbox
                    // defaultChecked={defaultData?.RWA?.checked}
                    checked={isRWAChecked}
                    onChange={(e) => {
                        setIsRWAChecked(e.target.checked);
                    }}
                >
                    {t('rwa')}
                </Checkbox>
            </Col>
            <Col span={24} style={{ display: 'flex', flexFlow: 'row nowrap', marginTop: '8px', alignItems: 'center' }}>
                <Checkbox
                    // defaultChecked={defaultData?.other?.checked}
                    checked={isOtherChecked}
                    onChange={(e) => {
                        const checked = e.target.checked;
                        if (checked) {
                            setIsOtherChecked(e.target.checked);
                        } else {
                            setIsOtherChecked(e.target.checked);
                            setOtherInput(undefined);
                        }
                    }}
                >
                    {t('other')}
                </Checkbox>
                <Input
                    // defaultValue={defaultData?.other?.value}
                    disabled={!isOtherChecked}
                    type="text"
                    autoComplete="off"
                    aria-autocomplete="none"
                    style={{ textTransform: 'lowercase', maxHeight: '27px' }}
                    onChange={(e) => setOtherInput(e.target.value)}
                    value={otherInput}
                />
            </Col>
        </Row>
    );
}

// export default customMemo(CheckBoxGroupData);
export default CheckBoxGroupData;

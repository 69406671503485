/* eslint-disable no-continue */
/* eslint-disable prefer-template */
/* eslint-disable no-void */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable one-var */
/* eslint-disable default-param-last */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */

import { customPolygonTool } from './custom-tool/custom-tool';
import { reversePoints } from './radius-erase/radius-erase-function';
import ListAlarmDevice from './speam/alarmDevices/alarmDevice';
import { RoomStatic } from './speam/rooms/roomStatic';
import { v4 as uuid } from 'uuid';

const loadCustomTool = () => {};

const loadExtensionEdit2D = async () => {
    try {
        window.edit2d = await window.viewer.loadExtension('Autodesk.Edit2D');

        // Register all standard tools in default configuration
        window.edit2d.registerDefaultTools();

        window.tools = window.edit2d.defaultTools;

        const ctx = window.edit2d.defaultContext;

        window.layer = ctx.layer;
        window.selection = ctx.selection;
        window.undoStack = ctx.undoStack;
        window.roomLabel = [];

        // Load custom function
        customPolygonTool();
    } catch (err) {
        console.error(err);
    }
};

export const refreshShowRoom = (isRoomOpen, roomId, roomName, layerCurrentPoints) => {
    const label = window?.roomLabel?.find((l) => l.roomId === roomId);

    if (isRoomOpen) {
        deleteRoom(roomId);

        if (label) {
            detachLabelFromRoom(label);
        }

        const polygon = new Autodesk.Edit2D.Polygon(layerCurrentPoints || []);
        polygon.id = roomId;

        // Add flag for checking whether polygon is room
        polygon.isRoom = true;

        window.layer.addShape(polygon);

        attachLabelToRoom(polygon, roomId, roomName);
    } else {
        deleteRoom(roomId);

        if (label) {
            detachLabelFromRoom(label);
        }
    }

    return { isOpen: isRoomOpen };
};

const showRoom = (status, roomId, roomName, isLayer, layerCurrentPoints) => {
    if (isLayer) {
        const { isOpen } = refreshShowRoom(status, roomId, roomName, layerCurrentPoints);

        return { isLayer: true, isOpen };
    }
    const { isOpen } = refreshShowRoom(status, roomId, roomName, layerCurrentPoints);

    return { isLayer: true, isOpen };
};

const attachLabelToRoom = (shape, roomId, roomName) => {
    // Create a label for this current room (shape)
    const newLabel = new Autodesk.Edit2D.ShapeLabel(shape, window?.layer);

    // Set label name
    newLabel.setText(roomName);
    newLabel.setVisible(true);

    // Add room information to newLabel
    newLabel.roomId = roomId;
    newLabel.name = roomName;

    // Set new Label to the state
    window.roomLabel = [...window.roomLabel, newLabel];
};

const detachLabelFromRoom = (label) => {
    window.roomLabel.splice(window.roomLabel.indexOf(label), 1);
    label.dtor();
};

const deleteRoom = (roomId) => {
    const shape = window?.layer?.shapes?.find((s) => s.id === roomId);
    window?.layer?.removeShape(shape);
};

const deleteShape = (shapeId) => {
    const shape = window?.layer?.shapes?.find((s) => s.id === shapeId);
    window?.layer?.removeShape(shape);
};

const markRoomTool = () => {
    startTool(window.tools.polygonTool);
};

const editRoomTool = () => {
    startTool(window.tools.polygonEditTool);
};

const editDeviceTool = () => {
    startTool(window?.tools?.moveTool);
};

const mapCoordinates = (point) => {
    const vpXform = viewer?.model?.getPageToModelTransform(1);
    const newMatrix = new THREE.Matrix4();
    newMatrix.getInverse(vpXform);
    const modelPt = new THREE.Vector3()?.set(Math.round(point.x), Math.round(point.y), Math.round(point.z));
    const pt = modelPt?.applyMatrix4(newMatrix);
    return { x: pt.x, y: pt.y, z: pt.z };
};

const mapCoordinatesToModel = (point) => {
    const vpXform = window?.viewer?.model?.getPageToModelTransform(1);
    const modelPt1 = new THREE.Vector3()?.set(point.x, point.y, 0).applyMatrix4(vpXform);
    return { x: modelPt1.x, y: modelPt1.y, z: 0 };
};

const startTool = (tool) => {
    const controller = window?.viewer?.toolController;
    // Check if currently active tool is from Edit2D
    let activeTool = controller?.getActiveTool();
    const isEdit2D = activeTool && activeTool.getName().startsWith('Edit2');
    // deactivate any previous edit2d tool

    if (isEdit2D) {
        controller.deactivateTool(activeTool.getName());

        activeTool = null;
    }

    // stop editing tools
    if (!tool) {
        return;
    }
    controller.activateTool(tool.getName());
};

const getMinMaxOfCoordinates = (boxPoints) => {
    const minX = Math.min.apply(
        Math,
        boxPoints?.map((o) => o.x),
    );

    const minY = Math.min.apply(
        Math,
        boxPoints?.map((o) => o.y),
    );
    const maxX = Math.max.apply(
        Math,
        boxPoints?.map((o) => o.x),
    );
    const maxY = Math.max.apply(
        Math,
        boxPoints?.map((o) => o.y),
    );

    return { minX, minY, maxX, maxY };
};

const getLayerLength = (point1, point2) => {
    const vec1 = new THREE.Vector3().set(point1.x, point1.y, 0);
    const vec2 = new THREE.Vector3().set(point2.x, point2.y, 0);
    return vec1.distanceTo(vec2);
};

const getLengthFromAPI = (length, scaleLength) => length / scaleLength;

const getUnitsNumber = (units) => {
    switch (units) {
        case 'microinch':
            return 39370078.74;
        case 'MicroInches':
            return 39370078.74;
        case 'km':
            return 0.001;
        case 'Kilometers':
            return 0.001;
        case 'miles':
            return 0.000621;
        case 'Miles':
            return 0.000621;
        case 'feet':
            return 3.3;
        case 'mm':
            return 1000;
        case 'Millimeters':
            return 1000;
        case 'cm':
            return 100;
        case 'Centimeters':
            return 100;
        case 'dm':
            return 10;
        case 'Decimeters':
            return 10;
        case 'in':
            return 25.4;
        case 'Inches':
            return 25.4;
        default:
            return 1;
    }
};

const getUnitSquare = (units) => {
    switch (units) {
        case 'Millimeters':
            return 1000000;
        case 'Centimeters':
            return 10000;
        case 'Decimeters':
            return 100;
        case 'Inches':
            return 1550;
        default:
            return 1;
    }
};

const convertStringToUnit = (unit) => {
    switch (unit) {
        case 'Kilometers':
            return 'km';
        case 'Millimeters':
            return 'mm';
        case 'Centimeters':
            return 'cm';
        case 'Decimeters':
            return 'dm';
        case 'Inches':
            return 'in';
        case 'Meters':
            return 'm';
        default:
            return null;
    }
};

const convertUnitToString = (unit) => {
    switch (unit) {
        case 'mm':
            return 'Millimeters';
        case 'cm':
            return 'Centimeters';
        case 'dm':
            return 'Decimeters';
        case 'in':
            return 'Inches';
        case 'm':
            return 'Meters';
        case 'km':
            return 'Kilometers';
        case 'microinch':
            return 'MicroInches';
        default:
            return null;
    }
};

const getLengthTool = (point1, point2, scale = 1) => {
    let localScale = scale;

    if (scale === null || scale === undefined || scale === 0) {
        localScale = 1;
    }
    localScale = 1;
    const vec1 = new THREE.Vector3().set(point1.x, point1.y, 0);
    const vec2 = new THREE.Vector3().set(point2.x, point2.y, 0);
    let length = vec1.distanceTo(vec2);
    // const units = window.viewer.model.getDisplayUnit();
    return (length / localScale).toFixed(2);
};
/** End Private function */

// ================================================================
// Helper functions those are used in AlarmDeviceTool component
// ================================================================

const getCenterPointOfPolygon = (arr) => {
    const x = arr.map((p) => p.x);
    const y = arr.map((p) => p.y);
    const cx = (Math.min(...x) + Math.max(...x)) / 2;
    const cy = (Math.min(...y) + Math.max(...y)) / 2;
    return { x: cx, y: cy };
};

const showDataAlarm = (alarms, rooms) => {
    const result = [];
    alarms.forEach((coord) => {
        try {
            const addedDevice = [];

            // eslint-disable-next-line eqeqeq
            const room = rooms.find((r) => String(r.roomId) == String(coord.roomId));
            if (room) {
                let i;
                const amountAlarm = coord.amountSmokeAlarm;
                if (amountAlarm < 1000) {
                    for (i = 0; i < amountAlarm; i++) {
                        if (i === 0) {
                            const newId = uuid();
                            const firstDevice = {
                                x: room.minX + coord.distanceWallXLayer,
                                y: room.minY + coord.distanceWallYLayer,
                                isRight: true,
                            };

                            let isCheckInside = false;
                            for (const r of rooms) {
                                isCheckInside = insidePoly(r.layerCurrentPoints, firstDevice);
                                if (isCheckInside) {
                                    break;
                                }
                            }

                            if (!isCheckInside) {
                                // const centerPoint = getCenterPointOfPolygon(room.layerCurrentPoints);
                                const centerPoint = getRandomPointInPolygon(room.layerCurrentPoints);
                                result.push({
                                    ...coord,
                                    isRight: firstDevice.isRight,
                                    x: centerPoint.x,
                                    y: centerPoint.y,
                                    radius: coord.radiusLayer || coord.radius,
                                    id: `${newId}`,
                                    deviceId: `${newId}`,
                                    coordId: coord.id,
                                    roomId: room.roomId,
                                });
                            } else {
                                result.push({
                                    ...firstDevice,
                                    radius: coord.radiusLayer || coord.radius,
                                    id: `${newId}`,
                                    deviceId: `${newId}`,
                                    coordId: coord.id,
                                    roomId: room.roomId,
                                });
                            }

                            addedDevice.push({ ...firstDevice, deviceId: newId });
                        } else {
                            const beforeCoord = addedDevice[i - 1];
                            if (beforeCoord) {
                                // LOGIC check
                                if (beforeCoord.isRight) {
                                    const checkWidthRoom = beforeCoord.x + coord.distanceSmokeAlarmXLayer;
                                    if (checkWidthRoom < room.maxX) {
                                        const nextDevice = {
                                            x: checkWidthRoom,
                                            y: beforeCoord.y,
                                            isRight: true,
                                        };
                                        const newId = uuid();
                                        let isCheckInside = false;
                                        for (const r of rooms) {
                                            isCheckInside = insidePoly(r.layerCurrentPoints, nextDevice);
                                            if (isCheckInside) {
                                                break;
                                            }
                                        }
                                        if (!isCheckInside) {
                                            // const centerPoint = getCenterPointOfPolygon(room.layerCurrentPoints);
                                            const centerPoint = getRandomPointInPolygon(room.layerCurrentPoints);
                                            result.push({
                                                ...coord,
                                                isRight: nextDevice.isRight,
                                                x: centerPoint.x,
                                                y: centerPoint.y,
                                                radius: coord.radiusLayer || coord.radius,
                                                id: `${newId}`,
                                                deviceId: `${newId}`,
                                                coordId: coord.id,
                                                roomId: room.roomId,
                                                isActive: false,
                                            });
                                        } else {
                                            result.push({
                                                ...nextDevice,
                                                radius: coord.radiusLayer || coord.radius,
                                                id: `${newId}`,
                                                deviceId: `${newId}`,
                                                coordId: coord.id,
                                                roomId: room.roomId,
                                                isActive: false,
                                            });
                                        }

                                        addedDevice.push({ ...nextDevice, deviceId: newId });
                                    } else {
                                        const checkLengthRoom = beforeCoord.y + coord.distanceSmokeAlarmYLayer;
                                        if (checkLengthRoom < room.maxY) {
                                            const nextDevice = {
                                                x: beforeCoord.x,
                                                y: checkLengthRoom,
                                                isRight: false,
                                            };

                                            let isCheckInside = false;
                                            for (const r of rooms) {
                                                isCheckInside = insidePoly(r.layerCurrentPoints, nextDevice);
                                                if (isCheckInside) {
                                                    break;
                                                }
                                            }
                                            const newId = uuid();
                                            if (!isCheckInside) {
                                                // const centerPoint = getCenterPointOfPolygon(room.layerCurrentPoints);
                                                const centerPoint = getRandomPointInPolygon(room.layerCurrentPoints);
                                                result.push({
                                                    ...coord,
                                                    isRight: nextDevice.isRight,
                                                    x: centerPoint.x,
                                                    y: centerPoint.y,
                                                    radius: coord.radiusLayer,
                                                    id: `${newId}`,
                                                    deviceId: `${newId}`,
                                                    coordId: coord.id,
                                                    roomId: room.roomId,
                                                    isActive: false,
                                                });
                                            } else {
                                                result.push({
                                                    ...nextDevice,
                                                    radius: coord.radiusLayer || coord.radius,
                                                    id: `${newId}`,
                                                    deviceId: `${newId}`,
                                                    coordId: coord.id,
                                                    roomId: room.roomId,
                                                    isActive: false,
                                                });
                                            }

                                            addedDevice.push({ ...nextDevice, deviceId: newId });
                                        }
                                    }
                                } else {
                                    const checkWidthRoom = beforeCoord.x - coord.distanceSmokeAlarmXLayer;
                                    if (checkWidthRoom > room.minX) {
                                        const nextDevice = {
                                            x: checkWidthRoom,
                                            y: beforeCoord.y,
                                            isRight: false,
                                        };
                                        const newId = uuid();
                                        let isCheckInside = false;
                                        for (const r of rooms) {
                                            isCheckInside = insidePoly(r.layerCurrentPoints, nextDevice);
                                            if (isCheckInside) {
                                                break;
                                            }
                                        }
                                        if (!isCheckInside) {
                                            // const centerPoint = getCenterPointOfPolygon(room.layerCurrentPoints);
                                            const centerPoint = getRandomPointInPolygon(room.layerCurrentPoints);
                                            result.push({
                                                ...coord,
                                                isRight: nextDevice.isRight,
                                                x: centerPoint.x,
                                                y: centerPoint.y,
                                                radius: coord.radiusLayer || coord.radius,
                                                id: `${newId}`,
                                                deviceId: `${newId}`,
                                                coordId: coord.id,
                                                roomId: room.roomId,
                                                isActive: false,
                                            });
                                        } else {
                                            result.push({
                                                ...nextDevice,
                                                radius: coord.radiusLayer || coord.radius,
                                                id: `${newId}`,
                                                deviceId: `${newId}`,
                                                coordId: coord.id,
                                                roomId: room.roomId,
                                                isActive: false,
                                            });
                                        }

                                        addedDevice.push({ ...nextDevice, deviceId: newId });
                                    } else {
                                        const checkLengthRoom = beforeCoord.y + coord.distanceSmokeAlarmYLayer;
                                        if (checkLengthRoom < room.maxY) {
                                            const nextDevice = {
                                                x: beforeCoord.x,
                                                y: checkLengthRoom,
                                                isRight: true,
                                            };
                                            const newId = uuid();
                                            let isCheckInside = false;
                                            for (const r of rooms) {
                                                isCheckInside = insidePoly(r.layerCurrentPoints, nextDevice);
                                                if (isCheckInside) {
                                                    break;
                                                }
                                            }
                                            if (!isCheckInside) {
                                                // const centerPoint = getCenterPointOfPolygon(room.layerCurrentPoints);
                                                const centerPoint = getRandomPointInPolygon(room.layerCurrentPoints);
                                                result.push({
                                                    ...coord,
                                                    isRight: nextDevice.isRight,
                                                    x: centerPoint.x,
                                                    y: centerPoint.y,
                                                    radius: coord.radiusLayer || coord.radius,
                                                    id: `${newId}`,
                                                    deviceId: `${newId}`,
                                                    coordId: coord.id,
                                                    roomId: room.roomId,
                                                    isActive: false,
                                                });
                                            } else {
                                                result.push({
                                                    ...nextDevice,
                                                    radius: coord.radiusLayer || coord.radius,
                                                    id: `${newId}`,
                                                    deviceId: `${newId}`,
                                                    coordId: coord.id,
                                                    roomId: room.roomId,
                                                    isActive: false,
                                                });
                                            }

                                            addedDevice.push({ ...nextDevice, deviceId: newId });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } catch (err) {
            console.error('Error', err);
        }
    });

    return result;
};

/**
 *
 * @param {*} position
 * @param {*} radius
 * @param {*} deviceId
 * @param {*} isShowCircle : set visible for circle
 */
const insertDeviceSymbol = async (position, radius, deviceId, roomId, polygon, isDrawCircle = false) => {
    const loader = new THREE.TextureLoader();
    await loader.load(
        // resource URL
        `${window.location.origin}/assets/img/B12-0172.png`,

        // Function when resource is loaded
        (texture) => {
            // do something with the texture
            const material = new THREE.MeshBasicMaterial({
                map: texture,
                transparent: true,
            });
            const geometry2 = new THREE.PlaneBufferGeometry(0.15, 0.15, 0);
            const planeMesh = new THREE.Mesh(geometry2, material);

            planeMesh.position.set(position.x, position.y, 3);
            planeMesh.deviceId = deviceId;
            planeMesh.roomId = roomId;
            planeMesh.radius = radius;
            planeMesh.polygon = polygon;
            planeMesh.isCircle = false;

            window.viewer.overlays.addScene(`custom-scene_${planeMesh.deviceId}`);
            window.viewer.overlays.addMesh(planeMesh, `custom-scene_${planeMesh.deviceId}`);
            ListAlarmDevice.addPlane(planeMesh);

            if (isDrawCircle) {
                draw_symbol_and_circle(planeMesh, false);
            }
        },
        // Function called when download progresses
        (xhr) => {
            console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
        },
        // Function called when download errors
        (error) => {
            console.log('An error at: ', error);
        },
    );
};

// eslint-disable-next-line camelcase
const draw_symbol_and_circle = async (planeMesh, isShowSymbol = false, type = 'device') => {
    window.layer.removeShape(planeMesh?.polygon);
    const tool = window.tools.insertSymbolTool;
    const style = new Autodesk.Edit2D.Style();
    const circle = new Autodesk.Edit2D.Circle(0.0, 0.0, planeMesh.radius || 0.5, style.clone(), 42);

    planeMesh.isCircle = true;
    planeMesh.polygon = circle;

    if (isShowSymbol) {
        insertDeviceSymbol(
            planeMesh.position,
            planeMesh.radius,
            planeMesh.deviceId || planeMesh.id,
            planeMesh.roomId,
            circle,
            planeMesh,
        );
    }

    circle.style.lineWidth = 1;
    tool.symbol = circle;
    tool.handleAutoDrawOnlyCircle(planeMesh, type);
    window.layer.update();
};

const drawSymbol = (position, id, symbol) => {
    const loader = new THREE.TextureLoader();
    // load a resource
    if (symbol) {
        let circle;
        loader.load(
            // resource URL
            `${window?.location?.origin}/assets/img/Symbol/${symbol?.parent}/${symbol?.code}`,
            // Function when resource is loaded
            (texture) => {
                // do something with the texture
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                    transparent: true,
                });

                const tool = window.tools.insertSymbolTool;
                const style = new Autodesk.Edit2D.Style();
                if (symbol.ratio && symbol?.ratio > 6) {
                    circle = new Autodesk.Edit2D.Circle(0.0, 0.0, symbol?.ratio / 24, style.clone(), 42);
                } else {
                    circle = new Autodesk.Edit2D.Circle(0.0, 0.0, symbol?.ratio / 14 || 0.1, style.clone(), 42);
                }
                circle.symbolId = id;
                circle.deviceId = null;
                circle.style.fillColor = 'rgba(255,255,255)';
                circle.style.lineColor = 'rgba(255,255,255)';
                circle.style.lineWidth = 0.01;
                tool.symbol = circle;
                const geometry2 = new THREE.PlaneBufferGeometry(0.2, 0.2, 0);
                const planeMesh = new THREE.Mesh(geometry2, material);
                planeMesh.scale.set((symbol?.ratio || 1) * 0.8, 0.8, 1);
                planeMesh.visible = true;
                planeMesh.position.set(position.x, position.y, 3);
                window.viewer.overlays.addScene(`custom-scene_${circle.symbolId}`);
                window.viewer.overlays.addMesh(planeMesh, `custom-scene_${circle.symbolId}`);
                tool.handleAutoDraw(position, planeMesh, id, 'symbol');
            },
            // Function called when download progresses
            (xhr) => {
                console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
            },
            // Function called when download errors
            (error) => {
                console.log('An error at: ', error);
            },
        );
    } else return;
};

const removeDevice = (id) => {
    window?.viewer?.overlays?.removeScene(`custom-scene_${id}`);
    window?.viewer?.overlays?.removeMesh(`custom-scene_${id}`);

    window?.layer?.shapes?.forEach((shape) => {
        if (shape.deviceId === id || shape.symbolId === id) {
            window.layer.removeShape(shape);
        }
    });
    window?.layer?.update();
};

function getBoxPoint(polygon) {
    // Initialize min/max values to the first vertex of the polygon
    let minX = polygon[0].x;
    let maxX = polygon[0].x;
    let minY = polygon[0].y;
    let maxY = polygon[0].y;

    // Loop through all the vertices to find the minimum and maximum values
    for (let i = 1; i < polygon.length; i++) {
        const vertex = polygon[i];
        minX = Math.min(minX, vertex.x);
        maxX = Math.max(maxX, vertex.x);
        minY = Math.min(minY, vertex.y);
        maxY = Math.max(maxY, vertex.y);
    }

    // Create a rectangle that encloses the polygon
    const result = [];

    // <---^
    //     |
    //  --->
    result.push({ x: minX, y: minY, z: 0 });
    result.push({ x: maxX, y: minY, z: 0 });
    result.push({ x: maxX, y: maxY, z: 0 });
    result.push({ x: minX, y: maxY, z: 0 });
    return result;
}

/**
 * Helper function that support to transform polygon to include
 * room's information, that is used in viewer.slice
 *
 * @param {*} polygon
 * @param {*} room
 * @param {*} scale
 * @returns
 */
function transformPolygonToRoom(room) {
    room.currentPoints = reversePoints(room.currentPoints);
    // room.boxPoints = reversePoints(room.boxPoints);
    room.boxPoints = reversePoints(getBoxPoint(room.currentPoints));

    const layerCurrentPoints = room?.currentPoints?.map((point) => mapCoordinates(point));
    const layerBoxPoints = room?.boxPoints?.map((point) => mapCoordinates(point));

    const { minX, minY, maxX, maxY } = getMinMaxOfCoordinates(room?.boxPoints);

    const width = Number(getLengthTool({ x: minX, y: minY }, { x: maxX, y: minY }, room?.scale));
    const height = Number(getLengthTool({ x: minX, y: minY }, { x: minX, y: maxY }, room?.scale));

    const {
        minX: minXLayer,
        minY: minYLayer,
        maxX: maxXLayer,
        maxY: maxYLayer,
    } = getMinMaxOfCoordinates(layerBoxPoints);

    const widthLayer = Number(getLayerLength({ x: minXLayer, y: minYLayer }, { x: maxXLayer, y: minYLayer }));
    const heightLayer = Number(getLayerLength({ x: minXLayer, y: minYLayer }, { x: minXLayer, y: maxYLayer }));

    const scaleLength = Number(width / widthLayer);

    room.layerCurrentPoints = layerCurrentPoints;
    room.layerBoxPoints = layerBoxPoints;
    room.minX = minXLayer;
    room.minY = minYLayer;
    room.maxX = maxXLayer;
    room.maxY = maxYLayer;
    room.width = width;
    room.height = height;
    room.widthLayer = widthLayer;
    room.heightLayer = heightLayer;
    room.scaleLength = scaleLength;

    return room;
}

const turnOffSelectionInViewer = () => {
    // turn off all highlight selection in viewer
    window?.selection?.onLayerCleared();
};

const handleDeviceSelect = (selectItem) => {
    if (selectItem !== undefined) {
        if (selectItem.isRoom || selectItem.type === 'wire') {
            window.selection.onLayerCleared();
            // reset selected roomId
            RoomStatic.roomId = '';
        }
    }
};

const handleRoomSelect = (selectItem) => {
    if (selectItem !== undefined) {
        if (!selectItem.isRoom || selectItem.isAlarmDevice) {
            window.selection.onLayerCleared();
            window.selection.isSelected = {};
        } else return selectItem;
    }
};

const changeCursor = (boolean) => {
    const forgeCursor =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAABHVBMVEUAAABPTk4AAAAAAAAJCQkRERE0MzQQEBAODg4QEBB4d3dbWlo9PDw/Pj4vLy8sLCwZGBgWFhYcHBwKCgoSEhIAAAAKCgoICAgKCgoQEBAODg4EBAQICAgPDw8REREMDAx2dnY0NDQvLy9QUFAaGhomJSYjIyM7OjokJCQNDA0mJiYNDQ0AAAAUFBQJCQkQEBAEBAQNDQ0PDw8VFRX///+amJkAAAD5+fnz8/PKycn9/f339vbi4eLR0dDNzMyAgIB8e3xycHH7+/vw7+/o6OjX1ta7urq4t7iwsLCnp6eioqKbmppva21OTk74+Pjl5eXc3Nzb29vLy8vDw8PDwsKrqqqdnZ2WlpaSkpKTkZKMiouEg4NkZGRISEgxLzBpgbsEAAAANHRSTlMA+fiQXgngKSYG/vX17uvBuqackpCNg3BpUkpAPBwTDvj18+vl0s/NwrOwoZZ+TDg4NBkBGrzX8QAAAP5JREFUKM99j9Vuw0AQRdeuKZyGkyZNmbnXDLHDVGb8/8/oy7paK1bO0+oc7WiGnGiaxq+QRTQAOh8f9Jv4H/Ge8PZPrCdlvkxfYluUT2WyyCq3mZ7unwlKVLcqOzA/Mf71j0TWJ/Ym6rPeca05Ni4iIevYc7yoUD2zQFhq71BdI9nvBeBabFDSPe8DswlUc1Riw3VxbH0NHBUPQ0jrbDnPYDjALQBMq9E7nkC5y7VDKTZlUg8Q0lmjvl74zlYErgvKa42GPKf3/a0kQmYCDY1SYMDosqMoiWrGwz/uAbNvc/fNon4kXRKGq+PUo2Mb96afV0iUxqGU2s4VBbKUP65NL/LKF+7ZAAAAAElFTkSuQmCC';

    if (window?.viewer === null) {
        return;
    } else if (!boolean) {
        window.viewer.canvas.style.cursor = `url(${forgeCursor}), auto`;
    } else {
        window.viewer.canvas.style.cursor = `url(${window.location.origin}/assets/img/symbol-cursor.png), auto`;
    }
};

const insidePoly = (poly, point) => {
    let i;
    let j;
    let inside = false;
    for (i = 0, j = poly.length - 1; i < poly.length; j = i++) {
        if (
            poly[i].y > point.y !== poly[j].y > point.y &&
            point.x < ((poly[j].x - poly[i].x) * (point.y - poly[i].y)) / (poly[j].y - poly[i].y) + poly[i].x
        )
            inside = !inside;
    }
    return inside;
};

function getRandomPointInPolygon(vertices) {
    // Calculate the bounds of the polygon
    let minX = vertices[0].x;
    let maxX = vertices[0].x;
    let minY = vertices[0].y;
    let maxY = vertices[0].y;
    for (let i = 1; i < vertices.length; i++) {
        minX = Math.min(minX, vertices[i].x);
        maxX = Math.max(maxX, vertices[i].x);
        minY = Math.min(minY, vertices[i].y);
        maxY = Math.max(maxY, vertices[i].y);
    }

    // Generate a random point within the bounds of the polygon
    let point;
    do {
        point = {
            x: minX + Math.random() * (maxX - minX),
            y: minY + Math.random() * (maxY - minY),
        };
    } while (!isPointInsidePolygon(point, vertices));
    return point;
}

function isPointInsidePolygon(point, vertices) {
    // Use the ray-casting algorithm to determine if the point is inside the polygon
    let inside = false;
    for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
        if (
            vertices[i].y > point.y !== vertices[j].y > point.y &&
            point.x <
                ((vertices[j].x - vertices[i].x) * (point.y - vertices[i].y)) / (vertices[j].y - vertices[i].y) +
                    vertices[i].x
        ) {
            inside = !inside;
        }
    }
    return inside;
}

function activateSetUnit(enable) {
    if (!enable) {
        window?.viewer?.getExtension('Autodesk.Measure')?.measureTool.deleteMeasurements();
        window?.viewer?.getExtension('Autodesk.Measure')?.exitMeasurementMode();
        window?.viewer?.getExtension('Autodesk.Measure')?.deactivate();
    }
    window?.viewer?.getExtension('Autodesk.Measure')?.enableMeasureTool(enable, 1);
}

function activateRulerFreehand(enable) {
    if (enable) {
        window?.viewer?.getExtension('Autodesk.Measure')?.activate('distance');
        window?.viewer?.getExtension('Autodesk.Measure')?.setFreeMeasureMode(true);
    } else {
        window?.viewer?.getExtension('Autodesk.Measure')?.measureTool.deleteMeasurements();
        window?.viewer?.getExtension('Autodesk.Measure')?.deactivate();
    }
}

// ================================================================
export const findShapeIdListByRegex = (regexValue) => {
    if (!regexValue) {
        return;
    }

    const shapeIdList = [];

    window?.layer?.shapes.forEach((shape) => {
        if (regexValue.test(shape?.id)) {
            shapeIdList.push(shape?.id);
        }
    });

    return shapeIdList;
};

const removeRoomWithColor = (roomId) => {
    turnOffSelectionInViewer();
    const label = window?.roomLabel.find((l) => l.roomId === roomId);

    deleteRoom(roomId);

    if (label) {
        detachLabelFromRoom(label);
    }
};

export const toggleRoomWithColor = (isShown, roomId, roomName, layerCurrentPoints, rgbColor) => {
    turnOffSelectionInViewer();
    const label = window?.roomLabel.find((l) => l.roomId === roomId);

    if (isShown) {
        deleteRoom(roomId);

        if (label) {
            detachLabelFromRoom(label);
        }

        const polygon = new Autodesk.Edit2D.Polygon(layerCurrentPoints || []);
        polygon.id = roomId;

        // Add flag for checking whether polygon is room
        polygon.isRoom = true;
        // Set style
        const style = polygon.style;
        style.fillColor = `rgb(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b})`;

        window.layer.addShape(polygon);

        attachLabelToRoom(polygon, roomId, roomName);
    } else {
        deleteRoom(roomId);

        if (label) {
            detachLabelFromRoom(label);
        }
    }
};

// ================================================================

export {
    drawSymbol,
    changeCursor,
    handleDeviceSelect,
    handleRoomSelect,
    showDataAlarm,
    getLengthFromAPI,
    getLengthTool,
    getLayerLength,
    loadCustomTool,
    loadExtensionEdit2D,
    markRoomTool,
    editRoomTool,
    editDeviceTool,
    attachLabelToRoom,
    detachLabelFromRoom,
    showRoom,
    mapCoordinates,
    mapCoordinatesToModel,
    deleteRoom,
    startTool,
    getMinMaxOfCoordinates,
    insertDeviceSymbol,
    removeDevice,
    transformPolygonToRoom,
    getUnitsNumber,
    getUnitSquare,
    turnOffSelectionInViewer,
    insidePoly,
    // eslint-disable-next-line camelcase
    draw_symbol_and_circle,
    convertStringToUnit,
    convertUnitToString,
    getRandomPointInPolygon,
    getCenterPointOfPolygon,
    activateSetUnit,
    activateRulerFreehand,
    removeRoomWithColor,
    deleteShape,
};

/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */
/* eslint-disable no-var */
/* eslint-disable no-new */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */

import InformationForm from '../InformationForm/InformationForm';
import styles from '../ModalControll.module.scss';
import UploadArea from './UploadArea/UploadArea';
import {
    Button,
    Checkbox,
    Dropdown,
    Empty,
    Image,
    Input,
    message,
    Modal,
    Popconfirm,
    Popover,
    Radio,
    Select,
    Switch,
    Tooltip,
    Upload,
} from 'antd';
import classNames from 'classnames/bind';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { image } from '~/assets';
import { ImagesThin } from '~/assets/images/svgFile';
import AppLoading from '~/components/AppLoading';
import useDebounce from '~/helper/hooks/useDebounce';
import { regexCheckTypeNum, regexNotSpecialCharacter } from '~/helper/regex';
import SearchResult from '~/pages/Home/MainHome/SearchResult/SearchResult';
import { controlFetchingGeneral, generalSelector } from '~/store/generalData/generalSlice';
import { clearModalStateModalAction } from '~/store/modal/ModalSlice';
import { createProject, getDefaultName, getOneProject, getTabs, updateProject } from '~/store/project/projectAction';
import {
    clearDefaultName,
    controlTabsFetching,
    projectSelector,
    setProject,
    setTabSelected,
} from '~/store/project/projectSlice';

const cx = classNames.bind(styles);

const { Option } = Select;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

function ModalUpload({ open, uploadFile, button, data }) {
    const [editMode, setEditMode] = useState(false);
    const [inputFields, setInputFields] = useState([]);
    const [listUpload, setListUpload] = useState([]);
    const [newFieldName, setNewFieldName] = useState('');
    const [errorNewField, setErrorNewField] = useState(null);
    const [checked, setCheked] = useState(true);
    const [newFieldOpen, setNewFieldOpen] = useState(false);
    const [valueSearch, setValueSearch] = useState('');
    const [searchList, setSearchList] = useState([]);
    const [checkSaveTime, setCheckSaveTime] = useState(false);
    const [saveTime, setSaveTime] = useState(0);
    const [disabledTabs, setDisabledTabs] = useState(true);
    const [previewImage, setPreviewImage] = useState(null);
    const [fileList, setFileList] = useState([]);

    const [change, setChange] = useState(false);

    const { tabs, tabSelected, tabsFetching, isFetchingGetOne, projectOpen, defaultName } =
        useSelector(projectSelector);
    const { customerList, operatorList } = useSelector(generalSelector);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const newFieldRef = useRef();
    const debouncedValue = useDebounce(valueSearch, 300);

    // ---------------------------------------------------------------------------------
    // Need to use Translation => Put it here
    // ---------------------------------------------------------------------------------
    const dataFields = [
        {
            id: 'name',
            name: 'project_name',
            edit: false,
            hide: false,
            eyes: false,
            remove: false,
            value: '',
            type: 'input',
            width: '70%',
        },
        {
            id: 'customerId',
            name: 'customer',
            edit: false,
            hide: false,
            eyes: false,
            remove: false,
            value: null,
            type: 'select',
            generalId: null,
            width: '70%',
        },
        {
            id: 'operatorId',
            name: 'operator',
            edit: false,
            eyes: false,
            hide: false,
            remove: false,
            value: null,
            type: 'select',
            generalId: null,
            width: '70%',
        },
        {
            id: 'objectType',
            name: 'object_type',
            edit: false,
            eyes: false,
            hide: false,
            remove: false,
            value: null,
            type: 'select',
            options: [
                {
                    id: 'office',
                    name: `${t('office')}`,
                    label: `${t('office')}`,
                    value: 'Office',
                },
                {
                    id: 'industrial-construction',
                    name: `${t('industrial_construction')}`,
                    label: `${t('industrial_construction')}`,
                    value: 'Industrial construction',
                },
                {
                    id: 'hotel',
                    name: `${t('hotel')}`,
                    label: `${t('hotel')}`,
                    value: 'Hotel',
                },
                {
                    id: 'residential-Building',
                    name: `${t('residential_building')}`,
                    label: `${t('residential_building')}`,
                    value: 'Residential building',
                },
                {
                    id: 'warehouse',
                    name: `${t('warehouse')}`,
                    label: `${t('warehouse')}`,
                    value: 'Warehouse',
                },
                {
                    id: 'other',
                    name: `${t('other_(with_the_possibility_to_add_a_description)')}`,
                    label: `${t('other_(with_the_possibility_to_add_a_description)')}`,
                    value: 'Other (with the possibility to add a description)',
                },
            ],
        },
        {
            id: 'alerts',
            name: 'what_kind_of_alerts_are_required',
            type: 'checkbox',
            options: [
                {
                    id: 'houseAlarm',
                    name: 'house_alarm',
                    check: false,
                },
                {
                    id: 'fireBrigade',
                    name: 'fire_brigade_activation',
                    check: false,
                },
            ],
        },

        {
            id: 'protected',
            name: 'how_should_the_building_be_protected',
            type: 'radio',
            options: [
                {
                    id: 'fullProtection',
                    name: 'full_protection',
                    check: true,
                    value: 1,
                },
                {
                    id: 'partialProtection',
                    name: 'partial_protection',
                    check: false,
                    value: 2,
                },
            ],
        },
        {
            id: 'plans',
            name: 'what_do_you_want_to_plan',
            type: 'checkbox',
            options: [
                {
                    id: 'BWA',
                    name: 'BWA',
                    check: false,
                },
                {
                    id: 'BMA',
                    name: 'BMA',
                    check: false,
                },
                {
                    id: 'NBA',
                    name: 'NBA',
                    check: false,
                },
                {
                    id: 'FL',
                    name: 'FL',
                    check: false,
                },
            ],
        },
        {
            id: 'tab',
            name: 'tab',
            file: null,
            type: 'pdf',
        },
    ];

    // ---------------------------------------------------------------------------------

    useEffect(() => {
        if (open) {
            const newList = () =>
                dataFields.map((field) => {
                    if (field.type === 'input') {
                        field.value = '';
                    }
                    if (field.type === 'select') {
                        field.value = null;
                    }
                    if (field.type === 'checkbox') {
                        field?.options.map((item) => {
                            item.check = false;
                            return { ...item };
                        });
                    }
                    return { ...field };
                });
            setInputFields(newList);
        }
    }, []);

    useEffect(() => {
        if (uploadFile) {
            setEditMode(true);
        }
        if (!!data) {
            dispatch(getOneProject({ id: data.id, isDeleted: false, dispatch, t }));
        }
        if (open) {
            if (!tabsFetching && (!tabs || tabs.length < 1)) {
                dispatch(getTabs({ t }));
                return;
            }
        }
    }, [data, open, uploadFile, tabs, tabsFetching]);

    useEffect(() => {
        if (!!defaultName) {
            setInputFields(() =>
                inputFields.map((item) => {
                    if (item.id === 'name') {
                        item.value = defaultName;
                    }
                    return { ...item };
                }),
            );
        } else if (!defaultName && !tabsFetching && open) {
            dispatch(getDefaultName({ t }));
            return;
        }
    }, [defaultName, open, tabsFetching]);

    const handleAddNewField = async (id, type) => {
        const idField = newFieldName.split(' ').join('');

        if (newFieldName !== '') {
            if (!type) {
                const checkExist = inputFields.find((field) => field.name.toLowerCase() === newFieldName.toLowerCase());
                if (regexNotSpecialCharacter.test(idField) && !checkExist) {
                    const newField = {
                        id: idField,
                        name: newFieldName,
                        edit: true,
                        hide: checked,
                        eyes: true,
                        remove: true,
                        value: '',
                        type: 'input',
                        dynamic: true,
                    };

                    if (checked) {
                        newField.eyes = true;
                    } else {
                        newField.eyes = false;
                    }

                    setInputFields([...inputFields, newField]);

                    setNewFieldOpen(false);
                    setNewFieldName('');
                } else {
                    setErrorNewField('this_field_is_invalid');
                    if (checkExist) setErrorNewField('field_name_is_exist');
                }
            } else if (type === 'change') {
                const checkExistName = inputFields.find((field) => field.name === newFieldName);
                if (checkExistName) {
                    setErrorNewField('field_name_is_exist');
                    return;
                }

                const dataChange = inputFields.find((field) => field.id === id);
                const index = inputFields.findIndex((field) => field.id === id);

                await inputFields.splice(index, 1, {
                    ...dataChange,
                    id: idField,
                    name: newFieldName,
                });
                setInputFields(inputFields);
                setNewFieldName('');
            }
        } else {
            setErrorNewField(`${t('please_type_something')}!`);
        }
    };

    const handleChoose = useCallback(
        (value, id) => {
            const newArr = inputFields.map((item) => {
                if (item.id === id && item.type === 'select') {
                    item.value = value;
                    if (item.name === 'customer') {
                        item.general = customerList.find((customer) => customer.name === value);
                    }
                    if (item.name === 'operator') {
                        item.general = operatorList.find((operator) => operator.name === value);
                    }
                    if (item.id === 'objectType') {
                        item.value = value;
                    }
                }
                return { ...item };
            });
            setInputFields(newArr);
        },
        [searchList, inputFields, customerList, operatorList],
    );

    const handleRemoveField = (id) => {
        setInputFields(() => inputFields.filter((field) => field.id !== id));
    };

    const handleChangeInput = (e, id) => {
        const newList = inputFields.map((field) => {
            if (field.id === id) {
                field.value = e.target.value;
                field.error = false;
            }
            return { ...field };
        });

        setInputFields(newList);
    };

    const handleShowContent = (id) => {
        const newListInput = inputFields.map((input) => {
            if (input.id === id) {
                input.hide = !input.hide;
            }
            return { ...input };
        });
        setInputFields(newListInput);
    };

    const handleUpload = async () => {
        if (editMode) {
            const request = {
                image: null,
                name: '',
                customerId: null,
                operatorId: null,
                objectType: null,
                dynamicFields: [],
                tab: '',
                alerts: [],
                protected: 0,
                plans: [],
                saveTime: null,
            };
            let newDynamic = [];
            await inputFields.forEach((field) => {
                if (field.type === 'input') {
                    if (field.dynamic) {
                        newDynamic.push(field);
                    }
                    if (field.id === 'name') {
                        request[field.id] = field.value;
                    }
                }

                if (field.type === 'select') {
                    if (field.id === 'objectType') {
                        request.objectType = field.value;
                    }
                    if (field.id === 'customerId') {
                        const customer = customerList.find((item) => item.name === field.value);
                        request[field.id] = customer?.id ?? '';
                    }
                    if (field.id === 'operatorId') {
                        const operator = operatorList.find((item) => item.name === field.value);
                        request[field.id] = operator?.id ?? '';
                    }
                }

                if (field.type === 'radio') {
                    field.options.map((item) => {
                        if (item.check) {
                            request[field.id] = item.value;
                        }
                    });
                }
                if (field.type === 'checkbox') {
                    request[field.id] = [JSON.stringify(field.options)];
                }
            });

            if (fileList && fileList.length > 0) {
                request.image = fileList[0].originFileObj;
            }

            if (!tabSelected || disabledTabs) {
                request.tab = '';
            } else {
                request.tab = JSON.stringify(tabSelected);
            }

            if (checkSaveTime && saveTime > 0) {
                request.saveTime = Number(saveTime);
            }

            if (newDynamic.length > 0) {
                request.dynamicFields = JSON.stringify(newDynamic);
            }
            if (uploadFile) {
                if (request?.name !== '') {
                    dispatch(createProject({ request, listUpload, dispatch, t }));
                    onCancel();
                } else {
                    message.warning({
                        content: t('something_missing'),
                        duration: 1,
                    });
                }
            } else {
                dispatch(updateProject({ id: projectOpen.id, request, t }));
                onCancel();
            }
        } else {
            onCancel();
        }
    };

    const onCancel = () => {
        dispatch(clearDefaultName());
        setListUpload([]);
        dispatch(controlFetchingGeneral(false));
        dispatch(controlTabsFetching(false));
        dispatch(setTabSelected(null));
        dispatch(setProject(null));
        dispatch(clearModalStateModalAction());
    };

    const handleCheck = (parentId, id, status) => {
        inputFields.map((field) => {
            if (field.id === parentId) {
                field.options.map((item) => {
                    if (item.id === id) {
                        if (item.id === 'fireBrigade') {
                            setDisabledTabs(!status);
                        }
                        item.check = status;
                    } else {
                        if (field.type === 'radio') {
                            item.check = !status;
                        }
                    }

                    return { ...item };
                });
                return { ...field };
            }
        });
        setChange(!change);
    };

    const changeEditMode = useCallback(() => {
        let dynamicFieldList = [];
        let current = editMode;
        setEditMode(!current);

        if (!editMode) {
            const newFields = inputFields.map((field) => {
                if (field.type === 'input') {
                    field.value = projectOpen[field.id];
                }
                if (field.type === 'select') {
                    if (field.id === 'customerId') {
                        const customer = customerList.find((item) => item.id === projectOpen.customerId);
                        field.value = customer?.name || '';
                    }
                    if (field.id === 'operatorId') {
                        const operator = operatorList.find((item) => item.id === projectOpen.operatorId);
                        field.value = operator?.name || '';
                    }
                    if (field.id === 'objectType') {
                        const type = field.options.find((f) => f.value === projectOpen.objectType);
                        field.value = type?.value || '';
                    }
                }
                if (field.type === 'checkbox') {
                    field.options = JSON.parse(projectOpen[field.id]);
                }
                if (field.type === 'radio') {
                    field.options.map((f) => {
                        f.check = false;
                        if (f.value === projectOpen[field.id]) {
                            f.check = true;
                        }
                        return { ...f };
                    });
                }

                return { ...field };
            });

            if (projectOpen?.dynamicFields && projectOpen?.dynamicFields !== '') {
                const parseData = JSON.parse(projectOpen.dynamicFields);
                dynamicFieldList = parseData;
            }
            if (projectOpen?.tab !== '') {
                const tabParse = JSON.parse(projectOpen.tab);
                if (!!tabSelected && tabParse.id === tabSelected?.id) return;
                dispatch(setTabSelected(JSON.parse(projectOpen.tab)));
            }
            if (projectOpen?.saveTime) {
                setCheckSaveTime(true);
                setSaveTime(projectOpen.saveTime);
            }
            console.log([...newFields, ...dynamicFieldList]);
            setInputFields([...newFields, ...dynamicFieldList]);
        }
    }, [editMode, dataFields, inputFields, projectOpen]);

    const handleSetList = (id) => {
        if (!customerList && !operatorList) return;
        let list = [];
        if (id === 'customerId') {
            list = customerList.map((item) => ({
                value: item.name,
                label: item.name,
                id: item.id,
                type: 0,
            }));
            setSearchList(list);
        }
        if (id === 'operatorId') {
            list = operatorList.map((item) => ({
                value: item.name,
                label: item.name,
                id: item.id,
                type: 0,
            }));
            setSearchList(list);
        }
    };

    const handleChange = (e) => {
        new Promise(() => {
            if (e.file.status === 'removed') {
                setFileList([]);
            } else {
                setFileList([{ ...e.file, url: e.file.thumbUrl, status: 'success' }]);
            }
        });
    };

    const handlePreview = async (file) => {
        if (!file?.url && !file?.preview) {
            file.preview = await getBase64(file?.originFileObj);
        }
        setPreviewImage(file?.url || file?.preview);
    };

    const handleChangeSaveTime = (e) => {
        const isNum = regexCheckTypeNum.test(e.target.value);
        if (!isNum) return;
        if (e.target.value < 0 || e.target.value > 60) {
            setSaveTime(1);
        } else {
            setSaveTime(e.target.value);
        }
    };

    return (
        <Modal
            width={editMode ? '70%' : '40%'}
            open={open}
            onCancel={onCancel}
            onOk={handleUpload}
            okText={t(editMode ? button : 'OK')}
            cancelText={t('cancel')}
            okType="danger primary"
            title={
                uploadFile ? (
                    t('create_new_project')
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0 }}>{t('information')}</h3>
                        <Tooltip title={editMode ? t('back') : t('edit')} placement="right">
                            <span className={cx('edit-btn')} onClick={changeEditMode}>
                                {editMode ? (
                                    <i className="fa-solid fa-arrow-left-to-line" />
                                ) : (
                                    <i className="fa-solid fa-pen-to-square" />
                                )}
                            </span>
                        </Tooltip>
                    </div>
                )
            }
        >
            {(isFetchingGetOne || tabsFetching) && <AppLoading menuTool />}

            {editMode ? (
                <div className={cx('body-modal-upload')}>
                    <div className={cx('body-modal-upload__child', 'child-w-40')} key="image">
                        <Upload
                            key="upload"
                            action=""
                            listType="picture-card"
                            onPreview={handlePreview}
                            onChange={handleChange}
                            style={{ border: 'none' }}
                            className={cx('upload-avatar', fileList.length < 1 && 'boder-upload')}
                            fileList={fileList}
                            accept={['.png', '.jpg']}
                        >
                            {fileList.length < 1 && (
                                <ImagesThin width="100%" height="100%" padding="8px" className={cx('image')} />
                            )}
                        </Upload>
                        {!!previewImage && (
                            <Modal
                                open={previewImage}
                                title={previewImage?.name}
                                footer={null}
                                onCancel={() => setPreviewImage(null)}
                            >
                                <img
                                    alt="preview"
                                    style={{
                                        width: '100%',
                                    }}
                                    src={previewImage}
                                />
                            </Modal>
                        )}

                        {inputFields.map((field) => {
                            if (field.type === 'input')
                                return (
                                    <div key={field.id}>
                                        <label className={cx('input')}>
                                            <Input
                                                style={{
                                                    marginBottom: 20,
                                                    width: field.width || '100%',
                                                }}
                                                className={cx(
                                                    'input-custom',
                                                    'input__field',
                                                    field.value !== '' && 'input__has-value',
                                                )}
                                                placeholder={'  '}
                                                type={field.hide ? 'password' : 'text'}
                                                value={field.value}
                                                onChange={(e) => handleChangeInput(e, field.id)}
                                            />
                                            <span className={cx('input__label')}>{t(field.name)}</span>
                                            <p className={cx('input__actions')}>
                                                {field.edit && (
                                                    <Popover
                                                        trigger={['click']}
                                                        content={
                                                            <>
                                                                <Input
                                                                    ref={newFieldRef}
                                                                    style={{ marginTop: '10px' }}
                                                                    className={cx('input-custom')}
                                                                    placeholder={field.name}
                                                                    value={newFieldName}
                                                                    onChange={(e) => {
                                                                        setErrorNewField(null);
                                                                        setNewFieldName(e.target.value);
                                                                    }}
                                                                />
                                                                {errorNewField && (
                                                                    <div style={{ marginTop: 10 }}>
                                                                        <span className={cx('err-mess')}>
                                                                            <i className="fa-solid fa-triangle-exclamation" />{' '}
                                                                            <span>{t(errorNewField)}</span>
                                                                        </span>
                                                                    </div>
                                                                )}
                                                                <Button
                                                                    style={{ marginTop: 10 }}
                                                                    className={cx(
                                                                        'btn-custom_full-width',
                                                                        'custom-btn',
                                                                    )}
                                                                    type="primary"
                                                                    onClick={() =>
                                                                        handleAddNewField(field.id, 'change')
                                                                    }
                                                                >
                                                                    {t('change')}
                                                                </Button>
                                                            </>
                                                        }
                                                    >
                                                        <i className="fa-solid fa-pen-to-square" />
                                                    </Popover>
                                                )}
                                                {field.eyes && field.edit && (
                                                    <i
                                                        className={
                                                            !field.hide
                                                                ? 'fa-sharp fa-solid fa-eye-slash'
                                                                : 'fa-solid fa-eye'
                                                        }
                                                        onClick={() => handleShowContent(field.id)}
                                                    />
                                                )}
                                                {field.remove && (
                                                    <Popconfirm
                                                        onConfirm={() => handleRemoveField(field.id)}
                                                        title={t('are_you_sure')}
                                                        okText={t('yes')}
                                                        cancelText={t('no')}
                                                    >
                                                        <i className="fa-solid fa-trash" />
                                                    </Popconfirm>
                                                )}
                                            </p>
                                        </label>
                                    </div>
                                );

                            if (field.type === 'select')
                                return (
                                    <Select
                                        key={field.id}
                                        style={{ width: field.width || '100%', marginBottom: 20 }}
                                        value={field.value || null}
                                        placeholder={t(field.name)}
                                        optionFilterProp="children"
                                        onSelect={(value) => handleChoose(value, field.id)}
                                        onClick={() => handleSetList(field.id)}
                                        className={cx('input-custom', 'input__field')}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={field.options ?? searchList}
                                    >
                                        {(!field.options || field.options.length < 1) && (
                                            <Option key="0">
                                                <Empty description={t('no_data')} />
                                            </Option>
                                        )}
                                    </Select>
                                );
                        })}
                        <div className={cx('new-field')} key="newField">
                            <Popover
                                content={
                                    <div className={cx('popover-modal')}>
                                        <Input
                                            ref={newFieldRef}
                                            style={{ marginTop: '10px' }}
                                            className={cx('input-custom')}
                                            placeholder={`${t('new_field')}*`}
                                            value={newFieldName}
                                            onChange={(e) => {
                                                setErrorNewField(null);
                                                setNewFieldName(e.target.value);
                                            }}
                                        />
                                        {errorNewField && (
                                            <span className={cx('err-mess')}>
                                                <i className="fa-solid fa-triangle-exclamation" />{' '}
                                                <span>{t(errorNewField)}</span>
                                            </span>
                                        )}
                                        <div className={cx('switch-btn')}>
                                            <Switch
                                                style={{ backgroundColor: checked ? 'var(--primary-color)' : '' }}
                                                size="large"
                                                onClick={() => setCheked(!checked)}
                                                defaultChecked
                                            />
                                            <span style={{ marginLeft: 8, fontWeight: 500 }}>{t('hide_content')}</span>
                                        </div>
                                        <Button
                                            className={cx('btn-custom_full-width', 'custom-btn')}
                                            type="primary"
                                            onClick={handleAddNewField}
                                        >
                                            {t('add')}
                                        </Button>
                                    </div>
                                }
                                title={<h3>{t('new_field')}</h3>}
                                placement="bottomLeft"
                                onOpenChange={(newOpen) => {
                                    setNewFieldOpen(newOpen);
                                    setNewFieldName('');
                                }}
                                open={newFieldOpen}
                                showArrow
                                trigger="click"
                            >
                                <Button
                                    className={cx('new-field__btn')}
                                    style={{ padding: 2 }}
                                    onClick={() => setNewFieldOpen(!newFieldOpen)}
                                >
                                    {t('new_field')}{' '}
                                    <i
                                        className={newFieldOpen ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down'}
                                        style={{ marginLeft: 4 }}
                                    />
                                </Button>
                            </Popover>
                        </div>
                    </div>

                    <div className={cx('body-modal-upload__child', 'child-w-30', 'child-pad-side-30')}>
                        <div>
                            {inputFields.map((field) => {
                                if (field.id === 'alerts')
                                    return (
                                        <div key={field.id}>
                                            <div style={{ marginBottom: 8 }}>
                                                <h4>{t(field.name)}?</h4>

                                                {field?.options.map((item) => (
                                                    <Checkbox
                                                        checked={item.check}
                                                        key={item.id}
                                                        style={{ marginLeft: 5 }}
                                                        onChange={(e) =>
                                                            handleCheck(field.id, item.id, e.target.checked)
                                                        }
                                                    >
                                                        <span>{t(item.name)}</span>
                                                    </Checkbox>
                                                ))}
                                            </div>
                                            {!disabledTabs && (
                                                <div style={{ paddingTop: 10 }}>
                                                    <Dropdown
                                                        overlay={
                                                            <div>
                                                                {!!tabs ? (
                                                                    <SearchResult
                                                                        data={tabs}
                                                                        value={debouncedValue}
                                                                        tabs
                                                                        onDone={() => setValueSearch('')}
                                                                    />
                                                                ) : (
                                                                    <AppLoading listFile />
                                                                )}
                                                            </div>
                                                        }
                                                        trigger={['click']}
                                                    >
                                                        <Input
                                                            value={valueSearch}
                                                            onChange={(e) => setValueSearch(e.target.value)}
                                                            className={cx('input-custom')}
                                                            placeholder={t('search_for_TAB')}
                                                        />
                                                    </Dropdown>

                                                    <div className={cx('tabs-list-selected')}>
                                                        {!!tabSelected && (
                                                            <div className={cx('tabs-list-selected__item')}>
                                                                <Image
                                                                    preview={false}
                                                                    src={image.pdf}
                                                                    width="40px"
                                                                    height="100%"
                                                                />
                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className={cx('name')}
                                                                    href={tabSelected.url}
                                                                >
                                                                    {tabSelected.name}
                                                                </a>
                                                                <Button
                                                                    className={cx('remove-btn')}
                                                                    onClick={() => dispatch(setTabSelected(null))}
                                                                >
                                                                    <i
                                                                        style={{ fontSize: '0.9rem' }}
                                                                        className="fa-sharp fa-solid fa-xmark"
                                                                    />
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    );

                                if (field.id === 'protected')
                                    return (
                                        <div className={cx('field-checkbox')} key={field.id}>
                                            <h4>{t(field.name)}?</h4>
                                            {field.options.map((item) => (
                                                <Radio
                                                    defaultChecked={false}
                                                    key={item.id}
                                                    checked={item.check}
                                                    onChange={(e) => handleCheck(field.id, item.id, e.target.checked)}
                                                >
                                                    {t(item.name)}
                                                </Radio>
                                            ))}
                                        </div>
                                    );

                                if (field.id === 'plans')
                                    return (
                                        <div className={cx('field-checkbox')} key={field.id}>
                                            <h4>{t(field.name)}?</h4>
                                            {field.options.map((item) => (
                                                <Checkbox
                                                    style={{ marginLeft: 5 }}
                                                    key={item.id}
                                                    checked={item.check}
                                                    onChange={(e) => handleCheck(field.id, item.id, e.target.checked)}
                                                >
                                                    <span>{item.name}</span>
                                                </Checkbox>
                                            ))}
                                        </div>
                                    );
                            })}
                        </div>

                        <div className={cx('save-time')}>
                            <Checkbox
                                checked={checkSaveTime}
                                style={{ marginTop: 4 }}
                                onClick={() => {
                                    if (checkSaveTime) setSaveTime(0);
                                    if (!checkSaveTime) setSaveTime(null);
                                    setCheckSaveTime(!checkSaveTime);
                                }}
                            />
                            <h4>
                                {t('auto_save_project_every')}
                                <Input
                                    value={saveTime}
                                    onChange={handleChangeSaveTime}
                                    style={{ width: 60, marginLeft: 6 }}
                                    disabled={!checkSaveTime}
                                />

                                <span>{t('minutes')}</span>
                            </h4>
                        </div>
                        <span className={cx('atten-save-time')}>* {t('you_can_set_time_from_1_to_60_minutes')}</span>
                    </div>

                    {uploadFile && <UploadArea listUpload={listUpload} setListUpload={(d) => setListUpload(d)} />}
                </div>
            ) : (
                <InformationForm projectOpen={projectOpen} />
            )}
        </Modal>
    );
}

export default memo(ModalUpload);

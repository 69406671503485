/* eslint-disable array-callback-return */
/* eslint-disable yoda */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */

import { setFiles } from '../files/filesSlice';
import uploadFile from '../files/uploadFile';
import { clearModalStateModalAction } from '../modal/ModalSlice';
import { addToProjectList, controlIsFetching } from './projectSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';

import api from '~/api';

const config = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

export const getTabs = createAsyncThunk('project/getTabs', async ({ t }, thunkAPI) => {
    try {
        const response = await axios.get('https://api.speam.io/projektierungstool/tabs', {
            headers: {
                Authorization: 'Basic 46E67BA4-1544-C165-BE5B-38142199D132',
            },
        });
        if (response.status < 400) {
            return response.data.files;
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        message.error({
            content: t('something_wrong'),
        });
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const getDefaultName = createAsyncThunk('project/getDefaultName', async ({ t }, thunkAPI) => {
    try {
        const response = await api.get('api/v1/user/Project/name');
        if (response.status < 400) {
            return response.data.result;
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const createProject = createAsyncThunk(
    'project/create',
    async ({ request, listUpload, dispatch, t }, thunkAPI) => {
        try {
            if (request.fileImage) {
                const formData = new FormData();
                formData.append('files', request.fileImage);
                request.thumbnail = formData;
            }
            const response = await api.post('api/v1/user/Project', request, config);
            if (response.status < 400) {
                message.success(t('creat_project_success'));
                dispatch(addToProjectList(response.data.result));
                dispatch(controlIsFetching(false));
                if (listUpload.length > 0) {
                    listUpload.forEach((file) => {
                        uploadFile([file], dispatch, response.data.result.id);
                    });
                }
            }
            return thunkAPI.rejectWithValue(response.data);
        } catch (err) {
            message.error({
                content: err.message,
                duration: 1,
            });
            return thunkAPI.rejectWithValue(err.response.data);
        }
    },
);

export const updateProject = createAsyncThunk('project/update', async ({ id, request, t }, thunkAPI) => {
    try {
        const response = await api.put(`api/v1/user/Project/${id}`, request, config);
        if (response.status < 400) {
            message.success({ content: `${t('update_success')}`, duration: 1.5 });
            return response.data.result;
        }
        message.error({
            content: response.data,
            duration: 1,
        });
        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        message.error({
            content: err.response.data,
            duration: 1,
        });
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const getProjectList = createAsyncThunk('project/getAll', async (payload, thunkAPI) => {
    try {
        const response = await api.get('api/v1/user/Project', {
            params: {
                IsDeleted: payload,
            },
        });
        if (response.status < 400) {
            return {
                type: payload,
                data: response.data.result,
            };
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const getOneProject = createAsyncThunk('project/getOne', async ({ id, isDeleted, dispatch, t }, thunkAPI) => {
    try {
        const response = await api.get(`api/v1/user/Project/${id}?isDeleted=${isDeleted}`);
        if (response.status < 400) {
            dispatch(setFiles(response.data.result.files));
            return response.data.result;
        }
        message.success({
            content: t('cant_get_project'),
            duration: 1,
        });
        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        message.success({
            content: t('cant_get_project'),
            duration: 1,
        });
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const deleteProject = createAsyncThunk('project/delete', async ({ id, t }, thunkAPI) => {
    try {
        const response = await api.delete(`/api/v1/user/Project/delete/${id}`);
        if (response.status < 400) {
            message.success({
                content: t('delete_success'),
                duration: 1.5,
            });
            return response.data.result;
        }
        message.error({
            content: t('something_wrong'),
            duration: 1,
        });
        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        message.error({
            content: t('something_wrong'),
            duration: 1,
        });
        return err.response.data;
    }
});

export const deleteManyProject = createAsyncThunk(
    'project/deleteMany ',
    async ({ subList, list, t, dispatch }, thunkAPI) => {
        try {
            const response = await api.delete('/api/v1/user/Project/delete', {
                data: { ids: subList, isForever: true },
            });
            if (response.status < 400) {
                dispatch(clearModalStateModalAction());
                message.success({
                    content: t('delete_success'),
                    duration: 1,
                });

                subList.map((item) => {
                    list.map((project, index) => {
                        if (item === project.id) {
                            list.splice(index, 1);
                        }
                    });
                });

                return list;
            }
            message.error({
                content: t('something_wrong'),
                duration: 1,
            });
            return thunkAPI.rejectWithValue(response.data);
        } catch (err) {
            message.error({
                content: t('something_wrong'),
                duration: 1,
            });
            return thunkAPI.rejectWithValue(err.response.data);
        }
    },
);

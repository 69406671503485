/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-curly-brace-presence */
import { useEffect, useMemo, useState } from 'react';
import { Col, Form, Input, Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { RoomStatic } from '~/helper/ForgeViewer/speam/rooms/roomStatic';
import useDebounce from '~/helper/hooks/useDebounce';
import YesNoAnswer from './YesNoAnswer';

/**
 * Render FormItemRHSSlopingCeilings
 */
export default function FormItemRHSSlopingCeilings({ fields, errorMessages }) {
    const [questionSlopingCeilings, setQuestionSlopingCeilings] = useState(fields['qSlopingCeilings']?.value);

    const [slopingCeilingsLength, setSlopingCeilingsLength] = useState(fields['slopingCeilingsLength']?.value);
    const [slopingCeilingsWidth, setSlopingCeilingsWidth] = useState(fields['slopingCeilingsWidth']?.value);

    const [errorMsg, setErrorMsg] = useState('');

    const { t } = useTranslation();
    // Get an instance of form
    const form = Form.useFormInstance();

    // Use debounce hook for input
    const slopingCeilingsLengthDebounce = useDebounce(slopingCeilingsLength, 500);
    const slopingCeilingsWidthDebounce = useDebounce(slopingCeilingsWidth, 500);

    /**
     * When submit form, we can choose the desired value
     * for corresponding field name
     */
    useEffect(() => {
        form.setFieldsValue({ qSlopingCeilings: questionSlopingCeilings });
        form.setFieldsValue({ slopingCeilingsLength: slopingCeilingsLengthDebounce });
        form.setFieldsValue({ slopingCeilingsWidth: slopingCeilingsWidthDebounce });
    }, [questionSlopingCeilings, slopingCeilingsWidthDebounce, slopingCeilingsLengthDebounce]);

    /**
     * Validate FormItem's rules
     */
    useEffect(() => {
        const validateForm = async () => {
            await form
                .validateFields(['slopingCeilingsLength'])
                .then((value) => {
                    if (value > 0) {
                        return value;
                    }
                    throw value;
                })
                .catch((_) => {}); // important!

            await form
                .validateFields(['slopingCeilingsWidth'])
                .then((value) => {
                    if (value > 0) {
                        return value;
                    }
                    throw value;
                })
                .catch((_) => {}); // important!
        };

        validateForm();

        return () => {};
    }, [questionSlopingCeilings, slopingCeilingsWidthDebounce, slopingCeilingsLengthDebounce]);

    /**
     * Check and show error message
     */
    useEffect(() => {
        const hasSlopingCeilingsLength = Object.hasOwn(errorMessages, 'slopingCeilingsLength');
        const hasSlopingCeilingsWidth = Object.hasOwn(errorMessages, 'slopingCeilingsWidth');

        if (hasSlopingCeilingsLength || hasSlopingCeilingsWidth) {
            setErrorMsg(`${t(`please_type_something`)}!`);
        }
    }, [errorMessages]);

    /**
     * Render renderFormItemQuestionSlopingCeilings
     */
    const renderFormItemQuestionSlopingCeilings = (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px' }}>
            <div>
                <span style={{ fontWeight: 'bold', marginBottom: '5px', marginRight: '8px' }}>
                    {t('are_there_sloping_ceilings')}
                </span>
                {errorMsg && (
                    <span style={{ textTransform: 'none', color: 'red', fontSize: '0.75rem' }}>{errorMsg}</span>
                )}
            </div>
            <Form.Item name="qSlopingCeilings" valuePropName="value" noStyle>
                <Radio.Group
                    onChange={(e) => setQuestionSlopingCeilings(e.target.value)}
                    value={questionSlopingCeilings}
                >
                    <YesNoAnswer />
                </Radio.Group>
            </Form.Item>
        </div>
    );

    /**
     * Helper for rendering: shifted FormItemSlopingCeilingsMeasurement
     * based on renderFormItemQuestionSlopingCeilings
     */
    useEffect(() => {
        if (questionSlopingCeilings > 0) {
            return;
        }
        setSlopingCeilingsLength('');
        setSlopingCeilingsWidth('');
        setErrorMsg('');
    }, [questionSlopingCeilings]);

    /**
     * Array of formItem that is needed for rendering
     */
    const slopingCeilingsMeasurement = useMemo(
        () => [
            {
                form_item_name: 'slopingCeilingsLength',
                form_item_placeholder: t('length'),
                form_item_value: slopingCeilingsLength,
                form_item_callback: setSlopingCeilingsLength,
            },
            {
                form_item_name: 'slopingCeilingsWidth',
                form_item_placeholder: t('width'),
                form_item_value: slopingCeilingsWidth,
                form_item_callback: setSlopingCeilingsWidth,
            },
        ],
        [slopingCeilingsLength, slopingCeilingsWidth],
    );

    /**
     * Render FormItemSlopingCeilingsMeasurement
     */
    const renderFormItemSlopingCeilingsMeasurement =
        questionSlopingCeilings === 0 ? null : (
            <Row gutter={8}>
                {slopingCeilingsMeasurement.map((item) => (
                    <Col key={`${item.form_item_name}--col`} style={{ width: '110px' }}>
                        <Form.Item
                            key={`${item.form_item_name}--col-fi`}
                            name={item.form_item_name}
                            rules={[
                                {
                                    required: true,
                                    message: `${t(`please_type_something`)}!`,
                                },
                            ]}
                            initialValue={item.form_item_value}
                            valuePropName="value"
                            noStyle
                        >
                            <Input key={`${item.form_item_name}--col-fi-i`} value={item.form_item_value} hidden />
                        </Form.Item>
                        <NumericFormat
                            key={`${item.form_item_name}--col-nf`}
                            customInput={Input}
                            suffix={' '.concat(`m`)}
                            thousandSeparator={t(',')}
                            decimalSeparator={t('.')}
                            style={{ textTransform: 'lowercase', marginLeft: '20px', maxHeight: '27px' }}
                            onValueChange={(values) => {
                                item.form_item_callback(values.floatValue);
                            }}
                            onFocus={() => setErrorMsg('')}
                            placeholder={item.form_item_placeholder}
                            defaultValue={item.form_item_value}
                        />
                    </Col>
                ))}
            </Row>
        );

    // ========================================================================
    // Render FormItemRHSSlopingCeilings
    // ========================================================================
    return (
        <Col style={{ position: 'relative', width: '320px' }}>
            <div style={{ position: 'absolute', width: '100%' }}>{renderFormItemQuestionSlopingCeilings}</div>
            <div style={{ position: 'absolute', left: '110px', top: '25px', width: '252px' }}>
                {renderFormItemSlopingCeilingsMeasurement}
            </div>
        </Col>
    );
}

/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */

import styles from '../ToolViewer.module.scss';
import RoomItem from './RoomItem';
import { Checkbox, Select } from 'antd';
import classNames from 'classnames/bind';
import jcc from 'json-case-convertor';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box2 } from 'three-full';
import { v4 as uuid } from 'uuid';

import { optionsUnit } from '~/assets/MenuToolData/MenuToolData';
import AppLoading from '~/components/AppLoading';
import RoomItemSettingForm from '~/components/RoomItemSettingForm';
import { customMemo } from '~/helper/common';
import { SYMBOL_BMZ } from '~/helper/ForgeViewer/connected-line/common';
import {
    deleteShape,
    drawSymbol,
    getCenterPointOfPolygon,
    getUnitsNumber,
    removeDevice,
} from '~/helper/ForgeViewer/forge-viewer-tool';
import { reversePoints } from '~/helper/ForgeViewer/radius-erase/radius-erase-function';
import { EditRoomNameInViewer, ToggleRoomInViewer } from '~/helper/ForgeViewer/speam/rooms/roomCommands';
import {
    AddRoomInViewer,
    ChangeRoomPointsInViewer,
    DeleteRoomInViewer,
    ToggleRoomsInViewer,
} from '~/helper/ForgeViewer/speam/rooms/roomsCommands';
import { RoomsStatic } from '~/helper/ForgeViewer/speam/rooms/roomsStatic';
import { RoomStatic } from '~/helper/ForgeViewer/speam/rooms/roomStatic';
import { UndoController } from '~/helper/ForgeViewer/speam/undoController';
import { selectAlarmDevice, setDevices, setUnit, setWrongUnit } from '~/store/alarm-device/alarmDeviceSlice';
import { filesList } from '~/store/files/filesSlice';
import { openModalModalAction, selectModalState } from '~/store/modal/ModalSlice';
import { removeSymbolByIdSymbolAction, selectSymbols, setSymbolList } from '~/store/symbol/symbolSlice';
import { selectToolState } from '~/store/tool/toolSlice';
import {
    makeViewerIsFetchingSelector,
    makeViewerShouldAlarmSystemBeInstalledSelector,
    makeViewerShouldApplySettingToAllRoomsSelector,
    makeViewerShouldDrawingCenteredBMZSelector,
} from '~/store/viewer/viewer.selectors';
import {
    setCurrentRoomSettingIdViewerAction,
    setEditedRoomsViewerAction,
    setObserveSaveFileStatusOfFileSliceViewerAction,
    setShouldDrawingCenteredBMZViewerAction,
} from '~/store/viewer/viewer.slice';
import { removeHashOfBMZWireByIdWireAlarmAction } from '~/store/wire-alarm/wireAlarm.slice';

const cx = classNames.bind(styles);

const makeRoomArr = (roomsStaticObj) => {
    if (!roomsStaticObj) return;

    const roomsArr = [];
    const roomsObj = RoomsStatic.getRooms();
    Object.keys(roomsObj).forEach((r) => roomsArr.push(roomsObj[r]));
    return roomsArr;
};

function SubTool({
    changeOpenSubTool,
    isOpen,
    roomsStaticObj,
    selectedRoomObjId,
    changedRoomInfo,
    notifySubTool,
    draggedRoom,
}) {
    // const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    // const [viewerUnit, setViewerUnit] = useState(window.viewer?.model.getDisplayUnit());

    const { devices, unit: unitSelect } = useSelector(selectAlarmDevice);

    // ===========================================
    const { toggleAllTools, data } = useSelector(selectToolState);
    // ===========================================

    // State for room
    const [roomsClass, setRoomsClass] = useState(null);
    const [roomsControllerClass, setRoomControllerClass] = useState(null);
    const [roomsList, setRoomsList] = useState(null);
    const [chosenRoomIdFromViewer, setChosenRoomIdFromViewer] = useState('');
    const [changedRoomInViewer, setChangedRoomInViewer] = useState(null);
    const [endDragRoomInViewer, setEndDragRoomInViewer] = useState(null);
    const [unit, setChangeUnit] = useState(null);

    // Management select all state
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [extraSelectAll, setExtraSelectAll] = useState(false);

    // Management render state
    const [emitter, setEmitter] = useState(0);
    const [flagCheckboxRoom, setFlagCheckboxRoom] = useState(0);

    // State for room setting form
    const [currentRoomSetting, setCurrentRoomSetting] = useState(null);
    const { modalData, modalOpen } = useSelector(selectModalState);

    const { id: paramId } = useParams();

    // State for adding symbol BMZ to the middle of the room when set
    // should alarm system be installed in RoomItemSettingForm
    const isFetchingViewerSelector = useSelector(makeViewerIsFetchingSelector());
    const shouldAlarmSystemBeInstalledSelector = useSelector(makeViewerShouldAlarmSystemBeInstalledSelector());
    const shouldDrawingCenteredBMZSelector = useSelector(makeViewerShouldDrawingCenteredBMZSelector());
    const shouldApplyToAllRoomsSelector = useSelector(makeViewerShouldApplySettingToAllRoomsSelector());

    // State for Symbols
    const { symbolList } = useSelector(selectSymbols);

    // dispatch to store
    const dispatch = useDispatch();

    // Helper turn off select all when change language
    useEffect(() => {
        handleChangeCheckboxAllRooms(false);
    }, [i18n.language]);

    /**
     * Check all checkbox and also select all rooms on viewer when click 'Show all' checkbox
     */
    const handleChangeCheckboxAllRooms = useCallback(
        (checked) => {
            return Promise.resolve().then(() => {
                if (!roomsClass && !roomsControllerClass) {
                    return;
                }

                /**
                 * set state for Select all and for extra select all helper
                 */
                setIsSelectAll(checked);
                setExtraSelectAll(checked);

                roomsControllerClass.execute(new ToggleRoomsInViewer(roomsClass, checked));

                // support for render this component the right way
                setEmitter((prev) => prev + 1);
            });
        },
        [roomsClass, roomsControllerClass],
    );

    /**
     * Helper checkbox selected room (also helps for SelectAll state)
     */
    const handleChangeCheckboxRoom = useCallback(
        (roomId) => {
            if (!roomsClass && !roomsControllerClass) {
                return;
            }

            const room = roomsClass.getRoomById(roomId);

            /**
             * Code below support for extra Select all state
             * This make sure that it's worked the right way!
             */
            if (!isOpen && isSelectAll) {
                setExtraSelectAll(false);
            }
            if (!isOpen && !isSelectAll) {
                setExtraSelectAll(false);
            }
            if (isOpen && isSelectAll) {
                setExtraSelectAll(false);
            }
            if (isOpen && !isSelectAll) {
                setExtraSelectAll(false);
            }

            // toggle check room with " ! " at room.isOpen
            roomsControllerClass.execute(new ToggleRoomInViewer(room, !room.isOpen));

            // support for render this component the right way
            setEmitter((prev) => prev + 1);
        },
        [dispatch, roomsClass, roomsControllerClass, isSelectAll],
    );

    /**
     * Helper for Select all
     * when all rooms are checked, Select All check box will also be checked
     */
    useEffect(() => {
        if (roomsList?.length > 0) {
            const isOpenAll = roomsList.every((room) => room.isOpen);
            if (isOpenAll) {
                setExtraSelectAll(true);
            }
        }
    }, [roomsList]);

    /**
     * Delete the selected room when click the "bin" icon button
     */
    const onDeleteSelectedRoom = useCallback(
        (roomId) => {
            if (!roomsClass && !roomsControllerClass) return;

            roomsControllerClass.execute(new DeleteRoomInViewer(roomsClass, roomId));

            if (devices && devices.length > 0) {
                devices.forEach((d) => {
                    if (d.roomId === roomId) {
                        removeDevice(d.deviceId);
                    }
                });
                const newDeviceList = devices.filter((d) => d.roomId !== roomId);

                dispatch(setDevices(newDeviceList));
            }

            // support for render this component the right way
            setEmitter((prev) => prev + 1);
        },
        [dispatch, roomsClass, roomsControllerClass, devices],
    );

    /**
     * Change the name of room (eg: Id:10 => Id:14) after click 'Edit" pen icon,
     * fulfill the input, and 'enter' to finish
     */
    const editNameSelectedRoom = useCallback(
        (editedRoomName) => {
            if (!roomsClass && !roomsControllerClass) {
                return;
            }

            const { roomId, newName } = editedRoomName;

            const room = roomsClass.getRoomById(roomId);

            roomsControllerClass.execute(new EditRoomNameInViewer(room, newName));

            // support for render this component the right way
            setEmitter((prev) => prev + 1);
        },
        [dispatch, roomsClass, roomsControllerClass],
    );

    // Change RoomStatic unit base on change of 'unit' from 'alarmDeviceSlice'
    useEffect(() => {
        if (!unitSelect) {
            return;
        }
        setChangeUnit(unitSelect);
        RoomStatic.unit = unitSelect;
    }, [unitSelect]);

    /**
     * Change the unit of length based on the value from Selected Box
     */
    const onUnitChange = useCallback(
        (unitValue) => {
            if (!roomsList) return;
            dispatch(setWrongUnit(false));
            const parseData = jcc.camelCaseKeys(JSON.parse(data?.fileData));

            const wDoor = parseData.widthDoor / getUnitsNumber(unitValue);
            dispatch(setUnit(unitValue));
            // set global unit for model
            RoomStatic.unit = unitValue;
            // support for render this component the right way

            if (wDoor / 2 < 0.9 || wDoor * 2 > 0.9) {
                dispatch(setWrongUnit(false));
            } else {
                dispatch(setWrongUnit(true));
            }
            setEmitter((prev) => prev + 1);
        },
        [roomsList, data, unitSelect],
    );

    /**
     * Helper for opening selected room setting form
     */
    const handleOpenRoomSetting = useCallback(
        (roomId) => {
            const currentRoom = roomsClass.getRoomById(roomId);

            if (currentRoom) {
                dispatch(openModalModalAction({ title: 'room-setting' }));
                setCurrentRoomSetting(currentRoom);
            }
        },
        [roomsClass],
    );
    /**
     * NOTE:
     * This is the main part for source of rooms's data.
     * When the rooms data is got by api call, it will be set to the state of
     * of this component.
     */
    useEffect(() => {
        if (!roomsStaticObj) return;

        setRoomsClass(roomsStaticObj);
        setRoomControllerClass(new UndoController());

        // support for render this component the right way
        setEmitter((prev) => prev + 1);
    }, [roomsStaticObj]);

    /**
     * Every change with rooms on viewer will update the rooms data array
     * then all changes will be dispatched to the store
     */
    useEffect(() => {
        if (!roomsClass) {
            return;
        }

        // const roomsArr = [];
        // const roomsObj = roomsClass.getRooms();
        // Object.keys(roomsObj).forEach((r) => roomsArr.push(roomsObj[r]));
        const roomsArr = makeRoomArr(roomsStaticObj);

        setRoomsList(roomsArr);

        const roomsInfo = {
            rooms: roomsArr,
            length: roomsArr?.length,
        };

        //
        // Dispatch changed array of rooms to the store
        //
        dispatch(setEditedRoomsViewerAction(JSON.parse(JSON.stringify(roomsInfo))));
    }, [roomsClass, emitter, flagCheckboxRoom, roomsStaticObj]); // IMPORTANT!

    /**
     * Observes 'finishPolygon' This action is executed after finished
     * drawing a new polygon by tool "Mark Room"
     */
    useEffect(() => {
        if (!roomsClass && !roomsControllerClass) {
            return;
        }

        const changeDrawnPolygonInViewerToRoom = (polygonToolEvent) => {
            const polyTool = polygonToolEvent?.detail;

            const poly = polyTool.poly;

            if (!roomsClass && !roomsControllerClass) {
                return;
            }

            // Arrange points by clockwise direction
            const points = reversePoints(poly.points);

            // Get bounding box from arranged points
            const bbox = new Box2();
            bbox.setFromPoints(points);

            // NOTE: please do not change 'poly.id => to uuid()'
            // if you change that, mark room will draw 2 shapes with different id
            // one that belong to room, the other belong to arbitrary shape...
            const newPolygon = { id: poly.name, points, bbox };

            // After create new polygon, this polygon have an Id of Type Number
            // so we will remove it and avoid duplicate when create new Room
            deleteShape(poly.id);

            // Execute create room in viewer
            roomsControllerClass.execute(new AddRoomInViewer(roomsClass, newPolygon));

            // Support for render this component the right way
            setEmitter((prev) => prev + 1);
        };

        window.addEventListener('finishPolygon', changeDrawnPolygonInViewerToRoom);

        return () => {
            window.removeEventListener('finishPolygon', changeDrawnPolygonInViewerToRoom);
        };
    }, [roomsClass, roomsControllerClass]);

    /**
     * Set the room that user selects in viewer by mouse to the state
     */
    useEffect(() => {
        if (!selectedRoomObjId) {
            setChosenRoomIdFromViewer('');
        } else {
            setChosenRoomIdFromViewer(selectedRoomObjId);
        }

        // support for render this component the right way
        setEmitter((prev) => prev + 1);
    }, [selectedRoomObjId]);

    /**
     * Observe room that has it's info are changed based on user's interactive
     */
    useEffect(() => {
        if (!changedRoomInfo) {
            setChangedRoomInViewer(null);
        } else {
            setChangedRoomInViewer(changedRoomInfo);
        }

        // support for render this component the right way
        setEmitter((prev) => prev + 1);
    }, [changedRoomInfo]);

    /**
     * Observe Dragged Room that has it's position are changed based on user's interactive
     */
    useEffect(() => {
        if (!draggedRoom) {
            setEndDragRoomInViewer(null);
        } else {
            setEndDragRoomInViewer(draggedRoom);
        }

        // support for render this component the right way
        setEmitter((prev) => prev + 1);
    }, [draggedRoom]);

    /**
     * Listen to the keyup event from user, check if room is selected then
     * performs actions:
     * - Delete selected room in viewer when press 'delete' key on keyboard
     * - undo all changes with rooms in viewer when press 'Ctrl + Z'
     * - redo all changes with rooms in viewer when press 'Ctrl + Y'
     */
    useEffect(() => {
        const updateRoomByKeyUp = (eventKeyUp) => {
            eventKeyUp.preventDefault();
            if (!roomsClass && !roomsControllerClass) return;

            if (!chosenRoomIdFromViewer) RoomStatic.roomId = '';

            // Delete selected room when press 'Delete'
            if (eventKeyUp?.code === 'Delete' && RoomStatic.roomId) {
                roomsControllerClass.execute(new DeleteRoomInViewer(roomsClass, RoomStatic.roomId));

                setChosenRoomIdFromViewer('');
                setEmitter((prev) => prev + 1);
            }

            // Undo changes with rooms in viewer "Ctrl + Z"
            if (
                eventKeyUp?.code === 'KeyZ' &&
                (eventKeyUp.metaKey || eventKeyUp.ctrlKey) &&
                roomsControllerClass.getUndoStackLength() > 0
            ) {
                roomsControllerClass.undo();
                setEmitter((prev) => prev + 1);
            }

            // Redo changes with rooms in viewer 'Ctrl + Y'
            if (
                eventKeyUp?.code === 'KeyY' &&
                (eventKeyUp.metaKey || eventKeyUp.ctrlKey) &&
                roomsControllerClass.getUndoStackLength() > 0
            ) {
                roomsControllerClass.redo();
                setEmitter((prev) => prev + 1);
            }
        };

        window.addEventListener('keyup', updateRoomByKeyUp);
        return () => window.removeEventListener('keyup', updateRoomByKeyUp);
    }, [roomsClass, roomsControllerClass, chosenRoomIdFromViewer]);

    /**
     * Observe changed or dragged room's info from user and apply change to selected room
     */
    useEffect(() => {
        if (!roomsClass && !roomsControllerClass) {
            return;
        }
        const handleUpdateRoomInViewer = (dataFromEvent, callbackStateUpdateRoomInViewer) => {
            const room = roomsClass.getRoomById(dataFromEvent?.id);
            if (room) {
                roomsControllerClass.execute(new ChangeRoomPointsInViewer(roomsClass, dataFromEvent));
                const roomsArr = makeRoomArr(roomsStaticObj);
                const roomsInfo = {
                    rooms: roomsArr,
                    length: roomsArr.length,
                };

                setRoomsList([...roomsArr]);

                dispatch(setEditedRoomsViewerAction(JSON.parse(JSON.stringify(roomsInfo))));
            }
            callbackStateUpdateRoomInViewer(null);
        };
        if (changedRoomInViewer) {
            handleUpdateRoomInViewer(changedRoomInViewer, setChangedRoomInViewer);
        }
        if (endDragRoomInViewer) {
            handleUpdateRoomInViewer(endDragRoomInViewer, setEndDragRoomInViewer);
        }
    }, [roomsClass, roomsControllerClass, changedRoomInViewer, endDragRoomInViewer, roomsStaticObj]);

    /**
     * Notify all actions
     */
    const notifyCheckboxRoom = useCallback((count) => {
        setFlagCheckboxRoom(count);
    }, []);

    /**
     * notify ui change, that helps parent component render the right way
     */
    useEffect(() => {
        if (emitter) {
            notifySubTool(emitter);
        }
    }, [emitter]);

    /**
     * reset emitter to avoid large number
     */
    useEffect(() => {
        if (emitter > 3) {
            setEmitter(1);
        }
    }, [emitter]);

    // =======================================================================
    // Helper for adding symbol BMZ to the middle of the room when set
    // should alarm system be installed in RoomItemSettingForm
    // =======================================================================
    useEffect(() => {
        if (!roomsList && !paramId) {
            return;
        }

        const removeAllCenteredBMZfromViewer = (symbols) => {
            // clone data of symbol
            let data = [...symbols];
            // using regex to filter and remove centered bmz symbol
            const regexCenteredBMZ = /^centered-bmz/;

            if (data.length > 0) {
                data = [...data].filter((s) => {
                    const hasCenteredBMZSymbol = regexCenteredBMZ.test(s.id);
                    if (hasCenteredBMZSymbol) {
                        dispatch(removeHashOfBMZWireByIdWireAlarmAction(s?.id));
                        dispatch(removeSymbolByIdSymbolAction(s?.id));
                        removeDevice(s?.id);
                    }

                    return !hasCenteredBMZSymbol;
                });
            }
            return data;
        };

        const drawAndReturnListOfCenteredBMZSymbol = () => {
            // Collect list of centered BMZ symbolS
            const listData = [];
            // Drawing BMZ symbol in the center of each room here
            roomsList?.forEach((r) => {
                const centerPoint = getCenterPointOfPolygon(r?.layerCurrentPoints);
                const centeredBmzID = `centered-bmz-${uuid()}`;
                const point = {
                    x: centerPoint.x,
                    y: centerPoint.y,
                    isRight: true,
                };
                // Draw centered BMZ symbol on viewer
                drawSymbol(point, centeredBmzID, SYMBOL_BMZ);

                const newSymbol = {
                    x: centerPoint.x,
                    y: centerPoint.y,
                    id: centeredBmzID,
                    fileId: paramId,
                    isSymbol: true,
                    symbol: SYMBOL_BMZ,
                };

                listData.push(newSymbol);
            });
            return listData;
        };

        const removeCurrentRoomCenteredBMZFromViewer = (symbols, currentRoom) => {
            let data = [...symbols];
            // using regex to filter and remove centered bmz symbol of currentRoom
            const regexCenteredBMZ = /^centered-bmz/;
            const regexCurrentRoomId = new RegExp(`${currentRoom?.roomId}$`);

            if (data.length > 0) {
                data?.filter((s) => {
                    const hasCenteredBMZSymbol = regexCenteredBMZ.test(s.id);
                    const matchedCurrentRoomId = regexCurrentRoomId.test(s.id);
                    const condition = hasCenteredBMZSymbol && matchedCurrentRoomId;

                    if (condition) {
                        dispatch(removeHashOfBMZWireByIdWireAlarmAction(s?.id));
                        dispatch(removeSymbolByIdSymbolAction(s?.id));
                        removeDevice(s?.id);
                    }
                    return !condition;
                });
            }
            return data;
        };

        const drawAndReturnCenteredBMZSymbolOfCurrentRoom = (currentRoom, paramId) => {
            const centerPoint = getCenterPointOfPolygon(currentRoom?.layerCurrentPoints);
            const centeredBmzID = `centered-bmz-${currentRoom?.roomId}`;
            const point = {
                x: centerPoint.x,
                y: centerPoint.y,
                isRight: true,
            };
            // Draw centered BMZ symbol on viewer
            drawSymbol(point, centeredBmzID, SYMBOL_BMZ);

            const newSymbol = {
                x: centerPoint.x,
                y: centerPoint.y,
                id: centeredBmzID,
                fileId: paramId,
                isSymbol: true,
                symbol: SYMBOL_BMZ,
            };

            return newSymbol;
        };

        // -------------------------------------------------------------------
        // start login here..
        // -------------------------------------------------------------------

        // if user choose 'YES' for this question in RoomItemSettingForm
        if (shouldAlarmSystemBeInstalledSelector && shouldDrawingCenteredBMZSelector) {
            if (shouldApplyToAllRoomsSelector) {
                // If all centered BMZ symbol are exist, remove all of them first.
                // but keep the other symbols
                const data = removeAllCenteredBMZfromViewer(symbolList);

                // drawing all centered BMZ symbol on viewer
                const bmzSymbols = drawAndReturnListOfCenteredBMZSymbol();
                // take list of centeredBMZsymbol and put it to store
                if (bmzSymbols && bmzSymbols.length > 0) {
                    // set list of centered BMZ symbols to the store
                    dispatch(setSymbolList([...data, ...bmzSymbols]));
                    // helper for turn off drawing symbol to avoid infinite loop
                    dispatch(setShouldDrawingCenteredBMZViewerAction(false));
                }
            } else {
                // set Id of currentRoom to store
                dispatch(setCurrentRoomSettingIdViewerAction(currentRoomSetting?.roomId));
                // If centered BMZ symbol of currentRoom is exist, remove it first,
                // but keep the other symbols
                const data = removeCurrentRoomCenteredBMZFromViewer(symbolList, currentRoomSetting);

                const bmzSymbolCurrentRoom = drawAndReturnCenteredBMZSymbolOfCurrentRoom(currentRoomSetting, paramId);

                if (bmzSymbolCurrentRoom) {
                    // set list of centered BMZ symbols to the store
                    dispatch(setSymbolList([...data, bmzSymbolCurrentRoom]));
                    // helper for turn off drawing symbol to avoid infinite loop
                    dispatch(setShouldDrawingCenteredBMZViewerAction(false));
                }
            }

            return;
        }

        // if user choose 'NO' for this question in RoomItemSettingForm
        if (!shouldAlarmSystemBeInstalledSelector) {
            if (shouldApplyToAllRoomsSelector) {
                removeAllCenteredBMZfromViewer(symbolList);
            } else {
                // set Id of currentRoom to store
                dispatch(setCurrentRoomSettingIdViewerAction(currentRoomSetting?.roomId));

                removeCurrentRoomCenteredBMZFromViewer(symbolList, currentRoomSetting);
            }

            return;
        }
    }, [
        shouldAlarmSystemBeInstalledSelector,
        shouldDrawingCenteredBMZSelector,
        shouldApplyToAllRoomsSelector,
        currentRoomSetting,
        roomsList,
        paramId,
        symbolList,
    ]);

    /**
     * Listen to the keyup event from user, check if room is selected then performs actions
     */
    // const loadDataRoom = useCallback(() => {
    //     DesignAutomationCallAPI();
    //     RoomsStatic.rooms = {};
    //     RoomStatic.hasRoomData = false;
    //     onHaveRoomsStaticObj(false);
    // }, [data, file, controller, roomsClass, roomsControllerClass]);

    /**
     * Helper for Open and Close RoomItemSettingForm after click Send Button to call api
     * if response is Success for Failed, it will also close form
     */
    const { isSuccessSaveFile, isFetchingSaveFile, isErrorSaveFile } = useSelector(filesList);
    useEffect(() => {
        const condition1 = !isFetchingSaveFile && isSuccessSaveFile;
        const condition2 = !isFetchingSaveFile && isErrorSaveFile;

        dispatch(setObserveSaveFileStatusOfFileSliceViewerAction(condition1 || condition2));
    }, [isFetchingSaveFile, isSuccessSaveFile]);

    return (
        <div style={{ display: toggleAllTools ? 'block' : 'none' }}>
            <div className={cx('sub-tools')} style={{ display: !isOpen ? 'none' : 'block' }}>
                {isFetchingViewerSelector && <AppLoading menuTool />}
                <div className={cx('sub-tools-back')} onClick={() => changeOpenSubTool()} aria-hidden="true">
                    <i className="fa-solid fa-arrow-left" /> {t('back')}
                </div>
                {/* ------------------------------------------- */}
                {/* ---- Checking if roomsList is existed! ---- */}
                {/* ------------------------------------------- */}
                {roomsList && (
                    <div className={cx('sub-tools-content')}>
                        <div className={cx('sub-tools-content__btn-group')}>
                            <div className={cx('main')}>
                                <h2 className={cx('sub-tools-content__title')}>{t('list_room')} </h2>
                                {/* <Button
                                size="small"
                                onClick={loadDataRoom}
                                className="btn-custom btn-custom-small"
                                disabled={roomsList?.length < 1}
                            >
                                <span>
                                    <i className="fa-solid fa-rotate-right" /> Reload Data
                                </span>
                            </Button> */}
                                <span>
                                    ({roomsList?.length} {roomsList.length > 1 ? t('rooms') : t('room')})
                                </span>
                            </div>
                        </div>

                        <div className={cx('sub-tools-unit')}>
                            <div className={cx('sub-tools-unit__option-unit')}>
                                <span>{t('choose_drawing_units')}</span>
                                <Select
                                    // value={unit ?? viewerUnit}
                                    onChange={onUnitChange}
                                    defaultValue={unit}
                                    value={unit}
                                    style={{
                                        width: 120,
                                        textAlign: 'center',
                                        textTransform: 'capitalize',
                                    }}
                                    options={optionsUnit}
                                />
                            </div>
                        </div>

                        <div className={cx('sub-tools-content__list')}>
                            <div className={cx('room-item')}>
                                <Checkbox
                                    onChange={async (event) => {
                                        const checked = event.target.checked;
                                        await handleChangeCheckboxAllRooms(checked);
                                    }}
                                    checked={extraSelectAll}
                                >
                                    {t('select_all')}
                                </Checkbox>
                            </div>
                            {roomsList?.map((room) => (
                                <div key={uuid()}>
                                    <RoomItem
                                        room={room}
                                        notifyCheckboxRoom={notifyCheckboxRoom}
                                        onChangeCheckboxRoom={handleChangeCheckboxRoom}
                                        onDeleteRoom={onDeleteSelectedRoom}
                                        onEditName={editNameSelectedRoom}
                                        // viewerUnit={viewerUnit || unit}
                                        viewerUnit={unit || unitSelect}
                                        onOpenRoomSetting={handleOpenRoomSetting}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {/* ---------------------------------------------- */}
                {/* ---- Room setting form with selected room ---- */}
                {/* ---------------------------------------------- */}
                {modalData?.title === 'room-setting' && (
                    <RoomItemSettingForm currentRoom={currentRoomSetting} modalData={modalData} modalOpen={modalOpen} />
                )}
            </div>
        </div>
    );
}

export default customMemo(SubTool);

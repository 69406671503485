import { CommandViewer } from '../commandViewer';

export class ToggleRoomInViewer extends CommandViewer {
    constructor(room, isCheck) {
        super();
        this.room = room;
        this.isCheck = isCheck
    }

    undo() {
      this.room.toggleRoomInViewer(!this.isCheck);
    }

    redo() {
      this.room.toggleRoomInViewer(this.isCheck);
    }
}

export class EditRoomNameInViewer extends CommandViewer {
  constructor(room, newName) {
      super();
      this.room = room;

      this.oldName = this.room.name;
      this.newName = newName;
  }

  undo() {
    this.room.editRoomNameInViewer(this.oldName);
  }

  redo() {
    this.room.editRoomNameInViewer(this.newName);
  }
}

/* eslint max-classes-per-file:0 */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable consistent-return */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-else-return */
/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
// eslint-disable-next-line import/no-named-as-default-member

import ModalExport from './ModalFile/ModalExport';
import styles from './Tool.module.scss';
import ToolViewer from './ToolViewer';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Divider, Empty, Modal, Spin } from 'antd';
import classNames from 'classnames/bind';
import Cookies from 'js-cookie';
import jcc from 'json-case-convertor';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { image } from '~/assets';
import AppLoading from '~/components/AppLoading';
import ModalControll from '~/components/ModalControll/ModalControll';
import { getMinMaxOfCoordinates } from '~/helper/ForgeViewer/forge-viewer-tool';
import ListAlarmDevice from '~/helper/ForgeViewer/speam/alarmDevices/alarmDevice';
import { RoomStatic } from '~/helper/ForgeViewer/speam/rooms/roomStatic';
import ModalFile from '~/pages/Tool/ModalFile';
import {
    clearProtectDataAlarms,
    clearStateAlarmDevice,
    releaseProtectDataAlarms,
    removeProtectDataAlarm,
    saveProtectDataAlarms,
    selectAlarmDevice,
    setOnDeviceMode,
    setUnit,
    setWrongUnit,
} from '~/store/alarm-device/alarmDeviceSlice';
import { saveDataFile } from '~/store/files/fileActions';
import { filesList, setIsFetchingExport, setUrlExport } from '~/store/files/filesSlice';
import { openModalModalAction } from '~/store/modal/ModalSlice';
import { getOneProject } from '~/store/project/projectAction';
import { projectSelector } from '~/store/project/projectSlice';
import { makeSymbolListSymbolSelector } from '~/store/symbol/symbol.selectors';
import {
    clearProtectSymbolsDataSymbolAction,
    clearStateSymbolAction,
    releaseProtectedSymbolsDataSymbolAction,
    removeProtectedSymbolsDataSymbolAction,
    saveProtectedSymbolsDataSymbolAction,
} from '~/store/symbol/symbolSlice';
import { getFileById, getForgeToken } from '~/store/tool/toolAction';
import {
    clearStateToolAction,
    doCallPostDataToolAction,
    isFetchingControlToolAction,
    selectToolState,
    setDataToolAction,
    setListToolAction,
    setToggleAllTools,
} from '~/store/tool/toolSlice';
import { clearViewerPdfState } from '~/store/viewer-pdf/viewer-pdf.action';
import {
    makeViewerCountRoomSelector,
    makeViewerFloorBoundingBoxSelector,
    makeViewerFloorSettingsToolDataSelector,
    makeViewerGuardAutoSaveFileStatusOfFileSliceSelector,
    makeViewerHaveViewerSelector,
    makeViewerRoomsSelector,
    makeViewerScaleModelSelector,
    protectDataRoomsSelector,
} from '~/store/viewer/viewer.selectors';
import {
    clearProtectData,
    clearProtectDataRoomsViewerAction,
    clearStateViewerAction,
    controlIsFetchingViewerAction,
    releaseProtectDataRoomsByFileIdViewerAction,
    removeProtectDataRoomByFileIdViewerAction,
    saveProtectDataRoomsViewerAction,
    setGuardAutoSaveFileStatusOfFileSliceViewerAction,
    setHasReleasedProtectDataRoomsViewerAction,
    setIsApplyRoomSettingSuccess,
    setViewerLoadSuccessViewerAction,
} from '~/store/viewer/viewer.slice';
import {
    makeHashOfBMZWASelector,
    makeIsShowWireWASelector,
    makeSortedWireAlarmPointsWASelector,
} from '~/store/wire-alarm/wireAlarm.selectors';
import {
    clearProtectDataWireAlarmAction,
    clearStateWireAlarmAction,
    releaseProtectDataWireAlarmAction,
    removeProtectDataWireAlarmAction,
    saveProtectedDataWireAlarmAction,
} from '~/store/wire-alarm/wireAlarm.slice';

const cx = classNames.bind(styles);

const localSave = JSON.parse(sessionStorage.getItem('list'));

// TODO: Tool - should optimize performance , add PropsTypes

export default function Tool({ connection, connStatus }) {
    const [files, setFiles] = useState([]);
    const [token, setToken] = useState(null);
    const [isOpenArticleList, setIsOpenArticleList] = useState(false);
    const [isModalOpenFile, setIsModalOpenFile] = useState(false);
    const [spin, setSpin] = useState(true);

    const roomsSelector = useSelector(makeViewerRoomsSelector());
    const scaleModelSelector = useSelector(makeViewerScaleModelSelector());
    const countRoomSelector = useSelector(makeViewerCountRoomSelector());
    const haveViewerSelector = useSelector(makeViewerHaveViewerSelector());
    const floorBoundingBoxSelector = useSelector(makeViewerFloorBoundingBoxSelector());

    // ----------------------------------------------------------------------------

    const { projectOpen } = useSelector(projectSelector);
    const { data, list, isFetching, isSuccess, doCallPostData, toggleAllTools } = useSelector(selectToolState);
    const {
        alarms,
        alarmInfos,
        countAlarm,
        devices,
        onAddSymbolMode,
        outSideList,
        unit,
        wrongUnit,
        isFetchingUnitMode,
    } = useSelector(selectAlarmDevice);
    const symbolList = useSelector(makeSymbolListSymbolSelector());

    const { files: fileListData, isFetchingSaveFile, isFetchingExportFile } = useSelector(filesList);

    // Floor settings tool data -------------------------------------
    const floorSettingsToolSelector = useSelector(makeViewerFloorSettingsToolDataSelector());

    // wire-alarm ---------------------------------------------------
    const hashBMZSelector = useSelector(makeHashOfBMZWASelector());
    const sortedWireAlarmPointsSelector = useSelector(makeSortedWireAlarmPointsWASelector());
    const isShowWireAlarmSelector = useSelector(makeIsShowWireWASelector());
    const protectDataRooms = useSelector(protectDataRoomsSelector());
    // ---------------------------------------------------------------

    const guardAutoSaveFileStatusOfFileSliceSelector = useSelector(
        makeViewerGuardAutoSaveFileStatusOfFileSliceSelector(),
    );

    const controller = new AbortController();
    const { id: paramId } = useParams();
    const clientId = Cookies.get('clientId');

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!data && (fileListData === null || fileListData.length < 1)) {
            dispatch(getOneProject({ id: data.projectId, isDeleted: false, dispatch, t }));
        }
    }, [data, fileListData]);

    useEffect(() => {
        if (!!data?.fileData) {
            let timer = null;
            if (
                !isFetchingSaveFile &&
                !!projectOpen &&
                !!data.fileData &&
                projectOpen.saveTime &&
                projectOpen.saveTime > 0
            ) {
                timer = setInterval(() => {
                    saveData(true);
                }, [projectOpen.saveTime * 60000]);

                // --------------------------------------------------------------
                // Guarding supports to close RoomItemSettingForm
                // --------------------------------------------------------------
                dispatch(setGuardAutoSaveFileStatusOfFileSliceViewerAction(true));
            }
            if (!!timer) {
                return () => clearInterval(timer);
            }
        }
    }, [projectOpen, data, isFetchingSaveFile]);

    useEffect(() => {
        if (!roomsSelector || roomsSelector.length < 1) {
            setSpin(true);
        } else if (isFetchingSaveFile) {
            setSpin(true);
        } else {
            setSpin(false);
        }
    }, [roomsSelector, isFetchingSaveFile]);

    useEffect(() => {
        if (connStatus === 1) {
            Modal.error({
                title: 'Connection errors!',
                content: 'Connection to Webhook failed. Please try again!',
                onOk() {},
            });
        }
        dispatch(setListToolAction(localSave ?? []));
        dispatch(getFileById(paramId));

        return () => {
            dispatch(clearStateAlarmDevice());
            dispatch(clearStateViewerAction());
            dispatch(clearStateToolAction);
            ListAlarmDevice.cleanup();
        };
    }, []);

    useEffect(() => {
        if (data && isSuccess) {
            const newList = [...list];
            setFiles(newList);
            dispatch(getForgeToken())
                .then(unwrapResult)
                .then((originalPromiseResult) => {
                    setToken(originalPromiseResult.result);
                })
                .catch((rejectedValueOrSerializedError) => {
                    console.error(rejectedValueOrSerializedError);
                });
        }
    }, [isSuccess, localSave, list]);

    useEffect(() => {
        const newList = [...list];
        setFiles(newList);
    }, [paramId, data, localSave]);

    const onBackHome = () => {
        // close the perimeter dialog
        try {
            controller.abort();
            setFiles([]);
            dispatch(setWrongUnit(false));
            dispatch(clearProtectDataAlarms());
            dispatch(clearProtectDataRoomsViewerAction());
            dispatch(clearProtectDataWireAlarmAction());
            dispatch(clearProtectSymbolsDataSymbolAction());
            dispatch(clearStateAlarmDevice());
            dispatch(clearStateViewerAction());
            dispatch(clearProtectData());
            dispatch(clearStateToolAction());
            dispatch(clearStateSymbolAction());
            dispatch(clearStateWireAlarmAction());
            dispatch(setUrlExport(null));
            dispatch(setIsFetchingExport(false));
            dispatch(clearViewerPdfState());

            // guarding that supports for Closing RoomItemSettingForm
            dispatch(setGuardAutoSaveFileStatusOfFileSliceViewerAction(false));
            sessionStorage.clear();

            // set global unit for model
            RoomStatic.unit = '';
            dispatch(setUnit(null));
            dispatch(setOnDeviceMode(false));
        } catch (err) {
            console.error(err);
        }

        ListAlarmDevice.cleanup();
        navigate(`/project/${projectOpen.id}`);
    };

    const handleCloseFile = useCallback(
        async (idFile) => {
            let num;
            const index = files.findIndex((file) => file.id === idFile);

            if (index > -1) {
                if (index > 0) num = index - 1;
                if (num < 0) num = index + 2;
                files.splice(index, 1);
                sessionStorage.setItem('list', JSON.stringify(files));
                dispatch(setListToolAction(files));
                await dispatch(removeProtectDataAlarm(idFile));
                await dispatch(removeProtectDataRoomByFileIdViewerAction(idFile));
                await dispatch(removeProtectDataWireAlarmAction(idFile));
                await dispatch(removeProtectedSymbolsDataSymbolAction(idFile));
            }
            if (files.length === 0) return onBackHome();
            if (idFile === paramId) handleSelectFile(files[num]);
        },
        [files, paramId],
    );

    const handleSelectFile = useCallback(
        async (file) => {
            if (file?.id === paramId) {
                return;
            } else {
                dispatch(controlIsFetchingViewerAction(false));
                const payloadAlarm = {
                    id: paramId,
                    alarms,
                    alarmInfos,
                    countAlarm,
                    unit,
                };

                const payloadRoom = {
                    id: paramId,
                    rooms: roomsSelector,
                    scaleModel: scaleModelSelector,
                    countRoom: countRoomSelector,
                    haveViewer: haveViewerSelector,
                    floorBoundingBox: floorBoundingBoxSelector,
                };

                const payloadWireAlarm = {
                    id: paramId,
                    hashOfBMZ: hashBMZSelector,
                    isShowWireWire: isShowWireAlarmSelector,
                    sortedWireAlarmPoints: sortedWireAlarmPointsSelector,
                };

                const payloadSymbols = {
                    id: paramId,
                    symbolList,
                };

                dispatch(isFetchingControlToolAction(true));
                if (roomsSelector && roomsSelector?.length > -1) {
                    // Save data file isOpen
                    await dispatch(saveProtectDataAlarms(payloadAlarm));
                    await dispatch(saveProtectDataRoomsViewerAction(payloadRoom));
                    await dispatch(saveProtectedDataWireAlarmAction(payloadWireAlarm));
                    await dispatch(saveProtectedSymbolsDataSymbolAction(payloadSymbols));
                }

                // Clear all State
                dispatch(clearStateAlarmDevice());
                dispatch(clearStateViewerAction());
                dispatch(clearStateSymbolAction());
                dispatch(clearStateWireAlarmAction());
                dispatch(clearViewerPdfState());

                // ================================================================
                dispatch(setDataToolAction(file));
                // ================================================================

                const hasProtectData = protectDataRooms?.find((d) => d.id === file.id);

                if (hasProtectData) {
                    // Release save data in dat-protect if have
                    await dispatch(releaseProtectDataAlarms(file?.id));
                    await dispatch(releaseProtectDataRoomsByFileIdViewerAction(file?.id));
                    await dispatch(releaseProtectedSymbolsDataSymbolAction(file?.id));
                    await dispatch(releaseProtectDataWireAlarmAction(file?.id));
                }

                // turn off state of forge viewer
                dispatch(setViewerLoadSuccessViewerAction(false));

                // flag that notify for update RoomsStatic
                dispatch(setHasReleasedProtectDataRoomsViewerAction(true));
                await dispatch(setOnDeviceMode(false));

                // Show viewer
                dispatch(isFetchingControlToolAction(false));

                navigate(`/tool/${file?.id}`);
            }
        },
        [
            alarms,
            paramId,
            alarmInfos,
            countAlarm,
            roomsSelector,
            scaleModelSelector,
            countRoomSelector,
            haveViewerSelector,
            floorBoundingBoxSelector,
            symbolList,
        ],
    );

    const onShowOpenFileModal = useCallback(() => {
        setIsModalOpenFile(true);
    }, []);

    const handleCancelOpenFileModal = useCallback(() => {
        setIsModalOpenFile(false);
    }, []);

    const saveData = useCallback(
        (auto) => {
            if (!data || !roomsSelector || roomsSelector.length < 1) return;
            const parseData = jcc.camelCaseKeys(JSON.parse(data.fileData));
            dispatch(
                saveDataFile({
                    id: data.id,
                    fileData: JSON.stringify({
                        ...parseData,
                        alarms: alarmInfos,
                        rooms: roomsSelector,
                        floorSettings: floorSettingsToolSelector,
                        devices,
                        outSideList,
                        symbol: symbolList,
                    }),
                    t,
                    auto,
                }),
            );
        },
        [alarmInfos, roomsSelector, data, files, devices, symbolList, floorSettingsToolSelector],
    );

    /**
     * Helper call api when room setting form is submitted
     */
    useEffect(() => {
        if (doCallPostData && guardAutoSaveFileStatusOfFileSliceSelector === false) {
            saveData();
            dispatch(doCallPostDataToolAction(false));
            dispatch(setIsApplyRoomSettingSuccess(true));
        }
        if (doCallPostData && guardAutoSaveFileStatusOfFileSliceSelector === true) {
            saveData();
        }
    }, [doCallPostData, guardAutoSaveFileStatusOfFileSliceSelector]);

    // ==================================================================
    // Render Tool
    // ==================================================================
    return (
        <div className={cx('tool-container')}>
            {onAddSymbolMode && (
                <div className={cx('notify')}>
                    <div className={cx('notify__title')}>
                        <img src={image.logo} alt="" style={{ width: '30px', height: '30px' }} />
                        <h4>{t('add_symbol_mode')}</h4>
                    </div>
                    <p>
                        <i className="fa-solid fa-circle-info" style={{ color: '#183153' }} />
                        {t('move_the_blueprint_by_holding_the_right_mouse_and_use_edit_device_to_move_symbol')}.
                    </p>

                    <p style={{ paddingRight: 2, color: 'var(--primary-color)' }}>
                        <i className="fa-solid fa-octagon-xmark" />
                        {t('press_esc_to_exit')}
                    </p>
                </div>
            )}
            {wrongUnit && <div className={cx('notify', 'noti-wrong-unit')}>{t('your_unit_is_wrong_now')}</div>}

            <div className={cx('tool-header')}>
                <div className={cx('tool-header__item')} onClick={onBackHome} aria-hidden="true">
                    <i className="fa-solid fa-arrow-left" /> {t('back')}
                </div>
                <Divider type="vertical" style={{ height: '50px' }} />
                <div
                    className={cx('tool-header__item', toggleAllTools && 'tool-header__active')}
                    onClick={async () => {
                        dispatch(setToggleAllTools());
                    }}
                    aria-hidden="true"
                >
                    <i className="fa-solid fa-gear" /> {t('tools')}
                </div>
                <Divider type="vertical" style={{ height: '50px' }} />
                <div
                    className={cx('tool-header__item')}
                    onClick={() => {
                        dispatch(openModalModalAction({ title: 'upload' }));
                    }}
                    aria-hidden="true"
                >
                    <i className="fa-solid fa-cloud-arrow-up" /> {t('upload_file')}
                </div>

                <div className={cx('tool-header__item')} onClick={onShowOpenFileModal} aria-hidden="true">
                    <i className="fa-solid fa-folder-open" /> {t('open_file')}
                </div>

                <div className={cx('tool-header__save-btn')} aria-hidden="true">
                    <Button
                        style={{ minWidth: 60 }}
                        onClick={() => saveData(false)}
                        className="btn-custom btn-custom-small"
                        disabled={spin}
                    >
                        <span>
                            {isFetchingSaveFile && (
                                <Spin className="loading-custom" style={{ position: 'absolute', right: '30%' }} />
                            )}
                            <i className="fa-solid fa-floppy-disk" style={{ marginRight: 4 }} />
                            {t('save')}
                        </span>
                    </Button>
                </div>

                {/* Save Button */}
                <div className={cx('tool-header__save-btn')} aria-hidden="true">
                    <Button onClick={() => setIsOpenArticleList(true)} className="btn-custom btn-custom-small">
                        <span>
                            <i className="fa-solid fa-list"></i> {t('article_list')}
                        </span>
                    </Button>
                    <Modal
                        title={t('article_list')}
                        okText={t('ok')}
                        cancelText={t('cancel')}
                        centered
                        open={isOpenArticleList}
                        onOk={() => setIsOpenArticleList(false)}
                        onCancel={() => setIsOpenArticleList(false)}
                        width="100vw"
                        bodyStyle={{ height: '80vh' }}
                    >
                        <iframe
                            src={`${window.location.origin}/article/index.html`}
                            sandbox="allow-scripts allow-downloads allow-same-origin"
                            width="100%"
                            height="100%"
                            title={t('article_list')}
                        ></iframe>
                    </Modal>
                </div>

                {/* Export Button */}
                <div className={cx('tool-header__save-btn')} aria-hidden="true">
                    <ModalExport
                        isDisabled={
                            !(
                                isFetchingExportFile ||
                                (devices && devices.length > 0) ||
                                (sortedWireAlarmPointsSelector && sortedWireAlarmPointsSelector.length > 0)
                            )
                        }
                        fileName={`${data?.name?.replace('.dwg', '')}.pdf`}
                        devices={devices}
                        wireData={sortedWireAlarmPointsSelector}
                        clientId={connection?.connectionId || clientId}
                    />
                </div>
            </div>

            <div className={cx('tool-viewer')}>
                <div className={cx('tool-viewer__file')}>
                    {files?.map((file) => (
                        <div
                            key={file?.id}
                            className={cx('file-item', file?.id === paramId && 'file-active')}
                            aria-hidden="true"
                        >
                            <button onClick={() => handleSelectFile(file)}>{file?.name}</button>
                            <button
                                className={cx('file-item__close')}
                                onClick={() => handleCloseFile(file?.id)}
                                aria-hidden="true"
                            >
                                <i className="fa-solid fa-circle-xmark" />
                            </button>
                        </div>
                    ))}
                </div>

                <div className={cx('tool-viewer__content')} style={{ width: '100%', height: 'calc(100vh - 180px)' }}>
                    {files.length < 1 || !token ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no_data')} />
                    ) : (
                        ((isFetching || isFetchingUnitMode) && <AppLoading listFile />) || (
                            <ToolViewer
                                file={data}
                                token={token}
                                clientId={connection?.connectionId || clientId}
                                controller={controller}
                            />
                        )
                    )}
                </div>
            </div>

            <ModalFile
                onCloseFile={(id) => handleCloseFile(id)}
                isOpen={isModalOpenFile}
                onCancel={handleCancelOpenFileModal}
                title={t('all_file')}
            />

            <ModalControll />
        </div>
    );
}

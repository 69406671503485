/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */

import useViewerPDF from './hook/useViewerPdf';
import { useBoolean } from 'ahooks';
import { Button, Col, Input, Modal, Row, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AppLoading from '~/components/AppLoading';
import { customMemo } from '~/helper/common';
import DraggableFSChildModal from '~/pages/Tool/ToolViewer/SubTool/FloorSettingsTool/DraggableFSChildModal';
import { updateCustomer } from '~/store/generalData/generalAction';
import { generalSelector } from '~/store/generalData/generalSlice';
import { getOneProject } from '~/store/project/projectAction';
import { projectSelector } from '~/store/project/projectSlice';
import { selectToolState } from '~/store/tool/toolSlice';
import {
    makeViewerPdfCurrentCustomerSelector,
    makeViewerPdfCurrentFileSelector,
} from '~/store/viewer-pdf/viewer-pdf.selector';
import { makeViewerFloorBoundingBoxSelector } from '~/store/viewer/viewer.selectors';

const { Text } = Typography;

const ProInput = React.forwardRef((props, ref) => {
    const { t } = useTranslation();

    return (
        <div style={{ position: 'relative' }}>
            <Text
                style={{
                    color: 'var(--primary-color)',
                    fontWeight: 600,
                    fontSize: '0.8rem',
                    lineHeight: '1.4375em',
                    letterSpacing: '0.00938em',
                    paddingBlock: 0,
                    paddingInline: '4px',
                    position: 'absolute',
                    left: '12px',
                    top: '2px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(133% - 24px)',
                    display: 'block',
                    backgroundColor: '#ffffff',
                    borderRadius: '25px',
                    pointerEvents: 'none',
                    userSelect: 'none',
                    WebkitTransform: 'translate(-4px, -13px)',
                    msTransform: 'translate(-4px, -13px)',
                    transform: 'translate(-4px, -13px)',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                    zIndex: 1,
                    textTransform: 'none',
                }}
            >
                {props?.title}
            </Text>
            <Input
                style={{
                    // pointerEvents: 'none',
                    userSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                }}
                {...props}
                ref={ref}
                placeholder={t('please_type_something')}
                contentEditable={false}
            />
        </div>
    );
});

const ProSelect = React.forwardRef((props, ref) => {
    return (
        <div style={{ position: 'relative' }}>
            <Text
                style={{
                    color: 'var(--primary-color)',
                    fontWeight: 600,
                    fontSize: '0.8rem',
                    lineHeight: '1.4375em',
                    letterSpacing: '0.00938em',
                    paddingBlock: 0,
                    paddingInline: '4px',
                    position: 'absolute',
                    left: '12px',
                    top: '2px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(133% - 24px)',
                    display: 'block',
                    backgroundColor: '#ffffff',
                    borderRadius: '25px',
                    pointerEvents: 'none',
                    userSelect: 'none',
                    WebkitTransform: 'translate(-4px, -13px)',
                    msTransform: 'translate(-4px, -13px)',
                    transform: 'translate(-4px, -13px)',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                    zIndex: 1,
                    textTransform: 'none',
                }}
            >
                {props?.title}
            </Text>
            <Select {...props} ref={ref} />
        </div>
    );
});

// -----------------------------------------------------
const pageSizeOptions = [
    {
        label: 'A0',
        value: 'a0',
    },
    {
        label: 'A1',
        value: 'a1',
    },
    {
        label: 'A2',
        value: 'a2',
    },
    {
        label: 'A3',
        value: 'a3',
    },
    {
        label: 'A4',
        value: 'a4',
    },
];
// -----------------------------------------------------

function ViewerPdf({ quality, open, setOpen }) {
    // Hook that manage viewer pdf
    const {
        downloadPDF,
        previewOrReloadIframePDF,
        releaseIframePDF,
        pdfDataUri,
        pdfRef,
        pageSize,
        setPageSize,
        isIframeLoading,
        setInS12,
        setInS22,
        setInS32,
        setInS42,
        setInS52,
        setInProjectInfo,
    } = useViewerPDF();

    // dispatch action to store
    const dispatch = useDispatch();

    // ---------------------------------------------------------------------------
    // Helper for changing information when open new files at tool bar
    // !! Really important !!
    const { isFetching } = useSelector(selectToolState);
    useEffect(() => {
        if (isFetching) {
            setOpen(false);
            return;
        }
    }, [isFetching]);
    // ---------------------------------------------------------------------------
    // helper for getting floorBoundingBox
    const floorBoundingBox = useSelector(makeViewerFloorBoundingBoxSelector());

    // State for Draggable
    const [disabled, setDisabled] = useState(true);

    // For translation
    const { t, i18n } = useTranslation();

    const [currentLanguage, setCurrentLanguage] = useState(i18n?.language || 'en');

    // Activate when user click on 'minus' sign button
    const [hasModalBody, setHasModalBody] = useState(true);

    // Helpful for loading Iframe PDF the first time
    useEffect(() => {
        if (quality && open) {
            previewOrReloadIframePDF(quality);
        }
    }, [quality, open]);

    // --------------------------------------------------------------------------------

    // Get Project information
    const { projectOpen } = useSelector(projectSelector);
    const { isSuccessUpdateCustomer } = useSelector(generalSelector);

    // For validation of desired data those are used in this component
    const [currentCustomer, setCurrentCustomer] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);

    // --------------------------------------------------------------------------------
    // With saga helper
    const currentCustomerSelector = useSelector(makeViewerPdfCurrentCustomerSelector());
    const currentFileSelector = useSelector(makeViewerPdfCurrentFileSelector());

    // --------------------------------------------------------------------------------
    const [isActivateDispatchGetOneProject, { set: setIsActivateDispatchGetOneProject }] = useBoolean();
    const [isAllowAction, { set: setIsAllowAction }] = useBoolean();
    // --------------------------------------------------------------------------------
    const [outS11, setOutS11] = useState(undefined);
    const [outS12, setOutS12] = useState(undefined);
    const [outS21, setOutS21] = useState(undefined);
    const [outS22, setOutS22] = useState(undefined);
    const [outS31, setOutS31] = useState(undefined);
    const [outS32, setOutS32] = useState(undefined);
    const [outS51, setOutS51] = useState(undefined);
    const [outS52, setOutS52] = useState(undefined);
    // --------------------------------------------------------------------------------
    const getTheLargestZoomOfFloor = useCallback(async () => {
        const { minP, maxP } = floorBoundingBox;
        const min3 = new window.THREE.Vector3(minP.x, minP.y, 0);
        const max3 = new window.THREE.Vector3(maxP.x, maxP.y, 0);
        const bbox = new window.THREE.Box3(min3, max3);
        window.viewer.navigation.fitBounds(false, bbox);
    }, [floorBoundingBox]);

    useEffect(() => {
        if (open && currentLanguage !== i18n?.language) {
            setOpen(false);
            setCurrentLanguage(i18n?.language);
        }
    }, [i18n?.language, open, currentLanguage]);

    // ---------------- Important! ----------------
    // If Project has customer => set data of customer from API
    useEffect(() => {
        // if (!currentFileSelector) return;
        if (!currentCustomerSelector) {
            // Helper for render default state of customer
            const defaultName = `${t('default')}`;

            const customer = {
                id: null,
                name: defaultName,
                street: defaultName,
                postcode: '0',
                city: defaultName,
                email: defaultName,
                dynamicFields: defaultName,
                telephone: '0',
                type: 0,
            };
            setCurrentCustomer({ ...customer });
        } else {
            setCurrentCustomer({ ...currentCustomerSelector });
        }

        // setCurrentFile({ ...currentFileSelector });
    }, [currentCustomerSelector, currentFileSelector]);

    // Base Information of PDF document
    useEffect(() => {
        if (!currentFileSelector) {
            return;
        }
        // Filename without extension of this file
        const filename = String(currentFileSelector?.name).toLowerCase();
        const indexOfExtension = filename.lastIndexOf('.dwg');
        // Keep all the transformation of letters of filename
        const filenameWithoutExtension = currentFileSelector?.name?.slice(0, indexOfExtension);
        // for change state of hook 'useViewerPdf'
        setInProjectInfo({
            title: `${filenameWithoutExtension}`,
            subject: `${filenameWithoutExtension}`,
            author: `${currentCustomer?.name}`,
            keywords: 'KFT',
            creator: `${currentCustomer?.name}`,
        });
    }, [currentFileSelector, currentCustomer]);

    // For rendering text in the bottom-right frame of PDF
    useEffect(() => {
        // Updated time of this file
        const updateAt = dayjs(currentFileSelector?.updateOn).format('MM / YYYY');

        // Filename without extension of this file
        const filename = String(currentFileSelector?.name).toLowerCase();
        const indexOfExtension = filename.lastIndexOf('.dwg');
        // Keep all the transformation of letters of filename
        const filenameWithoutExtension = currentFileSelector?.name?.slice(0, indexOfExtension);

        const s12 = `${currentCustomer?.name}`;
        const s22 = `${currentCustomer?.street}`;
        const s32 = `Projektierung ${filenameWithoutExtension}`;
        const s52 = `${updateAt}`;

        setOutS11(`object`);
        setOutS12(`${t(currentCustomer?.name)}`);
        setOutS21(`address`);
        setOutS22(`${t(currentCustomer?.street)}`);
        setOutS31(`plan_type`);
        setOutS32(`Projektierung ${filenameWithoutExtension}`);
        setOutS51(`stand`);
        setOutS52(`${updateAt}`);

        setInS12(s12);
        setInS22(s22);
        setInS32(s32);
        setInS42('KFT Kraus Feuerschutztechnik e. K. Friedric-List-Straße 32 70771 Leinfelden-Echterdingen');
        setInS52(s52);
    }, [currentFileSelector, currentCustomer]);

    const handleReloadPDFdata = useCallback(async () => {
        const customerId = currentCustomer?.id;
        // If Project has customer => when click reload button should call api
        if (customerId) {
            const dataToDispatch = {
                type: 0,
                name: outS12,
                street: outS22,
                postcode: currentCustomer?.postcode,
                city: currentCustomer?.city,
                telephone: currentCustomer?.telephone,
                email: currentCustomer?.email,
                dynamicFields: currentCustomer?.dynamicFields,
            };
            // Call api to update the user's information
            dispatch(updateCustomer({ id: customerId, data: dataToDispatch, t }));
        }
        // This is a flag helper for UseEffect below
        setIsActivateDispatchGetOneProject(true);
    }, [outS12, outS22, currentCustomer, isSuccessUpdateCustomer]);

    useEffect(() => {
        // After Updating the user, should call api to get the latest data of current Project
        if (isSuccessUpdateCustomer && isActivateDispatchGetOneProject) {
            dispatch(getOneProject({ id: projectOpen?.id, isDeleted: false, dispatch, t }));
            // turn off call api getOneProject
            setIsActivateDispatchGetOneProject(false);
        }
    }, [projectOpen, projectOpen?.id, isSuccessUpdateCustomer, isActivateDispatchGetOneProject]);

    // Validation to make sure user should fill all inputs
    useEffect(() => {
        const isValidate = outS12?.length === 0 || outS22?.length === 0 || outS32?.length === 0 || outS52?.length === 0;
        if (isValidate) {
            setIsAllowAction(false);
        } else {
            setIsAllowAction(true);
        }
    }, [outS12, outS22, outS32, outS52]);

    /**
     * Render errorMessage
     */
    const renderErrorMessage = (
        <div style={{ marginLeft: '4px' }}>
            <i className="fa-solid fa-triangle-exclamation" style={{ color: 'red' }} />{' '}
            <span
                style={{
                    textTransform: 'none',
                    color: 'red',
                    fontSize: '0.75rem',
                }}
            >
                {t('please_type_something')}!
            </span>
        </div>
    );

    return (
        <>
            <Modal
                key="modal-viewer-pdf"
                title={
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: '40px',
                            marginBlock: '-12px',
                        }}
                        onMouseOver={() => {
                            if (disabled) {
                                setDisabled(false);
                            }
                        }}
                        onMouseOut={() => {
                            setDisabled(true);
                        }}
                        onFocus={() => {}}
                        onBlur={() => {}}
                    >
                        <h3
                            style={{
                                userSelect: 'none',
                                pointerEvents: 'none',
                            }}
                        >
                            {`${t('preview_PDF')}`}
                        </h3>
                        <div>
                            <i
                                className="fa fa-minus"
                                style={{
                                    fontSize: '16px',
                                    marginRight: '8px',
                                    cursor: 'pointer',
                                    padding: '3px',
                                }}
                                onClick={() => {
                                    getTheLargestZoomOfFloor().then(() => {
                                        setTimeout(async () => {
                                            setHasModalBody((prev) => !prev);
                                            await previewOrReloadIframePDF(quality);
                                        }, [500]);
                                    });
                                }}
                            />
                            <i
                                className="fa fa-close"
                                style={{
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    padding: '3px',
                                }}
                                onClick={() => {
                                    setHasModalBody(true);
                                    setOpen(false);
                                    releaseIframePDF();
                                }}
                            />
                        </div>
                    </div>
                }
                open={open}
                mask={false}
                footer={null}
                bodyStyle={{
                    display: hasModalBody ? 'block' : 'none',
                    padding: '24px 18px 0px 24px',
                    overflow: 'auto',
                    minHeight: '546px',
                }}
                style={{ top: 48 }}
                width="1280px"
                maskClosable={false}
                closeIcon={null}
                closable={false}
                modalRender={(modal) => <DraggableFSChildModal modal={modal} disabled={disabled} />}
                data-id="modal-viewer-pdf"
            >
                <div>
                    <Row justify="space-between" style={{ marginBottom: '24px' }} gutter={[16, 16]} wrap={false}>
                        <Col span={6} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                            <ProSelect
                                title={t('page_size')}
                                value={pageSize}
                                style={{ width: '100%' }}
                                options={pageSizeOptions}
                                onChange={(value) => {
                                    setPageSize(value);
                                }}
                            ></ProSelect>

                            <div id="Objekt" style={{ height: '44px' }}>
                                <ProInput
                                    title={`${t(outS11)}`}
                                    value={outS12}
                                    onChange={(e) => {
                                        const text = e.target.value;
                                        // for change state of hook 'useViewerPdf'
                                        setInS12(text);
                                        setOutS12(text);
                                    }}
                                ></ProInput>
                                {outS12?.length === 0 && renderErrorMessage}
                            </div>

                            <div id="Adresse" style={{ height: '44px' }}>
                                <ProInput
                                    title={`${t(outS21)}`}
                                    value={outS22}
                                    onChange={(e) => {
                                        const text = e.target.value;
                                        // for change state of hook 'useViewerPdf'
                                        setInS22(text);
                                        setOutS22(text);
                                    }}
                                ></ProInput>
                                {outS22?.length === 0 && renderErrorMessage}
                            </div>

                            <div id="Planart" style={{ height: '44px' }}>
                                <ProInput
                                    title={`${t(outS31)}`}
                                    value={outS32}
                                    onChange={(e) => {
                                        const text = e.target.value;
                                        // for change state of hook 'useViewerPdf'
                                        setInS32(text);
                                        setOutS32(text);
                                    }}
                                ></ProInput>
                                {outS32?.length === 0 && renderErrorMessage}
                            </div>

                            <div id="Stand" style={{ height: '44px' }}>
                                <ProInput
                                    title={`${t(outS51)}`}
                                    value={outS52}
                                    onChange={(e) => {
                                        const text = e.target.value;
                                        // for change state of hook 'useViewerPdf'
                                        setInS52(text);
                                        setOutS52(text);
                                    }}
                                ></ProInput>
                                {outS52?.length === 0 && renderErrorMessage}
                            </div>

                            <Button
                                disabled={!isAllowAction}
                                onClick={() => {
                                    getTheLargestZoomOfFloor().then(() => {
                                        setTimeout(async () => {
                                            await handleReloadPDFdata();
                                            await previewOrReloadIframePDF(quality);
                                        }, [500]);
                                    });
                                }}
                            >
                                {t('reload_data')}
                            </Button>
                            <Button
                                type="primary"
                                disabled={!isAllowAction}
                                onClick={async () => {
                                    await downloadPDF();
                                }}
                            >
                                {t('download_PDF')}
                            </Button>
                        </Col>
                        <Col span={18}>
                            {isIframeLoading && <AppLoading menuTool />}
                            <div
                                style={{
                                    minWidth: '900px',
                                    minHeight: '506px',
                                }}
                            >
                                <iframe
                                    title="iframe-pdf-viewer"
                                    id="iframe-pdf-viewer"
                                    style={{ display: pdfDataUri ? 'block' : 'none', border: '0px solid white' }}
                                    width="900px"
                                    height="506px"
                                    ref={pdfRef}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
}

export default customMemo(ViewerPdf);

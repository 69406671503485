import PDF_FONT_LIST from "./fonts/index";

const CONFIG = {
  UNIT: {
    MM: "mm",
    CM: "cm",
    IN: "in",
    PT: "pt",
  },

  PAGE_SIZE: {
    A0: "a0",
    A1: "a1",
    A2: "a2",
    A3: "a3",
    A4: "a4",
  },

  LANGUAGE: {
    EN: "en",
    DE: "de",
  },

  FONT_LIST: PDF_FONT_LIST,
};

export default CONFIG;

/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
import { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

// optional value of detector type
const optionValues = [
    {
        value: 'example1',
        label: 'Example1',
    },
    {
        value: 'example2',
        label: 'Example2',
    },
    {
        value: 'example3',
        label: 'Example3',
    },
];

/**
 * Render FormItemDetectorTypeSelectBox
 */
export default function FormItemRHSDetectorTypeSelectBox({ fields }) {
    const [detectorTypeValue, setDetectorTypeValue] = useState(fields['detectorType']?.value);

    // helper for translation
    const { t } = useTranslation();
    // Get an instance of form
    const form = Form.useFormInstance();

    /**
     * When submit form, we can choose the desired value
     * for corresponding field name
     */
    useEffect(() => {
        form.setFieldsValue({ detectorType: detectorTypeValue });
    }, [detectorTypeValue]);

    return (
        <Col>
            <Form.Item name={'detectorType'} style={{ marginBottom: '8px' }} valuePropName="value" noStyle>
                <Input type="text" value={detectorTypeValue} hidden />
            </Form.Item>
            <Row justify="start" align='middle' gutter={8}>
                <Col>
                    <p>{t('detector_type')}</p>
                </Col>
                <Col>
                    <Select
                        style={{ width: '170px' }}
                        placeholder={t('choose_your_option').toLowerCase()}
                        onChange={(value) => setDetectorTypeValue(value)}
                        options={optionValues}
                    />
                </Col>
            </Row>
        </Col>
    );
}

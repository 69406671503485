import { createSlice } from '@reduxjs/toolkit';
import { getFileById } from './toolAction';

/**
 * Default state of tool slice
 */
const initialState = {
    data: null,
    list: [],
    isFetching: false,
    isError: false,
    isSuccess: false,
    errorMessage: [],
    doCallPostData: false,
    toggleAllTools: true,
};

/**
 * ***************************************************************************
 * Create a tool slice
 * ***************************************************************************
 */
const toolSlice = createSlice({
    name: 'tool',
    initialState,
    reducers: {
        /**
         * ACTION: set hide or show all tools
         * @param {*} state   => base state
         * @param {*} payload  => true or false
         */
        setToggleAllTools: (state) => {
            state.toggleAllTools = !state.toggleAllTools;
        },

        doCallPostDataToolAction: (state, { payload }) => {
            state.doCallPostData = payload;
        },

        /**
         * ACTION: set the file's data to the state's data
         * @param {*} state   => base state
         * @param {*} payload  => file's data
         */
        setDataToolAction: (state, { payload }) => {
            state.data = payload;
        },

        /**
         * ACTION: set the name of modal to modalData
         * @param {*} state   => base state
         * @param {*} payload  => list of files support for switching between tabs in Tool page
         */
        setListToolAction: (state, { payload }) => {
            state.list = payload;
        },

        /**
         * ACTION: set the name of modal to modalData
         * @param {*} state   => base state
         * @param {*} payload  => list of files support for switching between tabs in Tool page
         */
        removeItemInListToolAction: (state, { payload }) => {
            state.list = state.list.filter((item) => item.id !== payload);
        },

        /**
         * ACTION: helper supports during get released Protected Data
         * @param {*} state   => base state
         * @param {*} payload  => true or false
         */
        isFetchingControlToolAction: (state, { payload }) => {
            state.isFetching = payload;
        },

        /**
         * ACTION: set state of tool slice to default
         * @param {*} state   => base state
         * @param {*} payload  => true or false
         */
        clearStateToolAction: (state) => {
            state.list = [];
            state.data = null;
            state.isError = false;
            state.isFetching = false;
            state.isSuccess = false;
            state.errorMessage = [];
            state.doCallPostData = false;
            state.toggleAllTools = true;
        },
    },
    extraReducers: {
        [getFileById.pending]: (state) => {
            state.isFetching = true;
            state.isSuccess = false;
            state.isError = false;
        },
        [getFileById.fulfilled]: (state, { payload }) => {
            state.isError = false;
            state.isSuccess = true;
            state.errorMessage = [];
            state.data = payload.data.result;
            const localSave = JSON.parse(sessionStorage.getItem('list')) || [];
            const result = localSave.find((item) => item.id === payload.id);
            if (!result) {
                sessionStorage.setItem('list', JSON.stringify([...localSave, payload.data.result]));
                state.list = [...localSave, payload.data.result];
            }
            state.isFetching = false;
        },
        [getFileById.rejected]: (state) => {
            state.isFetching = false;
            state.isSuccess = false;
            state.isError = true;
            state.errorMessage = 'Error!';
        },
    },
});

/**
 * SELECTOR: current state of tool slice
 * @param {*} state
 * @returns
 */
export const selectToolState = (state) => state.tool;

// ============================================================================
// Export Actions
// ============================================================================
export const {
    doCallPostDataToolAction,
    setDataToolAction,
    setListToolAction,
    isFetchingControlToolAction,
    removeItemInListToolAction,
    clearStateToolAction,
    setToggleAllTools,
} = toolSlice.actions;

// ============================================================================
// Export Reducer
// ============================================================================
export default toolSlice.reducer;

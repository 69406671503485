/* eslint-disable react/prop-types */
import { Checkbox, Popconfirm, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropsTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { v4 as uuid } from 'uuid';

import classNames from 'classnames/bind';
import styles from './RoomItem.module.scss';

const cx = classNames.bind(styles);

const { Paragraph } = Typography;

export default function RoomItem({
    room,
    onChangeCheckboxRoom,
    onDeleteRoom,
    onEditName,
    viewerUnit,
    onOpenRoomSetting,
}) {
    // helper for translation
    const { t } = useTranslation();

    /**
     * Info of actions that supports for rendering
     */
    const actions = (item) => ({
        bin: {
            name: 'bin',
            id: item.roomId,
            title: t('do_you_want_to_delete_this_room'),
        },
        roomSetting: {
            name: 'room-setting',
            id: item.roomId,
        },
    });

    /**
     * Info of room attribute that supports for rendering
     */
    const roomAttributes = (currentRoom) => ({
        width: {
            name: 'width',
            type: currentRoom.width,
        },
        length: {
            name: 'length',
            type: currentRoom.height,
        },
    });

    /**
     * helper for emitting editedRoomName object to parent
     * @param {*} name
     */
    const onItemEditName = (name) => {
        const editedRoomName = {
            roomId: room.roomId,
            newName: name,
        };
        onEditName(editedRoomName);
    };

    /**
     * helper that renders all button action
     * @param {*} actionName
     */
    const renderButtonActions = (action) => {
        switch (action.name) {
            case 'bin':
                return (
                    <Popconfirm
                        title={action.title}
                        onConfirm={() => onDeleteRoom(action.id)}
                        okText={t('yes')}
                        cancelText={t('no')}
                    >
                        <i className="fa-solid fa-trash" />
                    </Popconfirm>
                );
            case 'room-setting':
                return (
                    <span onClick={() => onOpenRoomSetting(action.id)}>
                        <i className="fa-solid fa-gear" />
                    </span>
                );
            default:
                return <div />;
        }
    };

    /**
     * helper that renders room attributes
     * @param {*} attribute
     */
    const renderRoomAttributes = (attribute) => (
        <>
            <span>{t(attribute.name)}: </span>
            <NumericFormat
                value={attribute.type}
                suffix={' '.concat(`${viewerUnit}`)}
                displayType="text"
                thousandSeparator={t(',')}
                decimalSeparator={t('.')}
                style={{ textTransform: 'lowercase', marginLeft: 4 }}
            />
        </>
    );

    // ========================================================================
    // Render RoomItem component
    // ========================================================================
    return (
        <>
            <div className={cx('room-item')}>
                {/* Render checkbox of corresponding room's name */}
                <Checkbox onChange={() => onChangeCheckboxRoom(room?.roomId)} checked={room.isOpen}>
                    <Paragraph
                        editable={{
                            onChange: onItemEditName,
                            tooltip: t('edit'),
                        }}
                        style={{ marginLeft: 12 }}
                    >
                        {room?.name}
                    </Paragraph>
                </Checkbox>

                {/* Render button actions */}
                <div>
                    {Object.values(actions(room)).map((action) => (
                        <span key={uuid()} className={cx('room-item__action')}>
                            {renderButtonActions(action)}
                        </span>
                    ))}
                </div>
            </div>

            {/* When room's name is checked, width and length of room will be shown */}
            <ul className={cx('room-item-child')} style={{ display: room.isOpen ? 'block' : 'none' }}>
                {Object.values(roomAttributes(room)).map((attribute) => (
                    <li key={uuid()} className={cx('room-item-child__attribute')}>
                        {renderRoomAttributes(attribute)}
                    </li>
                ))}
            </ul>
        </>
    );
}

// =====================================================================
// Props
// =====================================================================
RoomItem.propTypes = {
    room: PropsTypes.shape({}).isRequired,
    onChangeCheckboxRoom: PropsTypes.func.isRequired,
    onDeleteRoom: PropsTypes.func.isRequired,
    onEditName: PropsTypes.func.isRequired,
    viewerUnit: PropsTypes.string.isRequired,
    onOpenRoomSetting: PropsTypes.func.isRequired,
};

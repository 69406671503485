import classNames from 'classnames/bind';
import { useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Dropdown, Empty, Image, Row } from 'antd';

import styles from '../Project/ProjectList.module.scss';
import { image } from '~/assets';
import AppLoading from '~/components/AppLoading';
import MenuControll from '../Menu/MenuControll';
import { projectSelector, setSubProjectList } from '~/store/project/projectSlice';
import { getProjectList } from '~/store/project/projectAction';
import { selectBinList } from '~/store/bin-list/binListSlice';

const cx = classNames.bind(styles);

function Recycle() {
    const [data, setData] = useState([]);

    const { projectList, projectTrashList, subProjectList, isFetchingProject } = useSelector(projectSelector);
    const { onBinScreen } = useSelector(selectBinList);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (onBinScreen) {
            dispatch(getProjectList(true));
            setData(() => [...projectTrashList].map((project) => ({ ...project, isCheck: false })));
        }
    }, [onBinScreen, projectList]);

    const handleCheck = (id, status) => {
        let newSubList = [];
        setData((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    item.isCheck = !item.isCheck;
                }
                return { ...item };
            }),
        );

        if (status) {
            newSubList = [...subProjectList, id];
        } else {
            newSubList = subProjectList.filter((project) => project !== id);
        }
        dispatch(setSubProjectList(newSubList));
    };

    return (
        <div className={cx('container')}>
            {isFetchingProject ? (
                <AppLoading listFile />
            ) : (
                <div className={cx('list-file-group')}>
                    <Row>
                        {data?.length === 0 ? (
                            <Empty
                                style={{ width: '100%', marginTop: '10%' }}
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                description={<span>{t('no_project')}</span>}
                            />
                        ) : (
                            data?.map((item) => (
                                <div className={cx('list-file-group__item')} key={item.id}>
                                    <div className={cx('list-file-group__item__check-box')}>
                                        <Checkbox
                                            checked={item.isCheck}
                                            onChange={(e) => handleCheck(item.id, e.target.checked)}
                                        />
                                    </div>

                                    <Dropdown
                                        key={item.id}
                                        overlay={<MenuControll key={item.id} file={item} type="recycle" />}
                                        overlayClassName="dropdown-menu"
                                        trigger={['click']}
                                    >
                                        <button className={cx('list-file-group__item__button')}>
                                            <i className="fa-solid fa-ellipsis" />
                                        </button>
                                    </Dropdown>
                                    <div
                                        className={cx('thumbnail-group')}
                                        onClick={() => navigate(`project/${item.id}`)}
                                    >
                                        <Image
                                            width="100%"
                                            height="100%"
                                            src={image.folder}
                                            alt="folder"
                                            preview={false}
                                        />
                                    </div>

                                    <span className={cx('item-name')}>{item.name}</span>
                                </div>
                            ))
                        )}
                    </Row>
                </div>
            )}
        </div>
    );
}

export default memo(Recycle);

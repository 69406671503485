import styles from './ModalFile.module.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { useBoolean } from 'ahooks';
import { Button, Col, Modal, notification, Row, Select, Spin } from 'antd';
import classNames from 'classnames/bind';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { mapCoordinatesToModel } from '~/helper/ForgeViewer/forge-viewer-tool';
import ViewerPdf from '~/helper/pdf/ViewerPdf';
import { exportFile } from '~/store/files/fileActions';
import { filesList, setUrlExport } from '~/store/files/filesSlice';
import { getOneProject } from '~/store/project/projectAction';
import { projectSelector } from '~/store/project/projectSlice';
import { observeOpenViewerPdf, setCurrentFileId } from '~/store/viewer-pdf/viewer-pdf.action';
import { makeViewerFloorBoundingBoxSelector } from '~/store/viewer/viewer.selectors';

const cx = classNames.bind(styles);

function SelectComponent({ handleChange, quality }) {
    const { i18n } = useTranslation();

    const options = useMemo(() => {
        let optionsBasedOnLanguage = [];

        if (i18n.language === 'en') {
            optionsBasedOnLanguage = [
                {
                    value: 'FAST',
                    label: 'Low',
                },
                {
                    value: 'MEDIUM',
                    label: 'Medium',
                },
                {
                    value: 'SLOW',
                    label: 'High',
                },
            ];
        }

        if (i18n.language === 'de') {
            optionsBasedOnLanguage = [
                {
                    value: 'FAST',
                    label: 'Niedrig',
                },
                {
                    value: 'MEDIUM',
                    label: 'Mittel',
                },
                {
                    value: 'SLOW',
                    label: 'Hoch',
                },
            ];
        }

        return optionsBasedOnLanguage;
    }, [i18n.language]);

    return (
        <div>
            {i18n.language === 'en' && (
                <Select
                    defaultValue="FAST"
                    style={{
                        width: '100%',
                    }}
                    value={quality}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    options={[...options]}
                />
            )}
            {i18n.language === 'de' && (
                <Select
                    defaultValue="FAST"
                    style={{
                        width: '100%',
                    }}
                    value={quality}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    options={[...options]}
                />
            )}
        </div>
    );
}

function ModalExport({ isDisabled, fileName, devices, wireData, clientId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id: idParams } = useParams();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [quality, setQuality] = useState('FAST');

    const { isFetchingExportFile, urlExportFile } = useSelector(filesList);

    // ------------------------------------------------------------------------
    const [openViewerPdf, { set: setOpenViewerPdf }] = useBoolean();

    const [isActivateDispatchGetOneProject, { set: setIsActivateDispatchGetOneProject }] = useBoolean();
    const { isSuccessGetOneProject, projectOpen } = useSelector(projectSelector);
    // ------------------------------------------------------------------------
    const floorBoundingBox = useSelector(makeViewerFloorBoundingBoxSelector());

    useEffect(() => {
        if (isFetchingExportFile) {
            notification.info({
                key: 'loading',
                placement: 'topRight',
                duration: null,
                message: <span>{t('loading')}... </span>,
                icon: (
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 24,
                                    marginLeft: 8,
                                }}
                                spin
                            />
                        }
                    />
                ),
            });
        } else if (!isFetchingExportFile) {
            notification.destroy('loading');
        }
    }, [isFetchingExportFile]);

    useEffect(() => {
        if (urlExportFile) {
            notification.success({
                key: 'url',
                placement: 'topRight',
                duration: null,
                message: t('click_to_download_DWG'),
                description: (
                    <a
                        href={urlExportFile}
                        rel="noreferrer"
                        target="_blank"
                        onClick={() => dispatch(setUrlExport(null))}
                    >
                        {t('download_DWG')}
                    </a>
                ),
                onClose: () => dispatch(setUrlExport(null)),
            });
        } else {
            notification.destroy('url');
        }
    }, [urlExportFile]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = (value) => {
        setQuality(value);
    };

    const handlePreviewPDF = useCallback(() => {
        // Observe from viewer pdf saga
        dispatch(observeOpenViewerPdf());

        // Before open PDF viewer, should call api to get the latest data of current Project
        // This is a flag helper for UseEffect below
        setIsActivateDispatchGetOneProject(true);
        // Dispatch call api to get latest data of project
        dispatch(getOneProject({ id: projectOpen?.id, isDeleted: false, dispatch, t }));

        // Get the right Zoom to show the largest image of Floor on PDF
        const { minP, maxP } = floorBoundingBox;
        const min3 = new window.THREE.Vector3(minP.x, minP.y, 0);
        const max3 = new window.THREE.Vector3(maxP.x, maxP.y, 0);
        const bbox = new window.THREE.Box3(min3, max3);
        window.viewer.navigation.fitBounds(false, bbox);

        return true;
    }, [
        projectOpen,
        projectOpen?.id,
        openViewerPdf,
        /* baseValidatePdfData, */ isActivateDispatchGetOneProject,
        floorBoundingBox,
    ]);

    useEffect(() => {
        if (idParams) {
            // Observe current fileId that helps for loading PDF
            // console.log('---ModelExport----here', idParams);
            dispatch(setCurrentFileId(idParams));
        }
        // When the latest data is successful got, should open the PDF viewer now
        if (isSuccessGetOneProject && isActivateDispatchGetOneProject) {
            handleCancel();
            setTimeout(() => {
                if (!openViewerPdf) {
                    setOpenViewerPdf(true);
                }
                // Reset the state of flag
                setIsActivateDispatchGetOneProject(false);
            }, [100]);
        }
    }, [isActivateDispatchGetOneProject, isSuccessGetOneProject, openViewerPdf, idParams]);

    const createDWGFile = () => {
        const newDevicesData = devices.map((d) => {
            const newD = mapCoordinatesToModel({ x: d.x, y: d.y });
            return { X: newD.x, Y: newD.y, Z: newD.z };
        });
        const newWireList = wireData.map((wire) => {
            const newWire = mapCoordinatesToModel({ x: wire.x, y: wire.y });
            return { X: newWire.x, Y: newWire.y, Z: newWire.z };
        });

        dispatch(
            exportFile({
                clientId,
                fileId: idParams,
                data: JSON.stringify({
                    wire: newWireList ?? [],
                    devices: newDevicesData ?? [],
                }),
            }),
        );
        handleCancel();
    };

    return (
        <>
            {/* -----------------  For PDF ------------------*/}
            <ViewerPdf quality={quality} open={openViewerPdf} setOpen={setOpenViewerPdf} />
            {/* -----------------  For PDF ------------------*/}

            <Button className="btn-custom btn-custom-small" disabled={isDisabled} onClick={showModal}>
                <span>
                    <i className="fa-solid fa-file-export" /> {t('export_file')}
                </span>
            </Button>
            <Modal
                title={t('export_file')}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                bodyStyle={{ paddingBottom: 40 }}
                style={{ minWidth: 620 }}
            >
                <Row justify="space-around" style={{ padding: '0 10px' }}>
                    <Col
                        style={{
                            borderRight: '1px solid var(--grey)',
                            paddingRight: '12%',
                            minWidth: 170,
                        }}
                    >
                        <h3 className={cx('title-export')}>{t('export_DWG_file')}:</h3>
                        <div aria-hidden="true">
                            <Button
                                type="primary"
                                danger
                                onClick={createDWGFile}
                                className={cx('btn-export')}
                                disabled={!((devices && devices.length > 0) || (wireData && wireData.length > 0))}
                            >
                                <span>
                                    <i className="fa-solid fa-file-export" /> {t('download_DWG')}
                                </span>
                            </Button>
                        </div>
                    </Col>
                    <Col
                        style={{
                            minWidth: 170,
                        }}
                    >
                        <h3 className={cx('title-export')}>{t('export_PDF_file')}:</h3>
                        <div aria-hidden="true">
                            <Button
                                type="primary"
                                danger
                                onClick={handlePreviewPDF}
                                className={cx('btn-export')}
                                disabled={!floorBoundingBox}
                            >
                                <span>
                                    <i className="fa-solid fa-file-export" /> {t('preview_PDF')}
                                </span>
                            </Button>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            {t('file_quality')}
                            <SelectComponent handleChange={handleChange} quality={quality} />
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}

ModalExport.propTypes = {};

export default ModalExport;

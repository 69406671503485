/* eslint-disable prefer-template */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import FloorSettingsChildList from './FloorSettingsChildList';
import { MODAL } from './shared';

const { Paragraph } = Typography;

const cs = classNames.bind(this);

export default function FloorSettingsChildContainer({
    dataSource,
    modalName,
    childIndex,
    onShowFSChild,
    onDeleteFSChild,
    onEditFSChild,
    onAddNewFSChild,
}) {
    // ===========================================

    const { t } = useTranslation();

    const titleNextToButton = () => {
        let content = '';
        if (modalName === MODAL.FIRE_COMPARTMENTS) {
            content = t('add_new_fire_compartments');
        }
        if (modalName === MODAL.ALARM_AREAS) {
            content = t('add_new_alarm_areas');
        }
        return content;
    };

    // ===========================================
    return (
        <Row key={'fscc' + modalName + childIndex} style={{ marginTop: '18px' }}>
            <Col span={24}>
                <Divider
                    style={{
                        marginBlock: '4px',
                        borderWidth: '2px',
                    }}
                ></Divider>
                <Paragraph
                    style={{
                        marginBottom: '8px',
                        fontWeight: '600',
                        color: '#666',
                    }}
                >
                    {t(modalName)}
                </Paragraph>
                <Row style={{ marginTop: '8px' }}>
                    <Col span={24} style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }}>
                        <Button
                            id="add-new-floor-settings-child"
                            htmlType="button"
                            className={cs('btn-custom')}
                            style={{ padding: '0px 6px', borderRadius: '4px', fontSize: '0.75rem' }}
                            onClick={() => {
                                const data = {
                                    title: modalName,
                                    isNew: true,
                                };
                                onAddNewFSChild(data);
                            }}
                        >
                            <PlusOutlined />
                        </Button>
                        <Paragraph
                            style={{
                                marginBottom: 0,
                                marginLeft: '8px',
                                fontSize: '0.9rem',
                                color: 'var(--text-grey)',
                            }}
                        >
                            {titleNextToButton()}
                        </Paragraph>
                    </Col>
                </Row>
                <Row style={{ marginTop: '8px' }}>
                    <Col span={24}>
                        <FloorSettingsChildList
                            dataSource={dataSource}
                            modalName={modalName}
                            onShowFSChild={onShowFSChild}
                            onDeleteFSChild={onDeleteFSChild}
                            onEditFSChild={onEditFSChild}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

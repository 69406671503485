import axios from 'axios';
import cookies from 'js-cookie';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const originUrl = window.location.origin;

const axiosClient = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    // data: params,
    // paramsSerializer: params => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
    const accessToken = cookies.get('access_token');
    try {
        if (accessToken) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${accessToken} `,
            };
        }
        return config;
    } catch (error) {
        return Promise.reject(error);
    }
});

axiosClient.interceptors.response.use(
    (response) => {
        if (response.data.code === 401) {
            window.location.href = '/logout';
            return Promise.reject();
        }
        if (response && response.result) {
            return response.result;
        }
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            window.location.href = '/logout';
        } else {
            try {
                if (error?.response?.data?.errors) error.message = error.response.data.errors[0];
            } catch (err) {
                return Promise.reject();
            }
        }
        return Promise.reject(error);
    },
);

export default axiosClient;

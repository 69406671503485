export class CommandViewer {
    constructor() {
        if (this.constructor === CommandViewer) {
            throw new Error('Cant not create an instance from abstract class');
        }
    }

    undo() {
        throw new Error(' Abstract method has no implementation');
    }

    redo() {
        throw new Error(' Abstract method has no implementation');
    }
}

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useCallback, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Affix } from 'antd';

import styles from './Sidebar.module.scss';
import { controlReset, setSubProjectList } from '~/store/project/projectSlice';
import { openModalModalAction } from '~/store/modal/ModalSlice';
import { setDeleteFile } from '~/store/files/filesSlice';
import { showBinListAction } from '~/store/bin-list/binListSlice';

const cx = classNames.bind(styles);

function Sidebar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const param = useParams();

    const [documentData, setDocumentData] = useState([
        {
            id: 'projectList',
            name: 'project_list',
            isActive: true,
            icon: 'fa-solid fa-clock-rotate-left',
            type: 'document',
        },
        // {
        //     id: 'recycle',
        //     name: 'trash_can',
        //     isActive: false,
        //     icon: 'fa-solid fa-trash-can',
        // },
        {
            id: 'customers',
            name: 'customers',
            isActive: false,
            icon: 'fa-solid fa-building',
            type: 'general',
        },
        {
            id: 'locations',
            name: 'locations',
            isActive: false,
            icon: 'fa-solid fa-location-dot',
            type: 'general',
        },
        {
            id: 'operators',
            name: 'operators',
            isActive: false,
            icon: 'fa-duotone fa-screwdriver-wrench',
            type: 'general',
        },
    ]);

    useEffect(() => {
        if (param.general) {
            setDocumentData((prev) =>
                prev.map((item) => {
                    if (item.id === param.general) {
                        item.isActive = true;
                    } else {
                        item.isActive = false;
                    }
                    return { ...item };
                }),
            );
        }
    }, [param]);

    const handleOpenModal = (type) => {
        if (!type || type !== '') {
            dispatch(openModalModalAction({ title: type }));
        }
    };

    const handleClick = useCallback((id) => {
        if (id === 'projectList') {
            dispatch(setDeleteFile([]));
            dispatch(setSubProjectList([]));
            dispatch(showBinListAction(false));
            dispatch(controlReset(true));
            navigate('/');
        }
        // else if ( && id === 'recycle') {
        //     dispatch(getProjectList(true));
        //     dispatch(setSubListBinListAction([]));
        //     dispatch(showBinListAction(true));
        //     navigate('/recycle-bin');
        // }
    }, []);

    return (
        <div className={cx('sidebar-container')}>
            <Affix offsetTop={60}>
                <div className={cx('sidebar-main')}>
                    <div className={cx('sidebar-item')} onClick={() => navigate('/')}>
                        <h5 className={cx('sidebar-item__title')}>{t('my_documents')}</h5>
                        <div className={cx('sidebar-button-group')}>
                            {documentData.map((item) => {
                                if (item.type === 'document')
                                    return (
                                        <div
                                            key={item.id}
                                            className={cx(
                                                'sidebar-item__button',
                                                item.isActive && 'sidebar-item__button-active',
                                            )}
                                            onClick={() => {
                                                handleClick(item.id);
                                            }}
                                        >
                                            <i className={item.icon} /> <span>{t(item.name)}</span>
                                        </div>
                                    );
                            })}
                        </div>
                    </div>

                    <div className={cx('sidebar-item')}>
                        <h5 className={cx('sidebar-item__title')}>{t('import')}</h5>
                        <div className={cx('sidebar-button-group')}>
                            <div
                                className={cx('sidebar-item__button')}
                                onClick={() => handleOpenModal(param.id ? 'upload' : 'createFile')}
                            >
                                <i className="fa-solid fa-cloud-arrow-up" />{' '}
                                {t(param.id ? 'upload_file' : 'create_project')}
                            </div>
                        </div>
                    </div>

                    <div className={cx('sidebar-item')}>
                        <div className={cx('sidebar-item__title')}>
                            <h5>{t('general_data')}</h5>
                        </div>
                        {documentData.map((item) => {
                            if (item.type === 'general')
                                return (
                                    <div className={cx('sidebar-button-group')} key={item.id}>
                                        <div
                                            className={cx(
                                                'sidebar-item__button',
                                                item.isActive && 'sidebar-item__button-active',
                                            )}
                                            onClick={() => navigate(`/general/${item.id}`)}
                                        >
                                            <i className={item.icon} /> {t(item.name)}
                                        </div>
                                    </div>
                                );
                        })}
                    </div>
                </div>
            </Affix>
        </div>
    );
}

export default memo(Sidebar);

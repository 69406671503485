/* eslint-disable array-callback-return */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */

import Sidebar from '../Home/Sidebar';
import styles from './GeneralData.module.scss';
import { Button, Col, Empty, Modal, Popconfirm, Row, Space, Table, Tooltip } from 'antd';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ModalControll from '~/components/ModalControll/ModalControll';
import { deleteCustomer, getGeneralList } from '~/store/generalData/generalAction';
import { generalSelector } from '~/store/generalData/generalSlice';
import { openModalModalAction } from '~/store/modal/ModalSlice';

const cx = classNames.bind(styles);

function GeneralData() {
    const [dataTable, setDataTable] = useState([]);
    const [columnData, setColumnData] = useState([]);

    const { t } = useTranslation();
    const { general } = useParams();
    const dispatch = useDispatch();

    const { customerList, operatorList, locationsList, isFetching } = useSelector(generalSelector);

    const columns = [
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: '15%',
            sorter: false,
            filter: [
                {
                    text: '123',
                    value: '123',
                },
            ],
        },
        {
            title: t('street'),
            dataIndex: 'street',
            key: 'street',
            align: 'center',
            width: '10%',
            sorter: false,
            filter: [],
        },
        {
            title: t('post_code'),
            dataIndex: 'postcode',
            key: 'postCode',
            align: 'center',
            width: '10%',
            sorter: false,
            filter: [],
        },
        {
            title: t('city'),
            dataIndex: 'city',
            key: 'city',
            align: 'center',
            width: '10%',
            sorter: false,
            filter: [],
        },
        {
            title: t('telephone'),
            dataIndex: 'telephone',
            key: 'telephone',
            align: 'center',
            width: '15%',
            sorter: false,
            filter: [],
        },
        {
            title: t('email'),
            dataIndex: 'email',
            key: 'email',
            align: 'center',
            width: '15%',
            sorter: false,
            filter: [],
        },
        {
            title: t('extra_info'),
            key: 'extraInfo',
            width: '15%',
            align: 'center',
            render: (_, cord) => {
                if (!!cord?.dynamicFields && cord?.dynamicFields !== '') {
                    const data = JSON.parse(cord?.dynamicFields);
                    return (
                        <div
                            key={cord.id}
                            style={{
                                textAlign: 'left',
                            }}
                        >
                            {data.map((item) => (
                                <p key={item.id}>
                                    <i className="fa-solid fa-circle" style={{ fontSize: 6, marginRight: 4 }} />
                                    {item.label}: {item.value}
                                </p>
                            ))}
                        </div>
                    );
                }
            },
        },
        {
            title: t('action'),
            key: 'edit',
            width: '10%',
            align: 'center',
            render: (_, cord) => (
                <Space key={cord.id}>
                    <Button
                        className="btn-custom btn-custom-medium"
                        style={{ borderRadius: 4, marginLeft: 8, fontSize: '0.7rem', fontWeight: 400 }}
                        onClick={() => dispatch(openModalModalAction({ title: general, content: cord }))}
                        icon={<i className="fa-solid fa-pen-to-square" style={{ paddingRight: 4 }} />}
                    >
                        {t('edit')}
                    </Button>

                    <Popconfirm
                        title={t('are_you_sure')}
                        onConfirm={() => dispatch(deleteCustomer({ id: cord.id, type: cord.type, t }))}
                        cancelText={t('no')}
                        okText={t('yes')}
                    >
                        <Button
                            className="btn-custom btn-custom-medium"
                            style={{ borderRadius: 4, marginLeft: 8, fontSize: '0.7rem', fontWeight: 400 }}
                            icon={<i className="fa-solid fa-trash" style={{ paddingRight: 4 }} />}
                        >
                            {t('delete')}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        if (!customerList || !operatorList || !locationsList) {
            dispatch(getGeneralList({ t }));
        }

        switch (general) {
            case 'customers':
                setColumnData(() => columns.filter((item) => item.key !== 'customer'));

                setDataTable(customerList);
                return;
            case 'locations':
                setColumnData([
                    {
                        title: t('customer'),
                        dataIndex: 'customer',
                        key: 'customer',
                        render: (_, cord) => {
                            let flag = '';
                            if (!!customerList) {
                                customerList.forEach((item) => {
                                    if (item.id === cord.customerId) flag = item.name;
                                });
                            }
                            return <>{flag}</>;
                        },
                    },
                    ...columns,
                ]);
                setDataTable(locationsList);
                return;
            case 'operators':
                setColumnData(() => columns.filter((item) => item.key !== 'customer'));

                setDataTable(operatorList);
                return;
            default:
                setDataTable([]);
        }
    }, [customerList, operatorList, locationsList, general, t]);

    return (
        <div>
            <Row>
                <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4}>
                    <Sidebar />
                </Col>

                <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
                    <div className={cx('container')}>
                        <h5 className={cx('head-title')}>{t('general_data')}</h5>

                        <div className={cx('data-select')}>
                            <Button
                                onClick={() => dispatch(openModalModalAction({ title: general }))}
                                className="btn-custom btn-custom-medium"
                                style={{ borderRadius: 4, marginLeft: 8 }}
                                icon={<i className="fa-solid fa-plus" style={{ marginRight: 6 }} />}
                            >
                                <span>{t('create_new')}</span>
                            </Button>
                            <Tooltip title={t('refresh')}>
                                <Button
                                    className="btn-custom btn-custom-medium"
                                    style={{ borderRadius: 4, marginRight: 8, minWidth: 64 }}
                                    onClick={() => dispatch(getGeneralList({ t }))}
                                    loading={isFetching}
                                    icon={<i className="fa-solid fa-arrows-rotate" />}
                                />
                            </Tooltip>
                        </div>

                        <div className={cx('table-data', 'animation')}>
                            {dataTable && dataTable.length > 0 ? (
                                <Table
                                    locale
                                    bordered
                                    loading={isFetching}
                                    dataSource={dataTable}
                                    columns={columnData}
                                    onChange={() => {}}
                                    responsive
                                    showTitle
                                    size="small"
                                    // pagination
                                    // pageSizeOptions={[]}
                                    // showSizeChanger
                                    // hideOnSinglePage
                                />
                            ) : (
                                <Empty
                                    style={{ marginTop: 80 }}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={t('no_data')}
                                />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            <ModalControll />
        </div>
    );
}

export default GeneralData;

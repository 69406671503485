import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '~/api';
import { convertStringToUnit } from '~/helper/ForgeViewer/forge-viewer-tool';

export const getFileById = createAsyncThunk('file/getById', async (payload, thunkAPI) => {
    try {
        const response = await api.get(`/api/v1/user/file/${payload}`);
        if (response.status < 400) {
            const changeUnit = convertStringToUnit(response.data.units);

            return {
                id: payload,
                data: { ...response.data, units: changeUnit },
            };
        }

        return thunkAPI.rejectWithValue(response.data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const getForgeToken = createAsyncThunk('file/getForgeToken', async (payload, thunkAPI) => {
    try {
        const response = await api.get('/api/v1/forge/get-token');
        if (response.status < 400) {
            return response.data;
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */

import ChildFormItemOptionalFieldInput from './ChildFormItemOptionalFieldInput';
import { Divider, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ParentFormItemOptionalFieldsInput({ fields, onChildErrorMessage }) {
    const [nfCollection] = useState(fields['newFieldCollection']?.value);
    const [convertedNFC, setConvertedNFC] = useState([]);

    const { t } = useTranslation();
    // Get an instance of form
    const form = Form.useFormInstance();

    useEffect(() => {
        let cNFC = [];
        Object.keys(nfCollection).forEach((k) => {
            cNFC = [
                ...cNFC,
                {
                    nf_new_field: k,
                    nf_value: nfCollection[k]?.value,
                    nf_hide_content: nfCollection[k]?.eye,
                },
            ];
        });
        setConvertedNFC([...cNFC]);
    }, []);

    /**
     * Delete fields in newFieldCollection
     * @param {*} fieldName
     */
    const handleDeleteFieldInNewFieldCollection = (fieldName) => {
        setConvertedNFC((prev) => [...prev]?.filter((f) => f.nf_new_field !== fieldName));

        const nfc = form.getFieldValue('newFieldCollection');
        const helperObj = {};

        Object.keys(nfc).forEach((k) => {
            if (k !== fieldName) {
                helperObj[k] = nfc[k];
            }
        });
        form.setFieldsValue({ newFieldCollection: { ...helperObj } });
    };

    return (
        <div>
            {convertedNFC && convertedNFC?.length > 0 && (
                <div style={{ display: 'flex', flexFlow: 'column', width: '100%' }}>
                    <Divider style={{ marginBlock: '4px' }}>{t('optional_fields')}</Divider>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 8,
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}
                    >
                        {convertedNFC.map((field, index) => (
                            <div key={`${field.nf_new_field}-parent`} style={{ flexShrink: '0', width: '183px' }}>
                                <p key={`${field.nf_new_field}-parent-label`} style={{ textTransform: 'capitalize' }}>
                                    {field.nf_new_field}
                                </p>
                                <ChildFormItemOptionalFieldInput
                                    key={`${field.nf_new_field}-${index}`}
                                    field={field}
                                    onDeleteField={handleDeleteFieldInNewFieldCollection}
                                    formInstance={form}
                                    onChildErrorMessage={onChildErrorMessage}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

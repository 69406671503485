/* eslint-disable import/no-cycle */
/* eslint-disable no-extra-boolean-cast */

import { getBinFile } from '../bin-list/binListActions';
import { setEmptyBinBinListAction } from '../bin-list/binListSlice';
import { getOneProject } from '../project/projectAction';
import { setEmtyFile } from './filesSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import api from '~/api';

export const getAllFile = createAsyncThunk('file/getAll', async (payload, thunkAPI) => {
    try {
        let params = {
            IsDeleted: false,
        };
        if (payload) {
            params = {
                ...params,
                ...payload,
            };
        }

        const response = await api.get('api/v1/user/File', {
            params,
        });
        if (response.status < 400) {
            return response.data;
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const updateFile = createAsyncThunk('file/update', async ({ id, payload, type, t }, thunkAPI) => {
    try {
        const response = await api.put(`api/v1/user/File/update/${id}`, { ...payload });
        if (response.status < 400) {
            return {
                data: response.data.result,
                type,
                id,
                t,
            };
        }
        message.error({
            content: `${t('failed')}!`,
            duration: 0.5,
        });
        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        message.error({
            content: `${t('failed')}!`,
            duration: 0.5,
        });
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const deleteFile = createAsyncThunk('file/deleteFile', async ({ fileId, t }, thunkAPI) => {
    try {
        const response = await api.delete(`api/v1/user/File/delete/${fileId}`);
        if (response.status < 400) {
            message.success({
                content: `${t('delete_success')}!`,
                duration: 0.5,
            });
            return response.data.result;
        }
        message.error({
            content: response.data.error[0],
            duration: 1,
        });
        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        message.error({
            content: err.data[0],
            duration: 1,
        });
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const deleteManyFile = createAsyncThunk(
    'file/deleteFiles',
    async ({ payload, projectId, dispatch, onBinScreen, t }, thunkAPI) => {
        try {
            const response = await api.delete('api/v1/user/File/delete', {
                data: {
                    ids: payload,
                    isForever: onBinScreen,
                },
            });
            if (response.status < 400) {
                message.success({
                    content: `${t('delete_success')}!`,
                    duration: 0.5,
                });
                if (onBinScreen) {
                    dispatch(setEmptyBinBinListAction());
                    dispatch(getBinFile());
                } else if (!onBinScreen) {
                    dispatch(setEmtyFile());
                    dispatch(getOneProject({ id: projectId, isDeleted: onBinScreen, dispatch, t }));
                }
                return response.data;
            }
            message.error({
                content: `${t('delete_error')}!`,
                duration: 0.5,
            });
            return thunkAPI.rejectWithValue(response.data);
        } catch (err) {
            message.error({
                content: `${t('delete_error')}!`,
                duration: 0.5,
            });
            return thunkAPI.rejectWithValue(err.response.data);
        }
    },
);

export const saveDataFile = createAsyncThunk('file/saveDataFile', async (payload, thunkAPI) => {
    try {
        const response = await api.post(`/api/v1/user/tool/save-room-data/${payload.id}`, {
            data: payload.fileData,
        });
        if (response.status < 400) {
            if (!!payload.t && !payload.auto) {
                notification.success({
                    message: `${payload.t('saved')}!`,
                    duration: 1,
                });
            }
            const { t, ...other } = payload;
            return other;
        }
        if (!!payload.t) {
            notification.error({
                message: `${payload.t('something_s_wrong')}!`,
                duration: 1,
            });
        }
        return thunkAPI.rejectWithValue(payload);
    } catch (error) {
        if (!!payload.t) {
            notification.error({
                message: `${payload.t('something_s_wrong')}!`,
                duration: 1,
            });
        }
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const searchAction = createAsyncThunk('all/search', async (payload, thunkAPI) => {
    try {
        const response = await api.get(`api/v1/user/File/search?s=${payload}`);
        if (response.status < 400) {
            return response.data;
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const exportFile = createAsyncThunk('files/export', async ({ fileId, clientId, data }, thunkAPI) => {
    try {
        const response = await api.post('api/v1/user/DesignAutomation/export-dwg', {
            fileId,
            clientId,
            data,
        });
        if (response.status < 400) {
            return response.data.result;
        }

        return thunkAPI.rejectWithValue(response.data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

import MenuControll from '../Menu/MenuControll';
import styles from './ProjectList.module.scss';
import { Button, Checkbox, Dropdown, Empty, Image, Row } from 'antd';
import classNames from 'classnames/bind';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FolderSpeam } from '~/assets/images/svgFile';
import AppLoading from '~/components/AppLoading';
import { projectSelector } from '~/store/project/projectSlice';

const cx = classNames.bind(styles);

function ProjectList({ dataProject, onCheck, onSetFileModal }) {
    const [data, setData] = useState([]);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { subProjectList, isFetchingProject } = useSelector(projectSelector);

    useEffect(() => {
        setData([...dataProject]);
    }, [dataProject]);

    return (
        <div className={cx('container')}>
            {isFetchingProject ? (
                <AppLoading listFile />
            ) : (
                <div className={cx('list-file-group')}>
                    <Row>
                        {data?.length === 0 ? (
                            <Empty
                                style={{ width: '100%', marginTop: '10%' }}
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                description={<span>{t('no_project')}</span>}
                            />
                        ) : (
                            data?.map((item) => (
                                <div className={cx('list-file-group__item', 'animation')} key={item.id}>
                                    <div className={cx('list-file-group__item__check-box', item.isCheck && 'active')}>
                                        <Checkbox
                                            checked={item.isCheck}
                                            onChange={(e) =>
                                                onCheck(item.id, e.target.checked, data, subProjectList, 'project')
                                            }
                                        />
                                    </div>

                                    <Dropdown
                                        overlay={<MenuControll type="project" />}
                                        overlayClassName="dropdown-menu"
                                        trigger={['click']}
                                        onOpenChange={(e) => onSetFileModal(e, item)}
                                    >
                                        <button className={cx('list-file-group__item__button')}>
                                            <i className="fa-solid fa-ellipsis" />
                                        </button>
                                    </Dropdown>

                                    <div
                                        className={cx('thumbnail-group')}
                                        onClick={() => navigate(`project/${item.id}`)}
                                    >
                                        {item.thumbnail ? (
                                            <Image
                                                width="100%"
                                                height="100%"
                                                src={item.thumbnail}
                                                alt="folder"
                                                preview={false}
                                            />
                                        ) : (
                                            <FolderSpeam width="190px" height="160px" padding="10px" />
                                        )}
                                    </div>

                                    <span className={cx('item-name')}>{item.name}</span>
                                </div>
                            ))
                        )}
                    </Row>
                </div>
            )}
        </div>
    );
}

export default memo(ProjectList);

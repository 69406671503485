/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-useless-escape */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import GeneralForm from './GeneralForm/GeneralForm';
import ModalUpload from './ModalUpload/ModalUpload';
import UploadArea from './ModalUpload/UploadArea/UploadArea';
import { Input, message, Modal, Select } from 'antd';
import Cookies from 'js-cookie';
import { memo, useCallback, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { optionsUnit } from '~/assets/MenuToolData/MenuToolData';
import { convertUnitToString } from '~/helper/ForgeViewer/forge-viewer-tool';
import { changeUnitAPI } from '~/store/alarm-device/alarmAction';
import { selectAlarmDevice, setUnit } from '~/store/alarm-device/alarmDeviceSlice';
import { selectBinList } from '~/store/bin-list/binListSlice';
import { deleteFile, deleteManyFile, updateFile } from '~/store/files/fileActions';
import { filesList } from '~/store/files/filesSlice';
import uploadFile from '~/store/files/uploadFile';
import { clearModalStateModalAction, selectModalState } from '~/store/modal/ModalSlice';
import { deleteManyProject, deleteProject } from '~/store/project/projectAction';
import { projectSelector } from '~/store/project/projectSlice';

function ModalControll() {
    const { projectList, projectOpen, subProjectList } = useSelector(projectSelector);
    const { modalData: data, modalOpen } = useSelector(selectModalState);
    const { fileModal: file, deleteFiles } = useSelector(filesList);
    const { onBinScreen, subList } = useSelector(selectBinList);
    const { unit } = useSelector(selectAlarmDevice);

    const [valueInput, setValueInput] = useState(file?.name);
    const [listUpload, setListUpload] = useState([]);
    const [unitChange, setUnitChange] = useState(unit);
    const [distanceValue, setDistanceValue] = useState('');

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const param = useParams();

    useEffect(() => {
        if (data?.content?.unit) setUnitChange(data.content.unit);
        if (data?.content?.distance) setDistanceValue(data.content.distance);
    }, [data?.content]);

    const handleRemove = useCallback(() => {
        dispatch(clearModalStateModalAction());
        if (onBinScreen) {
            dispatch(deleteFile({ fileId: file.id, t }));
        } else if (!onBinScreen) {
            // dispatch(
            //     updateFile({
            //         id: file.id,
            //         payload: { isDeleted: true },
            //         type: 'delete',
            //         t,
            //     }),
            // );
            dispatch(deleteFile({ fileId: file.id, t }));
        }
    }, [onBinScreen, file]);

    const handleRename = useCallback(() => {
        dispatch(clearModalStateModalAction());
        dispatch(
            updateFile({
                id: file.id,
                payload: { name: valueInput },
                type: 'rename',
                t,
            }),
        );
        setValueInput('');
    }, [valueInput]);

    const handleDeleteAll = useCallback(() => {
        dispatch(clearModalStateModalAction());
        if (subProjectList?.length > 0) {
            dispatch(
                deleteManyProject({
                    subList: [...subProjectList],
                    list: [...projectList],
                    t,
                    dispatch,
                }),
            );
        } else {
            // eslint-disable-next-line no-lonely-if
            if (onBinScreen && subList.length > 0) {
                dispatch(deleteManyFile({ payload: subList, dispatch, onBinScreen, t }));
            } else if (!onBinScreen && deleteFiles.length > 0) {
                dispatch(deleteManyFile({ payload: deleteFiles, projectId: param.id, dispatch, onBinScreen, t }));
            }
        }
    }, [subProjectList, onBinScreen, subList, deleteFiles, file]);

    const handleDeleteProject = useCallback(() => {
        dispatch(deleteProject({ id: file.id, t }));
        dispatch(clearModalStateModalAction());
    }, [file]);

    const handleUpload = useCallback(() => {
        if (listUpload.length > 0) {
            dispatch(clearModalStateModalAction());
            listUpload.forEach((f) => {
                uploadFile([f], dispatch, projectOpen.id);
            });
            setListUpload([]);
        } else {
            message.warning({
                content: t('put_file_first'),
            });
        }
    }, [projectOpen, listUpload]);

    const handleChangeUnit = async () => {
        const clientId = Cookies.get('clientId');
        const unitConvert = convertUnitToString(unitChange);

        const payload = {
            clientId,
            fileId: param.id,
            data: JSON.stringify({ ...data.content, unit: unitConvert, scale: distanceValue / data.content.distance }),
        };

        dispatch(clearModalStateModalAction());
        await dispatch(changeUnitAPI({ data: payload, t }));
        dispatch(setUnit(unitChange));
        window?.viewer?.getExtension('Autodesk.Measure').deactivate();
    };

    const handleData = () => {
        switch (data?.title) {
            case 'rename':
                return (
                    <Modal
                        title={t('rename_floor_plan')}
                        open={modalOpen}
                        onCancel={() => {
                            dispatch(clearModalStateModalAction());
                            setValueInput('');
                        }}
                        onOk={handleRename}
                        okText={t('save')}
                        cancelText={t('cancel')}
                        okType={valueInput !== '' ? 'danger primary' : 'disabled dashed'}
                    >
                        <h3 style={{ marginBottom: 10 }}>{t('enter_a_new_name_for_the_floor_plan')}</h3>
                        <Input
                            autoFocus
                            style={{ borderRadius: '4px', height: '36px' }}
                            value={valueInput}
                            onChange={(e) => setValueInput(e.target.value)}
                            placeholder="e.g ground floor"
                        />
                    </Modal>
                );
            case 'delete':
                return (
                    <Modal
                        title={t('delete_floor_plan')}
                        open={modalOpen}
                        onCancel={() => dispatch(clearModalStateModalAction())}
                        onOk={handleRemove}
                        okText={t('delete')}
                        cancelText={t('cancel')}
                        okType="danger primary"
                    >
                        <h3 style={{ textAlign: 'center', color: '#9b4444' }}>
                            {t('are_you_sure_want_to_delete_this_file?')}
                        </h3>
                    </Modal>
                );

            case 'deleteProject':
                return (
                    <Modal
                        title={t('delete_project')}
                        open={modalOpen}
                        onCancel={() => dispatch(clearModalStateModalAction())}
                        onOk={handleDeleteProject}
                        okText={t('delete')}
                        cancelText={t('cancel')}
                        okType="danger primary"
                    >
                        <h3 style={{ textAlign: 'center', color: '#9b4444' }}>
                            {t('are_you_sure_want_to_delete_this_file?')}
                        </h3>
                    </Modal>
                );

            case 'deleteAll':
                return (
                    <Modal
                        title={t('delete_all')}
                        open={modalOpen}
                        onCancel={() => dispatch(clearModalStateModalAction())}
                        onOk={handleDeleteAll}
                        okText={t('delete_all')}
                        cancelText={t('cancel')}
                        okType="danger primary"
                    >
                        <h3 style={{ textAlign: 'center', color: '#9b4444' }}>
                            {t('do_you_want_to_delete_selected_files')}
                        </h3>
                    </Modal>
                );

            case 'createFile':
                return <ModalUpload open={modalOpen} uploadFile button="upload" />;
            case 'upload':
                return (
                    <Modal
                        open={modalOpen}
                        onCancel={() => {
                            dispatch(clearModalStateModalAction());
                            setListUpload([]);
                        }}
                        onOk={handleUpload}
                        okText={t('upload')}
                        cancelText={t('cancel')}
                        okType="danger primary"
                        title={`${t('upload_file_to_project')}: ${projectOpen?.name}`}
                        style={{ textTransform: 'none' }}
                    >
                        <UploadArea listUpload={listUpload} setListUpload={(d) => setListUpload(d)} />
                    </Modal>
                );
            case 'customers':
                return <GeneralForm dataRender="customers" modalOpen={modalOpen} data={data.content} />;
            case 'locations':
                return <GeneralForm dataRender="locations" modalOpen={modalOpen} data={data.content} />;
            case 'operators':
                return <GeneralForm dataRender="operators" modalOpen={modalOpen} data={data.content} />;
            case 'information':
                return <ModalUpload open={modalOpen} uploadFile={false} button="save" data={file} />;
            case 'unitChange':
                return (
                    <Modal
                        open={modalOpen}
                        onCancel={() => {
                            window?.viewer?.getExtension('Autodesk.Measure')?.measureTool.deleteMeasurements();
                            dispatch(clearModalStateModalAction());
                        }}
                        onOk={handleChangeUnit}
                        okText={t('change')}
                        cancelText={t('cancel')}
                        okType="danger primary"
                        title={<h3>{t('select_the_unit_you want')}</h3>}
                        style={{ textTransform: 'none', textAlign: 'center', width: 300 }}
                        centered
                        mask={false}
                        maskClosable={false}
                    >
                        <h3>{t('now_your_distance_is')}: </h3>
                        <div style={{ display: 'block', marginTop: 15 }}>
                            <Input
                                defaultValue={data.content.distance}
                                onChange={(e) => setDistanceValue(e.target.value)}
                                value={distanceValue}
                                style={{ width: '60%' }}
                            />
                            <Select
                                defaultValue={unit}
                                value={unitChange}
                                options={optionsUnit}
                                onChange={(value) => setUnitChange(value)}
                                style={{ minWidth: '30%', marginLeft: 15 }}
                            />
                        </div>
                    </Modal>
                );
            default:
                return <div />;
        }
    };

    return handleData();
}

export default memo(ModalControll);

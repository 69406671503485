/* eslint-disable no-extra-boolean-cast */
import { useEffect, useState } from 'react';
import { Affix, Button, Col, notification, Row, Switch, Table } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import classNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import styles from './Dll.module.scss';
import api from '~/api';
import AppLoading from '../AppLoading';

const cx = classNames.bind(styles);

function DllComponent() {
    const [id, setId] = useState('');
    const [dataTable, setDataTabel] = useState([]);
    const [fileCurrent, setFileCurrent] = useState(null);
    const [fileUpload, setFileUpload] = useState([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const openNotification = (type, message) => {
        notification[type]({
            message: `${t(message)}!`,
        });
    };

    const columns = [
        {
            width: '10%',
            title: 'No.',
            dataIndex: 'key',
            align: 'center',
        },
        {
            width: '70%',
            title: t('file_name'),
            dataIndex: 'name',
            align: 'center',
        },
        {
            width: '20%',
            title: t('current'),
            dataIndex: 'current',
            align: 'center',
        },
    ];

    useEffect(() => {
        api.get('api/v1/admin/Dll/get-dll/excel')
            .then((res) => {
                const data = [];
                let current = null;

                res.data.result.files.forEach((file, index) => {
                    if (file.toLowerCase() !== res.data.result.currentFile.toLowerCase()) {
                        current = <Switch key={uuid()} onClick={(e) => handleSetCurrentFile(file, e)} />;
                    } else {
                        current = <Switch key={uuid()} checked />;
                    }

                    data.push({
                        key: index + 1,
                        name: file,
                        current,
                    });
                });
                setId(res.data.result.id);
                setFileCurrent(res.data.result.currentFile);
                setDataTabel(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dataTable.length, fileUpload]);

    useEffect(() => {
        setDataTabel(() =>
            dataTable.map((item) => {
                if (item.name.toLowerCase() !== fileCurrent.toLowerCase()) {
                    item.current = <Switch onClick={(e) => handleSetCurrentFile(item.name, e)} />;
                } else {
                    item.current = <Switch key={uuid()} checked />;
                }
                return { ...item };
            }),
        );
    }, [fileCurrent]);

    const uploadDll = () => {
        if (!!fileUpload) {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', fileUpload);

            api.post('api/v1/admin/Dll/upload-dll/excel', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((res) => {
                    const data = [];
                    res.data.result.files.forEach((file, index) => {
                        data.push({
                            key: index + 1,
                            name: file,
                            current: <Switch />,
                        });
                    });
                    setId(res.data.result.id);
                    setFileCurrent(res.data.result.currentFile);
                    setDataTabel(data);
                })
                .then(() => {
                    setLoading(false);
                    setFileUpload([]);
                    openNotification('success', 'Upload_success!');
                })
                .catch((err) => {
                    console.log('err', err);
                    setLoading(false);
                    openNotification('error', 'Upload_error!');
                });
        }
    };

    const handleSetCurrentFile = (fileName, e) => {
        if (e) {
            api.put('api/v1/admin/Dll/update-dll/excel', {
                id,
                file: fileName,
            })
                .then((res) => {
                    setFileCurrent(res.data.result);
                })
                .then(() => {
                    openNotification('success', 'change_current_file_success!');
                })
                .catch((err) => {
                    openNotification('error', 'change_current_file_failed!');
                    console.log(err);
                });
        }
    };

    return (
        <div className={cx('dll-container')}>
            {loading && <AppLoading upload />}
            <Row>
                <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                    <Table
                        pagination={false}
                        bordered
                        size="middle"
                        columns={columns}
                        dataSource={dataTable}
                        title={() => t('dynamic_link_library_list')}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <Affix offsetTop={85}>
                        <div className={cx('dll-upload-container')}>
                            <h3 className={cx('upload-title')}>{t('upload_file')}: </h3>

                            <form id="form" encType="multipart/form-data">
                                <Dragger
                                    id="upload"
                                    name="file"
                                    style={{ marginTop: 10 }}
                                    onChange={(info) => {
                                        info.file.status = 'done';
                                        setFileUpload(info.fileList[0].originFileObj);
                                    }}
                                    fileList={fileUpload}
                                    multiple={false}
                                    accept={['.dll', '.zip']}
                                    action={() => {}}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        {t('click_or_drag_a_file_to_this_area_to_upload')}
                                    </p>
                                </Dragger>
                            </form>
                            <Button
                                style={{ width: '100%', backgroundColor: 'var(--primary-color)', color: '#fff' }}
                                onClick={uploadDll}
                            >
                                {t('upload')}
                            </Button>
                        </div>
                    </Affix>
                </Col>
            </Row>
        </div>
    );
}

export default DllComponent;

import { createSlice } from '@reduxjs/toolkit';
import { releaseProtectedData, removeProtectedData, saveProtectedData } from '~/helper/common';
import { fetchSortedPointsWireAlarmAction } from './wireAlarm.actions';

/**
 * Default state of wire-alarm slice
 */
export const initialState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    sortedWireAlarmPoints: [],
    isShowWireAlarm: false,
    hashOfBMZ: {},
    protectedWireAlarmData: [],
    wirePerimeter: 0,
};

/**
 * ***************************************************************************
 * Create a  wireAlarm slice
 * ***************************************************************************
 */
const wireAlarmSlice = createSlice({
    name: 'wireAlarm',
    initialState,
    reducers: {
        /**
         * ACTION: set state of wirePerimeter
         * @param {*} state  => base state
         * @param {*} payload => BMZ hash data
         */
        setWirePerimeter: (state, { payload }) => {
            state.wirePerimeter = payload;
        },

        /**
         * ACTION: set state of hashOfBMZ
         * @param {*} state  => base state
         * @param {*} payload => BMZ hash data
         */
        setHashOfBMZWireAlarmAction: (state, { payload }) => {
            state.hashOfBMZ = payload;
        },

        /**
         * ACTION: delete an item in HashOfBMZ by it's id
         * @param {*} state  => base state
         * @param {*} payload => item's Id
         */
        removeHashOfBMZWireByIdWireAlarmAction: (state, { payload }) => {
            const hashOfBMZ = { ...state.hashOfBMZ };
            // check if the id is exist
            // if so, delete it
            if (hashOfBMZ[payload]) {
                delete hashOfBMZ[payload];
            }

            state.hashOfBMZ = hashOfBMZ;
        },

        /**
         * ACTION: set state of isShowWireAlarm
         * @param {*} state  => base state
         * @param {*} payload => true or false
         */
        setIsShowWireWireAlarmAction: (state, { payload }) => {
            state.isShowWireAlarm = payload;
        },

        /**
         * ACTION: set state to default
         */
        resetSortedPointsFromApiWireAlarmAction: (state) => {
            state.isFetching = false;
            state.isSuccess = false;
            state.isError = false;
            state.errorMessage = [];
            state.sortedWireAlarmPoints = [];
            state.isShowWireAlarm = false;
        },

        /**
         * ACTION: set state of protectedWireAlarmData
         * protectedWireAlarmData is an array that holds wireAlarm data of each opened file,
         * useful when switch file between tabs in 'tool' page
         * @param {*} state   => base state
         * @param {*} payload  => the wireAlarm data
         */
        saveProtectedDataWireAlarmAction: (state, { payload }) => {
            state.protectedWireAlarmData = saveProtectedData(state.protectedWireAlarmData, payload);
        },

        /**
         * ACTION: release the data from protectedWireAlarmData
         * the data of corresponding opened file.
         * @param {*} state   => base state
         * @param {*} payload  => the opened file's Id
         */
        releaseProtectDataWireAlarmAction: (state, { payload }) => {
            const result = releaseProtectedData(state.protectedWireAlarmData, payload);
            state.hashOfBMZ = result?.hashOfBMZ || {};
            state.isShowWireAlarm = result?.isShowWireAlarm || false;
            state.sortedWireAlarmPoints = result?.sortedWireAlarmPoints || [];
        },

        /**
         * ACTION: delete the data of wireAlarm of corresponding file's Id of protectedWireAlarmData
         * @param {*} state   => base state
         * @param {*} payload  => the opened file's Id
         */
        removeProtectDataWireAlarmAction: (state, { payload }) => {
            state.protectedWireAlarmData = removeProtectedData(state.protectedWireAlarmData, payload);
        },

        /**
         * ACTION: set the state of protectedWireAlarmData to default
         * @param {*} state   => base state
         */
        clearProtectDataWireAlarmAction: (state) => {
            state.protectedWireAlarmData = [];
        },

        /**
         * ACTION: set state to default
         * @param {*} state   => base state
         */
        clearStateWireAlarmAction: (state) => {
            state.isFetching = false;
            state.isSuccess = false;
            state.isError = false;
            state.errorMessage = [];
            state.sortedWireAlarmPoints = [];
            state.isShowWireAlarm = false;
            state.hashOfBMZ = {};
            state.wirePerimeter = 0;
        },
    },
    extraReducers: {
        [fetchSortedPointsWireAlarmAction.pending]: (state) => {
            state.isFetching = true;
            state.isSuccess = false;
            state.isError = false;
        },
        [fetchSortedPointsWireAlarmAction.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = false;
            state.isSuccess = true;
            state.errorMessage = [];
            state.sortedWireAlarmPoints = payload?.result;
        },
        [fetchSortedPointsWireAlarmAction.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = false;
            state.isError = true;
            state.errorMessage = payload?.errors;
            state.sortedWireAlarmPoints = [];
        },
    },
});

// ============================================================================
// Export Actions
// ============================================================================
export const {
    setWirePerimeter,
    setHashOfBMZWireAlarmAction,
    setIsShowWireWireAlarmAction,
    clearStateWireAlarmAction,
    resetSortedPointsFromApiWireAlarmAction,
    saveProtectedDataWireAlarmAction,
    releaseProtectDataWireAlarmAction,
    removeProtectDataWireAlarmAction,
    clearProtectDataWireAlarmAction,
    removeHashOfBMZWireByIdWireAlarmAction,
} = wireAlarmSlice.actions;

// ============================================================================
// Export Reducer
// ============================================================================
export default wireAlarmSlice.reducer;

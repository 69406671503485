/* eslint-disable react/prop-types */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-extra-boolean-cast */

import styles from './index.module.scss';
import { Button, Input, Popconfirm, Popover, Switch } from 'antd';
import classNames from 'classnames/bind';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { customMemo } from '~/helper/common';
import useDebounce from '~/helper/hooks/useDebounce';
import { regexNotSpecialCharacter } from '~/helper/regex';

const cx = classNames.bind(styles);

function NewFieldPopover({
    onChangeNewFieldData,
    defaultNewFieldData,
    isHideContent,
    placement = 'right' /* left */,
    actionSize = 'default' /* large */,
}) {
    // =================================================================================
    // Support for New field Popover
    // =================================================================================
    const [newFieldData, setNewFieldData] = useState([]);

    /**
     * Observe change of new field data
     */
    useEffect(() => {
        setNewFieldData(JSON.parse(JSON.stringify([...defaultNewFieldData])));
    }, [defaultNewFieldData]);

    const [isHideNewFieldContent, setIsHideNewFieldContent] = useState(isHideContent ?? true);
    const [newFieldNamePopover, setNewFieldNamePopover] = useState('');
    const [newFieldOpenPopover, setNewFieldOpenPopover] = useState(false);

    // get error message after validation
    const [errorMessage, setErrorMessage] = useState('');

    const newFieldNameInputPopoverRef = useRef();

    // set size for action icon
    const actionIconSize = useMemo(() => {
        let fontSize = '';
        switch (actionSize) {
            case 'large':
                fontSize = '16px';
                break;
            default:
                fontSize = '12px';
                break;
        }
        return fontSize;
    }, [actionSize]);

    // for translation
    const { t } = useTranslation();

    /**
     * Observe change of new field data, with debounce 500ms
     */
    const newFieldDataDebounce = useDebounce(newFieldData, 500);

    /**
     * Observe change of new field data, with debounce 500ms
     */
    useEffect(() => {
        if (!newFieldDataDebounce) {
            return;
        }

        onChangeNewFieldData(JSON.parse(JSON.stringify([...newFieldDataDebounce])));
    }, [newFieldDataDebounce]);

    /**
     * Active when user click on 'eye' icon in the input of new field
     */
    const handleShowContent = useCallback((id) => {
        setNewFieldData((prev) =>
            prev.map((input) => {
                if (input.id === id) {
                    input.show = !input.show;
                }
                return { ...input };
            }),
        );
    }, []);

    /**
     * Active when user click on 'trash' icon in the input of new field
     */
    const handleDeleteField = useCallback((id) => {
        setNewFieldData((prev) => prev.filter((input) => input.id !== id));
    }, []);

    /**
     * Active when user types something at input that have created
     */
    const handleChangeInput = useCallback((id, value) => {
        setNewFieldData((prev) =>
            prev.map((input) => {
                if (input.id === id) {
                    input.value = value;
                }
                return { ...input };
            }),
        );
    }, []);

    /**
     * Active when user click 'Add' button of NewFieldPopover
     */
    const handleAddNewField = useCallback(() => {
        if (newFieldNamePopover.length < 1) {
            setErrorMessage(`${t('please_type_something')}!`);
            return;
        }

        const newId = newFieldNamePopover.split(' ').join('');
        const checkIdExist = newFieldData.find((input) => input.id.toLowerCase() === newId.toLowerCase());

        if (!!checkIdExist) {
            setErrorMessage(`${t('this_field_already_exists')}!`);
        } else if (!regexNotSpecialCharacter.test(newId)) {
            setErrorMessage(`${t('do_not_use_special_characters')}`);
        } else {
            setNewFieldOpenPopover(false);
            setNewFieldNamePopover('');
            if (isHideNewFieldContent) {
                setNewFieldData([
                    ...newFieldData,
                    {
                        new: true /* This will make sure new field input data will be rendered */,
                        id: newId,
                        label: newFieldNamePopover,
                        show: false,
                        type: 'text',
                        value: '',
                        eye: true,
                        // edit: true, // For pencil icon
                    },
                ]);
            } else {
                setNewFieldData([
                    ...newFieldData,
                    {
                        new: true /* This will make sure new field input data will be rendered */,
                        id: newId,
                        label: newFieldNamePopover,
                        show: true,
                        type: 'text',
                        value: '',
                        // eye: true,
                        // edit: true, // For pencil icon
                    },
                ]);
            }
        }
    }, [newFieldNamePopover, newFieldData, newFieldNamePopover]);

    /**
     * Render errorMessage
     * @param {*} errMsg
     */
    const renderErrorMessage = (errMsg) => (
        <div>
            {errMsg && errMsg.length > 0 && (
                <>
                    <i className="fa-solid fa-triangle-exclamation" style={{ color: 'red' }} />{' '}
                    <span
                        style={{
                            textTransform: 'none',
                            color: 'red',
                            fontSize: '0.75rem',
                        }}
                    >
                        {errMsg}
                    </span>
                </>
            )}
        </div>
    );

    // =================================================================================
    // Render NewFieldPopover
    // =================================================================================
    return (
        <>
            {/* ------------------------------------------------------------------- */}
            {/* ---------- Render New field Input after have been added ----------- */}
            {/* ------------------------------------------------------------------- */}

            {newFieldData?.length > 0 &&
                newFieldData.map((input) => (
                    <div
                        key={'form-input' + input.id}
                        style={{ marginBottom: 14, textTransform: 'none' }}
                        className={cx('form-item')}
                    >
                        <label className={cx('input')} key={'label' + input.id}>
                            <Input
                                key={'input' + input.id}
                                className={cx('input-custom', 'input__field', input.value !== '' && 'input__has-value')}
                                placeholder={'  '}
                                value={input.value}
                                onChange={(e) => handleChangeInput(input.id, e.target.value)}
                                type={input.show ? input.type : 'password'}
                                status={input.error ? 'error' : ''}
                                autoComplete="off"
                                aria-autocomplete="none"
                            />
                            <span className={cx('input__label')}>{t(input.label)}</span>
                            {input.error && (
                                <span className={cx('err-mess')}>
                                    <i className="fa-solid fa-triangle-exclamation" />{' '}
                                    <span>{t('this_field_is_invalid')}!</span>
                                </span>
                            )}
                        </label>

                        <div key={'action' + input.name} style={{ position: 'relative', textTransform: 'none' }}>
                            <div className={cx('actions-new-field')}>
                                {/* ---------------------------------------*/}
                                {/* ------------ Show Eye icon ------------*/}
                                {/* ---------------------------------------*/}
                                {input?.eye && (
                                    <i
                                        className={input.show ? 'fa-solid fa-eye' : 'fa-sharp fa-solid fa-eye-slash'}
                                        style={{ marginRight: 14, fontSize: String(actionIconSize) }}
                                        onClick={() => handleShowContent(input.id)}
                                    />
                                )}
                                {/* ---------------------------------------*/}
                                {/* ---------- Show Pencil icon -----------*/}
                                {/* ---------------------------------------*/}
                                {/* {input?.edit && (
                                    <i
                                        className={cx('fa-solid fa-pencil')}
                                        style={{ marginRight: 14, fontSize: String(actionIconSize) }}
                                        onClick={() => handleShowContent(input.id)}
                                    />
                                )} */}
                                {/* ---------------------------------------*/}
                                {/* ----------- Show Trash icon -----------*/}
                                {/* ---------------------------------------*/}
                                <Popconfirm
                                    title={t('are_you_sure')}
                                    onConfirm={() => handleDeleteField(input.id)}
                                    okText={t('yes')}
                                    cancelText={t('no')}
                                >
                                    <div>
                                        <i className="fa-solid fa-trash" style={{ fontSize: String(actionIconSize) }} />
                                    </div>
                                </Popconfirm>
                            </div>
                        </div>
                    </div>
                ))}

            {/* --------------------------------------------- */}
            {/* ------------- New field popover --------------*/}
            {/* --------------------------------------------- */}

            <div className={cx('new-field')} key="newField">
                <Popover
                    key="popup"
                    content={
                        <div className={cx('popover-modal')}>
                            <div style={{ height: '48px' }}>
                                <Input
                                    ref={newFieldNameInputPopoverRef}
                                    style={{ marginTop: '10px', textTransform: 'none' }}
                                    className={cx('input-custom')}
                                    placeholder={`${t('new_field')}*`}
                                    value={newFieldNamePopover}
                                    onChange={(e) => setNewFieldNamePopover(e.target.value)}
                                    autoComplete="off"
                                    aria-autocomplete="none"
                                    onFocus={() => setErrorMessage('')}
                                />
                                {renderErrorMessage(errorMessage)}
                            </div>
                            <div className={cx('switch-btn')}>
                                <Switch
                                    style={{ backgroundColor: isHideNewFieldContent ? 'var(--primary-color)' : '' }}
                                    size="large"
                                    onClick={() => {
                                        const current = isHideNewFieldContent;
                                        setIsHideNewFieldContent(!current);
                                    }}
                                    defaultChecked={isHideNewFieldContent}
                                />
                                <span style={{ marginLeft: 8, fontWeight: 400, color: 'var(--text-grey)' }}>
                                    {t('hide_content')}
                                </span>
                            </div>
                            <Button
                                className={cx(
                                    'btn-custom',
                                    'btn-custom_full-width',
                                    'btn-custom-small',
                                    'btn-add-field',
                                )}
                                type="primary"
                                onClick={handleAddNewField}
                            >
                                {t('add')}
                            </Button>
                        </div>
                    }
                    title={<h3>{t('new_field')}</h3>}
                    placement="topLeft"
                    onOpenChange={(newOpen) => {
                        setNewFieldOpenPopover(newOpen);
                        setNewFieldNamePopover('');
                        setErrorMessage('');
                    }}
                    open={newFieldOpenPopover}
                    showArrow={false}
                    trigger="click"
                >
                    <Button
                        key="add"
                        style={{ border: 0, padding: '0px 8px', float: `${placement}` }}
                        className={cx('btn-custom-ghost')}
                        onClick={() => setNewFieldOpenPopover(!newFieldOpenPopover)}
                        icon
                    >
                        {t('new_field')}{' '}
                        <i
                            className={newFieldOpenPopover ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down'}
                            style={{ marginLeft: '8px' }}
                        />
                    </Button>
                </Popover>
            </div>
        </>
    );
}

export default customMemo(NewFieldPopover);

import {
    clearViewerPdfState,
    setBottomRightFrameInfo,
    setCurrentCustomer,
    setCurrentFile,
    setCurrentFileId,
    setProjectInfo,
} from './viewer-pdf.action';
import { createReducer } from '@reduxjs/toolkit';

/**
 * Default state of viewer pdf slice
 */
const initialState = {
    projectInfo: null,
    bottomRightFrameInfo: null,
    currentCustomer: null,
    currentFile: null,
    currentFileId: null,
};

/**
 * Create a viewer pdf reducer
 */
const viewerPdfReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setCurrentFileId, (state, { payload }) => {
            state.currentFileId = payload;
        })
        .addCase(setCurrentFile, (state, { payload }) => {
            state.currentFile = payload;
        })
        .addCase(setCurrentCustomer, (state, { payload }) => {
            state.currentCustomer = payload;
        })
        .addCase(setProjectInfo, (state, { payload }) => {
            state.projectInfo = payload;
        })
        .addCase(clearViewerPdfState, (state) => {
            state.projectInfo = null;
            state.bottomRightFrameInfo = null;
            state.currentCustomer = null;
            state.currentFile = null;
            state.currentFileId = null;
        })
        .addCase(setBottomRightFrameInfo, (state, { payload }) => {
            state.bottomRightFrameInfo = payload;
        })
        .addDefaultCase((_state, _action) => {});
});

/// ============================================================================
// Export Reducer
// ============================================================================
export { initialState, viewerPdfReducer };

export const UndoControllerStack = {
    BEFORE_ACTION: 'BEFORE_ACTION',
    AFTER_ACTION: 'AFTER_ACTION',
};

export class UndoController {
    constructor() {
        // Array of CommandViewer objects
        this._undoStack = [];

        // By default, this simply points to the end of this._undoStack. Only if undo has
        // been called, it points to the next operation to be run on a redo() exit
        this._current = 0;
    }

    getUndoStackLength() {
        return this._undoStack.length;
    }

    execute(commandViewer) {
        // if we did a couple of undoes before, a new operation will clear all redo steps
        this._undoStack.splice(this._current);

        window.dispatchEvent(new CustomEvent(UndoControllerStack.BEFORE_ACTION, { detail: commandViewer }));

        commandViewer.redo();

        window.dispatchEvent(new CustomEvent(UndoControllerStack.AFTER_ACTION, { detail: commandViewer }));

        this._undoStack.push(commandViewer);

        this._current = this._undoStack.length;
    }

    undo() {
        if (!this._current) {
            return false;
        }

        this._current -= 1;

        const commandViewer = this._undoStack[this._current];

        window.dispatchEvent(new CustomEvent(UndoControllerStack.BEFORE_ACTION, { detail: commandViewer }));

        commandViewer.undo();

        window.dispatchEvent(new CustomEvent(UndoControllerStack.AFTER_ACTION, { detail: commandViewer }));

        return true;
    }

    redo() {
        const commandViewer = this._undoStack[this._current];

        if (!commandViewer) {
            return false;
        }

        window.dispatchEvent(new CustomEvent(UndoControllerStack.BEFORE_ACTION, { detail: commandViewer }));

        commandViewer.redo();

        window.dispatchEvent(new CustomEvent(UndoControllerStack.AFTER_ACTION, { detail: commandViewer }));

        this._current += 1;

        return true;
    }

    clearCache() {
        this._undoStack = [];
        this._current = 0;
    }
}

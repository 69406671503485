/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */

import { isAcceptDrawRoom, isAllPointsInLine } from './helper-custom-tool';

// eslint-disable-next-line no-undef
const namespace = AutodeskNamespace('Autodesk.Edit2D');
const av = AutodeskNamespace('Autodesk.Viewing');
const aem = AutodeskNamespace('Autodesk.Extensions.Measure');
const avem = AutodeskNamespace('Autodesk.Viewing.Extensions.Measure');

const customPolygonTool = () => {
    if (window.tools) {
        window.tools.polygonEditTool.vertexMoveTool.moveDragVertex = function (canvasX, canvasY) {
            const { loop, vertex } = this.draggedVertex;

            // Note that the vertex we are dragging does not always match exactly with the mouse position. E.g., we may have picked the bottom-left boundary of a vertex gizmo at drag-start.
            const x = canvasX + this.dragOffset.x;
            const y = canvasY + this.dragOffset.y;

            // get delta between last and current position
            const p = this.getSnapPosition(x, y);
            const dx = p.x - this.lastDragPoint.x;
            const dy = p.y - this.lastDragPoint.y;

            // apply this offset to polygon point
            let point = this.poly.getPoint(vertex, loop);
            const pointClone = point.clone();
            this.poly.updatePoint(vertex, point.x + dx, point.y + dy, loop);

            const isInLine = isAllPointsInLine(this.poly.points);
            if (isInLine) {
                this.poly.updatePoint(vertex, pointClone.x, pointClone.y, loop);

                // re-center gizmo at new point position
                const vertexGizmo = this.vertexGizmos[loop][vertex];
                vertexGizmo.setPosition(pointClone.x, pointClone.y);

                this.gizmoLayer.update(); // we moved the vertex gizmo
                this.layer.update(); // we changed the main polygon

                this.lastDragPoint.copy(pointClone);
            } else {
                const vertexGizmo = this.vertexGizmos[loop][vertex];
                vertexGizmo.setPosition(point.x, point.y);

                this.gizmoLayer.update(); // we moved the vertex gizmo
                this.layer.update(); // we changed the main polygon

                this.lastDragPoint.copy(p);
            }
        };

        window.tools.polygonTool.lineRectTool.moveDrag = function (p, forceQuad) {
            this.endPoint.copy(this.startPoint);
            this.updateRect(forceQuad);
        };

        window.tools.polygonTool.finishPolygon = function () {
            const points = window.tools.polygonTool.poly.points;

            if (isAcceptDrawRoom(points)) {
                // remove all vertex gizmos
                window.tools.polygonTool.clearGizmos();
                // Stop snapping to edges of this polygon
                window.tools.polygonTool.snapper.stopAngleSnapping();
                // moe polygon to main layer
                window.tools.polygonTool.runAction(
                    new namespace.Actions.AddShape(window.tools.polygonTool.layer, window.tools.polygonTool.poly),
                );

                window.tools.polygonTool.dispatchEvent({
                    type: namespace.PolygonTool.POLYGON_ADDED,
                    polygon: window.tools.polygonTool.poly,
                });

                // This event is dispatch to "addNewRoom" function that helps for create new polygon
                window.dispatchEvent(new CustomEvent('finishPolygon', { detail: window.tools.polygonTool }));

                // Start another polygon on next click
                window.tools.polygonTool.poly = null;
            }
        };

        window.layer.removeShape = function (shape) {
            const index = window.layer.shapes.indexOf(shape);
            if (index === -1) {
                return false;
            }
            window.layer.shapes.splice(index, 1);

            window.layer.dispatchEvent({ type: namespace.EditLayer.SHAPE_REMOVED, shape });

            window.layer._onModified();

            window.dispatchEvent(new CustomEvent('removePolygon', { detail: shape }));
            return true;
        };

        window.tools.insertSymbolTool.handleAutoDraw = function (position, planeMesh, id, type, isCircle) {
            const symbol = window.tools.insertSymbolTool.symbol.clone();
            symbol.move(position.x, position.y);
            symbol.planeMesh = planeMesh;
            symbol.isCircle = isCircle;
            if (type === 'symbol') {
                symbol.symbolId = id;
            } else {
                symbol.isAlarmDevice = true; // flag for Alarm device
                symbol.deviceId = id;
            }
            symbol.type = type;
            window.tools.insertSymbolTool.undoStack.run(new namespace.Actions.AddShape(this.layer, symbol));
            window.tools.insertSymbolTool.dispatchEvent({
                type: namespace.InsertSymbolTool.SYMBOL_INSERTED,
                symbol,
            });
            return true;
        };

        window.tools.insertSymbolTool.handleAutoDrawOnlyCircle = function (planeMesh, type = 'device') {
            const symbol = window.tools.insertSymbolTool.symbol.clone();
            symbol.move(planeMesh.position.x, planeMesh.position.y);
            symbol.planeMesh = planeMesh;
            symbol.isCircle = planeMesh.isCircle;
            symbol.isAlarmDevice = true; // flag for Alarm device
            symbol.deviceId = planeMesh.deviceId;
            symbol.roomId = planeMesh.roomId;
            symbol.type = type;

            window.tools.insertSymbolTool.undoStack.run(new namespace.Actions.AddShape(this.layer, symbol));
            window.tools.insertSymbolTool.dispatchEvent({
                type: namespace.InsertSymbolTool.SYMBOL_INSERTED,
                symbol,
            });

            return true;
        };

        window.tools.polygonEditTool.moveTool.endDrag = function (p) {
            if (window.tools.polygonEditTool.moveTool.mouseTracker) {
                window.tools.polygonEditTool.moveTool.mouseTracker.stopTracking(
                    this.getName(),
                    namespace.Trackings.Drag,
                );
            }

            // No drag active
            if (!window.tools.polygonEditTool.moveTool.shapes) {
                return;
            }

            // Check if polygon is Room so do not move
            const firstShape = window.tools.polygonEditTool.moveTool.shapes[0];
            // Don't change the position of this code below
            const poly = {
                id: firstShape?.id,
                points: firstShape?.points,
            };

            // Revert any temporary modifications done during mouse move
            window.tools.polygonEditTool.moveTool.shapes.forEach((shape, i) =>
                shape.copy(window.tools.polygonEditTool.moveTool.backupShapes[i]),
            );

            // Apply move operation
            const dx = p.x - window.tools.polygonEditTool.moveTool.dragStartPoint.x;
            const dy = p.y - window.tools.polygonEditTool.moveTool.dragStartPoint.y;

            if (firstShape.isRoom) {
                window.hitShape = undefined;
                window.hitShapeOldId = undefined;
                window.dispatchEvent(
                    new CustomEvent('endDragRoom', {
                        detail: {
                            ...poly,
                        },
                    }),
                );
            }

            if (window.tools.polygonEditTool.moveTool.loopIndex === -1) {
                window.tools.polygonEditTool.moveTool.undoStack.run(
                    new namespace.Actions.MoveShapes(
                        window.tools.polygonEditTool.moveTool.layer,
                        window.tools.polygonEditTool.moveTool.shapes,
                        dx,
                        dy,
                    ),
                );
            } else {
                window.tools.polygonEditTool.moveTool.undoStack.run(
                    new namespace.Actions.MoveLoop(
                        window.tools.polygonEditTool.moveTool.layer,
                        window.tools.polygonEditTool.moveTool.shapes[0],
                        window.tools.polygonEditTool.moveTool.loopIndex,
                        dx,
                        dy,
                    ),
                );
            }

            window.tools.polygonEditTool.moveTool.shapes = null;
            window.tools.polygonEditTool.moveTool.loopIndex = -1;
            return true;
        };

        // START Custom move Circle (Alarm device)
        window.tools.moveTool.endDrag = function (p) {
            if (window.tools.moveTool.mouseTracker) {
                window.tools.moveTool.mouseTracker.stopTracking(this.getName(), namespace.Trackings.Drag);
            }

            // No drag active
            if (!window.tools.moveTool.shapes) {
                return;
            }

            // Check if polygon is Room so do not move
            const firstShape = window.tools.moveTool.shapes[0];
            // if (firstShape.isRoom) {
            //     window.tools.moveTool.resetDragging();
            //     return;
            // }

            // Revert any temporary modifications done during mouse move
            window.tools.moveTool.shapes.forEach((shape, i) => shape.copy(window.tools.moveTool.backupShapes[i]));

            // Apply move operation
            const dx = p.x - window.tools.moveTool.dragStartPoint.x;
            const dy = p.y - window.tools.moveTool.dragStartPoint.y;

            if (firstShape.isMoved) {
                firstShape.isMoved = false;
                window.hitShape = undefined;
                window.hitShapeOldId = undefined;
                window.dispatchEvent(
                    new CustomEvent('endDragCircle', {
                        detail: {
                            ...firstShape,
                            type: firstShape.type || 'device',
                        },
                    }),
                );
            }

            if (window.tools.moveTool.loopIndex === -1) {
                window.tools.moveTool.undoStack.run(
                    new namespace.Actions.MoveShapes(window.tools.moveTool.layer, window.tools.moveTool.shapes, dx, dy),
                );
            } else {
                window.tools.moveTool.undoStack.run(
                    new namespace.Actions.MoveLoop(
                        window.tools.moveTool.layer,
                        window.tools.moveTool.shapes[0],
                        window.tools.moveTool.loopIndex,
                        dx,
                        dy,
                    ),
                );
            }

            window.tools.moveTool.shapes = null;
            window.tools.moveTool.loopIndex = -1;
            // eslint-disable-next-line consistent-return
            return true;
        };

        window.tools.moveTool.moveDrag = function (p) {
            const firstShape = window.tools.moveTool.shapes[0];
            if (firstShape.isRoom) {
                window.tools.moveTool.resetDragging();
                window.selection.isSelected = {};
                return;
            }

            const dx = p.x - window.tools.moveTool.dragStartPoint.x;
            const dy = p.y - window.tools.moveTool.dragStartPoint.y;

            // Apply this offset to shape. We always start with the original shape as a reference
            // to avoid accumulating delta inaccuracies.
            window.tools.moveTool.shapes.forEach((shape, i) => {
                if (shape.isRoom) return;
                const planeMesh = shape.planeMesh;
                const deviceId = shape.deviceId;
                const type = shape.type;
                const isAlarmDevice = shape.isAlarmDevice; // flag for Alarm device;
                const isCircle = shape.isCircle;
                const roomId = shape.roomId;

                shape.copy(window.tools.moveTool.backupShapes[i]);

                if (window.tools.moveTool.loopIndex === -1) {
                    // Default: Move whole shape(s)
                    shape.move(dx, dy);
                } else {
                    // Only move single loop
                    shape.moveLoop(dx, dy, window.tools.moveTool.loopIndex);
                }

                shape.deviceId = deviceId;
                shape.type = type;
                shape.isAlarmDevice = isAlarmDevice; // flag for Alarm device;
                shape.isCircle = isCircle;
                shape.roomId = roomId;
                shape.isMoved = true; // flag for Alarm device;

                if (planeMesh) {
                    planeMesh.position.set(shape.centerX, shape.centerY, 3);
                    planeMesh.polygon = shape;
                    shape.planeMesh = planeMesh;
                    window.viewer.impl.invalidate(true, true, true);
                }
                window.tools.moveTool.shapeIdClick = deviceId;
            });
            window.tools.moveTool.layer.update();
            window.tools.moveTool.gizmoLayer.update();
        };

        window.tools.moveTool.handleSingleClick = function () {
            const shapesToMove = this.selection.getSelectedShapes();
            return shapesToMove && shapesToMove.length > 0;
        };

        window.tools.moveTool.handleDoubleClick = function () {
            const shapesToMove = this.selection.getSelectedShapes();
            return shapesToMove && shapesToMove.length > 0;
        };

        window.tools.moveTool.handleButtonDown = function (event, button) {
            // Only respond to left button
            if (button !== 0) {
                return;
            }

            const p = this.layer.canvasToLayer(event.canvasX, event.canvasY);
            const hitShape = this.layer.hitTest(p.x, p.y);
            if (!hitShape) {
                this.selection.clear();

                if (window.hitShape && window.hitShape.isAlarmDevice) {
                    window.dispatchEvent(
                        new CustomEvent('endDragCircle', {
                            detail: {
                                ...window.hitShape,
                                type: window.hitShape.type || 'device',
                                isButtonDown: true,
                                callback: () => {
                                    // const id = window.hitShapeOldId;
                                    const id = window.hitShape?.deviceId;
                                    window.layer.shapes.forEach((shape) => {
                                        if (shape.deviceId === id || shape.symbolId === id) {
                                            window.layer.removeShape(shape);
                                        }
                                    });
                                },
                            },
                        }),
                    );
                    window.layer.update();
                }

                window.hitShape = undefined;
                window.hitShapeOldId = undefined;

                return false;
            } else {
                if (hitShape.isAlarmDevice) {
                    window.hitShapeOldId = window.hitShape?.deviceId;

                    if (window.hitShapeOldId && window.hitShapeOldId !== hitShape?.deviceId) {
                        window.dispatchEvent(
                            new CustomEvent('endDragCircle', {
                                detail: {
                                    ...window.hitShape,
                                    type: window.hitShape.type || 'device',
                                    isButtonDown: true,
                                    callback: () => {
                                        const id = window.hitShapeOldId;
                                        // const id = window.hitShape?.deviceId;
                                        window.layer.shapes.forEach((shape) => {
                                            if (shape.deviceId === id || shape.symbolId === id) {
                                                window.layer.removeShape(shape);
                                            }
                                        });
                                        window.layer.update();
                                        window.hitShapeOldId = undefined;
                                    },
                                },
                            }),
                        );
                    }

                    window.hitShape = hitShape;
                }
            }

            // If shape was not selected before, select it
            const wasSelected = hitShape && this.selection.shapeIsSelected(hitShape);
            if (!wasSelected) {
                this.selection.selectOnly(hitShape);
            }

            // Move all selected shapes
            const shapesToMove = this.selection.getSelectedShapes();
            if (shapesToMove.length > 0) {
                this.startDrag(shapesToMove, p);
            }

            return true;
        };
        // END Custom move Circle (Alarm device)

        // window.edit2d.undoTool.undoStack.run = function () {};

        window.edit2d.undoTool.undoStack.undo = function () {
            let current = 0;
            let stack = [];
            if (!current) {
                // We reached the beginning of the stack
                return false;
            }
            current--;

            const action = stack[current];

            this.dispatchEvent({ type: window.edit2d.undoTool.undoStack.BEFORE_ACTION, action, isUndo: true });

            action.undo();

            this.dispatchEvent({ type: window.edit2d.undoTool.undoStack.AFTER_ACTION, action, isUndo: true });

            action.layer.update();

            return true;
        };
    }
};

export { customPolygonTool };

import html2canvas from 'html2canvas';
import { Vector2, Vector3 } from 'three-full';

import { getLengthTool, getMinMaxOfCoordinates, mapCoordinatesToModel } from '~/helper/ForgeViewer/forge-viewer-tool';

/**
 * Helper for controlling local image should be Opened or Downloaded on Browser
 * @param {*} imgPath
 * @param {*} imgFileName
 * @param {*} download
 */
export function controlImageOpenOrDownload(imgPath, imgFileName, download = false) {
    const img = new Image();
    img.src = imgPath; // "public/kft.png";

    // make <canvas> of the same size
    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');

    // If image is loaded successfully then tasks

    img.onload = function () {
        c.width = this.naturalWidth; // update canvas size to match image
        c.height = this.naturalHeight;

        ctx.drawImage(this, 0, 0); // draw in image

        c.toBlob(
            (blob) => {
                // get content as JPEG blob
                // blob ready, download it
                const link = document.createElement('a');
                link.style = 'display:none;';

                // link.download = "image";
                const href = URL.createObjectURL(blob);

                // listening for link 'a' event

                const handleDownloadOrOpen = () => {
                    // open new window tab with image
                    if (download) {
                        link.href = href;
                        link.download = imgFileName;
                    } else {
                        window.open(href);
                    }
                };

                link.addEventListener('click', handleDownloadOrOpen);

                link.click();

                // delete the internal blob reference, to let the browser clear memory from it
                URL.revokeObjectURL(link.href);

                link.removeEventListener('click', handleDownloadOrOpen);
            },
            'image/jpeg',
            0.75,
        );
    };

    // If not then error handling

    img.onerror = function (event, source, lineno, colno, error) {
        console.error('Error when loading image: ', error.message);
    };
}

/**
 * Helper for looking up the specified canvas element
 * @param {*} htmlCanvasIdTag
 * @param {*} width
 * @returns canvas object
 */
export function getDivCanvasFromDocument(htmlCanvasIdTag = 'appId', width = 100) {
    // Setup canvas size
    return Promise.resolve()
        .then(() => {
            // Setup default canvas information
            const canvas = {
                src: null,
                idTag: htmlCanvasIdTag,
                width,
                height: 0,
            };

            // Looking up from document
            const divCanvas = document.getElementById(canvas.idTag);

            // Highlight border color for testing
            divCanvas.style.border = '2px solid blue';

            // Ratio that helps resizing
            const aspectRatio = divCanvas.clientWidth / divCanvas.clientHeight;

            return html2canvas(divCanvas)
                .then((data) => {
                    canvas.height = Math.floor(canvas.width / aspectRatio);
                    canvas.src = data;

                    // Return canvas element
                    return canvas;
                })
                .catch((error) => {
                    throw error;
                });
        })
        .catch((error) => {
            console.error('Error: ', error.message);
        });
}

/**
 * Helper for get resize screen shot to render in PDF
 * @param {*} ratio
 * @param {*} pageSize
 * @returns
 */
export function getResizedScreen(ratio, pageSize) {
    const aspectRatio = ratio;

    const resizedScreenWidth = 0;
    const resizedScreenHeight = 0;

    const sizes =
        {
            resizedScreenWidth,
            resizedScreenHeight,
        } || null;

    if (pageSize === 'a0') {
        sizes.resizedScreenWidth = 1139;
        sizes.resizedScreenHeight = Math.floor(sizes.resizedScreenWidth / aspectRatio);
    }
    if (pageSize === 'a1') {
        sizes.resizedScreenWidth = 791;
        sizes.resizedScreenHeight = Math.floor(sizes.resizedScreenWidth / aspectRatio);
    }
    if (pageSize === 'a2') {
        sizes.resizedScreenWidth = 554;
        sizes.resizedScreenHeight = Math.floor(sizes.resizedScreenWidth / aspectRatio);
    }
    if (pageSize === 'a3') {
        sizes.resizedScreenWidth = 375;
        sizes.resizedScreenHeight = Math.floor(sizes.resizedScreenWidth / aspectRatio);
    }
    if (pageSize === 'a4') {
        sizes.resizedScreenWidth = 220;
        sizes.resizedScreenHeight = Math.floor(sizes.resizedScreenWidth / aspectRatio);
    }

    return sizes;
}

/**
 * Get screen shot from viewer
 * @returns Promise{blobImageURL}
 */
export async function getViewerScreenShot(vw, vh) {
    return new Promise((resolve) => {
        let blobImageURL;
        window.viewer.getScreenShot(vw, vh, (blob) => {
            blobImageURL = blob;
            resolve(blobImageURL);
        });
    });
}

/**
 * Helper that wrap text (longString) in in pdf based on 'wrapWidth'
 */
export function helperWrapText(longString, docInstance, line, lineHeight, leftMargin, wrapWidth) {
    let lineInside = line;
    const splitText = docInstance.splitTextToSize(longString, wrapWidth);

    for (let i = 0, length = splitText.length; i < length; i++) {
        // loop thru each line and increase
        docInstance.text(splitText[i], leftMargin, lineInside);
        lineInside = lineHeight + lineInside;
    }
}

export function getScaleOfDrawingInForgeViewerForPage(viewerFrameWidthInPage, viewerFrameHeightInPage, pageSize) {
    // ---------------------------------------------------
    const PIXEL_TO_MM = 0.26458333; // 1px to mm
    // ---------------------------------------------------

    // console.log('pagesize:', pageSize)
    // console.log({
    //     viewerFrameWidthInPage,
    //     viewerFrameHeightInPage,
    // });

    const viewerFrameWidthToPxMin = 0 / PIXEL_TO_MM;
    const viewerFrameHeightToPxMin = 0 / PIXEL_TO_MM;
    const viewerFrameWidthToPxMax = viewerFrameWidthInPage / PIXEL_TO_MM;
    const viewerFrameHeightToPxMax = viewerFrameHeightInPage / PIXEL_TO_MM;

    // --- Change all viewer frame pixel to layer point in canvas viewer ---

    const viewerClientToWorldMin = window?.viewer?.clientToWorld(
        viewerFrameWidthToPxMin,
        viewerFrameHeightToPxMin,
        true,
    );
    const viewerClientToWorldMax = window?.viewer?.clientToWorld(
        viewerFrameWidthToPxMax,
        viewerFrameHeightToPxMax,
        true,
    );

    const viewerFramePointMin = viewerClientToWorldMin.point;
    const viewerFramePointMax = viewerClientToWorldMax.point;

    // console.log('viewerFramePointMin: ', viewerClientToWorldMin.point)
    // console.log('viewerFramePointMax: ', viewerClientToWorldMax.point)

    // ----------------------------------------------------

    // --- With layerBoxPoints, we need to find a way to convert them to Bounding box points ---

    const viewerFrameBoundingBox = [viewerFramePointMin, viewerFramePointMax].map((p) => mapCoordinatesToModel(p));

    const {
        minX: bboxMinX,
        minY: bboxMinY,
        maxX: bboxMaxX,
        maxY: bboxMaxY,
    } = getMinMaxOfCoordinates(viewerFrameBoundingBox);

    const realLengthBetween2Points = Number(getLengthTool({ x: bboxMinX, y: bboxMinY }, { x: bboxMaxX, y: bboxMaxY }));

    // ----------------------------------------------------

    const viewerFrameLayerBoxPointMaxMin = getMinMaxOfCoordinates([viewerFramePointMin, viewerFramePointMax]);
    const viewerFrameLayerBoxPointMinX = viewerFrameLayerBoxPointMaxMin.minX;
    const viewerFrameLayerBoxPointMinY = viewerFrameLayerBoxPointMaxMin.minY;
    const viewerFrameLayerBoxPointMaxX = viewerFrameLayerBoxPointMaxMin.maxX;
    const viewerFrameLayerBoxPointMaxY = viewerFrameLayerBoxPointMaxMin.maxY;

    const boxPointOnScreenInPixelMin = window.viewer.worldToClient(
        new Vector3(viewerFrameLayerBoxPointMinX, viewerFrameLayerBoxPointMinY, 0),
    );
    const boxPointOnScreenInPixelMax = window.viewer.worldToClient(
        new Vector3(viewerFrameLayerBoxPointMaxX, viewerFrameLayerBoxPointMaxY, 0),
    );

    const boxPointOnScreenInPixelStart = new Vector2(boxPointOnScreenInPixelMin.x, boxPointOnScreenInPixelMin.y);
    const boxPointOnScreenInPixelEnd = new Vector2(boxPointOnScreenInPixelMax.x, boxPointOnScreenInPixelMax.y);

    // --- Calculate the distance between 2 box points on screen ---
    const distanceTwoBoxPointsOnScreen = boxPointOnScreenInPixelStart.distanceTo(boxPointOnScreenInPixelEnd);

    // Then convert it to millimeter
    const lengthBetween2PointsOnScreen = distanceTwoBoxPointsOnScreen * PIXEL_TO_MM; // Convert from pixel to millimeter

    // ----------------------------------------------------

    let drawingScale = Math.floor(realLengthBetween2Points / lengthBetween2PointsOnScreen);

    // console.log('before---drawingScale---', drawingScale);

    // reference to: https://www.firstinarchitecture.co.uk/understanding-scales-and-scale-drawings/
    switch (pageSize) {
        case 'a4':
            drawingScale += Math.floor(drawingScale * 0.81);
            break;
        case 'a3':
            drawingScale += Math.floor(drawingScale * 0.67);
            break;
        case 'a2':
            drawingScale += Math.floor(drawingScale * 0.51);
            break;
        case 'a1':
            drawingScale += Math.floor(drawingScale * 0.31);
            break;
        case 'a0':
        default:
            break;
    }

    // console.log('after---drawingScale---', drawingScale);

    // --- For testing only ---

    // const contentDraw = `realityWidth: ${realLengthBetween2Points} mm - distanceTwoCanvas: ${lengthBetween2PointsOnScreen} mm - scale: 1:${drawingScale}`;
    // console.log(contentDraw);

    // --- For testing only ---

    return drawingScale;
}

// Helper function for testing drawing line
export const drawTestLine = (doc, point = { x: 0, y: 0 }, page = { left: 0, top: 0 }) => {
    doc.setDrawColor(255, 0, 0);
    doc.lines([[point.x, point.y]], page.left, page.top, [1, 1], 'S', false);
    doc.setDrawColor(0, 0, 0);
};

import styles from '../../ToolViewer.module.scss';
import { Checkbox, Popconfirm } from 'antd';
import classNames from 'classnames/bind';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

function AlarmDeviceList({ room, deviceList, handleOpen, handleCheck, confirmDelete }) {
    const [noDevice, setNoDevice] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        const result = deviceList.filter((item) => item?.roomId === room?.roomId);
        if (result.length === 0) {
            setNoDevice(true);
        } else {
            setNoDevice(false);
        }
    }, [deviceList, room]);

    return (
        <div key={room?.roomId} className={cx('sub-tools-room')}>
            <div className={cx('sub-tools-room__sort-button')} type="button">
                <Checkbox checked={room?.isActive} onClick={(e) => handleOpen(room?.roomId, e)} disabled={noDevice} />
                <h4 className={cx('title')} aria-hidden="true">
                    {t('room')} {room?.name}
                </h4>
            </div>

            {deviceList.map((device) => (
                <div
                    key={device?.deviceId || device?.id || device?.planeMesh?.deviceId}
                    className={cx('device-item', room?.isActive && 'open')}
                >
                    <div className={cx('device-head')}>
                        <Checkbox
                            onClick={(e) => handleCheck(device?.id || device?.deviceId, e.target.checked)}
                            checked={device?.isOpen}
                        />
                        <h5 className={cx('device-title')}>
                            {t('device')}: {device?.deviceId}
                        </h5>

                        <Popconfirm
                            title={t('do_you_want_to_delete_this_file')}
                            onConfirm={() => confirmDelete(device?.deviceId)}
                            okText={t('yes')}
                            cancelText={t('no')}
                        >
                            <i
                                className="fa-solid fa-trash"
                                style={{
                                    cursor: 'pointer',
                                    padding: '4px',
                                }}
                            />
                        </Popconfirm>
                    </div>
                    <p className={cx('device-radius')}>
                        {t('radius')}: {(device?.radius || device.planeMesh.radius).toFixed(2)}m
                    </p>
                </div>
            ))}
        </div>
    );
}

export default memo(AlarmDeviceList);

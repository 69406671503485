export function draw_A0_MainContent(doc, leftMost, topMost, mainContent, compressOption) {
    // // Config drawing size
    // // frame for main content based on ratio = 16 / 9
    // const width = 1139;
    // const height = (width * 9) / 16;

    // const drawingInfo = {
    //     left: leftMost + 10, // mm
    //     top: topMost + 10, // mm
    //     // width: Number(mainContent?.width) + 2, // mm
    //     // height: Number(mainContent?.height) + 2, // mm
    //     width,
    //     height,
    // };
    // // Set color of drawing to Red
    // doc.setDrawColor(255, 0, 0);
    // doc.rect(drawingInfo.left, drawingInfo.top, drawingInfo.width, drawingInfo.height);
    // // Reset color of drawing
    // doc.setDrawColor(0, 0, 0);

    doc.addImage(
        mainContent?.src,
        'WEBP',
        leftMost + 10,
        topMost + 10,
        mainContent?.width,
        mainContent?.height,
        undefined,
        compressOption || 'FAST', // compression of image
    );
}

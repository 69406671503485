/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { Vector2 } from 'three-full';
import {
    uniqBy,
    getPointIntersection,
    rotateVector,
    findAngle,
    pt_assign,
    getClockwiseAngle,
} from './radius-erase-function';
import { reversePoints } from '~/helper/ForgeViewer/radius-erase/radius-erase-function';
import { insertDeviceSymbol } from '../forge-viewer-tool';

const handleRadiusErase = (listPoint, listCircle, planeMesh) => {
    let listError = [];
    // listPoint = reversePoints(listPoint);
    // If the point array is not a polygon
    if (listPoint.length < 3) return;

    // eslint-disable-next-line array-callback-return
    listCircle.map((circle) => {
        // eslint-disable-next-line camelcase
        const center_point = new Vector2(circle.x, circle.y);
        const radius = circle.radius;

        let arrResult = [];

        for (let i = 0; i < listPoint.length; i++) {
            const lastIndex = listPoint.length - 1;

            let nextPoint = i + 1;
            if (nextPoint > lastIndex) {
                nextPoint = 0;
            }

            const point1 = new Vector2(listPoint[i].x, listPoint[i].y);
            const point2 = new Vector2(listPoint[nextPoint].x, listPoint[nextPoint].y);
            const result = intersectionCircleAndBox(point1, point2, i, nextPoint, center_point, radius);
            arrResult.push(...result);
        }

        // remove duplicate points
        arrResult = uniqBy(arrResult, JSON.stringify);
        if (arrResult.length < 3) {
            listError.push(circle);
            return;
        }
        let listPointArc = [];

        arrResult = reversePoints(arrResult);

        for (let i = 0; i < arrResult.length - 1; i++) {
            const lastIndex = arrResult.length - 1;
            if (i === 0) {
                if (
                    !arrResult[lastIndex].isOrigin &&
                    !arrResult[0].isOrigin &&
                    arrResult[lastIndex].indexLine !== arrResult[0].indexLine
                ) {
                    listPointArc.push({ p1: arrResult[lastIndex], p2: arrResult[0], currentPointInList: lastIndex });
                }
            }

            if (
                !arrResult[i].isOrigin &&
                !arrResult[i + 1].isOrigin &&
                arrResult[i].indexLine !== arrResult[i + 1].indexLine
            ) {
                listPointArc.push({ p1: arrResult[i], p2: arrResult[i + 1], currentPointInList: i });
            }
        }

        drawPolygon(listPointArc, center_point, radius, arrResult, circle.deviceId, circle.roomId, planeMesh);
    });

    // eslint-disable-next-line consistent-return
    return listError;
};

// eslint-disable-next-line camelcase
const intersectionCircleAndBox = (point1, point2, indexLine, nextPoint, center_point, radius) => {
    const arrResult = [];

    const findPoint = getPointIntersection(point1, point2, center_point, radius);
    if (findPoint === null || findPoint === undefined) return arrResult;

    const distance_center_point = center_point.distanceTo(findPoint);
    const distance_center_1 = center_point.distanceTo(point1);
    const distance_center_2 = center_point.distanceTo(point2);

    const distance_1 = point1.distanceTo(findPoint);
    const distance_2 = point2.distanceTo(findPoint);
    const find_distance = Math.sqrt(radius ** 2 - distance_center_point ** 2);

    let findPoint1;
    let findPoint2;

    // If the tangent point exceeds the origin point, then take origin
    if (find_distance >= distance_1 || distance_center_1 <= radius) {
        const pointResult = point1.clone();
        pointResult.isOrigin = true;
        pointResult.indexArr = indexLine;
        findPoint1 = pointResult;
        // If the passing tangent point is inside, then find the tangent point by find_distance
    } else {
        const find_vector = new Vector2();
        find_vector.subVectors(point1, findPoint).normalize().multiplyScalar(find_distance);
        const result_point = findPoint.clone().add(find_vector);
        result_point.isOrigin = false;
        result_point.indexLine = indexLine;
        result_point.indexArr = indexLine;
        findPoint1 = result_point;
    }

    if (find_distance >= distance_2 || distance_center_2 <= radius) {
        const pointResult = point2.clone();
        pointResult.isOrigin = true;
        pointResult.indexArr = nextPoint;
        findPoint2 = pointResult;
    } else {
        const find_vector = new Vector2();
        find_vector.subVectors(point2, findPoint).normalize().multiplyScalar(find_distance);
        const result_point = findPoint.clone().add(find_vector);
        result_point.isOrigin = false;
        result_point.indexLine = indexLine;
        result_point.indexArr = nextPoint;
        findPoint2 = result_point;
    }

    arrResult.push(findPoint1, findPoint2);

    return arrResult;
};

// Vẽ lại area sau khi radius erase
const drawPolygon = (listIntersectionPoint, center_point, radius, listPoints, deviceId, roomId, planeMesh) => {
    const listDiemTiepTuyen = listIntersectionPoint.map((item) => {
        const point1 = new Vector2(item.p1.x, item.p1.y);
        const point2 = new Vector2(item.p2.x, item.p2.y);

        // The radius vector of the point 1
        const radius_vector_1 = new Vector2();
        radius_vector_1.subVectors(point1, center_point).normalize();

        // The radius vector of the point 2
        const radius_vector_2 = new Vector2();
        radius_vector_2.subVectors(point2, center_point).normalize();

        /**
         * Angle between 2 radius vectors
         * Angle needed to find the length of the tangent
         * Length of tangent line
         */
        const clockwiseAngle = getClockwiseAngle(radius_vector_1, radius_vector_2);

        const ptAssign = pt_assign(point1, point2);

        let angle_to_look_for = clockwiseAngle * 0.3;
        let mineAngle = 0;
        if (clockwiseAngle < 0) {
            angle_to_look_for = (2 * Math.PI - Math.abs(clockwiseAngle)) * 0.2885;
            mineAngle = 6;
        }

        const length_of_tangent = Math.tan(angle_to_look_for) * radius;

        /**
         * radius 1 always rotates -90
         * radius 2 always rotates 90 */
        const rotate_of_ban_kinh_1 = rotateVector(radius_vector_1, -90 - mineAngle);
        const rotate_of_ban_kinh_2 = rotateVector(radius_vector_2, 90 + mineAngle);

        // Find the vector of the tangent line
        const vector_tangent_1 = rotate_of_ban_kinh_1.clone().multiplyScalar(length_of_tangent);
        const vector_tangent_2 = rotate_of_ban_kinh_2.clone().multiplyScalar(length_of_tangent);

        // Points to find
        const tangent_point_1 = point1.clone().add(vector_tangent_1);
        const tangent_point_2 = point2.clone().add(vector_tangent_2);

        return {
            tangent_point_1,
            tangent_point_2,
            currentPointInList: item.currentPointInList,
        };
    });

    const path = new Autodesk.Edit2D.PolygonPath([...listPoints]);
    path.style.lineWidth = 1;
    path.isCircle = false;
    path.deviceId = deviceId;
    path.roomId = roomId;
    path.isAlarmDevice = true;
    listDiemTiepTuyen.map((item) => {
        path.setBezierArc(
            item.currentPointInList, // segment index (= index of its start vertex)
            item.tangent_point_1.x,
            item.tangent_point_1.y, // control point to define start tangent
            item.tangent_point_2.x,
            item.tangent_point_2.y, // control point to define end tangent
        );
        return true;
    });

    if (planeMesh) {
        const oldPath = planeMesh.polygon;
        window.layer.removeShape(oldPath);
        planeMesh.polygon = path;

        if (planeMesh.isRerender) {
            insertDeviceSymbol(center_point, planeMesh.radius, planeMesh.deviceId, planeMesh.roomId, path);
        }
    } else {
        insertDeviceSymbol(center_point, radius, deviceId, roomId, path);
    }

    window?.layer.addShape(path);
};

export { handleRadiusErase };

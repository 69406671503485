/* eslint-disable object-shorthand */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-curly-brace-presence */
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Radio, Row } from 'antd';
import { NumericFormat } from 'react-number-format';
import YesNoAnswer from './YesNoAnswer';

/**
 * Render FormItemRHSQuestionBearers
 */
export default function FormItemRHSQuestionBearers({ fields }) {
    const [questionBearers, setQuestionBearers] = useState(fields['qBearers']?.value);

    const [bearerMeasurementWidth1, setBearerMeasurementWidth1] = useState(fields['bearerMeasurementWidth1']?.value);
    const [bearerMeasurementHeight1, setBearerMeasurementHeight1] = useState(fields['bearerMeasurementHeight1']?.value);
    const [bearerMeasurementWidth2, setBearerMeasurementWidth2] = useState(fields['bearerMeasurementWidth2']?.value);
    const [bearerMeasurementHeight2, setBearerMeasurementHeight2] = useState(fields['bearerMeasurementHeight2']?.value);
    const [bearerMeasurementWidth3, setBearerMeasurementWidth3] = useState(fields['bearerMeasurementWidth3']?.value);
    const [bearerMeasurementHeight3, setBearerMeasurementHeight3] = useState(fields['bearerMeasurementHeight3']?.value);

    const { t } = useTranslation();
    // Get an instance of form
    const form = Form.useFormInstance();

    /**
     * When submit form, we can choose the desired value
     * for corresponding field name
     */
    useEffect(() => {
        form.setFieldsValue({ qBearers: questionBearers });

        form.setFieldsValue({ bearerMeasurementWidth1: bearerMeasurementWidth1 });
        form.setFieldsValue({ bearerMeasurementHeight1: bearerMeasurementHeight1 });
        form.setFieldsValue({ bearerMeasurementWidth2: bearerMeasurementWidth2 });
        form.setFieldsValue({ bearerMeasurementHeight2: bearerMeasurementHeight2 });
        form.setFieldsValue({ bearerMeasurementWidth3: bearerMeasurementWidth3 });
        form.setFieldsValue({ bearerMeasurementHeight3: bearerMeasurementHeight3 });
    }, [
        questionBearers,
        bearerMeasurementWidth1,
        bearerMeasurementHeight1,
        bearerMeasurementWidth2,
        bearerMeasurementHeight2,
        bearerMeasurementWidth3,
        bearerMeasurementHeight3,
    ]);

    /**
     * Render FormItemQuestionBearers
     */
    const renderFormItemQuestionBearers = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{t('are_there_bearers')}</p>
            <Form.Item name="qBearers" valuePropName="value" noStyle>
                <Radio.Group onChange={(e) => setQuestionBearers(e.target.value)} value={questionBearers}>
                    <YesNoAnswer />
                </Radio.Group>
            </Form.Item>
        </div>
    );

    /**
     * Helper for rendering: shifted FormItemBearerMeasurement
     * based on renderFormItemQuestionBearers
     */
    useEffect(() => {
        if (questionBearers > 0) {
            return;
        }
        setBearerMeasurementWidth1('');
        setBearerMeasurementHeight1('');
        setBearerMeasurementWidth2('');
        setBearerMeasurementHeight2('');
        setBearerMeasurementWidth3('');
        setBearerMeasurementHeight3('');
    }, [questionBearers]);

    /**
     * Array of formItem that is needed for rendering
     */
    const formListBearerMeasurement = useMemo(
        () => [
            {
                form_label_name: `${t('bearer')} 1`,
                form_item_width_name: 'bearerMeasurementWidth1',
                form_item_height_name: 'bearerMeasurementHeight1',
                form_item_width_value: bearerMeasurementWidth1,
                form_item_height_value: bearerMeasurementHeight1,
                form_item_width_callback: setBearerMeasurementWidth1,
                form_item_height_callback: setBearerMeasurementHeight1,
            },
            {
                form_label_name: `${t('bearer')} 2`,
                form_item_width_name: 'bearerMeasurementWidth2',
                form_item_height_name: 'bearerMeasurementHeight2',
                form_item_width_value: bearerMeasurementWidth2,
                form_item_height_value: bearerMeasurementHeight2,
                form_item_width_callback: setBearerMeasurementWidth2,
                form_item_height_callback: setBearerMeasurementHeight2,
            },
            {
                form_label_name: `${t('bearer')} 3`,
                form_item_width_name: 'bearerMeasurementWidth3',
                form_item_height_name: 'bearerMeasurementHeight3',
                form_item_width_value: bearerMeasurementWidth3,
                form_item_height_value: bearerMeasurementHeight3,
                form_item_width_callback: setBearerMeasurementWidth3,
                form_item_height_callback: setBearerMeasurementHeight3,
            },
        ],
        [
            bearerMeasurementWidth1,
            bearerMeasurementWidth2,
            bearerMeasurementWidth3,
            bearerMeasurementHeight1,
            bearerMeasurementHeight2,
            bearerMeasurementHeight3,
        ],
    );

    /**
     * Render FormItemBearerMeasurement
     */
    const renderFormItemBearerMeasurement = (
        formLabelName,
        formItemWidthName,
        formItemHeightName,
        formItemWidthValue,
        formItemHeightValue,
        formItemWidthCallback,
        formItemHeightCallback,
    ) =>
        questionBearers === 0 ? null : (
            <Row gutter={8}>
                <Col style={{ paddingTop: '2px', marginLeft: '22px' }}>{formLabelName}:</Col>
                <Col style={{ width: '110px' }}>
                    <Form.Item name={formItemWidthName} initialValue={formItemWidthValue} valuePropName="value" noStyle>
                        <Input value={formItemWidthValue} hidden />
                    </Form.Item>
                    <NumericFormat
                        customInput={Input}
                        suffix={' '.concat(`m`)}
                        thousandSeparator={t(',')}
                        decimalSeparator={t('.')}
                        style={{ textTransform: 'lowercase', marginLeft: '8px', maxHeight: '27px' }}
                        onValueChange={(values) => {
                            formItemWidthCallback(values.floatValue);
                        }}
                        placeholder={t('width')}
                        defaultValue={formItemWidthValue}
                    />
                </Col>
                <Col style={{ width: '110px' }}>
                    <Form.Item
                        name={formItemHeightName}
                        initialValue={formItemHeightValue}
                        valuePropName="value"
                        noStyle
                    >
                        <Input value={formItemHeightValue} hidden />
                    </Form.Item>
                    <NumericFormat
                        customInput={Input}
                        suffix={' '.concat(`m`)}
                        thousandSeparator={t(',')}
                        decimalSeparator={t('.')}
                        style={{ textTransform: 'lowercase', marginLeft: '8px', maxHeight: '27px' }}
                        onValueChange={(values) => {
                            formItemHeightCallback(values.floatValue);
                        }}
                        placeholder={t('height')}
                        defaultValue={formItemHeightValue}
                    />
                </Col>
            </Row>
        );

    // ========================================================================
    // Render FormItemRHSQuestionBearers
    // ========================================================================
    return (
        <Col style={{ width: '100%' }}>
            {renderFormItemQuestionBearers}
            {formListBearerMeasurement.map((formItem, index) => (
                <div
                    key={`${formItem.form_item_width_name}-${formItem.form_item_width_name}-${index}`}
                    style={{ width: '350px', marginTop: '8px' }}
                >
                    {renderFormItemBearerMeasurement(
                        formItem.form_label_name,
                        formItem.form_item_width_name,
                        formItem.form_item_height_name,
                        formItem.form_item_width_value,
                        formItem.form_item_height_value,
                        formItem.form_item_width_callback,
                        formItem.form_item_height_callback,
                    )}
                </div>
            ))}
        </Col>
    );
}

/* eslint-disable array-callback-return */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */

import { HubConnectionBuilder } from '@microsoft/signalr';
import { Alert, message } from 'antd';
import Cookies from 'js-cookie';
import jcc from 'json-case-convertor';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { convertStringToUnit, getUnitsNumber, getUnitSquare } from '~/helper/ForgeViewer/forge-viewer-tool';
import { RoomsStatic } from '~/helper/ForgeViewer/speam/rooms/roomsStatic';
import { RoomStatic } from '~/helper/ForgeViewer/speam/rooms/roomStatic';
import { controlIsFetchingUnitMode, setAlarmInfos, setOutSideRoom } from '~/store/alarm-device/alarmDeviceSlice';
import { saveDataFile } from '~/store/files/fileActions';
import { setIsFetchingExport, setUrlExport, updateFileInfo } from '~/store/files/filesSlice';
import { openModalModalAction } from '~/store/modal/ModalSlice';
import { getFileById } from '~/store/tool/toolAction';
import { setDataToolAction } from '~/store/tool/toolSlice';
import { controlIsFetchingViewerAction, initListRoomViewerAction, setFloorBoundingBox } from '~/store/viewer/viewer.slice';

export default function AppConnection({ children }) {
    const [connection, setConnection] = useState(null);
    const [status, setStatus] = useState(-1);
    const [idParam, setIdParam] = useState('');
    const dispatch = useDispatch();

    const param = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        setIdParam(param.id);
        const token = Cookies.get('access_token');
        if (!connection && token) {
            const newConn = new HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_HUB_URL}/?access_token=${token}`)
                .build();

            newConn
                .start()
                .then(() => {
                    setConnection(newConn);
                    setStatus('success');
                })
                .catch((error) => {
                    if (error) setStatus(1);
                });
        }

        return () => {
            if (connection) {
                connection.stop();
                setConnection(null);
            }
        };
    }, [connection, setStatus, param]);

    useEffect(() => {
        if (connection) {
            Cookies.set('clientId', connection.connectionId);

            connection.on('defineRooms', (response) => {
                if (response.data.id !== idParam || param.id !== idParam) {
                    message.warning({
                        content: response.data,
                        duration: 1,
                    });
                } else {
                    if (response.isSuccess) {
                        const localSave = JSON.parse(sessionStorage.getItem('list'));
                        const result = jcc.camelCaseKeys(JSON.parse(response.data.fileData));

                        if (!result.convertTotalArea) {
                            const unitNumber = getUnitSquare(result?.units);
                            const convertTotalArea = (Number(result.totalArea) / unitNumber).toFixed(0);
                            const convertWidthDoor = Number(result.widthDoor) / getUnitsNumber(result?.units);
                            result.convertTotalArea = Number(convertTotalArea);
                            result.convertWidthDoor = Number(convertWidthDoor);
                            result.units = convertStringToUnit(result.units);
                        }
                        const data = { ...response.data, fileData: JSON.stringify(result) };
                        dispatch(initListRoomViewerAction(result?.rooms));

                        if (result?.outSideList && result?.outSideList.length > 0) {
                            dispatch(setOutSideRoom(result.outSideList));
                        }

                        const newListSave = localSave.map((item) => {
                            if (item.id === data.id) {
                                item = data;
                            }
                            return { ...item };
                        });
                        sessionStorage.removeItem('list');
                        sessionStorage.setItem('list', JSON.stringify(newListSave));
                        dispatch(setDataToolAction(data));
                        dispatch(
                            saveDataFile({
                                id: response.data.id,
                                fileData: JSON.stringify(result),
                            }),
                        );

                        // After upload file from local to Forge and the data is got success set data here to RoomsStatic
                        RoomsStatic.initializeRoomsStatic(result?.rooms);
                        RoomStatic.hasRoomData = true;
                    } else {
                        message.error({
                            content: response.data,
                            duration: 0.5,
                        });
                    }
                }
                dispatch(controlIsFetchingViewerAction(false));
            });

            connection.on('uploadFileSuccess', (response) => {
                dispatch(updateFileInfo({ thumbnailLoading: true, ...response }));
            });

            connection.on('uploadFileError', (response) => {
                dispatch(updateFileInfo(response));
            });

            connection.on('getThumbnailSuccess', (response) => {
                dispatch(updateFileInfo({ thumbnailLoading: false, ...response }));

                message.success({
                    content: `${t('upload_success')}!`,
                    duration: 0.8,
                    maxCount: 2,
                });
            });
            connection.on('urlDownload', (response) => {
                dispatch(setIsFetchingExport(false));
                dispatch(setUrlExport(response.data));
            });

            connection.on('getThumbnailError', (response) => {
                dispatch(updateFileInfo(response));
                message.error({
                    content: `${t('upload_error')}!`,
                    duration: 0.5,
                    maxCount: 3,
                });
            });

            connection.on('uploadAlarmDeviceSuccess', (response) => {
                const parseData = jcc.camelCaseKeys(JSON.parse(response));
                dispatch(setAlarmInfos(parseData.rooms));
            });

            connection.on('uploadAlarmDeviceError', () => {});

            connection.on('changeUnit', (response) => {
                if (!response.isSuccess) {
                    message.error({
                        content: t('change_unit_error_please_try_again'),
                        duration: 1.5,
                    });
                    dispatch(getFileById(param.id));
                    dispatch(controlIsFetchingUnitMode(false));
                    return;
                }

                message.success({
                    content: t('change_unit_success'),
                    duration: 1.5,
                });
                dispatch(getFileById(response.data.id));
                dispatch(setDataToolAction(response.data));
                dispatch(controlIsFetchingUnitMode(false));
            });

            connection.on('getBox', (response) => {
                if (!response?.isSuccess) {
                    console.error('Can not get bounding box of floor!')
                }
                const parseData = jcc.camelCaseKeys(JSON.parse(response?.data));
                dispatch(setFloorBoundingBox(parseData))
            });
        } else {
            <Alert message={t('error')} description={t('connection_not_found')} type="error" showIcon />;
        }
    }, [connection, param]);

    return status !== -1 ? React.cloneElement(children, { connection, connStatus: status }) : '';
}

/* eslint-disable no-undef */
/* eslint-disable yoda */
/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable arrow-body-style */

import ToolButton from './ToolButton/ToolButtons';
import styles from './ToolViewer.module.scss';
import { Button, Image, Modal, notification, Select } from 'antd';
import classNames from 'classnames/bind';
import jcc from 'json-case-convertor';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import { image } from '~/assets';
import dataSymbol from '~/assets/DataSymbol/data.json';
import { toolsData } from '~/assets/MenuToolData/MenuToolData';
import AppLoading from '~/components/AppLoading';
import HelpButton from '~/components/HelpButton/HelpButton';
import { customMemo } from '~/helper/common';
import {
    activateRulerFreehand,
    activateSetUnit,
    editDeviceTool,
    editRoomTool,
    getUnitsNumber,
    getUnitSquare,
    markRoomTool,
    startTool,
} from '~/helper/ForgeViewer/forge-viewer-tool';
import { RoomsStatic } from '~/helper/ForgeViewer/speam/rooms/roomsStatic';
import { RoomStatic } from '~/helper/ForgeViewer/speam/rooms/roomStatic';
import {
    controlModeUnit,
    selectAlarmDevice,
    setActiveEditDevice,
    setAlarmInfos,
    setOnDeviceMode,
    setUnit,
} from '~/store/alarm-device/alarmDeviceSlice';
import { filesList } from '~/store/files/filesSlice';
import { clearModalStateModalAction, openModalModalAction, selectModalState } from '~/store/modal/ModalSlice';
import { setSymbolList } from '~/store/symbol/symbolSlice';
import { selectToolState } from '~/store/tool/toolSlice';
import { fetchFloorBoundingBox } from '~/store/viewer/viewer.action';
import {
    makeHasReleasedProtectDataRoomSelector,
    makeViewerIsFetchingSelector,
    makeViewerIsSuccessSelector,
    makeViewerRoomsSelector,
    protectDataRoomsSelector,
} from '~/store/viewer/viewer.selectors';
import {
    controlIsFetchingViewerAction,
    initListRoomViewerAction,
    resetIsSuccessGetListRoomViewerAction,
    setActiveEditRoomViewerAction,
    setEditedRoomsViewerAction,
    setFloorBoundingBox,
    setFloorSettingsToolDataViewerAction,
    setHasReleasedProtectDataRoomsViewerAction,
} from '~/store/viewer/viewer.slice';

const cx = classNames.bind(styles);

const handleOpen = (data, name, setState) => {
    setState(() =>
        data.map((item) => {
            if (item?.name === name) {
                item.isActive = !item.isActive;
            }
            return { ...item };
        }),
    );
};

function MenuTool({
    changeOpenSubTool,
    file,
    onHaveRoomsStaticObj,
    notifyMenuTool,
    DesignAutomationCallAPI,
    dataEmty,
    clientId,
}) {
    // State---------------------------------------
    const [emitter, setEmitter] = useState(0);
    const [noData, setNoData] = useState(true);
    const [change, setChange] = useState(false);
    const [widthDoor, setWidthDoor] = useState(0);
    const [totalArea, setTotalArea] = useState(null);

    const [unitChange, setUnitChange] = useState(window?.viewer?.model.getDisplayUnit());

    const dispatch = useDispatch();

    const [menus, setMenus] = useState(toolsData);

    const [symbolData, setSymbolData] = useState([]);

    const [adjust, setAdjust] = useState([
        // {
        //     id: 'add_wall_break',
        //     name: 'add_wall_break',
        //     isActive: true,
        //     icon: <i className="fa-solid fa-scissors" />,
        // },
        // {
        //     id: 'add_wall_opening',
        //     name: 'add_wall_opening',
        //     isActive: false,
        //     icon: <i className="fa-solid fa-up-right-from-square" />,
        // },
    ]);

    const [energy, setEnergy] = useState([
        // {
        //     id: 'hight',
        //     children: [
        //         {
        //             id: 'glt',
        //             name: 'GLT',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_2.png',
        //             isActive: false,
        //         },
        //         {
        //             id: 'light',
        //             name: 'light_switch',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_3.png',
        //             isActive: false,
        //         },
        //         {
        //             id: 'vient',
        //             name: 'va_et_vient',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_1.png',
        //             isActive: false,
        //         },
        //         {
        //             id: 'socket',
        //             name: '20A_socket',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_4.png',
        //             isActive: false,
        //         },
        //         {
        //             id: '32socket',
        //             name: '32A_socket',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_7.png',
        //             isActive: false,
        //         },
        //         {
        //             id: 'plafond',
        //             name: 'lum. plafond',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_8.png',
        //             isActive: false,
        //         },
        //         {
        //             id: 'applique',
        //             name: 'lum. applique',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_9.png',
        //             isActive: false,
        //         },
        //         {
        //             id: 'electric',
        //             name: 'electric socket',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_5.png',
        //             isActive: false,
        //         },
        //         {
        //             id: 'dimmer',
        //             name: 'dimmer',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_4.png',
        //             isActive: false,
        //         },
        //     ],
        //     name: 'hight_current',
        //     isActive: false,
        // },
        // {
        //     id: 'low',
        //     children: [
        //         {
        //             id: 'access',
        //             name: 'internet_access',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_10.png',
        //             isActive: false,
        //         },
        //         {
        //             id: 'socket',
        //             name: 'RJ45 socket',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_11.png',
        //             isActive: false,
        //         },
        //         {
        //             id: 'antenna',
        //             name: 'antenna socket',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_12.png',
        //             isActive: false,
        //         },
        //     ],
        //     name: 'low_current',
        //     isActive: false,
        // },
        // {
        //     id: 'thermal',
        //     children: [
        //         {
        //             id: 'heater',
        //             name: 'water_heater',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_13.png',
        //             isActive: false,
        //         },
        //         {
        //             id: 'radiator',
        //             name: 'radiator',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/energy_14.png',
        //             isActive: false,
        //         },
        //         {
        //             id: 'callPoint',
        //             name: 'manual_call_point',
        //             image: 'https://test.kft-edv.de/ptool-copy/public/assets/images/objects/manual_call_point.png',
        //             isActive: false,
        //         },
        //     ],
        //     name: 'thermal',
        //     isActive: false,
        // },
    ]);

    // Selector
    const { unit, editUnitMode } = useSelector(selectAlarmDevice);
    const { modalOpen, modalData } = useSelector(selectModalState);
    const { toggleAllTools } = useSelector(selectToolState);
    const roomsSelector = useSelector(makeViewerRoomsSelector());
    const isFetchingSelector = useSelector(makeViewerIsFetchingSelector());
    const isSuccessSelector = useSelector(makeViewerIsSuccessSelector());
    const hasReleasedProtectData = useSelector(makeHasReleasedProtectDataRoomSelector());
    const protectDataRooms = useSelector(protectDataRoomsSelector());
    //---

    const parseData = jcc.camelCaseKeys(JSON.parse(file?.fileData));
    const date = new Date(file.createdOn);
    const { t, i18n } = useTranslation();

    // useEffect
    useEffect(() => {
        const primitiveUnit = window.viewer?.model.getDisplayUnit();

        if (primitiveUnit) {
            dispatch(setUnit(primitiveUnit));
            setUnitChange(primitiveUnit);
        }
    }, [window?.viewer?.model]);

    useEffect(() => {
        const result = dataSymbol.map((symbol) => {
            const newChildren = symbol.children.map((child) => {
                return { ...child, isActive: false };
            });

            return { ...symbol, children: newChildren, isActive: false };
        });
        setSymbolData(result);
    }, []);

    /**
     * If all protected data have been released, set all data room RoomsStatic
     */
    useEffect(() => {
        if (roomsSelector && hasReleasedProtectData) {
            RoomsStatic.rooms = {};

            // after data is released, close all opened rooms
            const isOpenRoom = false;

            roomsSelector.forEach((room) => {
                RoomsStatic.createRoom(
                    room.roomId,
                    room.currentPoints,
                    room.boxPoints,
                    room.scale,
                    room.layerCurrentPoints,
                    room.layerBoxPoints,
                    room.polyId,
                    room.name,
                    room.width,
                    room.widthLayer,
                    room.height,
                    room.heightLayer,
                    room.minX,
                    room.minY,
                    room.maxX,
                    room.maxY,
                    room.scaleLength,
                    room.isLayer,
                    isOpenRoom,
                    room.roomSetting,
                );
            });
            const roomsInfo = {
                rooms: roomsSelector,
                length: roomsSelector?.length,
            };

            dispatch(setEditedRoomsViewerAction(roomsInfo));

            onHaveRoomsStaticObj(true);
            // notify an action
            setEmitter((prev) => prev + 1);
            // reset
            // dispatch(setHasReleasedProtectData(false));
        }
    }, [roomsSelector, hasReleasedProtectData]);

    /**
     * When a new is uploaded the first time, the 'connection' at AppConnection
     * is activated, and the rooms data is taken by RoomsStatic
     * this below helps useful for rendering 'Load Data First' menu
     */
    useEffect(() => {
        if (roomsSelector && RoomStatic.hasRoomData) {
            onHaveRoomsStaticObj(true);
            setNoData(false);
            // reset
            RoomStatic.hasRoomData = false;
            // notify an action
            setEmitter((prev) => prev + 1);
        }
    }, [roomsSelector]);

    useEffect(() => {
        if (isSuccessSelector) {
            notification.success({
                message: `${t('success')}!`,
                placement: 'topRight',
                duration: 1,
            });
        }
        dispatch(resetIsSuccessGetListRoomViewerAction());
    }, [isSuccessSelector]);

    // notify ui change, that helps parent component render the right way
    useEffect(() => {
        if (emitter) {
            notifyMenuTool(emitter);
        }
        if (emitter > 3) {
            setEmitter(1);
        }
        if (dataEmty) {
            setNoData(true);
        }
    }, [emitter, dataEmty]);

    useEffect(() => {
        onSelect('select');
    }, []);

    useEffect(() => {
        const closeMode = (e) => {
            if (e.key === 'Escape') onSelect('select');
        };

        if (!editUnitMode) window?.addEventListener('keydown', closeMode);
        return () => window?.removeEventListener('keydown', closeMode);
    }, [editUnitMode]);

    const onSelect = useCallback(
        async (tool) => {
            switch (tool) {
                case 'editRoom': {
                    setMenus((prev) =>
                        prev.map((menu) => {
                            if (menu.id === tool) {
                                menu.isActive = true;
                            } else {
                                menu.isActive = false;
                            }
                            return { ...menu };
                        }),
                    );
                    activateRulerFreehand(false);
                    dispatch(setActiveEditDevice(false));
                    dispatch(setActiveEditRoomViewerAction(true));
                    editRoomTool();
                    activateSetUnit(false);

                    // notify an action
                    setEmitter((prev) => prev + 1);
                    dispatch(controlModeUnit(false));

                    break;
                }
                case 'editDevice': {
                    setMenus((prev) =>
                        prev.map((menu) => {
                            if (menu.id === tool) {
                                menu.isActive = true;
                            } else {
                                menu.isActive = false;
                            }
                            return { ...menu };
                        }),
                    );
                    activateRulerFreehand(false);
                    dispatch(setActiveEditDevice(true));
                    dispatch(setActiveEditRoomViewerAction(false));
                    editDeviceTool();
                    activateSetUnit(false);
                    dispatch(controlModeUnit(false));

                    // notify an action
                    setEmitter((prev) => prev + 1);
                    break;
                }
                case 'markRoom': {
                    setMenus((prev) =>
                        prev.map((menu) => {
                            if (menu.id === tool) {
                                menu.isActive = true;
                            } else {
                                menu.isActive = false;
                            }
                            return { ...menu };
                        }),
                    );
                    activateRulerFreehand(false);
                    dispatch(setActiveEditDevice(false));
                    dispatch(setActiveEditRoomViewerAction(false));
                    markRoomTool();
                    activateSetUnit(false);
                    dispatch(controlModeUnit(false));

                    // notify an action
                    setEmitter((prev) => prev + 1);

                    break;
                }
                case 'listRoom': {
                    changeOpenSubTool(tool);

                    activateRulerFreehand(false);
                    // notify an action
                    setEmitter((prev) => prev + 1);
                    onSelect('editRoom');
                    activateSetUnit(false);
                    dispatch(controlModeUnit(false));

                    break;
                }
                case 'listAlarmDevice': {
                    changeOpenSubTool(tool);

                    activateRulerFreehand(false);
                    // notify an action
                    setEmitter((prev) => prev + 1);
                    onSelect('editDevice');
                    activateSetUnit(false);
                    dispatch(controlModeUnit(false));

                    break;
                }
                case 'select':
                    setMenus((prev) =>
                        prev.map((menu) => {
                            if (menu.id === tool) {
                                menu.isActive = true;
                            } else {
                                menu.isActive = false;
                            }
                            return { ...menu };
                        }),
                    );

                    dispatch(setActiveEditDevice(false));
                    dispatch(setActiveEditRoomViewerAction(false));
                    activateSetUnit(false);
                    startTool(false);
                    dispatch(controlModeUnit(false));

                    activateRulerFreehand(false);
                    // notify an action
                    setEmitter((prev) => prev + 1);
                    break;
                case 'listSymbol':
                    changeOpenSubTool(tool);
                    onSelect('editDevice');
                    activateSetUnit(false);
                    dispatch(controlModeUnit(false));

                    break;

                case 'editUnit':
                    setMenus((prev) =>
                        prev.map((menu) => {
                            if (menu.id === tool) {
                                menu.isActive = true;
                            } else {
                                menu.isActive = false;
                            }
                            return { ...menu };
                        }),
                    );
                    startTool(false);
                    dispatch(setActiveEditDevice(false));
                    dispatch(setActiveEditRoomViewerAction(false));
                    activateRulerFreehand(false);
                    activateSetUnit(true);
                    dispatch(controlModeUnit(true));

                    break;

                case 'distance':
                    setMenus((prev) =>
                        prev.map((menu) => {
                            if (menu.id === tool) {
                                menu.isActive = true;
                            } else {
                                menu.isActive = false;
                            }
                            return { ...menu };
                        }),
                    );

                    dispatch(setActiveEditRoomViewerAction(false));
                    activateSetUnit(false);
                    startTool(false);
                    dispatch(controlModeUnit(false));
                    activateRulerFreehand(true);
                    break;

                case 'floorSettings':
                    changeOpenSubTool(tool);
                    onSelect('editRoom');
                    activateSetUnit(false);
                    // notify an action
                    setEmitter((prev) => prev + 1);
                    dispatch(controlModeUnit(false));
                    activateSetUnit(false);

                    break;
                default:
                    break;
            }
            dispatch(setOnDeviceMode(false));
        },
        [unit],
    );

    const toggleActive = (list, symbol) => {
        list.map((item) => {
            item?.children.map((child) => {
                if (child.code === symbol.code) {
                    child.isActive = true;
                } else {
                    child.isActive = false;
                }
                setChange(!change);
                return { ...child };
            });
            return { ...item };
        });
    };

    const handleSelect = (list, id, setState) => {
        setState(() =>
            list.map((item) => {
                if (item.id === id) {
                    item.isActive = true;
                } else {
                    item.isActive = false;
                }
                return { ...item };
            }),
        );
    };

    const loadDataRoom = useCallback(() => {
        const hasProtectData = protectDataRooms?.find((d) => d.id === file.id);
        if (hasProtectData && hasProtectData?.rooms && hasProtectData.rooms.length > 0) {
            setNoData(false);
            setEmitter((prev) => prev + 1);
            onHaveRoomsStaticObj(true);
            dispatch(controlIsFetchingViewerAction(false));
            dispatch(setHasReleasedProtectDataRoomsViewerAction(false));
            dispatch(openModalModalAction({ title: 'fileInfo' }));
            return;
        }

        if (file?.fileData) {
            const cvData = jcc.camelCaseKeys(JSON.parse(file?.fileData));
            if (cvData?.rooms && cvData.rooms.length > 0) {
                dispatch(controlIsFetchingViewerAction(true));
                const unitNumber = getUnitSquare(unit);
                const convertTotalArea = Number(cvData.totalArea) / unitNumber;
                const convertWidthDoor = Number(cvData.widthDoor) / getUnitsNumber(cvData?.units);
                const unitConvertStr = cvData?.units;
                dispatch(setUnit(unitConvertStr));
                setTotalArea(convertTotalArea);
                setWidthDoor(convertWidthDoor);

                // if (hasReleasedProtectData) {
                //     onHaveRoomsStaticObj(true);
                //     dispatch(setHasReleasedProtectDataRoomsViewerAction(false));
                //     dispatch(controlIsFetchingViewerAction(false));
                //     // if have room data after click the Load button, the MenuTool will show
                //     setNoData(false);
                //     // notify an action
                //     setEmitter((prev) => prev + 1);
                //     return;
                // }

                // ================================================================
                // Set Global Rooms Static here
                RoomsStatic.initializeRoomsStatic(cvData?.rooms);
                onHaveRoomsStaticObj(true);
                // ================================================================

                dispatch(initListRoomViewerAction(cvData?.rooms));
                if (cvData?.alarms) {
                    dispatch(setAlarmInfos(cvData?.alarms));
                }
                if (cvData?.symbol) {
                    dispatch(setSymbolList(cvData?.symbol));
                }

                // ================================================================
                // Set FloorSettingsTool Data
                // ================================================================
                if (cvData?.floorSettings) {
                    dispatch(setFloorSettingsToolDataViewerAction(cvData?.floorSettings));
                }

                onHaveRoomsStaticObj(true);
                dispatch(controlIsFetchingViewerAction(false));
                // notify an action
                setEmitter((prev) => prev + 1);
                // if have room data after click the Load button, the MenuTool will show
                setNoData(false);
                dispatch(openModalModalAction({ title: 'fileInfo' }));

                // Get data that support get floor bounding box - from SignalR (AppConnection)
                if (!cvData?.box) {
                    fetchFloorBoundingBox(file?.id, clientId);
                } else {
                    dispatch(setFloorBoundingBox(cvData?.box));
                }
                return;
            }
        }

        // else reset room data
        RoomsStatic.rooms = {};
        RoomStatic.hasRoomData = false;
        onHaveRoomsStaticObj(false);
        DesignAutomationCallAPI();
    }, [file]);

    return (
        <div className={cx('menu-tools')} style={{ display: toggleAllTools ? 'block' : 'none' }}>
            <p style={{ display: 'none' }}>{noData}</p>
            {noData ? (
                <>
                    {isFetchingSelector && <AppLoading menuTool />}
                    <img src={image.logoSpeam} alt="" className={cx('menu-tools__img')} />
                    <div className={cx('menu-tools__nodata')}>
                        <Button onClick={loadDataRoom} className="btn-custom btn-custom-large">
                            {t('load_room_data')}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    {/* <div className={cx('menu-tools__title')}>
                        <div className={cx('part-list')}>
                            <h5>{t('parts_list')}:</h5>
                            <span>RWM: 0 - Handfeuermelder: 0</span>
                        </div>
                    </div> */}

                    <div className={cx('menu-tools__item')}>
                        <div className={cx('item-title', 'space-between')}>
                            <span className={cx('title')}>{t('tools')}</span>
                            <HelpButton title="tools" />
                        </div>

                        <div className={cx('item-buttom-group')}>
                            {menus?.map((menu) => (
                                <div key={menu.id} onClick={() => onSelect(menu.id)} aria-hidden="true">
                                    <ToolButton data={menu} tooltip={i18n.translator.language !== 'en'} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={cx('menu-tools__item')}>
                        <div className={cx('item-title', 'space-between')}>
                            <span className={cx('title')}>{t('symbols')}</span>
                            <HelpButton title="symbols" />
                        </div>
                        <div className={cx('item-tool-group')}>
                            {symbolData?.map((item) => (
                                <div className={cx('tree-item')} key={item.name}>
                                    <div
                                        className={cx('item-title')}
                                        onClick={() => handleOpen(symbolData, item.name, setSymbolData)}
                                        aria-hidden="true"
                                    >
                                        <button className={cx('sort-button')} type="button">
                                            <i
                                                className={cx(
                                                    'fa-solid fa-sort-down',
                                                    !item.isActive && 'rotate-right',
                                                )}
                                            />
                                        </button>
                                        <span className={cx('item-name')}>{t(item.name.toLowerCase())}</span>
                                    </div>
                                    <div className={cx('tree-item__children', !item.isActive && 'hide')}>
                                        {item.children.map((child, index) => (
                                            <div
                                                key={`${child.code}${index}`}
                                                onClick={() => toggleActive(symbolData, child)}
                                                aria-hidden="true"
                                            >
                                                {/* For render UI */}
                                                <a style={{ display: 'none' }}>{change}</a>

                                                <ToolButton
                                                    data={child}
                                                    parent={item.name}
                                                    onSelect={onSelect}
                                                    tooltip
                                                    bigSize
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={cx('menu-tools__item')}>
                        <div className={cx('item-title', 'space-between')}>
                            <span className={cx('title')}>{t('adjust')}</span>
                            <HelpButton title="adjust" />
                        </div>
                        <div className={cx('item-tool-group')}>
                            {adjust?.map((item) => (
                                <button
                                    className={cx('adjust-btn')}
                                    key={item.id}
                                    onClick={() => handleSelect(adjust, item.id, setAdjust)}
                                >
                                    <ToolButton horizontal data={item} />
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className={cx('menu-tools__item')}>
                        <div className={cx('item-title', 'space-between')}>
                            <span className={cx('title')}>{t('energy')}</span>
                            <HelpButton title="energy" />
                        </div>
                        <div className={cx('item-tool-group')}>
                            {energy.map((item) => (
                                <div className={cx('tree-item')} key={item.id}>
                                    <button
                                        className={cx('item-title')}
                                        onClick={() => handleOpen(energy, item.name, setEnergy)}
                                    >
                                        <p className={cx('sort-button')}>
                                            <i
                                                className={cx(
                                                    'fa-solid fa-sort-down',
                                                    !item.isActive && 'rotate-right',
                                                )}
                                            />
                                        </p>
                                        <span className={cx('item-name')}>{t(item.name)}</span>
                                    </button>
                                    <div className={cx('tree-item__children', !item.isActive && 'hide')}>
                                        {item.children.map((child) => (
                                            <div
                                                key={child.id}
                                                onClick={() => toggleActive(item.children, child.id)}
                                                aria-hidden="true"
                                            >
                                                <a style={{ display: 'none' }}>{change}</a>
                                                <ToolButton data={child} bigSize tooltip />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}

            {/* --- Modal that show at the beginning when the first access (/tool/:fileId) --- */}
            {/* ---after click LOAD DATA ROOM http://localhost:3000/tool/f3deef12-c5aa-46a4-83f8-8e5bc7fb78de --- */}

            {/* <Modal
                title={file.name}
                centered
                open={modalOpen && modalData?.title === 'fileInfo'}
                onCancel={() => dispatch(clearModalStateModalAction())}
                footer={null}
                width="580px"
            >
                {(parseData.convertWidthDoor || widthDoor) > 2 && (
                    <div style={{ marginBottom: 24 }}>
                        <i
                            className="fa-solid fa-triangle-exclamation"
                            style={{ color: 'rgb(223 223 86)', marginRight: 4, fontSize: '1.5rem' }}
                        />
                        <span style={{ fontSize: '0.9rem' }}>
                            {t('maybe_the_drawing_is_in_the_wrong_unit_please_choose_again')}
                        </span>
                    </div>
                )}
                <div className={cx('modal-container')}>
                    <div className={cx('modal-img')}>
                        <Image
                            preview={false}
                            width="100%"
                            height="100%"
                            src={file.thumbnail}
                            alt=""
                            fallback={image.logo}
                        />
                    </div>
                    {parseData ? (
                        <div className={cx('modal-info')} style={{ color: 'var(--text-grey)', fontWeight: 400 }}>
                            <p>
                                {t('name')}:<span>{file.name}</span>
                            </p>
                            <p>
                                {t('type')}:<span>{String(file.fileExtension).slice(1)}</span>
                            </p>
                            <p>
                                {t('size')}:<span>{(file.size * 0.00000095367432).toFixed(2)} MB</span>
                            </p>
                            <p>
                                {t('create_on')}:<span>{date.toDateString()}</span>
                            </p>
                            <p>
                                {t('rooms')}:<span>{parseData?.rooms?.length || 'unknown'}</span>
                            </p>
                            <p>
                                {t('devices')}:<span>{parseData?.devices?.length || 'unknown'}</span>
                            </p>
                            <p>
                                {t('total_area')}:
                                <span
                                    style={{
                                        marginLeft: 10,
                                        height: '27px',
                                        border: '1px solid #d9d9d9',
                                        borderRadius: '5px',
                                        padding: '2px 8px',
                                    }}
                                >
                                    <NumericFormat
                                        displayType="text"
                                        suffix={' '.concat(`${unitChange}`)}
                                        thousandSeparator={t(',')}
                                        decimalSeparator={t('.')}
                                        style={{ textTransform: 'lowercase', maxHeight: '27px', marginLeft: 0 }}
                                        value={totalArea || parseData?.totalArea}
                                    />
                                    <sup>2</sup>
                                </span>
                            </p>
                        </div>
                    ) : (
                        <AppLoading listFile />
                    )}
                </div>
            </Modal> */}
        </div>
    );
}

export default customMemo(MenuTool);

/* eslint-disable consistent-return */
import { memo } from 'react';

/**
 * Using React.memo with compare function
 * @param {*} component
 * @returns
 */
export function customMemo(component) {
    const compare = (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps);
    return memo(component, compare);
}

/**
 * Helper function that save data into protectedData
 *   protectDataRoom is an array that holds rooms data of each opened file,
 *   useful when switch file between tabs in 'tool' page
 * @param {*} data
 * @param {*} objData
 * @returns
 */
export const saveProtectedData = (data, objData) => {
    if (data && data.length > 0) {
        const dataExist = data.find((item) => item.id === objData.id);
        if (dataExist) {
            const index = data.findIndex((item) => item.id === objData.id);
            data.splice(index, 1, objData);
        } else {
            data.push(objData);
        }
    } else if (!data || data.length < 1) {
        data = [objData];
    }
    return data;
};

/**
 * Helper function that releases data with specified file's id in protectedData
 *   protectDataRoom is an array that holds rooms data of each opened file,
 *   useful when switch file between tabs in 'tool' page
 * @param {*} data
 * @param {*} fileId
 * @returns remained protectedData
 */
export const releaseProtectedData = (protectedData, fileId) => {
    const result = protectedData.find((item) => item.id === fileId);
    return result;
};

/**
 * Helper function that removes data with specified file's id from protectedData
 *   protectDataRoom is an array that holds rooms data of each opened file,
 *   useful when switch file between tabs in 'tool' page
 * @param {*} data
 * @param {*} fileId
 * @returns remained protectedData
 */
export const removeProtectedData = (protectedData, fileId) => {
    const result = protectedData.filter((item) => item.id !== fileId);
    return result;
};

/**
 * Helper for comparing each element of two array
 * @param {*} a source array
 * @param {*} b target array
 * @returns
 */
export function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

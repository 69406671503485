/* eslint-disable react/no-array-index-key */
/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-curly-brace-presence */
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Radio } from 'antd';
import YesNoAnswer from './YesNoAnswer';

/**
 * Render FormItemLHSQuestionCheckbox
 */
export default function FormItemLHSQuestionCheckbox({ fields }) {
    const [questionFalseCeilings, setQuestionFalseCeilings] = useState(fields['qFalseCeilings']?.value);
    const [questionIntermediateFloors, setQuestionIntermediateFloors] = useState(
        fields['qIntermediateFloors']?.value,
    );
    const [questionITServerRoom, setQuestionITServerRoom] = useState(fields['qItServerRoom']?.value);
    const [questionDualDetectorDependency, setQuestionDualDetectorDependency] = useState(
        fields['qDualDetectorDependency']?.value,
    );
    const [questionExtinguishingSystem, setQuestionExtinguishingSystem] = useState(
        fields['qExtinguishingSystem']?.value,
    );
    const [questionShouldFireAlarmSystemBeInstalled, setQuestionShouldFireAlarmSystemBeInstalled] = useState(
        fields['qShouldFireAlarmSystemBeInstalled']?.value,
    );
    const [questionFireLoads, setQuestionFireLoads] = useState(fields['qFireLoads']?.value);

    const { t } = useTranslation()
    // Get an instance of form
    const form = Form.useFormInstance();

    /**
     * When submit form, we can choose the desired value
     * for corresponding field name
     */
    useEffect(() => {
        form.setFieldsValue({ qFalseCeilings: questionFalseCeilings });

        form.setFieldsValue({ qIntermediateFloors: questionIntermediateFloors });

        form.setFieldsValue({ qItServerRoom: questionITServerRoom });

        form.setFieldsValue({ qDualDetectorDependency: questionDualDetectorDependency });

        form.setFieldsValue({ qExtinguishingSystem: questionExtinguishingSystem });

        form.setFieldsValue({ qShouldFireAlarmSystemBeInstalled: questionShouldFireAlarmSystemBeInstalled });

        form.setFieldsValue({ qFireLoads: questionFireLoads });
    }, [
        questionFalseCeilings,
        questionIntermediateFloors,
        questionITServerRoom,
        questionDualDetectorDependency,
        questionExtinguishingSystem,
        questionShouldFireAlarmSystemBeInstalled,
        questionFireLoads,
    ]);

    /**
     * Array of formItem that is needed for rendering
     */
    const formListQuestions = useMemo(
        () => [
            {
                form_item_name: 'qFalseCeilings',
                form_item_question: `${t('are_there_false_ceiling')}`,
                form_item_value: questionFalseCeilings,
                form_item_callback: setQuestionFalseCeilings,
            },
            {
                form_item_name: 'qIntermediateFloors',
                form_item_question: `${t('are_there_intermediate_floors')}`,
                form_item_value: questionIntermediateFloors,
                form_item_callback: setQuestionIntermediateFloors,
            },
            {
                form_item_name: 'qItServerRoom',
                form_item_question:`${t('is_the_room_an_it_eg_server_room')}`,
                form_item_value: questionITServerRoom,
                form_item_callback: setQuestionITServerRoom,
            },
        ],
        [questionFalseCeilings, questionIntermediateFloors, questionITServerRoom],
    );

    /**
     * Render FormItemQuestion
     */
    const renderFormItemQuestion = (formItemName, formItemQuestion, formItemValue, formItemCallback) => (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px' }}>
            <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{formItemQuestion}</p>
            <Form.Item name={formItemName} valuePropName="value" noStyle>
                <Radio.Group onChange={(e) => formItemCallback(e.target.value)} value={formItemValue}>
                    <YesNoAnswer />
                </Radio.Group>
            </Form.Item>
        </div>
    );

    /**
     * Render FormItemQuestionIntermediateFloors
     */
    const renderFormItemQuestionDualDetectorDependency = () => (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px' }}>
            <p
                style={{ fontWeight: 'bold', marginBottom: '5px' }}
            >{t('is_there_a_dual_detector_dependency_in_this_room')}</p>
            <Form.Item name="qDualDetectorDependency" valuePropName="value" noStyle>
                <Radio.Group
                    onChange={(e) => setQuestionDualDetectorDependency(e.target.value)}
                    value={questionDualDetectorDependency}
                >
                    <YesNoAnswer />
                </Radio.Group>
            </Form.Item>
        </div>
    );

    /**
     * Helper for rendering shifted FormItemQuestionIntermediateFloors
     * based on FormItemQuestionIntermediateFloors
     */
    useEffect(() => {
        if (questionDualDetectorDependency > 0) {
            return;
        }
        setQuestionExtinguishingSystem(0);
    }, [questionDualDetectorDependency]);

    /**
     * Render FormItemQuestionIntermediateFloors
     */
    const renderFormItemQuestionExtinguishingSystem = () => (
        <div
            style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px', paddingLeft: '20px' }}
            hidden={questionDualDetectorDependency === 0}
        >
            <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{t('is_there_already_an_extinguishing_system')}</p>
            <Form.Item name="qExtinguishingSystem" valuePropName="value" noStyle>
                <Radio.Group
                    onChange={(e) => setQuestionExtinguishingSystem(e.target.value)}
                    value={questionExtinguishingSystem}
                >
                    <YesNoAnswer />
                </Radio.Group>
            </Form.Item>
        </div>
    );

    /**
     * Render FormItemQuestionShouldFireAlarmSystemBeInstalled
     */
    const renderFormItemQuestionShouldFireAlarmSystemBeInstalled = () => (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px' }}>
            <p
                style={{ fontWeight: 'bold', marginBottom: '5px' }}
            >{t('should_the_fire_alarm_system_be_installed_in_this_room')}</p>
            <Form.Item name="qShouldFireAlarmSystemBeInstalled" valuePropName="value" noStyle>
                <Radio.Group
                    onChange={(e) => setQuestionShouldFireAlarmSystemBeInstalled(e.target.value)}
                    value={questionShouldFireAlarmSystemBeInstalled}
                >
                    <YesNoAnswer />
                </Radio.Group>
            </Form.Item>
        </div>
    );

    /**
     * Helper for rendering shifted FormItemQuestionExtinguishingSystem
     * based on FormItemQuestionDualDetectorDependency
     */
    useEffect(() => {
        if (questionShouldFireAlarmSystemBeInstalled > 0) {
            return;
        }
        setQuestionFireLoads(0);
    }, [questionShouldFireAlarmSystemBeInstalled]);

    /**
     * Render FormItemQuestionIntermediateFloors
     */
    const renderFormItemQuestionFireLoads = () => (
        <div
            style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px', paddingLeft: '20px' }}
            hidden={questionShouldFireAlarmSystemBeInstalled === 0}
        >
            <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{t('are_there_fire_loads_in_the_room')}</p>
            <Form.Item name="qFireLoads" valuePropName="value" noStyle>
                <Radio.Group onChange={(e) => setQuestionFireLoads(e.target.value)} value={questionFireLoads}>
                    <YesNoAnswer />
                </Radio.Group>
            </Form.Item>
        </div>
    );

    // ========================================================================
    // Render FormItemLHSQuestionCheckbox
    // ========================================================================
    return (
        <Col>
            {formListQuestions.map((formItem, index) => (
                <div key={`${formItem.form_item_name}-${index}`}>
                    {renderFormItemQuestion(
                        formItem.form_item_name,
                        formItem.form_item_question,
                        formItem.form_item_value,
                        formItem.form_item_callback,
                    )}
                </div>
            ))}
            {renderFormItemQuestionDualDetectorDependency()}
            {renderFormItemQuestionExtinguishingSystem()}
            {renderFormItemQuestionShouldFireAlarmSystemBeInstalled()}
            {renderFormItemQuestionFireLoads()}
        </Col>
    );
}

import { filesList } from '../files/filesSlice';
import { projectSelector } from '../project/projectSlice';
import { observeOpenViewerPdf, setCurrentCustomer, setCurrentFile } from './viewer-pdf.action';
import { makeViewerPdfCurrentFileIdSelector } from './viewer-pdf.selector';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

// ============================================================================
// Async function
// ============================================================================

export function* watchBaseConditionForLoadingPdf() {
    const { projectOpen } = yield select(projectSelector);
    const { files: fileList } = yield select(filesList);
    const fileId = yield select(makeViewerPdfCurrentFileIdSelector());

    if (!projectOpen) {
        return;
    }

    const currentCustomer = projectOpen?.customer || null;
    if (currentCustomer) {
        yield put(setCurrentCustomer(currentCustomer));
    }

    const filesInProject = projectOpen?.files;
    const filesInList = fileList;

    if (filesInProject && filesInList && filesInList.length === filesInProject.length) {
        const currentFile = filesInProject.find((f) => f.id === fileId);
        yield put(setCurrentFile(currentFile));
    }

    if (filesInProject && filesInList && filesInList.length !== filesInProject.length) {
        const currentFile = filesInList.find((f) => f.id === fileId);
        yield put(setCurrentFile(currentFile));
    }
}

export function* viewerPdfSaga() {
    yield all([takeEvery(observeOpenViewerPdf, watchBaseConditionForLoadingPdf)]);
}

// import { drawTestLine } from "../../utils";

// Page size:
// Width: 420mm - 16.53inch
// Height: 297mmm - 11.69inch

export function draw_A3_FramePage(doc, leftMost, topMost) {
  leftMost += 20;
  topMost += 10;

  // // Horizontal line
  // drawTestLine(doc, {x: 390, y: 0}, {left: 20, top: 10})
  // // Vertical line
  // drawTestLine(doc, {x: 0, y: 287}, {left: 20, top: 10})

  // left: 420(pageWidth) -20(fromLeft) - 10(fromRight) = 390,
  // top: 297(pageHeight) -10(fromTop) - 10(fromBottom) = 277

  const framePageWidth = 390; // should be set based on page size
  const framePageHeight = 277; // should be set based on page size

  const drawingFrame = {
    left: leftMost, // mm
    top: topMost, // mm
    width: framePageWidth, // mm
    height: framePageHeight, // mm
  };
  doc.rect(
    drawingFrame.left,
    drawingFrame.top,
    drawingFrame.width,
    drawingFrame.height
  );

  const leftFramePage = leftMost;
  const topFramePage = topMost;

  return [leftFramePage, topFramePage];
}

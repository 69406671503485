/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import { selectAlarmDevice } from '~/store/alarm-device/alarmDeviceSlice';
import { RoomStatic } from '~/helper/ForgeViewer/speam/rooms/roomStatic';

/**
 * Create and Show Perimeter Dialog
 */
export default function PerimeterDialog({ perimeter, isOpen }) {
    const { unit: unitGlobal } = useSelector(selectAlarmDevice);

    const unit = RoomStatic.unit ?? unitGlobal;
    const { t } = useTranslation();

    const [canOpen, setCanOpen] = useState(false);
    const [pValue, setPValue] = useState(0);

    useEffect(() => {
        setCanOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        const num = Number(perimeter);
        if (!Number.isNaN(num)) {
            setPValue(num);
        }
    }, [perimeter]);

    return (
        <>
            {canOpen && (
                <div
                    key="wire-alarm-perimeter-dialog"
                    style={{
                        width: '250px',
                        height: '88px',
                        backgroundColor: 'white',
                        maxWidth: 'calc(100vw - 48px)',
                        overflow: 'hidden',
                        lineHeight: 1.5715,
                        borderRadius: '2px',
                        boxShadow:
                            '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
                    }}
                >
                    <div style={{ width: '100%', fontSize: '1rem' }}>
                        <section style={{ width: '100%', display: 'flex' }}>
                            <span
                                style={{
                                    borderStyle: 'solid',
                                    borderWidth: '2px',
                                    borderBlockWidth: '2px',
                                    borderColor: 'rgb(235,100,64)',
                                    backgroundColor: 'rgb(235,100,64)',
                                    marginRight: '0.5rem',
                                    height: '102px',
                                }}
                            >
                                {' '}
                            </span>
                            <div style={{ marginTop: '8px', width: '100%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <h3>{t('perimeter')}</h3>
                                    <i
                                        className="fa fa-close"
                                        style={{
                                            fontSize: '16px',
                                            marginRight: '12px',
                                            cursor: 'pointer',
                                            padding: '3px',
                                        }}
                                        onClick={() => setCanOpen((prev) => !prev)}
                                    />
                                </div>
                                <div>
                                    <NumericFormat
                                        value={unit === 'mm' ? (pValue / 1000).toFixed(2) : pValue.toFixed(2)}
                                        suffix={' '.concat(unit === 'mm' ? 'm' : 'm')}
                                        displayType="text"
                                        thousandSeparator={t(',')}
                                        decimalSeparator={t('.')}
                                        style={{ textTransform: 'lowercase', marginLeft: 4 }}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </>
    );
}

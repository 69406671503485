import ViewerPdfType from './viewer-pdf.constant';
import { createAction } from '@reduxjs/toolkit';

/**
 * Actions
 */

/**
 * ACTION:
 * @param {*} state  => base state
 * @param {*} payload
 */
const observeOpenViewerPdf = createAction(ViewerPdfType.OBSERVE_OPEN_VIEWER_PDF);

/**
 * ACTION:
 * @param {*} state  => base state
 * @param {*} payload
 */
const setCurrentCustomer = createAction(ViewerPdfType.SET_CURRENT_CUSTOMER);

/**
 * ACTION:
 * @param {*} state  => base state
 * @param {*} payload
 */
const setCurrentFileId = createAction(ViewerPdfType.SET_CURRENT_FILE_ID);

/**
 * ACTION:
 * @param {*} state  => base state
 * @param {*} payload
 */
const setCurrentFile = createAction(ViewerPdfType.SET_CURRENT_FILE);

/**
 * ACTION:
 * @param {*} state  => base state
 * @param {*} payload
 */
const setProjectInfo = createAction(ViewerPdfType.SET_PROJECT_INFO);

/**
 * ACTION:
 * @param {*} state  => base state
 * @param {*} payload
 */
const clearViewerPdfState = createAction(ViewerPdfType.CLEAR_VIEWER_PDF_STATE);

/**
 * ACTION:
 * @param {*} state  => base state
 * @param {*} payload
 */
const setBottomRightFrameInfo = createAction(ViewerPdfType.SET_BOTTOM_RIGHT_FRAME_INFO);

export {
    observeOpenViewerPdf,
    setProjectInfo,
    setBottomRightFrameInfo,
    setCurrentCustomer,
    setCurrentFile,
    setCurrentFileId,
    clearViewerPdfState,
};

/* eslint-disable consistent-return */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */

import styles from './ModalFile.module.scss';
import { Button, message, Modal, Popconfirm as Onconfirm } from 'antd';
import classNames from 'classnames/bind';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { renderFileStatus } from '~/pages/Home/MainHome/handleFileUI/renderFileStatus';
import {
    clearStateAlarmDevice,
    releaseProtectDataAlarms,
    removeProtectDataAlarm,
    saveProtectDataAlarms,
    selectAlarmDevice,
    setOnDeviceMode,
} from '~/store/alarm-device/alarmDeviceSlice';
import { selectBinList } from '~/store/bin-list/binListSlice';
import { deleteFile, getAllFile, updateFile } from '~/store/files/fileActions';
import { filesList } from '~/store/files/filesSlice';
import { projectSelector } from '~/store/project/projectSlice';
import {
    clearStateSymbolAction,
    releaseProtectedSymbolsDataSymbolAction,
    saveProtectedSymbolsDataSymbolAction,
    selectSymbols,
} from '~/store/symbol/symbolSlice';
import { getFileById } from '~/store/tool/toolAction';
import { isFetchingControlToolAction, selectToolState, setDataToolAction } from '~/store/tool/toolSlice';
import { clearViewerPdfState } from '~/store/viewer-pdf/viewer-pdf.action';
import {
    makeViewerCountRoomSelector,
    makeViewerFloorBoundingBoxSelector,
    makeViewerHaveViewerSelector,
    makeViewerRoomsSelector,
    makeViewerScaleModelSelector,
} from '~/store/viewer/viewer.selectors';
import {
    clearStateViewerAction,
    controlIsFetchingViewerAction,
    releaseProtectDataRoomsByFileIdViewerAction,
    saveProtectDataRoomsViewerAction,
    setViewerLoadSuccessViewerAction,
} from '~/store/viewer/viewer.slice';
import {
    makeHashOfBMZWASelector,
    makeIsShowWireWASelector,
    makeSortedWireAlarmPointsWASelector,
} from '~/store/wire-alarm/wireAlarm.selectors';
import {
    clearStateWireAlarmAction,
    releaseProtectDataWireAlarmAction,
    saveProtectedDataWireAlarmAction,
} from '~/store/wire-alarm/wireAlarm.slice';

const cx = classNames.bind(styles);

export default function ModalFile({ onCloseFile, isOpen, onCancel, title, onLoadFile }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: idParam } = useParams();
    const { t } = useTranslation();

    const { projectOpen } = useSelector(projectSelector);
    const { data, isFetching } = useSelector(selectToolState);
    const { onBinScreen } = useSelector(selectBinList);
    const { files } = useSelector(filesList);
    const { alarms, alarmInfos, countAlarm, unit } = useSelector(selectAlarmDevice);

    const roomsSelector = useSelector(makeViewerRoomsSelector());
    const scaleModelSelector = useSelector(makeViewerScaleModelSelector());
    const countRoomSelector = useSelector(makeViewerCountRoomSelector());
    const haveViewerSelector = useSelector(makeViewerHaveViewerSelector());
    const floorBoundingBoxSelector = useSelector(makeViewerFloorBoundingBoxSelector());

    const hashBMZSelector = useSelector(makeHashOfBMZWASelector());
    const sortedWireAlarmPointsSelector = useSelector(makeSortedWireAlarmPointsWASelector());
    const isShowWireAlarmSelector = useSelector(makeIsShowWireWASelector());

    const { symbolList } = useSelector(selectSymbols);

    const footer = [
        <Button key="submit" type="primary" onClick={onCancel} className="btn-custom">
            {t('close')}
        </Button>,
    ];

    useEffect(() => {
        if (isOpen && files?.length < 1) {
            dispatch(
                getAllFile({
                    id: projectOpen?.id,
                }),
            );
        }
    }, [isOpen, files]);

    const showFile = async (file) => {
        dispatch(controlIsFetchingViewerAction(false));

        const payloadAlarm = {
            id: idParam,
            alarms,
            alarmInfos,
            countAlarm,
            unit,
        };

        const payloadRoom = {
            id: idParam,
            rooms: roomsSelector,
            scaleModel: scaleModelSelector,
            countRoom: countRoomSelector,
            haveViewer: haveViewerSelector,
            floorBoundingBox: floorBoundingBoxSelector,
        };

        const payloadWireAlarm = {
            id: idParam,
            hashOfBMZ: hashBMZSelector,
            isShowWireWire: isShowWireAlarmSelector,
            sortedWireAlarmPoints: sortedWireAlarmPointsSelector,
        };

        const payloadSymbols = {
            id: idParam,
            symbolList,
        };

        onCancel();

        if (!isFetching) {
            const localData = JSON.parse(sessionStorage.getItem('list'));
            dispatch(isFetchingControlToolAction(true));

            await dispatch(saveProtectDataAlarms(payloadAlarm));
            await dispatch(saveProtectDataRoomsViewerAction(payloadRoom));
            await dispatch(saveProtectedDataWireAlarmAction(payloadWireAlarm));
            await dispatch(saveProtectedSymbolsDataSymbolAction(payloadSymbols));

            dispatch(clearStateAlarmDevice());
            dispatch(clearStateViewerAction());
            dispatch(clearStateWireAlarmAction());
            dispatch(clearStateSymbolAction());
            dispatch(clearViewerPdfState());

            const rs = localData.find((d) => d.id === file.id);

            if (!!rs) {
                await dispatch(setDataToolAction(rs));
            } else {
                await dispatch(getFileById(file.id));
            }

            await dispatch(releaseProtectDataAlarms(file.id));
            await dispatch(releaseProtectDataRoomsByFileIdViewerAction(file.id));
            await dispatch(releaseProtectDataWireAlarmAction(file.id));
            await dispatch(releaseProtectedSymbolsDataSymbolAction(file.id));

            // turn off state of forge viewer
            dispatch(setViewerLoadSuccessViewerAction(false));

            dispatch(isFetchingControlToolAction(false));
            dispatch(setOnDeviceMode(false));

            navigate(`/tool/${file.id}`);
        }
    };

    const closeModal = () => {
        message.info({
            content: t('your_file_is_already'),
            duration: 0.5,
        });
        onCancel();
    };

    const confirmDelete = async (id) => {
        // dispatch(
        //     updateFile({
        //         id,
        //         payload: { isDeleted: true },
        //         type: 'delete',
        //         t,
        //     }),
        // );
        await dispatch(
            deleteFile({
                fileId: id,
                t,
            }),
        );
        onCloseFile(id);
    };

    const onSelectFile = (file) => {
        if (file.id === idParam) return closeModal();
        showFile(file);
    };

    return (
        <Modal
            title={title}
            open={isOpen}
            onCancel={onCancel}
            width={600}
            footer={footer}
            bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)', padding: '15px 0' }}
        >
            {files?.map((file) => {
                if (file?.projectId === data?.projectId) {
                    return (
                        <div className={cx('modal-item')} key={file.id}>
                            <div className={cx('modal-item__content')} onClick={() => onSelectFile(file)}>
                                <div className={cx('modal-item__thumnail-group')}>
                                    {renderFileStatus(file, onBinScreen, t)}
                                </div>
                                <span className={cx('modal-item__name', file.id === idParam ? 'active' : '')}>
                                    {file.name}
                                </span>
                            </div>
                            <div>
                                <p className={cx('modal-item__delete')}>
                                    <Onconfirm
                                        title={`${t('are_you_sure_to_delete_this_file')}`}
                                        onConfirm={() => confirmDelete(file.id)}
                                        okText={t('yes')}
                                        cancelText={t('no')}
                                    >
                                        <i className="fa-solid fa-trash-can" />
                                    </Onconfirm>
                                </p>
                            </div>
                        </div>
                    );
                }
            })}
        </Modal>
    );
}

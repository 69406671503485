/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */

import styles from './RoomItemSettingForm.module.scss';
import { Form, Input, Popconfirm } from 'antd';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

function EyeIcon({ hideContent, onClick, field }) {
    return (
        <span key={`${field.nf_new_field}--child-eye`} style={{ marginRight: '8px' }}>
            <i
                key={`${field.nf_new_field}--child-icon-eye-close`}
                className={cx(
                    'icon_action',
                    hideContent ? 'fa-sharp fa-solid fa-eye-slash fa-xs' : 'fa-solid fa-eye fa-xs',
                )}
                onClick={() => {
                    onClick((prev) => !prev);
                }}
            />
        </span>
    );
}

function TrashIcon({ onClick, field }) {
    const { t } = useTranslation();

    return (
        <span key={`${field.nf_new_field}--child-trash`}>
            <Popconfirm
                title={t('are_you_sure')}
                onConfirm={() => onClick(field.nf_new_field)}
                okText={t('yes')}
                cancelText={t('no')}
            >
                <i
                    key={`${field.nf_new_field}--child-icon-trash`}
                    className={cx('fa-solid fa-trash fa-xs', 'icon_action')}
                />
            </Popconfirm>
        </span>
    );
}

export default function ChildFormItemOptionalFieldInput({
    setFieldValue,
    field,
    onDeleteField,
    // errorMessage,
    onChildErrorMessage,
    formInstance,
}) {
    const [hideContent, setHideContent] = useState(field?.nf_hide_content ?? false);
    const [errMsg, setErrMsg] = useState(undefined);

    const [inputValue, setInputValue] = useState(field?.nf_value);

    const { t } = useTranslation();

    const handleClickOnTrashIcon = (fieldName) => {
        onDeleteField(fieldName);
    };
     const handleOnChangeInput = (value) => {
        setInputValue(value);
    };

    useEffect(() => {
        if (!inputValue || inputValue.length === 0) {
            onChildErrorMessage(`${t('please_type_something')}`)
            setErrMsg(`${t('please_type_something')}`);
        } else {
            onChildErrorMessage(undefined)
            setErrMsg(undefined);

            const hasFormInstance = Boolean(formInstance);

            hasFormInstance
                ? formInstance.setFieldValue(`${field?.nf_new_field}`, {
                      value: inputValue || field?.nf_value,
                      eye: field?.nf_hide_content,
                  })
                : setFieldValue(`${field.nf_new_field}`, {
                      value: inputValue || field?.nf_value,
                      eye: field?.nf_hide_content,
                  });
        }
    }, [inputValue, errMsg]);

    return (
        <div key={`${field.nf_new_field}--child`}>
            <Form.Item
                key={`${field.nf_new_field}--child-fi`}
                name={field.nf_new_field}
                valuePropName="value"
                initialValue={field.nf_value}
                noStyle
            >
                <Input key={`${field.nf_new_field}--child-fi-in1`} hidden value={field?.nf_value} />
            </Form.Item>

            {field?.nf_hide_content && (
                <Input
                    key={`${field.nf_new_field}--child-in2`}
                    placeholder={`${t(`please_type_something`).toLowerCase()}...`}
                    onChange={(e) => {
                        handleOnChangeInput(e.target.value);
                    }}
                    defaultValue={field.nf_value}
                    type={hideContent ? 'password' : 'text'}
                    suffix={[
                        <div key={`${field.nf_new_field}--child-actions`}>
                            <EyeIcon field={field} hideContent={hideContent} onClick={setHideContent} />
                            <TrashIcon field={field} onClick={handleClickOnTrashIcon} />
                        </div>,
                    ]}
                />
            )}

            {!field?.nf_hide_content && (
                <Input
                    key={`${field.nf_new_field}--child-in2`}
                    placeholder={`${t(`please_type_something`).toLowerCase()}...`}
                    onChange={(e) => {
                        handleOnChangeInput(e.target.value);
                    }}
                    defaultValue={field.nf_value}
                    suffix={[
                        <div key={`${field.nf_new_field}--child-actions`}>
                            <TrashIcon field={field} onClick={handleClickOnTrashIcon} />
                        </div>,
                    ]}
                />
            )}

            <div key={`${field.nf_new_field}--child-err`}>
                {errMsg && (
                    <p
                        key={`${field.nf_new_field}--child-err-msg`}
                        style={{ textTransform: 'none', color: 'red', fontSize: '0.75rem' }}
                    >
                        {errMsg}
                    </p>
                )}
            </div>
        </div>
    );
}

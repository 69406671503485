export const MODAL = {
  FIRE_COMPARTMENTS: 'fire_compartments',
  ALARM_AREAS: 'alarm_areas',
};

export const getCurrentFSChildData = (values, floorSettingsChildData) => {
  let title = '';

  if (values?.title === MODAL.FIRE_COMPARTMENTS) {
      title = MODAL.FIRE_COMPARTMENTS;
  }

  if (values?.title === MODAL.ALARM_AREAS) {
      title = MODAL.ALARM_AREAS;
  }

  const foundChildByTitle = [...floorSettingsChildData].find((item) => item.title === title);
  const foundChildData = foundChildByTitle.data;
  const element = foundChildData.find((item) => item.id === values?.itemId);

  return element;
};

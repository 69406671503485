/* eslint-disable no-lonely-if */
/* eslint-disable react/prop-types */

import styles from './SearchResult.module.scss';
import { Button, Image, Menu } from 'antd';
import classNames from 'classnames/bind';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { image } from '~/assets';
import { FolderSpeam } from '~/assets/images/svgFile';
import { selectBinList } from '~/store/bin-list/binListSlice';
import { searchAction } from '~/store/files/fileActions';
import { filesList, setSearchResult } from '~/store/files/filesSlice';
import { setTabSelected } from '~/store/project/projectSlice';

const cx = classNames.bind(styles);

function SearchResult({ data = [], value, onChangeValue, closeDrop, tabs, onDone, onMouseLeave }) {
    const [result, setResult] = useState([]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { onBinScreen } = useSelector(selectBinList);
    const { searchResult } = useSelector(filesList);

    const handleView = (item) => {
        console.log(item);
        if (tabs) {
            dispatch(setTabSelected(item));
            onDone();
        } else {
            if (onBinScreen) return;
            if (!item.isProject) {
                navigate(`/tool/${item.id}`);
            } else {
                navigate(`/project/${item.id}`);
            }
        }
        setResult([]);
        onChangeValue('');
        closeDrop();
    };

    const itemsSearch = [
        {
            key: 0,
            label: (
                <div
                    style={{
                        borderBottom: '1px solid var(--text-grey)',
                        width: '96%',
                        margin: '0 auto',
                        textAlign: 'center',
                        fontSize: '1rem',
                        fontWeight: 500,
                        color: 'var(--primary-color)',
                        opacity: '0.9',
                        cursor: 'default',
                    }}
                >
                    {tabs && <>{t('search_tabs')}</>}
                    {!tabs && <>{t('search_your_file')}</>}
                </div>
            ),
            disabled: true,
        },
    ];

    useEffect(() => {
        if (tabs) {
            const filterArr = data.filter((item) => item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
            const rsSearch = filterArr.map((item, index) => ({
                key: index + 1,
                label: (
                    <div className={cx('search-item')} onClick={() => handleView(item)}>
                        <div className={cx('search-item__thumnail-group')}>
                            <Image
                                width="100%"
                                height="100%"
                                src={tabs ? image.pdf : item.thumbnail}
                                preview={false}
                                fallback={image.logo}
                            />
                        </div>
                        <p className={cx('search-item__name')}>{item.name}</p>
                    </div>
                ),
            }));

            setResult([...itemsSearch, ...rsSearch] || [...itemsSearch]);
        } else {
            if (value) {
                dispatch(searchAction(value));
            } else {
                dispatch(setSearchResult([]));
                setResult(itemsSearch);
            }
        }
    }, [value, tabs]);

    useEffect(() => {
        if (!tabs && searchResult.length > 0) {
            const rsSearch = searchResult.map((item) => {
                return {
                    key: item.id,
                    label: (
                        <Button className={cx('search-item')} onClick={() => handleView(item)}>
                            <div className={cx('search-item__thumnail-group')}>
                                {(item.thumbnail && (
                                    <Image
                                        width="100%"
                                        height="100%"
                                        src={item?.thumbnail}
                                        preview={false}
                                        fallback={!item.isProject && image.logo}
                                    />
                                )) ||
                                    (item.isProject ? (
                                        <FolderSpeam width="100%" height="100%" />
                                    ) : (
                                        <Image width="100%" height="100%" src={image.logo} preview={false} />
                                    ))}
                            </div>
                            <p className={cx('search-item__name')}>{item?.name}</p>
                        </Button>
                    ),
                };
            });
            setResult([...itemsSearch, ...rsSearch] || [...itemsSearch]);
        }
    }, [tabs, searchResult]);

    return (
        <div className={cx('search-result-container')}>
            <Menu onMouseLeave={onMouseLeave} items={result} className={cx('search-outside')} />
        </div>
    );
}

export default SearchResult;

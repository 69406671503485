import { unwrapResult } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LoginMaster from '~/components/LoginMaster';
import RegisterComponent from '~/components/LoginMaster/Register';
import { signUpAuthAction } from '~/store/auth/authAction';

export default function Register() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('register');
    }, [t]);

    const success = (emailResponse) => {
        Modal.success({
            title: t('success'),
            content: t('successful_account_registration_log_in_now'),
            onOk() {
                navigate(`/login/${emailResponse}`);
            },
            onCancel() {},
        });
    };

    const submitForm = (values) => {
        setIsLoading(true);
        dispatch(signUpAuthAction(JSON.stringify(values)))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                setIsLoading(false);
                success(originalPromiseResult?.result?.email);
            })
            .catch((rejectedValueOrSerializedError) => {
                setIsLoading(false);
                let message = rejectedValueOrSerializedError?.errors[0];

                if (message === 'Email is already registered!') {
                    message = `${t('email_is_already_registered!')}`
                }

                setErrorMessage(message || rejectedValueOrSerializedError.name);
            });
    };

    return (
        <LoginMaster>
            <RegisterComponent
                submitForm={submitForm}
                submitFailed={() => setIsLoading(false)}
                isLoading={isLoading}
                errorMessage={errorMessage}
            />
        </LoginMaster>
    );
}

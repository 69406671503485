export function draw_A4_MainContent(doc, leftMost, topMost, mainContent, compressOption) {
  // // Config drawing size
  // // frame for main content based on ratio = 16 / 9
  // const width = 220
  // const height = width * 9 / 16

  // const drawingInfo = {
  //   left: leftMost + 23, // mm
  //   top: topMost + 5, // mm
  //   width: Number(mainContent?.width) + 2, // mm
  //   height: Number(mainContent?.height) + 2, // mm
  //   // width,
  //   // height,
  // };
  // // Set color of drawing to Red
  // doc.setDrawColor(255, 0, 0)
  // doc.rect(
  //   drawingInfo.left,
  //   drawingInfo.top,
  //   drawingInfo.width,
  //   drawingInfo.height
  // );
  // // Reset color of drawing
  // doc.setDrawColor(0, 0, 0)

  doc.addImage(
    mainContent?.src,
    "WEBP",
    leftMost + 23,
    topMost + 5,
    mainContent?.width,
    mainContent?.height,
    undefined,
    compressOption || 'FAST' // compression of image
  );
}

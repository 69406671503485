/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-const */
/* eslint-disable no-nested-ternary */

import Sidebar from '../Sidebar';
import ListFile from './ListFile';
import styles from './MainHome.module.scss';
import ProjectList from './Project/ProjectList';
import Recycle from './Recycle/Recycle';
import SearchResult from './SearchResult/SearchResult';
import { Breadcrumb, Button, Checkbox, Drawer, Dropdown, Input, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import classNames from 'classnames/bind';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ModalControll from '~/components/ModalControll/ModalControll';
import useDebounce from '~/helper/hooks/useDebounce';
import { getBinFile } from '~/store/bin-list/binListActions';
import { selectBinList, setSubListBinListAction } from '~/store/bin-list/binListSlice';
import { filesList, resetSup, setDeleteFile, setFileModal, setFiles } from '~/store/files/filesSlice';
import { getGeneralList } from '~/store/generalData/generalAction';
import { generalSelector } from '~/store/generalData/generalSlice';
import { openModalModalAction } from '~/store/modal/ModalSlice';
import { getOneProject, getProjectList } from '~/store/project/projectAction';
import { controlReset, projectSelector, setProject, setSubProjectList } from '~/store/project/projectSlice';

const { Search } = Input;
const cx = classNames.bind(styles);

function MainHome() {
    const [dataFiles, setDataFiles] = useState([]);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [valueSearch, setValueSearch] = useState('');
    const [checkAll, setCheckAll] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [openSearchResult, setOpenSearchResult] = useState(false);

    const param = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const debouncedValue = useDebounce(valueSearch, 400);

    const { projectOpen, projectList, subProjectList, resetProject, isFetchingGetOne, isFetchingProject } =
        useSelector(projectSelector);
    const { files, deleteFiles, isErrorFile } = useSelector(filesList);
    const { subList, onBinScreen, isErrorBin } = useSelector(selectBinList);
    const { customerList, operatorList, locationsList } = useSelector(generalSelector);

    useEffect(() => {
        if (!onBinScreen) {
            setCheckAll(false);
            if (param.id) {
                if (files !== null) {
                    setDataFiles(() => [...files].map((file) => ({ ...file, isCheck: false })));
                }
            } else if (!param.id) {
                if (!projectList) {
                    dispatch(getProjectList(false));
                } else {
                    setData([...projectList]);
                }

                if (projectList && (projectList?.length > 0 || resetProject)) {
                    setData(() => [...projectList].map((project) => ({ ...project, isCheck: false })));
                    dispatch(controlReset(false));
                }
            }
        }
    }, [projectList, files, onBinScreen, resetProject, param.id]);

    useEffect(() => {
        if (!param.id) {
            dispatch(setProject(null));
        }

        if (!customerList || !operatorList || !locationsList) {
            dispatch(getGeneralList({ t }));
        }

        dispatch(setSubProjectList(null));
        dispatch(setDeleteFile(null));
        dispatch(setSubListBinListAction(null));
        setCheckAll(false);
    }, [param.id, projectList?.length, onBinScreen, customerList, operatorList, locationsList]);

    useEffect(() => {
        if (param.id) {
            if (!!deleteFiles && files?.length !== 0 && files?.length === deleteFiles?.length) {
                setCheckAll(true);
            } else {
                setCheckAll(false);
            }
        } else if (!param.id) {
            if (!!subProjectList && projectList?.length !== 0 && projectList?.length === subProjectList?.length) {
                setCheckAll(true);
            } else if (!subProjectList || subProjectList?.length === 0) {
                setCheckAll(false);
            } else {
                setCheckAll(false);
            }
        }
    }, [projectList?.length, subProjectList?.length, files?.length, deleteFiles?.length]);

    useEffect(() => {
        if (!param.id && projectList && projectList?.length < 1) {
            setDisabled(true);
        } else if (param.id && files && files?.length < 1) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [param.id, projectList, files]);

    useEffect(() => {
        if (isErrorBin || isErrorFile) {
            Modal.error({
                title: `${t('connection_errors')}!`,
                content: `${t('cant_connect_to_the_server_please_try_again')}!`,
                onOk() {
                    navigate('/not-found-404');
                },
            });
        }
    }, [isErrorFile, isErrorBin]);

    const handleCheck = useCallback((id, status, list, subListing, type) => {
        let newSubList = [];
        const newList = [...list].map((item) => {
            const itemTemp = { ...item };
            if (itemTemp.id === id) {
                let current = itemTemp.isCheck;
                itemTemp.isCheck = !current;
            }
            return itemTemp;
        });

        if (status) {
            if (subListing) {
                newSubList = [...subListing, id];
            } else {
                newSubList.push(id);
            }
        } else {
            newSubList = [...subListing].filter((item) => item !== id);
        }

        if (type === 'project') {
            setData(newList);
            dispatch(setSubProjectList(newSubList));
        }
        if (type === 'files') {
            setDataFiles(newList);
            dispatch(setDeleteFile(newSubList));
        }
    }, []);

    const handleChooseAll = () => {
        const current = checkAll;
        setCheckAll(!checkAll);

        if (!param.id) {
            if (projectList?.length > 0) {
                const newData = data.map((project) => ({ ...project, isCheck: !current }));
                if (current) {
                    dispatch(setSubProjectList(null));
                } else {
                    const payload = data.map((project) => project.id);
                    dispatch(setSubProjectList(payload));
                }
                setData(newData);
            }
        } else if (param.id) {
            if (files?.length > 0) {
                const newData = dataFiles.map((file) => ({ ...file, isCheck: !current }));
                setDataFiles(newData);
                if (current) {
                    dispatch(setDeleteFile(null));
                } else {
                    const payload = dataFiles.map((file) => file.id);
                    dispatch(setDeleteFile(payload));
                }
            }
        }
    };

    const handleRefresh = () => {
        dispatch(setFiles(null));
        setCheckAll(false);
        if (param.id) {
            dispatch(resetSup());
            if (onBinScreen) {
                dispatch(getBinFile());
            } else if (!onBinScreen) {
                dispatch(getOneProject({ id: param.id, isDeleted: false, dispatch, t }));
            }
        } else if (!param.id) {
            dispatch(setSubProjectList(null));
            if (onBinScreen) {
                dispatch(getProjectList(true));
            } else if (!onBinScreen) {
                dispatch(getProjectList(false));
            }
        }
    };

    const handleDeleteMany = useCallback(() => {
        if (subProjectList?.length > 0 || subList?.length > 0 || deleteFiles?.length > 0) {
            dispatch(openModalModalAction({ title: 'deleteAll' }));
        } else return;
    }, [subProjectList, subList, deleteFiles]);

    const setFileOpenModal = useCallback(
        (e, file) => {
            if (e) {
                if (checkAll) handleChooseAll();
                dispatch(setFileModal(file));
            } else {
                dispatch(setFileModal(null));
            }
        },
        [checkAll, data, dataFiles],
    );

    const handleChangeInputSearch = (e) => {
        setValueSearch(e.target.value);
        setOpenSearchResult(e.target.value !== '');
    };

    return (
        <div className={cx('main-home-container')}>
            <div className={cx('main-home-head')}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Breadcrumb
                        style={{
                            fontSize: '1.2rem',
                            fontWeight: 500,
                            maxWidth: 250,
                        }}
                    >
                        <Breadcrumb.Item
                            onClick={() => {
                                setCheckAll(false);
                                dispatch(setProject(null));
                                dispatch(setFiles([]));
                                setDataFiles([]);
                            }}
                        >
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    navigate('/');
                                    handleRefresh();
                                }}
                            >
                                {t('project_list')}
                            </span>
                        </Breadcrumb.Item>
                        {projectOpen && param.id && <Breadcrumb.Item>{projectOpen?.name}</Breadcrumb.Item>}
                    </Breadcrumb>
                    <span style={{ marginLeft: 10, marginBottom: 6 }}>
                        <Tooltip title={t('select_all')} placement="right">
                            <Checkbox checked={checkAll} onClick={handleChooseAll} disabled={disabled} />
                        </Tooltip>
                    </span>
                </div>

                <div className={cx('action-group')}>
                    <div className={cx('action-group__search')} id="test-search">
                        <Dropdown
                            overlay={
                                <SearchResult
                                    onMouseLeave={() => setOpenSearchResult(false)}
                                    onChangeValue={(value) => setValueSearch(value)}
                                    closeDrop={() => setOpenSearchResult(false)}
                                    value={debouncedValue}
                                />
                            }
                            destroyPopupOnHide
                            open={openSearchResult}
                        >
                            <Search
                                placeholder={`${t('search')}...`}
                                value={valueSearch}
                                onChange={handleChangeInputSearch}
                                allowClear
                                className={cx('action-group__search-input')}
                                onFocus={() => setOpenSearchResult(true)}
                                onBlur={() => setOpenSearchResult(false)}
                                onMouseEnter={() => {
                                    if (valueSearch !== '') setOpenSearchResult(true);
                                }}
                            />
                        </Dropdown>
                    </div>
                    <Tooltip title={t('refresh')} placement="bottom">
                        <Button
                            className={cx('action-group__btn-refresh')}
                            onClick={handleRefresh}
                            icon={
                                (!isFetchingProject || !isFetchingGetOne) && (
                                    <i className="fa-regular fa-arrows-rotate" />
                                )
                            }
                            loading={isFetchingProject || isFetchingGetOne}
                        />
                    </Tooltip>

                    <Button
                        className={cx('action-group__btn-delete-all')}
                        onClick={handleDeleteMany}
                        icon={<i className="fa-solid fa-trash" />}
                    >
                        {t('delete_many')}
                    </Button>

                    <button className={cx('action-group__menu-btn', open && 'border')} onClick={() => setOpen(true)}>
                        <i className="fa-solid fa-bars" style={{ fontSize: 20, float: 'right' }} />
                    </button>
                </div>
            </div>
            {param.id === 'recycle-bin' ? (
                <Recycle />
            ) : (
                (param.id && (
                    <ListFile dataFiles={dataFiles} onCheck={handleCheck} onSetFileModal={setFileOpenModal} />
                )) || <ProjectList dataProject={data} onCheck={handleCheck} onSetFileModal={setFileOpenModal} />
            )}
            <ModalControll />

            <Drawer title={t('menu')} placement="right" onClose={() => setOpen(false)} open={open}>
                <Sidebar />
            </Drawer>
        </div>
    );
}

export default memo(MainHome);

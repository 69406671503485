/* eslint-disable arrow-body-style */
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './symbolSlice';

/**
 * SELECTOR: current state of symbol slice
 * @param {*} state
 * @returns
 */
export const selectSymbolDomain = (state) => {
    return state.symbol || initialState;
};

/**
 * SELECTOR: state of symbolList
 * @returns
 */
export const makeSymbolListSymbolSelector = () => createSelector([selectSymbolDomain], (symbol) => symbol.symbolList);

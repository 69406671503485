import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Render RadioYesNo
 */
export default function YesNoAnswer() {
    const { t } = useTranslation();

    return (
        <>
            <Radio value={0}>{t('no')}</Radio>
            <Radio value={1}>{t('yes')}</Radio>
        </>
    );
}

import { Progress } from 'antd';
import { memo } from 'react';

function ProgressBar({ percent }) {
    return (
        <Progress
            strokeColor={{
                '0%': '#f56f69',
                '100%': '#f44c45',
            }}
            percent={percent}
        />
    );
}

export default memo(ProgressBar);

/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
/* eslint-disable yoda */
/* eslint-disable import/no-cycle */

import {
    createProject,
    deleteManyProject,
    deleteProject,
    getDefaultName,
    getOneProject,
    getProjectList,
    getTabs,
    updateProject,
} from './projectAction';
import { createSlice } from '@reduxjs/toolkit';

const projectSlice = createSlice({
    name: 'project',
    initialState: {
        tabs: null,
        tabSelected: null,
        projectOpen: null,
        projectList: null,
        projectTrashList: [],
        subProjectList: null,
        tabsFetching: false,
        isFetchingProject: false,
        isFetchingGetOne: false,
        isErrorProject: null,
        isSuccessProject: false,
        isSuccessGetOneProject: false,
        resetProject: false,
        defaultName: null,
    },
    reducers: {
        setTabs: (state, { payload }) => {
            state.tabs = payload;
        },
        setTabSelected: (state, { payload }) => {
            state.tabSelected = payload;
        },
        controlTabsFetching: (state, { payload }) => {
            state.tabsFetching = payload;
        },
        addToProjectList: (state, { payload }) => {
            state.projectList.unshift(payload);
        },
        setSubProjectList: (state, { payload }) => {
            state.subProjectList = payload;
        },
        setProject: (state, { payload }) => {
            state.projectOpen = payload;
        },

        controlIsFetching: (state, { payload }) => {
            state.isFetchingProject = payload;
        },
        controlReset: (state, { payload }) => {
            state.resetProject = payload;
        },
        clearDefaultName: (state) => {
            state.defaultName = null;
        },
    },
    extraReducers: {
        [getTabs.pending]: (state) => {
            state.tabsFetching = true;
        },
        [getTabs.fulfilled]: (state, { payload }) => {
            state.tabsFetching = false;
            state.tabs = payload;
        },
        [getTabs.rejected]: (state) => {
            state.tabsFetching = true;
        },
        [getDefaultName.pending]: (state) => {
            state.tabsFetching = true;
        },
        [getDefaultName.fulfilled]: (state, { payload }) => {
            state.tabsFetching = false;
            state.defaultName = payload;
        },
        [getDefaultName.rejected]: (state) => {
            state.tabsFetching = false;
            state.defaultName = null;
        },
        [createProject.pending]: (state) => {
            state.isFetchingProject = true;
        },
        [createProject.rejected]: (state) => {
            state.isFetchingProject = false;
        },
        [updateProject.pending]: (state) => {
            state.isFetchingProject = true;
        },
        [updateProject.fulfilled]: (state, { payload }) => {
            state.isFetchingProject = false;
            state.projectOpen = null;
            const index = state.projectList.findIndex((project) => project.id === payload.id);
            state.projectList.splice(index, 1, payload);
        },
        [updateProject.rejected]: (state) => {
            state.projectOpen = null;
            state.isFetchingProject = true;
        },
        [getProjectList.pending]: (state) => {
            state.isFetchingProject = true;
        },
        [getProjectList.fulfilled]: (state, { payload }) => {
            state.isFetchingProject = false;
            state.isSuccessProject = true;
            if (payload.type) {
                state.projectTrashList = payload.data;
            } else {
                state.projectList = payload.data;
            }
        },
        [getProjectList.rejected]: (state, { payload }) => {
            state.isFetchingProject = false;
            state.isSuccessProject = false;
            state.isErrorProject = payload;
        },
        [getOneProject.pending]: (state) => {
            state.isFetchingGetOne = true;
            state.isSuccessGetOneProject = false;
        },
        [getOneProject.fulfilled]: (state, { payload }) => {
            state.isFetchingGetOne = false;
            state.isSuccessProject = true;
            state.isSuccessGetOneProject = true;
            state.projectOpen = payload;
        },
        [getOneProject.rejected]: (state, { payload }) => {
            state.isFetchingGetOne = false;
            state.isErrorProject = payload;
            state.isSuccessGetOneProject = false;
        },
        [deleteProject.pending]: (state) => {
            state.isFetchingProject = true;
        },
        [deleteProject.fulfilled]: (state, { payload }) => {
            state.projectList = state.projectList.filter((project) => project.id !== payload);
            state.isFetchingProject = false;
        },
        [deleteProject.rejected]: (state) => {
            state.isFetchingProject = false;
        },
        [deleteManyProject.pending]: (state) => {
            state.isFetchingProject = true;
        },
        [deleteManyProject.fulfilled]: (state, { payload }) => {
            state.projectList = payload;
            state.subProjectList = null;
            state.isFetchingProject = false;
        },
        [deleteManyProject.rejected]: (state) => {
            state.subProjectList = null;
            state.isFetchingProject = false;
        },
    },
});

const { actions, reducer } = projectSlice;
export const projectSelector = (state) => state.project;
export const {
    setTabs,
    setTabSelected,
    controlTabsFetching,
    addToProjectList,
    setProject,
    setSubProjectList,
    controlIsFetching,
    controlReset,
    clearDefaultName,
} = actions;
export default reducer;

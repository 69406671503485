/* eslint-disable consistent-return */
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { loadExtensionEdit2D } from '~/helper/ForgeViewer/forge-viewer-tool';

/**
 * Initialize Forge viewer for the first time
 *
 * @param {*} currentUrn
 * @param {*} currentToken
 */
const initializeViewer = (currentUrn, currentToken, language) => {
    const viewerOptions = {
        env: 'AutodeskProduction',
        getAccessToken(onTokenReady) {
            const timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
            onTokenReady(currentToken, timeInSeconds);
        },
        api: 'derivativeV2',
        language,
    };
    const viewerContainer = document.getElementById('forgeViewer');
    const viewer = new Autodesk.Viewing.Private.GuiViewer3D(viewerContainer, { extensions: ['Autodesk.Edit2D'] });

    Autodesk.Viewing.Initializer(viewerOptions, () => {
        /**
         * Disable Spin Loader of Autodesk and Replace by "SPEAM" logo
         */
        viewer.addEventListener(Autodesk.Viewing.VIEWER_INITIALIZED, () => {
            // in the DOM, it's html tag is:
            // <div id="forge-spinner"...>...</div>
            const spinnerContainer = viewer._loadingSpinner.domElement;

            // Remove 2 children element inside:
            // <svg> tag: that represents for loading logo
            // <img> tag: that represents for Autodesk logo
            while (spinnerContainer.hasChildNodes()) {
                spinnerContainer.removeChild(spinnerContainer.lastChild);
            }

            // Put 'SPEAM" logo inside 'spinnerContainer'
            const speamLogo = document.createElement('img');
            speamLogo.src = `${window.location.origin}/assets/img/logo_new.png`;
            speamLogo.alt = 'speam-logo';
            speamLogo.id = 'speam-logo-forge-loading';

            spinnerContainer.appendChild(speamLogo);
        });

        /**
         * Show viewer if loading file is successful
         * @param {*} viewerDocument
         */
        function onDocumentLoadSuccess(viewerDocument) {
            // viewerDocument is an instance of Autodesk.Viewing.Document
            const defaultModel = viewerDocument.getRoot().getDefaultGeometry();

            viewer
                .loadDocumentNode(viewerDocument, defaultModel)
                .then(() => {
                    // Set global variable 'window.viewer' when then document is successful loaded
                    window.viewer = viewer;
                })
                .then(() => loadExtensionEdit2D()) // Loading extension that helps for editing viewer
                .then(() => {
                    // Notify by a custom event that the extension is loaded successfully
                    // that event is created from 'EventTool' component
                    window.dispatchEvent(new CustomEvent('loadExtensionEdit2DSuccess', { detail: true }));
                });
        }

        /**
         * Show errors from viewer if loading file have some problems
         * @param {*} viewerDocument
         */
        function onDocumentLoadFailure() {
            notification.error({
                message: 'Failed fetching Forge manifest.',
            });
        }

        /**
         * Check Started Code to make sure that viewer is created
         */
        const startedCode = viewer.start(undefined, undefined, undefined, undefined, viewerOptions);
        if (startedCode > 0) {
            // TODO: show modal error
            notification.error({
                message: 'Failed to create a Viewer: WebGL not supported.',
            });
            return;
        }

        /**
         * Start Loading the Document here
         */
        Autodesk.Viewing.Document.load(`urn:${currentUrn}`, onDocumentLoadSuccess, onDocumentLoadFailure);
    });
};

// ===========================================================================
// App Forge Viewer component
// ===========================================================================
function AppViewer({ urn, token, pathExternalExtensions }) {
    const { i18n } = useTranslation();

    // useEffect(() => {
    // if (appViewer) {
    //     (pathExternalExtensions || []).map((path) => {
    //         load(path, (err, script) => {
    //             if (err) {
    //                 console.log('err load measure');
    //             } else {
    //                 console.log('load script ok', script.src);
    //             }
    //         });
    //     });
    //     var tool = appViewer.getExtension('Autodesk.Measure');
    //     console.log('MeasureExtension: ', tool);
    // }
    // }, []);

    useEffect(() => {
        // When on mount start initializing viewer

        initializeViewer(urn, token, i18n.language);

        // When on un-mount destroy viewer
        return () => {
            if (window.viewer) {
                window.edit2d = null;
                window.layer = null;
                window.tools = null;
                window.selection = null;
                window.undoStack = null;
                window.roomLabel = null;
                window.viewer.finish();
                window.viewer = null;
            }
            Autodesk.Viewing.shutdown();
        };
    }, [i18n.language]);

    useEffect(() => {
        window?.viewer?.toolbar?.setDisplay('none');
    }, []);

    return <div id="forgeViewer" />;
}

export default AppViewer;

/*
eslint
  no-undef: 0,
*/

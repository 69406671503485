/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { Divider, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import ChildFormItemOptionalFieldInput from './ChildFormItemOptionalFieldInput';

export default function ParentFormItemAdditionalFieldsInput({
    newFields,
    setFieldValue,
    onChildErrorMessage,
}) {
    const [newFieldsData, setNewFieldsData] = useState([]);

    const { t } = useTranslation();
    // Get an instance of form
    const form = Form.useFormInstance();

    useEffect(() => {
        setNewFieldsData([...newFields]);
    }, [newFields]);

    const handleDeleteField = (fieldName) => {
        setNewFieldsData((prev) => [...prev]?.filter((f) => f.nf_new_field !== fieldName));

        const nFields = form.getFieldValue('newFields');
        if (nFields && nFields?.length > 0) {
            const remainedNewField = nFields.filter((rf) => rf.nf_new_field !== fieldName);
            form.setFieldsValue({ newFields: remainedNewField });
        }
    };

    return (
        <div style={{ display: 'flex', flexFlow: 'column', width: '100%' }}>
            <Divider style={{ marginBlock: '4px' }}>{t('additional_fields')}</Divider>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 8,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}
            >
                {newFieldsData.map((field) => (
                    <div key={`${field.nf_new_field}-parent`} style={{ flexShrink: '0', width: '183px' }}>
                        <p key={`${field.nf_new_field}-parent-label`} style={{ textTransform: 'capitalize' }}>
                            {field.nf_new_field}
                        </p>
                        <ChildFormItemOptionalFieldInput
                            key={`${field.nf_new_field}-parent-child-fi`}
                            setFieldValue={setFieldValue}
                            field={field}
                            onDeleteField={handleDeleteField}
                            onChildErrorMessage={onChildErrorMessage}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

/* eslint-disable react/no-array-index-key */
/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-curly-brace-presence */
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Radio } from 'antd';
import YesNoAnswer from './YesNoAnswer';

/**
 * Render FormItemRHSQuestionCheckbox
 */
export default function FormItemRHSQuestionCheckbox({ fields }) {
    const [questionDisturbances, setQuestionDisturbances] = useState(fields['qDisturbances']?.value);
    const [questionHindrances, setQuestionHindrances] = useState(fields['qHindrances']?.value);
    const [questionExplosive, setQuestionExplosive] = useState(fields['qExplosive']?.value);

    const { t } = useTranslation()
    // Get an instance of form
    const form = Form.useFormInstance();

    /**
     * When submit form, we can choose the desired value
     * for corresponding field name
     */
    useEffect(() => {
        form.setFieldsValue({ qDisturbances: questionDisturbances });

        form.setFieldsValue({ qHindrances: questionHindrances });

        form.setFieldsValue({ qExplosive: questionExplosive });
    }, [questionDisturbances, questionHindrances, questionExplosive]);

    /**
     * Array of formItem that is needed for rendering
     */
    const formListQuestions = useMemo(
        () => [
            {
                form_item_name: 'qDisturbances',
                form_item_question: `${t('are_there_any_disturbances_dust_aerosol')}`,
                form_item_value: questionDisturbances,
                form_item_callback: setQuestionDisturbances,
            },
            {
                form_item_name: 'qHindrances',
                form_item_question: `${t('are_there_any_hindrances_during_the_installation')}`,
                form_item_value: questionHindrances,
                form_item_callback: setQuestionHindrances,
            },
            {
                form_item_name: 'qExplosive',
                form_item_question: `${t('is_the_room_explosive')}`,
                form_item_value: questionExplosive,
                form_item_callback: setQuestionExplosive,
            },
        ],
        [questionDisturbances, questionHindrances, questionExplosive],
    );

    /**
     * Render FormItemQuestion
     */
    const renderFormItemQuestion = (formItemName, formItemQuestion, formItemValue, formItemCallback) => (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px' }}>
            <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{formItemQuestion}</p>
            <Form.Item name={formItemName} valuePropName="value" noStyle>
                <Radio.Group onChange={(e) => formItemCallback(e.target.value)} value={formItemValue}>
                    <YesNoAnswer />
                </Radio.Group>
            </Form.Item>
        </div>
    );

    // ========================================================================
    // Render FormItemRHSQuestionCheckbox
    // ========================================================================
    return (
        <Col>
            {formListQuestions.map((formItem, index) => (
                <div key={`${formItem.form_item_name}-${index}`}>
                    {renderFormItemQuestion(
                        formItem.form_item_name,
                        formItem.form_item_question,
                        formItem.form_item_value,
                        formItem.form_item_callback,
                    )}
                </div>
            ))}
        </Col>
    );
}

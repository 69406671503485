/* eslint-disable import/no-named-as-default-member */

import PerimeterDialog from '../PerimeterDialog/PerimeterDialog';
import EventTool from './EventTool';
import MenuTool from './MenuTool';
import SubTool from './SubTool';
import AlarmDeviceTool from './SubTool/AlarmDeviceTool/AlarmDeviceTool';
import FloorSettingsTool from './SubTool/FloorSettingsTool';
import SubToolEvent from './SubTool/SubToolEvent';
import SymbolTool from './SubTool/SymbolTool';
import styles from './ToolViewer.module.scss';
import { notification } from 'antd';
import classNames from 'classnames/bind';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import api from '~/api';
import AppLoading from '~/components/AppLoading';
import AppViewer from '~/components/AppViewer';
import { RoomsStatic } from '~/helper/ForgeViewer/speam/rooms/roomsStatic';
import { selectAlarmDevice } from '~/store/alarm-device/alarmDeviceSlice';
import { fetchFloorBoundingBox } from '~/store/viewer/viewer.action';
import { makeViewerHaveViewerSelector } from '~/store/viewer/viewer.selectors';
import { controlIsFetchingViewerAction } from '~/store/viewer/viewer.slice';
import {
    makeIsFetchingWASelector,
    makeIsShowWireWASelector,
    makeWirePerimeterWASelector,
} from '~/store/wire-alarm/wireAlarm.selectors';

const cx = classNames.bind(styles);

export default function ToolViewer({ file, token, clientId, controller }) {
    const { t } = useTranslation();
    const [isOpenSubTool, setIsOpenSubTool] = useState(false);
    const [isOpenAlarmDeviceTool, setIsOpenAlarmDeviceTool] = useState(false);
    const [isOpenSymbolTool, setIsOpenSymbolTool] = useState(false);
    const [isOpenFloorSettingsTool, setIsOpenFloorSettingsTool] = useState(false);

    const [roomsStaticObj, setRoomsStaticObj] = useState(null);
    const [selectedRoomObjId, setSelectedRoomObjId] = useState('');
    const [changedRoomInfo, setChangedRoomInfo] = useState(null);
    const [draggedRoom, setDraggedRoom] = useState(null);

    const [flagSubTool, setFlagSubTool] = useState(0);
    const [flagSubToolEvent, setFlagSubToolEvent] = useState(0);
    const [flagMenuTool, setFlagMenuTool] = useState(0);
    const [noData, setNoData] = useState(false);

    const isShowWireAlarmSelector = useSelector(makeIsShowWireWASelector());
    const wirePerimeterSelector = useSelector(makeWirePerimeterWASelector());

    const dispatch = useDispatch();

    const DesignAutomationCallAPI = () => {
        dispatch(controlIsFetchingViewerAction(true));

        Promise.all([
            api.post(
                'api/v1/user/DesignAutomation/define-room',
                {
                    fileId: file.id,
                    clientId,
                },
                {
                    signal: controller.signal,
                }),
            fetchFloorBoundingBox(file?.id, clientId)
        ])
        .catch(() => {
            dispatch(controlIsFetchingViewerAction(false));
            setNoData(true);
            notification.error({
                message: `${t('failed_network')}!`,
                placement: 'topRight',
            });
        });
    };

    const haveViewerSelector = useSelector(makeViewerHaveViewerSelector());

    const changeOpenSubTool = useCallback(
        (id) => {
            switch (id) {
                case 'listAlarmDevice': {
                    setIsOpenAlarmDeviceTool((prev) => !prev);
                    break;
                }
                case 'listSymbol':
                    setIsOpenSymbolTool((prev) => !prev);
                    break;
                case 'floorSettings':
                    setIsOpenFloorSettingsTool((prev) => !prev);
                    break;
                default: {
                    setIsOpenSubTool((prev) => !prev);
                    break;
                }
            }
        },
        [flagSubTool, flagMenuTool, flagSubToolEvent],
    );

    const handleHaveRoomsStaticObj = useCallback((isHaveRoomsObj) => {
        if (isHaveRoomsObj) {
            setRoomsStaticObj(RoomsStatic);
        } else {
            setRoomsStaticObj(null);
        }
    }, []);

    const handleSelectedRoomId = useCallback((selectedRoomId) => {
        if (selectedRoomId) {
            setSelectedRoomObjId(selectedRoomId);
        } else {
            setSelectedRoomObjId('');
        }
    }, []);

    const handleChangeRoomInfo = useCallback((room) => {
        if (room) {
            setChangedRoomInfo(room);
        } else {
            setChangedRoomInfo(null);
        }
    }, []);

    const handleEndDragRoom = useCallback((endDraggedRoom) => {
        if (endDraggedRoom) {
            setDraggedRoom(endDraggedRoom);
        } else {
            setDraggedRoom(null);
        }
    }, []);

    const notifySubTool = useCallback((count) => {
        setFlagSubTool(count);
    }, []);

    const notifyMenuTool = useCallback((count) => {
        setFlagMenuTool(count);
    }, []);

    const notifySubToolEvent = useCallback((count) => {
        setFlagSubToolEvent(count);
    }, []);

    return (
        <div className={cx('tool-viewer-container')}>
            {/* Helper for Listener any Event */}
            <EventTool />

            {/* Only show tools if viewer is existed  */}
            {haveViewerSelector && (
                <>
                    <SubToolEvent
                        notifySubToolEvent={notifySubToolEvent}
                        onSelectedRoomId={handleSelectedRoomId}
                        onChangeRoomInfo={handleChangeRoomInfo}
                        onEndDragRoom={handleEndDragRoom}
                    />
                    <SubTool
                        DesignAutomationCallAPI={DesignAutomationCallAPI}
                        onHaveRoomsStaticObj={handleHaveRoomsStaticObj}
                        file={file}
                        changeOpenSubTool={changeOpenSubTool}
                        isOpen={isOpenSubTool}
                        clientId={clientId}
                        controller={controller}
                        roomsStaticObj={roomsStaticObj}
                        selectedRoomObjId={selectedRoomObjId}
                        changedRoomInfo={changedRoomInfo}
                        notifySubTool={notifySubTool}
                        draggedRoom={draggedRoom}
                    />
                    <AlarmDeviceTool
                        changeOpenSubTool={changeOpenSubTool}
                        isOpen={isOpenAlarmDeviceTool}
                        clientId={clientId}
                    />
                    <SymbolTool changeOpenSubTool={changeOpenSubTool} isOpen={isOpenSymbolTool} />
                    <FloorSettingsTool
                        file={file}
                        changeOpenSubTool={changeOpenSubTool}
                        isOpen={isOpenFloorSettingsTool}
                    />
                    <MenuTool
                        dataEmty={noData}
                        DesignAutomationCallAPI={DesignAutomationCallAPI}
                        changeOpenSubTool={changeOpenSubTool}
                        clientId={clientId}
                        file={file}
                        controller={controller}
                        onHaveRoomsStaticObj={handleHaveRoomsStaticObj}
                        notifyMenuTool={notifyMenuTool}
                    />
                </>
            )}
            {/* Start loading viewer */}
            <div style={{ width: '100%' }}>
                <AppViewer urn={file?.modelDerivativeUrn} token={token} pathExternalExtensions={' '} />
            </div>

            {/* Helper for wireAlarm Show dialog of perimeter of wire on viewer */}
            <div style={{ position: 'absolute', zIndex: '9999', right: '24px', bottom: '24px' }}>
                {wirePerimeterSelector > 0 && (
                    <PerimeterDialog perimeter={wirePerimeterSelector} isOpen={isShowWireAlarmSelector} />
                )}
            </div>
        </div>
    );
}

/* eslint-disable react/prop-types */

import { Button, Form, Input, Modal, Space, Switch } from 'antd';
import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { regexNotAllowWhiteSpaceAtFirstCharacter } from '~/helper/regex';

// set 'cx' as Css class names
const cx = classNames.bind(this);

/**
 * Reset form fields when modal is form, closed
 */
const useResetFormOnCloseModal = ({ form, open }) => {
    const prevOpenRef = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;

    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

/**
 * Render NewFieldForm
 */
export default function NewFieldForm({ open, onCancel, msgHasSameField }) {
    // get error message after validation
    const [errorMessage, setErrorMessage] = useState('');

    // helper for translation
    const { t } = useTranslation();

    // useFrom of antd
    const [form] = Form.useForm();

    /**
     * Hook that reset form's fields to default when it is closed
     */
    useResetFormOnCloseModal({ form, open });

    useEffect(() => {
        if (msgHasSameField) {
            setErrorMessage(msgHasSameField);
        }
    }, [msgHasSameField]);

    /**
     * Submit form
     */
    const handleSubmit = () => {
        let checkedStr = ''
        checkedStr = form.getFieldValue('nf_new_field');

        if (!regexNotAllowWhiteSpaceAtFirstCharacter.test(checkedStr)) {
            return;
        }

        form.setFieldValue('nf_new_field', checkedStr.trim())

        form.submit();
    };

    /**
     * Render errorMessage
     * @param {*} errMsg
     */
    const renderErrorMessage = (errMsg) => (
        <div>
            {errMsg && errMsg.length > 0 && (
                <>
                    <i className="fa-solid fa-triangle-exclamation" style={{ color: 'red' }} />{' '}
                    <span
                        style={{
                            textTransform: 'none',
                            color: 'red',
                            fontSize: '0.75rem',
                        }}
                    >
                        {errMsg}
                    </span>
                </>
            )}
        </div>
    );

    return (
        <Modal
            title={<h3>{t('new_field')}</h3>}
            open={open}
            onCancel={onCancel}
            onOk={handleSubmit}
            centered
            footer={[
                <Button
                    key="submit"
                    className={cx('btn-custom', 'btn-custom_full-width')}
                    style={{ height: '38px' }}
                    type="primary"
                    htmlType="button"
                    onClick={handleSubmit}
                >
                    {t('add')}
                </Button>,
            ]}
            bodyStyle={{ padding: '10px 20px 0px 20px', height: '110px' }}
            width="225px"
            afterClose={() => setErrorMessage('')}
        >
            <Form
                form={form}
                layout="vertical"
                name="newFieldForm"
                onFinishFailed={(response) => setErrorMessage(response?.errorFields[0]?.errors[0])}
            >
                <Space direction="vertical" size="middle" style={{ marginTop: '6px', width: '100%' }}>
                    <div style={{ height: '30px', marginBottom: '8px' }}>
                        <Form.Item noStyle name="nf_value" valuePropName="value" initialValue={String('')}>
                            <Input hidden />
                        </Form.Item>
                        <Form.Item
                            noStyle
                            name="nf_new_field"
                            valuePropName="value"
                            rules={[{ required: true, message: `${t('please_type_something')}!` }]}
                        >
                            <Input onFocus={() => setErrorMessage('')} placeholder={`${t('new_field')}`} />
                        </Form.Item>
                        {renderErrorMessage(errorMessage)}
                    </div>
                    <div>
                        <Form.Item noStyle name="nf_hide_content" valuePropName="checked" initialValue={false}>
                            <Switch size="small" checkedChildren={t('yes')} unCheckedChildren={t('no')} />
                        </Form.Item>
                        <span style={{ marginLeft: 8 }}>{t('hide_content')}</span>
                    </div>
                </Space>
            </Form>
        </Modal>
    );
}

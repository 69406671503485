/* eslint-disable new-cap */
import { jsPDF } from 'jspdf';
import { draw_A0_FramePage, draw_A1_FramePage, draw_A2_FramePage, draw_A3_FramePage, draw_A4_FramePage } from './feature/framePage/index';
import { draw_A0_FrameInfoBottomRight, draw_A1_FrameInfoBottomRight, draw_A2_FrameInfoBottomRight, draw_A3_FrameInfoBottomRight, draw_A4_FrameInfoBottomRight } from './feature/frameInfoBottomRight/index';
import { draw_A0_MainContent, draw_A1_MainContent, draw_A2_MainContent, draw_A3_MainContent, draw_A4_MainContent } from './feature/mainContent';

/**
 * Setup font
 * Convert 'ttf' to base64: https://www.giftofspeed.com/base64-encoder/
 * @param {object[]} fontList
 */
jsPDF.API.addFontList = function (fontList) {
    const pdf = this;
    fontList.forEach((font) => {
        pdf.addFileToVFS(font.filename, font.fileContent);
        pdf.addFont(font.filename, font.name, font.fontStyle, font.fontWeight);
    });
};

/**
 * Class that control the creation of PDF
 */
export default class PDF {
    pdf; // property: jsPDF

    compress; // compression PDF file

    constructor(baseConfig) {
        this.pdf = new jsPDF({
            ...baseConfig,
        });

        this.compress = baseConfig?.compressOption;
    }

    /**
     * Initialize PDF instance with configuration properties
     * @param {*} baseConfig - an object containing configuration properties
     * @returns an instance of jsPDF
     */
    static initialize(baseConfig) {
        return new PDF(baseConfig);
    }

    /**
     * Base setup pdf document
     * @param {*} doc - jsPDF instance
     * @param {*} language - 'en' or 'de'
     * @param {*} fonts - list of font in config folder
     * @param {*} profileInfo - profile information for this PDF
     * @returns
     */
    setupDocument(language, fonts, profileInfo) {
        // return Promise.resolve().then(() => {
        this.pdf.setLanguage(language);

        this.pdf.setDocumentProperties({
            title: profileInfo?.title,
            subject: profileInfo?.subject,
            author: profileInfo?.author,
            keywords: profileInfo?.keywords,
            creator: profileInfo?.creator,
        });

        this.pdf.addFontList(fonts);
        // });
    }

    /**
     * Running the drawing functions for pdf document
     * @param {*} doc - jsPDF instance
     * @param {*} pageSize - a0, a1, a2, a3, a4
     * @param {*} logoConfig - logo at the bottom-right of frame info
     * @param {*} texts - all texts at the bottom-right of frame info
     * @param {*} mainContentAsImage - main content of this document
     * @returns
     */
    drawDocument(pageSize, logoConfig, texts, mainContentAsImage) {
        // return Promise.resolve().then(() => {
        // Set draw color
        const black = 'rgb(0, 0, 0)';
        this.pdf.getDrawColor(black);

        // Set based font-family, font-style, font-size
        this.pdf.setFont('Roboto', 'normal');
        this.pdf.setFontSize(14);

        // Default left and top position
        let topMost = 0;
        let leftMost = 0;

        // --- A0 page ----
        if (typeof pageSize === 'string' && pageSize === 'a0') {
            // ----------------------------------------------------------
            // SECTION 1 : Drawing a frame in page
            // ----------------------------------------------------------
            const [leftFramePage, topFramePage] = draw_A0_FramePage(this.pdf, leftMost, topMost);
            leftMost = leftFramePage;
            topMost = topFramePage;

            // ----------------------------------------------------------
            // SECTION 2 : Drawing main content
            // ----------------------------------------------------------
            if (mainContentAsImage) {
                draw_A0_MainContent(this.pdf, leftMost, topMost, mainContentAsImage, this.compress);
            }
            // ----------------------------------------------------------
            // SECTION 3 : Drawing a frame at the bottom-right
            // ----------------------------------------------------------
            draw_A0_FrameInfoBottomRight(this.pdf, leftMost, topMost, logoConfig, texts);
        }

        // --- A1 page ----
        if (typeof pageSize === 'string' && pageSize === 'a1') {
            // ----------------------------------------------------------
            // SECTION 1 : Drawing a frame in page
            // ----------------------------------------------------------
            const [leftFramePage, topFramePage] = draw_A1_FramePage(this.pdf, leftMost, topMost);
            leftMost = leftFramePage;
            topMost = topFramePage;

            // ----------------------------------------------------------
            // SECTION 2 : Drawing main content
            // ----------------------------------------------------------
            if (mainContentAsImage) {
                draw_A1_MainContent(this.pdf, leftMost, topMost, mainContentAsImage, this.compress);
            }
            // ----------------------------------------------------------
            // SECTION 3 : Drawing a frame at the bottom-right
            // ----------------------------------------------------------
            draw_A1_FrameInfoBottomRight(this.pdf, leftMost, topMost, logoConfig, texts);
        }

        // --- A2 page ----
        if (typeof pageSize === 'string' && pageSize === 'a2') {
            // ----------------------------------------------------------
            // SECTION 1 : Drawing a frame in page
            // ----------------------------------------------------------
            const [leftFramePage, topFramePage] = draw_A2_FramePage(this.pdf, leftMost, topMost);
            leftMost = leftFramePage;
            topMost = topFramePage;

            // ----------------------------------------------------------
            // SECTION 2 : Drawing main content
            // ----------------------------------------------------------
            if (mainContentAsImage) {
                draw_A2_MainContent(this.pdf, leftMost, topMost, mainContentAsImage, this.compress);
            }
            // ----------------------------------------------------------
            // SECTION 3 : Drawing a frame at the bottom-right
            // ----------------------------------------------------------
            draw_A2_FrameInfoBottomRight(this.pdf, leftMost, topMost, logoConfig, texts);
        }

        // --- A3 page ----
        if (typeof pageSize === 'string' && pageSize === 'a3') {
            // ----------------------------------------------------------
            // SECTION 1 : Drawing a frame in page
            // ----------------------------------------------------------
            const [leftFramePage, topFramePage] = draw_A3_FramePage(this.pdf, leftMost, topMost);
            leftMost = leftFramePage;
            topMost = topFramePage;

            // ----------------------------------------------------------
            // SECTION 2 : Drawing main content
            // ----------------------------------------------------------
            if (mainContentAsImage) {
                draw_A3_MainContent(this.pdf, leftMost, topMost, mainContentAsImage, this.compress);
            }

            // ----------------------------------------------------------
            // SECTION 3 : Drawing a frame at the bottom-right
            // ----------------------------------------------------------
            draw_A3_FrameInfoBottomRight(this.pdf, leftMost, topMost, logoConfig, texts);
        }

        // --- A4 page ----
        if (typeof pageSize === 'string' && pageSize === 'a4') {
            // ----------------------------------------------------------
            // SECTION 1 : Drawing a frame in page
            // ----------------------------------------------------------
            const [leftFramePage, topFramePage] = draw_A4_FramePage(this.pdf, leftMost, topMost);
            leftMost = leftFramePage;
            topMost = topFramePage;

            // ----------------------------------------------------------
            // SECTION 2 : Drawing main content
            // ----------------------------------------------------------
            if (mainContentAsImage) {
                draw_A4_MainContent(this.pdf, leftMost, topMost, mainContentAsImage, this.compress);
            }

            // ----------------------------------------------------------
            // SECTION 3 : Drawing a frame at the bottom-right
            // ----------------------------------------------------------
            draw_A4_FrameInfoBottomRight(this.pdf, leftMost, topMost, logoConfig, texts);
        }
    }
}

// const { mediaBox } = doc.getCurrentPageInfo();

// mediaBox: { bottomLeftX: 0, bottomLeftY: 0, topRightX: 1190.55, topRightY: 841.89 },

// ******************************************************************
// * Example
// * - Open PDF on the new tab
// * - They should be put in a Click event Function of a component
// ******************************************************************

//  const testCanvas = await getDivCanvasFromDocument("appId", 100);

//  const mainContentAsImage = testCanvas;

//  const pdf = new jsPDF({
//    unit: pageUnit.MM,
//    format: pageSize.A3,
//    orientation: "landscape",
//  });

//  const LANG = lang?.DE;

//  setupDocumentPDF(pdf, LANG, fontList, profileInfo)
//    .then(() => {
//      drawDocumentPDF(
//        pdf,
//        pageSize.A3,
//        logoConfig,
//        texts,
//        mainContentAsImage
//      ).then(() => {
//        const data = pdf.output("bloburl");

//        if (!data) {
//          throw new Error("Can not open PDF");
//        }
//        window.open(data);
//      });
//    })
//    .catch((error) => {
//      console.warn(error);
//    });

/* eslint-disable consistent-return */
/* eslint-disable spaced-comment */

import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PDF from '~/helper/pdf';
import PDF_CONFIG from '~/helper/pdf/config';
import { getResizedScreen, getScaleOfDrawingInForgeViewerForPage, getViewerScreenShot } from '~/helper/pdf/utils';

const { UNIT, PAGE_SIZE, FONT_LIST, LANGUAGE } = PDF_CONFIG;

// =====================  Example Only  =====================

// const TEXT = {
//     s11: 'Objekt',
//     s12: 'Schäfer Etiketten GmbH & Co. KG',
//     s21: 'Adresse',
//     s22: 'Nütinger Straße 62, 72649 Wolfschlugen',
//     s31: 'Planart',
//     s32: 'Projektierung 2. OG',
//     s41: 'Planersteller',
//     s42: 'KFT Kraus Feuerschutztechnik e. K.',
//     s43: 'Friedric-List-Straße 32',
//     s44: '70771 Leinfelden-Echterdingen',
//     s51: 'Stand',
//     s52: '04 / 2019',
//     s61: 'Seite',
//     s62: '1',
// };

// const PROFILE_INFO = {
//     title: 'Title_name',
//     subject: 'Subject_name',
//     author: 'Author_name',
//     keywords: 'Keywords_name',
//     creator: 'Creator_name',
// };

// =====================  Example Only  =====================

const LOGO_CONFIG = {
    // PATH: "public/assets/img/kft.png",
    PATH: `${window.location.origin}/assets/img/kft.png`,
    WIDTH: 44, // mm
};

/**
 * HOOK: handling feature PDF
 * @returns
 */
export default function useViewerPDF() {
    const [pageUnit] = useState(UNIT);
    const [pageSize, setPageSize] = useState('a3');
    const [logoConfig] = useState(LOGO_CONFIG);
    const [lang] = useState(LANGUAGE);
    const [fontList] = useState(FONT_LIST);

    // --------------------------- texts ----------------------------

    const [inS11, setInS11] = useState('object');
    const [inS12, setInS12] = useState(undefined);

    const [inS21, setInS21] = useState('address');
    const [inS22, setInS22] = useState(undefined);

    const [inS31, setInS31] = useState('plan_type');
    const [inS32, setInS32] = useState(undefined);

    const [inS41, setInS41] = useState('planner_creator');
    const [inS42, setInS42] = useState(undefined);

    const [inS51, setInS51] = useState('stand');
    const [inS52, setInS52] = useState(undefined);

    const [inS61, setInS61] = useState('page');

    const [inS71, setInS71] = useState('scale');

    // ----------------------------------------------------------------

    const [inProjectInfo, setInProjectInfo] = useState(undefined);
    const [isIframeLoading, setIsIframeLoading] = useState(false);
    const [pdfDataUri, setPdfDataUri] = useState(undefined);
    const [docInstance, setDocInstance] = useState(null);

    // --------------------------------------------------------
    const pdfRef = useRef();
    const { t: translate } = useTranslation();
    // --------------------------------------------------------

    const getPDFdataInstance = async (cloneViewer, compressOption) => {
        // setup frame size of this canvas viewer

        // Monitor with resolution of 2K
        cloneViewer.width = 2560;
        cloneViewer.height = 1440;

        const baseConfig = {
            unit: pageUnit.MM,
            orientation: 'landscape',
            format: pageSize,
            compress: !!compressOption,
            compressOption,
        };

        // -----------------------------------------------------------------------
        const doc = PDF.initialize(baseConfig);
        // -----------------------------------------------------------------------

        const screenShotWidth = cloneViewer.width; // px
        const screenShotHeight = cloneViewer.height; // px
        const aspectRatio = screenShotWidth / screenShotHeight;

        // helper for get resize screen Shot
        const sizes = getResizedScreen(aspectRatio, pageSize);

        // console.log('-------- pages --------: ', pageSize);
        // console.log('-------- sizes --------: ', sizes);

        // check sizes if not valid => show message => out of this function
        if (!sizes) {
            return;
        }

        // get resize
        const { resizedScreenHeight, resizedScreenWidth } = sizes;

        // get drawing scale of model in viewer then show it in pdf
        const drawingScale = getScaleOfDrawingInForgeViewerForPage(resizedScreenWidth, resizedScreenHeight, pageSize);

        // console.log('-------- scale -----------: ', drawingScale);

        // get viewer screen shot
        const screenShotSrc = await getViewerScreenShot(screenShotWidth, screenShotHeight);

        if (!screenShotSrc) {
            return;
        }

        const mainContentAsImage = {
            src: screenShotSrc,
            width: resizedScreenWidth,
            height: resizedScreenHeight,
        };

        // -----------------------------------------------------------------------
        // PDF with all it's elements are drawn here...
        // -----------------------------------------------------------------------

        doc.setupDocument(lang.DE, fontList, inProjectInfo);

        // Drawing PDF with chosen information
        doc.drawDocument(
            pageSize,
            logoConfig,
            {
                s11: `${translate(inS11)}`,
                s12: inS12,
                s21: `${translate(inS21)}`,
                s22: inS22,
                s31: `${translate(inS31)}`,
                s32: inS32,
                s41: `${translate(inS41)}`,
                s42: inS42,
                s51: `${translate(inS51)}`,
                s52: inS52,
                s61: `${translate(inS61)}`,
                s71: `${translate(inS71)}`,
                drawingScale,
            },
            mainContentAsImage,
        );

        setDocInstance(doc);

        // -----------------------------------------------------------------------

        return doc;
    };

    /**
     * Helper for downloading PDF
     * @param {*} compressedSize
     * @returns
     */
    const downloadPDF = async (compressedSize) => {
        if (!pdfRef.current) {
            return;
        }

        // Clone the current canvas viewer
        let clone = window.viewer?.canvas?.cloneNode(true);
        clone.setAttribute('id', 'viewer-pdf-id');

        if (!clone) {
            return;
        }

        if (!docInstance) {
            return;
        }

        docInstance.pdf.save(`${inProjectInfo?.subject}.pdf`);

        // release from memory
        clone = undefined;
        // doc = undefined;
    };

    /**
     * Helper for previewing or reloading iframe PDF
     * @param {*} compressedSize
     * @returns
     */
    const previewOrReloadIframePDF = async (compressedSize) => {
        if (!pdfRef.current) {
            return;
        }

        // Clone the current canvas viewer
        let clone = window.viewer?.canvas?.cloneNode(true);

        clone.setAttribute('id', 'viewer-pdf-id');

        if (!clone) {
            return;
        }

        let doc = await getPDFdataInstance(clone, compressedSize);

        // set doc here...
        setDocInstance(doc);

        if (!doc) {
            return;
        }

        let data = doc.pdf.output('datauristring');

        if (!data) {
            // release from memory
            clone = undefined;
            doc = undefined;
            setPdfDataUri(undefined);
            throw new Error('Can not open PDF');
        }

        // set data uri here...
        setPdfDataUri(data);

        // ---------------------------------------------------------
        setIsIframeLoading(true);

        pdfRef.current.onload = () => {
            setIsIframeLoading(false);
        };
        pdfRef.current.src = data;
        // ---------------------------------------------------------

        // release from browser memory
        clone = undefined;
        doc = undefined;
        data = undefined;
    };

    /**
     * Helper for turning off iframe PDF
     * @param {*} compressedSize
     * @returns
     */
    const releaseIframePDF = async () => {
        setPdfDataUri(undefined);
        pdfRef.current.src = '';
    };

    return {
        downloadPDF,
        previewOrReloadIframePDF,
        releaseIframePDF,
        pdfDataUri,
        pdfRef,
        pageSize,
        setPageSize,
        isIframeLoading,
        setInS42,
        setInS12,
        setInS22,
        setInS32,
        setInS52,
        setInProjectInfo,
    };
}

import { robotoMedium } from "./roboto/robotoMedium";
import { robotoLight } from "./roboto/robotoLight";
import { robotoBold } from "./roboto/robotoBold";

const FONT_LIST = [
  {
    name: "Roboto",
    filename: "Roboto-Medium.ttf",
    fileContent: robotoMedium,
    fontStyle: "normal",
    fontWeight: 400,
  },
  {
    name: "Roboto",
    filename: "Roboto-Light.ttf",
    fileContent: robotoLight,
    fontStyle: "normal",
    fontWeight: 300,
  },
  {
    name: "Roboto",
    filename: "Roboto-Bold.ttf",
    fileContent: robotoBold,
    fontStyle: "normal",
    fontWeight: 700,
  },
];

export default FONT_LIST;

/* eslint-disable no-extra-boolean-cast */

import styles from './MenuControll.module.scss';
import { Menu } from 'antd';
import classNames from 'classnames/bind';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    removeFromBinListByFileIdBinListAction,
    selectBinList,
    setSubListBinListAction,
} from '~/store/bin-list/binListSlice';
import { updateFile } from '~/store/files/fileActions';
import { filesList, resetSup } from '~/store/files/filesSlice';
import { openModalModalAction } from '~/store/modal/ModalSlice';
import { setSubProjectList } from '~/store/project/projectSlice';

const cx = classNames.bind(styles);

function MenuControll({ handleToView, type }) {
    const [items, setItems] = useState([]);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams();

    const { fileModal } = useSelector(filesList);
    const { onBinScreen, subList } = useSelector(selectBinList);

    useEffect(() => {
        if (type === 'project') {
            if (onBinScreen) {
                setItems(itemsProjectTrash);
            } else {
                setItems(itemsProjectList);
            }
        } else if (type === 'file') {
            if (onBinScreen) {
                setItems(itemsInBin);
            } else {
                setItems(itemsInFile);
            }
        }
    }, [type, onBinScreen]);

    const handleRestore = () => {
        dispatch(removeFromBinListByFileIdBinListAction(fileModal?.id));
        if (subList.length > 0) {
            subList.forEach((item) => dispatch(updateFile(item, { isDeleted: false }, 'restore', t)));
        } else {
            dispatch(
                updateFile({
                    id: fileModal?.id,
                    payload: { isDeleted: false },
                    type: 'restore',
                    t,
                }),
            );
        }
        dispatch(setSubListBinListAction([]));
    };

    const handleOpenModal = (key) => {
        dispatch(openModalModalAction({ title: key }));
    };

    const itemsProjectList = [
        {
            key: 'information',
            label: (
                <div className={cx('hover')} style={{ width: '100%' }} onClick={() => handleOpenModal('information')}>
                    <span className={cx('MenuControll_item')} key="information">
                        {t('information')}
                    </span>
                </div>
            ),
            className: cx('MenuControll_item'),
        },
        {
            key: 'delete',
            label: (
                <div className={cx('hover')} style={{ width: '100%' }} onClick={() => handleOpenModal('deleteProject')}>
                    <span className={cx('MenuControll_item')} key="delete">
                        {t('delete')}
                    </span>
                </div>
            ),
            className: cx('MenuControll_item'),
        },
    ];

    const itemsProjectTrash = [
        {
            key: 'information',
            label: (
                <div className={cx('hover')} style={{ width: '100%' }}>
                    <span className={cx('MenuControll_item')} key="Information">
                        {t('Information')}
                    </span>
                </div>
            ),
            className: cx('MenuControll_item'),
        },
        {
            key: 'delete',
            label: (
                <div className={cx('hover')} style={{ width: '100%' }}>
                    <span className={cx('MenuControll_item')} key="delete">
                        {t('delete')}
                    </span>
                </div>
            ),
            className: cx('MenuControll_item'),
        },
    ];

    const itemsInBin = [
        {
            key: 'restore',
            label: (
                <div className={cx('hover')} style={{ width: '100%' }} onClick={handleRestore}>
                    <span className={cx('MenuControll_item')} key="restore">
                        {t('restore')}
                    </span>
                </div>
            ),
            className: cx('MenuControll_item'),
        },
        {
            key: 'delete',
            label: (
                <div
                    className={cx('hover')}
                    style={{ width: '100%', paddingLeft: '4px', marginTop: 4 }}
                    onClick={() => handleOpenModal('delete')}
                >
                    <span className={cx('MenuControll_item')} key="delete">
                        {t('delete')}
                    </span>
                </div>
            ),
        },
    ];

    const itemsInFile = [
        {
            key: 'edit',
            label: (
                <div style={{ width: '100%' }} className={cx('hover')} onClick={() => handleToView(fileModal)}>
                    <span className={cx('MenuControll_item')} key="edit">
                        {t('edit')}
                    </span>
                </div>
            ),
        },
        {
            key: 'rename',
            label: (
                <button className={cx('MenuControll_item', 'hover')} onClick={() => handleOpenModal('rename')}>
                    {t('rename')}
                </button>
            ),
        },
        {
            key: 'delete',
            label: (
                <button className={cx('MenuControll_item', 'hover')} onClick={() => handleOpenModal('delete')}>
                    {t('delete')}
                </button>
            ),
        },
    ];

    return <Menu className={cx} key={fileModal?.id} style={{ padding: '8px 0', minWidth: 130 }} items={items} />;
}

export default memo(MenuControll);

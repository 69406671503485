import { Vector2 } from 'three-full';
import { findAngle } from '../radius-erase/radius-erase-function';

/**
 * Helper for checking if all points in line, apply with
 * coordinate of X or coordinate of Y or by diagonal
 * @param {*} points
 * @returns
 */
export const isAllPointsInLine = (points) => {
    const ESP = 0.01;
    const boolArr = [];

    for (let i = 0; i < points.length; i++) {
        if (i > 1) {
            const p0 = points[i];
            const p1 = points[i - 1];
            const p2 = points[i - 2];

            const vector1 = new Vector2();
            vector1.subVectors(p0, p1).normalize();

            const vector2 = new Vector2();
            vector2.subVectors(p2, p1).normalize();

            const angle = Math.abs(findAngle(vector1, vector2));

            if (angle <= ESP || Math.abs(angle - Math.PI) <= ESP) {
                boolArr.push(true);
            } else {
                boolArr.push(false);
            }
        }
    }
    const result = boolArr.every((e) => e === true);

    return result;
};

/**
 * Helper for checking should accept for drawing room
 * @param {*} points
 * @returns
 */
export const isAcceptDrawRoom = (points) => {
    /**
     * Do not create room if have only 2 points
     */
    if (points <= 2) {
        return false;
    }

    /**
     * Do not create room if all points in line
     */
    if (isAllPointsInLine(points)) {
        return false;
    }

    return true;
};

/* eslint-disable arrow-body-style */

import { initialState } from './wireAlarm.slice';
import { createSelector } from '@reduxjs/toolkit';

/**
 * SELECTOR: current state of wireAlarm slice
 * @param {*} state
 * @returns
 */
export const selectWireAlarmDomain = (state) => {
    return state.wireAlarm || initialState;
};

// ==========================================================================

/**
 * SELECTOR: state of wirePerimeter
 * @returns
 */
export const makeWirePerimeterWASelector = () =>
    createSelector([selectWireAlarmDomain], (wireAlarm) => wireAlarm.wirePerimeter);

/**
 * SELECTOR: state of hashOfBMZ
 * @returns
 */
export const makeHashOfBMZWASelector = () =>
    createSelector([selectWireAlarmDomain], (wireAlarm) => wireAlarm.hashOfBMZ);
/**
 * SELECTOR: state of isShowWireWire
 * @returns
 */
export const makeIsShowWireWASelector = () =>
    createSelector([selectWireAlarmDomain], (wireAlarm) => wireAlarm.isShowWireAlarm);

/**
 * SELECTOR: state of sortedWireAlarmPoints
 * @returns
 */
export const makeSortedWireAlarmPointsWASelector = () =>
    createSelector([selectWireAlarmDomain], (wireAlarm) => wireAlarm.sortedWireAlarmPoints);

/**
 * SELECTOR: state of isFetching when call fetchSortedPointsWireAlarmAction
 * @returns
 */
export const makeIsFetchingWASelector = () =>
    createSelector([selectWireAlarmDomain], (wireAlarm) => wireAlarm.isFetching);

export const protectedWireAlarmDataSelector = () =>
    createSelector([selectWireAlarmDomain], (wireAlarm) => wireAlarm.protectedWireAlarmData);

export const makeIsSuccessWASelector = () =>
    createSelector([selectWireAlarmDomain], (wireAlarm) => wireAlarm.isFetching);

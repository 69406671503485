// Page size:
// Width: 841mm - 33.11inch
// Height: 594mmm - 23.39inch

export function draw_A1_FramePage(doc, leftMost, topMost) {
    leftMost += 20;
    topMost += 10;

    // // Horizontal line
    // drawTestLine(doc, {x: 811, y: 0}, {left: 20, top: 10})
    // // Vertical line
    // drawTestLine(doc, {x: 0, y: 584}, {left: 20, top: 10})

    // left: 841(pageWidth) -20(fromLeft) - 10(fromRight) = 811,
    // top: 594(pageHeight) -10(fromTop) - 10(fromBottom) = 574

    const framePageWidth = 811; // should be set based on page size

    const framePageHeight = 574; // should be set based on page size

    const drawingFrame = {
        left: leftMost, // mm
        top: topMost, // mm
        width: framePageWidth, // mm
        height: framePageHeight, // mm
    };
    doc.rect(drawingFrame.left, drawingFrame.top, drawingFrame.width, drawingFrame.height);

    const leftFramePage = leftMost;
    const topFramePage = topMost;

    return [leftFramePage, topFramePage];
}

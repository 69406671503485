/* eslint-disable arrow-body-style */

import { initialState } from './viewer-pdf.reducer';
import { createSelector } from '@reduxjs/toolkit';

/**
 * SELECTOR: current state of viewer pdf
 * @param {*} state
 * @returns
 */
export const selectViewerDomain = (state) => {
    return state.viewerPdf || initialState;
};

// ==========================================================================
/**
 * SELECTOR: state of projectInfo
 * @returns
 */
export const makeViewerPdfProjectSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.projectInfo);

/**
 * SELECTOR: state of bottomRightFrameInfo
 * @returns
 */
export const makeViewerPdfBottomRightFrameInfoSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.bottomRightFrameInfo);

/**
 * SELECTOR: state of currentFileId
 * @returns
 */
export const makeViewerPdfCurrentFileIdSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.currentFileId);

/**
 * SELECTOR: state of currentFile
 * @returns
 */
export const makeViewerPdfCurrentFileSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.currentFile);

/**
 * SELECTOR: state of currentCustomer
 * @returns
 */
export const makeViewerPdfCurrentCustomerSelector = () =>
    createSelector([selectViewerDomain], (viewer) => viewer.currentCustomer);

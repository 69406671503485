/* eslint-disable react/no-array-index-key */
/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */

import ParentFormItemAdditionalFieldsInput from './ParentFormItemAdditionalFieldInput';
import ParentFormItemOptionalFieldsInput from './ParentFormItemOptionalFieldsInput';
import { Form } from 'antd';
import { useEffect, useState } from 'react';

/**
 * Render FormItemOptionalFields
 */
export default function FormItemOptionalFields({
    fields,
    hasErrorInFormItemOptionalFields
}) {
    const [optionalFieldError, setOptionalFieldError] = useState(false);
    const [additionalFieldError, setAdditionalFieldError] = useState(false);

    useEffect(() => {
        if (optionalFieldError || additionalFieldError) {
            hasErrorInFormItemOptionalFields(true);
            return;
        }
        hasErrorInFormItemOptionalFields(false);
    }, [optionalFieldError, additionalFieldError]);

    return (
        <>
            <div style={{ marginBottom: '8px' }}>
                <ParentFormItemOptionalFieldsInput
                    fields={fields}
                    onChildErrorMessage={(errorMessage) => {
                        if (errorMessage && errorMessage.length > 0) {
                            setOptionalFieldError(true);
                        } else {
                            setOptionalFieldError(false);
                        }
                    }}
                />
            </div>
            <div>
                <Form.Item key="form-item-should-update-new-field" noStyle dependencies={['newFields']}>
                    {({ getFieldValue, setFieldValue }) => {
                        const newFields = getFieldValue('newFields') || [];

                        // ----------------------------------------
                        // render ParentFormItemAdditionalFieldsInput
                        // ----------------------------------------
                        return newFields && newFields?.length > 0 ? (
                            <ParentFormItemAdditionalFieldsInput
                                newFields={newFields}
                                setFieldValue={setFieldValue}
                                onChildErrorMessage={(errorMessage) => {
                                    if (errorMessage && errorMessage.length > 0) {
                                        setAdditionalFieldError(true);
                                    } else {
                                        setAdditionalFieldError(false);
                                    }
                                }}
                            />
                        ) : null;
                    }}
                </Form.Item>
            </div>
        </>
    );
}

/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { customMemo } from '~/helper/common';
import { Room } from '~/helper/ForgeViewer/speam/rooms/room';
import { RoomsStatic } from '~/helper/ForgeViewer/speam/rooms/roomsStatic';
import { RoomStatic } from '~/helper/ForgeViewer/speam/rooms/roomStatic';

function SubToolEvent({ notifySubToolEvent, onSelectedRoomId, onChangeRoomInfo, onEndDragRoom }) {
    const [emitter, setEmitter] = useState(0);

    /**
     * Register selection for in Layer of Viewer
     * @param {*} onSelectionChanged
     */
    const handleSelectionEvent = (onSelectionChanged) => {
        const selectedShape = onSelectionChanged.target.getFirstSelected();

        /**
         * Set select only Room
         */
        if (selectedShape && selectedShape.isRoom) {
            // const serializableSelectedShape = JSON.parse(JSON.stringify(selectedShape));

            /**
             * Action is dispatched when any changes to shapes in edit2D mode
             */
            const room = RoomsStatic.getRoomById(selectedShape.id);
            if (room) {
                onSelectedRoomId(room.roomId);
                // Make this selected Room global
                RoomStatic.roomId = room.roomId;

                // notify an action
                setEmitter((prev) => prev + 1);
            }
        } else {
            return;
        }

        /**
         * Reset select element
         */
        if (selectedShape === undefined || selectedShape === null) {
            onSelectedRoomId('');

            // notify an action
            setEmitter((prev) => prev + 1);
        }
    };

    /**
     * Register event that observes any change with selected room in Layer of Viewer
     * @param {*} afterAction
     */
    const handleAfterActionEvent = (afterAction) => {
        const poly = afterAction?.action?.poly;

        if (poly?.isRoom) {
            const changedRoomInfo = { id: poly.id, points: poly.points };
            onChangeRoomInfo(changedRoomInfo);
        }

        // notify an action
        setEmitter((prev) => prev + 1);
    };

    useEffect(() => {
        // ======================================================================
        // Add Event Listener
        // ======================================================================
        window?.selection?.addEventListener(Autodesk.Edit2D.Selection.Events.SELECTION_CHANGED, handleSelectionEvent);
        window?.undoStack?.addEventListener(Autodesk.Edit2D.UndoStack.AFTER_ACTION, handleAfterActionEvent);

        return () => {
            if (Autodesk.Edit2D.Selection.Events.SELECTION_CHANGED && window?.selection) {
                window?.selection.removeEventListener(
                    Autodesk.Edit2D.Selection.Events.SELECTION_CHANGED,
                    handleSelectionEvent,
                );
            }
            if (Autodesk.Edit2D.UndoStack.AFTER_ACTION && window.undoStack) {
                window.undoStack.removeEventListener(Autodesk.Edit2D.UndoStack.AFTER_ACTION, handleAfterActionEvent);
            }
        };
    }, []);

    // ======================================================================
    // Handle End Dragging Room
    // ======================================================================
    const handleEndDragRoom = (endDragRoomEvent) => {
        const endDraggedRoom = {
            id: endDragRoomEvent?.detail?.id,
            points: endDragRoomEvent?.detail?.points,
        }

        onEndDragRoom(endDraggedRoom)

        // notify an action
        setEmitter((prev) => prev + 1);
    }

    useEffect(() => {
        window.addEventListener('endDragRoom', handleEndDragRoom);

        return () => {
            window.removeEventListener('endDragRoom', handleEndDragRoom);
        };
    }, [])

    // notify ui change, that helps parent component render the right way
    useEffect(() => {
        if (emitter) {
            notifySubToolEvent(emitter);
        }
    }, [emitter]);

    // reset emitter to avoid large number
    useEffect(() => {
        if (emitter > 3) {
            setEmitter(1);
        }
    }, [emitter]);
}

export default customMemo(SubToolEvent);

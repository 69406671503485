import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import api from '~/api';

/**
 * ACTION: fetch sorted points array that is processed by wiring alarm algorithm from server
 * --------------------------------------------------------------------------------------------
 * payload: {
 *   data: "[{roomId: "", center: {}, roomPoints: [], devicePoints: [] }, ...]", -> Json string
 *   type: "1" -> Wiring alarm algorithm
 * }
 * --------------------------------------------------------------------------------------------
 */

export const algorithmSupport = async (data) => {
    const res = await api.post('/api/v1/user/tool/algorithm-support', data);
    return res;
};

export const fetchSortedPointsWireAlarmAction = createAsyncThunk(
    'wireAlarm/fetchSortedPointsWireAlarmAction',
    async ({ data, t }, thunkAPI) => {
        try {
            const response = await algorithmSupport(data);
            if (response.status < 400) {
                return response.data;
            }
            message.error({
                content: t('something_wrong'),
            });
            return thunkAPI.rejectWithValue(response.data);
        } catch (error) {
            message.error({
                content: t('something_wrong'),
            });
            return thunkAPI.rejectWithValue(error.response.data);
        }
    },
);

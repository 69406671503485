/* eslint-disable consistent-return */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import api from '~/api';

export const getAlarm = createAsyncThunk('tool/getDeviceInfo', async (payload, thunkAPI) => {
    try {
        const response = await api.post('/api/v1/user/tool/get-device-info', payload);
        if (response.status < 400) {
            if (response.data.result.rooms.length > 1000) {
                message.warning({
                    content: 'Can not show viewer. Too many device. More than 1000!',
                    duration: 0.5,
                });
            } else {
                return response.data;
            }
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const changeUnitAPI = createAsyncThunk('tool/changeUnit', async ({ data, t }, thunkAPI) => {
    try {
        const response = await api.post('api/v1/user/DesignAutomation/change-unit', data);
        if (response.status < 400) {
            return;
        }
        message.error({
            content: t('change_unit_error'),
            duration: 1.5,
        });
        return thunkAPI.rejectWithValue(response.data);
    } catch (err) {
        message.error({
            content: t('change_unit_error'),
            duration: 1.5,
        });
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

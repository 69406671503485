/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-template */
/* eslint-disable camelcase */

import styles from '../../ToolViewer.module.scss';
import CheckBoxGroupData from './CheckboxGroupData';
import FloorSettingsChildContainer from './FloorSettingsChildContainer';
import FloorSettingsChildModal from './FloorSettingsChildModal';
import { getCurrentFSChildData, MODAL } from './shared';
import { Col, Divider, message, Radio, Row, Typography } from 'antd';
import classNames from 'classnames/bind';
import jcc from 'json-case-convertor';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import NewFieldPopover from '~/components/NewFieldPopover';
import YesNoAnswer from '~/components/RoomItemSettingForm/YesNoAnswer';
import { customMemo } from '~/helper/common';
import {
    findShapeIdListByRegex,
    removeRoomWithColor,
    toggleRoomWithColor,
} from '~/helper/ForgeViewer/forge-viewer-tool';
import { RoomsStatic } from '~/helper/ForgeViewer/speam/rooms/roomsStatic';
import { selectAlarmDevice } from '~/store/alarm-device/alarmDeviceSlice';
import { clearModalStateModalAction, openModalModalAction, selectModalState } from '~/store/modal/ModalSlice';
import { selectToolState } from '~/store/tool/toolSlice';
import { makeViewerRoomsSelector } from '~/store/viewer/viewer.selectors';
import { setFloorSettingsToolDataViewerAction, setIsBackToMenuToolViewerAction } from '~/store/viewer/viewer.slice';

const cs = classNames.bind(styles);

const { Paragraph } = Typography;

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// BackButton - back to the main settings
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
function BackButton({
    changeOpenSubTool,
    questionRelayOutputs,
    checkboxGroupData,
    floorSettingsChildData,
    newFieldData,
}) {
    // ===========================================

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { modalData } = useSelector(selectModalState);

    // ===========================================
    const handleOnClick = useCallback(() => {
        // helper for turn all the eyes floorSettingsChildData off
        dispatch(setIsBackToMenuToolViewerAction(true));

        // remove all polygons those have been drawn from viewer
        const regexFloorId = /^floor-/;

        const shapeIdList = findShapeIdListByRegex(regexFloorId) ?? [];

        if (shapeIdList && shapeIdList.length > 0) {
            shapeIdList.forEach((id) => {
                removeRoomWithColor(id);
            });
        }

        // -----------------------------------------------------------
        // Save all Data to viewer store
        // -----------------------------------------------------------
        let fireCompartmentsData = [];
        let alarmAreasData = [];

        floorSettingsChildData.forEach((item) => {
            if (item.title === MODAL.FIRE_COMPARTMENTS) {
                fireCompartmentsData = item.data;
            }
            if (item.title === MODAL.ALARM_AREAS) {
                alarmAreasData = item.data;
            }
        });

        dispatch(
            setFloorSettingsToolDataViewerAction({
                questionRelayOutputs,
                checkboxGroupData,
                floorSettingsChildData: [
                    {
                        title: MODAL.FIRE_COMPARTMENTS,
                        data: [...fireCompartmentsData],
                    },
                    {
                        title: MODAL.ALARM_AREAS,
                        data: [...alarmAreasData],
                    },
                ],
                newFieldData,
            }),
        );
        // -----------------------------------------------------------

        // helper for render viewer the right way
        setTimeout(() => {
            const title = modalData?.title || '';

            if (title.includes(MODAL.ALARM_AREAS) || title.includes(MODAL.FIRE_COMPARTMENTS)) {
                dispatch(clearModalStateModalAction());
            }

            // turn back to Menu tool Setting
            dispatch(setIsBackToMenuToolViewerAction(false));
            changeOpenSubTool('floorSettings');
        });
    }, [modalData?.title, questionRelayOutputs, checkboxGroupData, floorSettingsChildData, newFieldData]);

    // ===========================================
    return (
        <div className={cs('sub-tools-back')} onClick={() => handleOnClick()} aria-hidden="true">
            <i className="fa-solid fa-arrow-left" />{' '}
            <span style={{ textTransform: 'capitalize', fontSize: '1rem' }}>{t('back')}</span>
        </div>
    );
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// FloorSettingsTool component
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

function FloorSettingsTool({ file, isOpen, changeOpenSubTool }) {
    // ===========================================
    // Main data for this component
    // ===========================================

    const [questionRelayOutputs, setQuestionRelayOutputs] = useState(0);
    const [checkboxGroupData, setCheckboxGroupData] = useState(undefined);
    const [floorSettingsChildData, setFloorSettingsChildData] = useState([
        {
            title: MODAL.FIRE_COMPARTMENTS,
            data: [],
        },
        {
            title: MODAL.ALARM_AREAS,
            data: [],
        },
    ]);
    const [newFieldData, setNewFieldData] = useState([]);
    // const [newFieldSize, setNewFieldSize] = useState(0);

    // ===========================================
    const { toggleAllTools } = useSelector(selectToolState);
    // ===========================================

    // Really importance, that handles loading the first data for rendering
    const floorFileData = useMemo(() => {
        let floorData = {};
        if (file?.fileData) {
            const cvData = jcc.camelCaseKeys(JSON.parse(file?.fileData));
            if (cvData?.floorSettings) {
                floorData = JSON.parse(JSON.stringify(cvData?.floorSettings));
            }
        }

        if (Object.hasOwn(floorData, 'questionRelayOutputs')) {
            setQuestionRelayOutputs(floorData?.questionRelayOutputs);
        }

        if (Object.hasOwn(floorData, 'checkBoxGroupData')) {
            setCheckboxGroupData(floorData?.checkboxGroupData);
        }

        if (Object.hasOwn(floorData, 'floorSettingsChildData')) {
            setFloorSettingsChildData(floorData?.floorSettingsChildData);
        }

        if (Object.hasOwn(floorData, 'newFieldData')) {
            setNewFieldData(JSON.parse(JSON.stringify(floorData?.newFieldData)));
        }
        return floorData;
    }, [file]);

    // ===========================================

    const [allowedAddNewFSChild, setAllowedAddNewFSChild] = useState(null);
    const [allowedEditCurrentFSChild, setAllowedEditCurrentFSChild] = useState(null);

    // Data source for Modals
    const { modalData, modalOpen } = useSelector(selectModalState);
    const roomsSelector = useSelector(makeViewerRoomsSelector());
    const { unit } = useSelector(selectAlarmDevice);

    // ===========================================

    const { t } = useTranslation();

    const dispatch = useDispatch();

    // ===========================================

    /**
     * Observe all changes of FloorSettingsTool data and put data to Viewer Store
     */
    useEffect(() => {
        let fireCompartmentsData = [];
        let alarmAreasData = [];

        floorSettingsChildData?.forEach((item) => {
            if (item.title === MODAL.FIRE_COMPARTMENTS) {
                fireCompartmentsData = item.data;
            }
            if (item.title === MODAL.ALARM_AREAS) {
                alarmAreasData = item.data;
            }
        });

        dispatch(
            setFloorSettingsToolDataViewerAction({
                questionRelayOutputs,
                checkboxGroupData,
                floorSettingsChildData: [
                    {
                        title: MODAL.FIRE_COMPARTMENTS,
                        data: [...fireCompartmentsData],
                    },
                    {
                        title: MODAL.ALARM_AREAS,
                        data: [...alarmAreasData],
                    },
                ],
                newFieldData,
            }),
        );
    }, [questionRelayOutputs, checkboxGroupData, floorSettingsChildData, newFieldData]);

    // ---------------------------------------------------------------------------------
    // Handle State of ChangeNewFieldData
    // ---------------------------------------------------------------------------------
    const handleChangeNewFieldData = useCallback((values) => {
        if (values && values.length > 0) {
            setNewFieldData(values);
        }
    }, []);

    // ---------------------------------------------------------------------------------
    // Handle State of ChangeCheckBoxGroupData
    // ---------------------------------------------------------------------------------
    const handleChangeCheckBoxGroupData = useCallback((values) => {
        setCheckboxGroupData(values);
    }, []);

    // ---------------------------------------------------------------------------------
    // Handle State of FloorSettingsChildModal
    // ---------------------------------------------------------------------------------
    const handleChangeFloorSettingsChildModal = useCallback(
        (values) => {
            const defaultValues = null;

            if (allowedAddNewFSChild) {
                setFloorSettingsChildData((prev) => {
                    return [...prev].map((element) => {
                        if (element.title === values?.title) {
                            element.data = [...element.data, values];
                            return element;
                        }
                        return element;
                    });
                });

                setAllowedAddNewFSChild(defaultValues);
            }

            if (allowedEditCurrentFSChild) {
                setFloorSettingsChildData((prev) => {
                    return [...prev].map((element) => {
                        if (element.title === values?.title) {
                            const itemIndex = [...element.data].findIndex((item) => item.id === values?.id);
                            element.data[itemIndex] = values;

                            return element;
                        }
                        return element;
                    });
                });

                setAllowedEditCurrentFSChild(defaultValues);
            }
        },
        [floorSettingsChildData, allowedAddNewFSChild, allowedEditCurrentFSChild],
    );

    // ---------------------------------------------------------------------------------
    // Handle State of FloorSettingsChildContainer
    // ---------------------------------------------------------------------------------

    /**
     * Activate when user click on eye icon, a Modal floor settings child will open for config
     */
    const handleShowFSChild = useCallback(
        (values) => {
            // ------------------------------
            // rooms will be drawn here
            // ------------------------------

            const currentItem = getCurrentFSChildData(values, floorSettingsChildData);

            const isShown = values.isShown;

            const roomIdList = currentItem.roomIdList;

            const rgbColor = currentItem.rgbColor;

            // ------------------------------------------------------
            // More important logic is implemented here....
            // ------------------------------------------------------
            const roomShapes = roomIdList
                .map((id) => {
                    return RoomsStatic.getRoomById(id);
                })
                .map((room) => {
                    const roomId = `floor-${room.roomId}-${values?.itemId}`;

                    const newRoom = {
                        roomId,
                        layerCurrentPoints: room.layerCurrentPoints,
                        name: room.name,
                        rgbColor,
                    };

                    return newRoom;
                });

            // Should create new classes for each room with new ID

            if (isShown) {
                roomShapes.forEach((room) => {
                    toggleRoomWithColor(true, room.roomId, room.name, room.layerCurrentPoints, room.rgbColor);
                });
            } else {
                roomShapes.forEach((room) => {
                    toggleRoomWithColor(false, room.roomId, room.name, room.layerCurrentPoints, room.rgbColor);
                });
            }
        },
        [floorSettingsChildData],
    );

    const showTitleWarning = (translate, title) => {
        message.warn(`${translate('please_confirm_or_close')} ${translate(`${title}`)} ${translate('dialog')}!`);
    };

    /**
     * Activate when user click on pencil icon, a Modal floor settings child will open for config
     */

    const handleEditFSChild = useCallback(
        (values) => {
            const title = modalData?.title || '';

            if (title.includes(MODAL.ALARM_AREAS) || title.includes(MODAL.FIRE_COMPARTMENTS)) {
                showTitleWarning(t, title);
                return;
            }

            const defaultValues = null;

            const currentItem = getCurrentFSChildData(values, floorSettingsChildData);

            setAllowedAddNewFSChild(defaultValues);

            setAllowedEditCurrentFSChild(currentItem);
            dispatch(openModalModalAction({ title: currentItem.title }));
        },
        [floorSettingsChildData, modalData?.title],
    );

    /**
     * Activate when user click on trash icon of element that they want to delete
     */
    const handleDeleteFSChild = useCallback(
        (values) => {
            const title = modalData?.title || '';

            if (title.includes(MODAL.ALARM_AREAS) || title.includes(MODAL.FIRE_COMPARTMENTS)) {
                showTitleWarning(t, title);
                return;
            }

            const currentItem = getCurrentFSChildData(values, floorSettingsChildData);

            const roomIdList = currentItem.roomIdList;

            const roomShapes = roomIdList
                .map((id) => {
                    return RoomsStatic.getRoomById(id);
                })
                .map((room) => {
                    const roomId = `floor-${room.roomId}-${values?.itemId}`;
                    const newRoom = {
                        roomId,
                    };

                    return newRoom;
                });

            roomShapes.forEach((room) => {
                removeRoomWithColor(room.roomId);
            });

            // Remove element by id
            setFloorSettingsChildData((prev) => {
                return [...prev].map((element) => {
                    if (element.title === values?.title) {
                        element.data = [...element.data].filter((item) => item.id !== values.itemId);
                        return element;
                    }
                    return element;
                });
            });
        },
        [floorSettingsChildData, modalData?.title],
    );

    /**
     * Activate when user click button Add new 'fire compartments' or 'alarm areas',
     */
    const handleAddNewFSChild = useCallback(
        (values) => {
            const title = modalData?.title || '';

            if (title.includes(MODAL.ALARM_AREAS) || title.includes(MODAL.FIRE_COMPARTMENTS)) {
                showTitleWarning(t, title);
                return;
            }

            const defaultValues = null;

            setAllowedEditCurrentFSChild(defaultValues);

            setAllowedAddNewFSChild(values);

            dispatch(openModalModalAction({ title: values.title }));
        },
        [modalData?.title],
    );

    // ---------------------------------------------------------------------------------
    // Render FloorSettingsChild Container
    // ---------------------------------------------------------------------------------
    const renderFloorSettingsChildContainer = () => {
        return (
            <>
                {floorSettingsChildData &&
                    floorSettingsChildData.map((child, childIndex) => {
                        let title = '';
                        let dataSource = [];

                        if (child.title === MODAL.FIRE_COMPARTMENTS) {
                            title = MODAL.FIRE_COMPARTMENTS;
                            dataSource = child.data;
                        }

                        if (child.title === MODAL.ALARM_AREAS) {
                            title = MODAL.ALARM_AREAS;
                            dataSource = child.data;
                        }

                        return (
                            <FloorSettingsChildContainer
                                key={'fsd' + childIndex}
                                dataSource={dataSource}
                                childIndex={childIndex}
                                modalName={title}
                                onShowFSChild={handleShowFSChild}
                                onDeleteFSChild={handleDeleteFSChild}
                                onEditFSChild={handleEditFSChild}
                                onAddNewFSChild={handleAddNewFSChild}
                            />
                        );
                    })}
            </>
        );
    };

    // ================================================================================================================
    // Render FloorSettingsTool
    // ================================================================================================================

    return (
        <div style={{ display: toggleAllTools ? 'block' : 'none' }}>
            <div className={cs('sub-tools')} style={{ display: !isOpen ? 'none' : 'block', textTransform: 'none' }}>
                {/* ---------------------------------------- */}
                {/* -------------- Back Button ------------- */}
                {/* ---------------------------------------- */}
                <BackButton
                    questionRelayOutputs={questionRelayOutputs}
                    checkboxGroupData={checkboxGroupData}
                    floorSettingsChildData={floorSettingsChildData}
                    newFieldData={newFieldData}
                    changeOpenSubTool={changeOpenSubTool}
                />

                <div className={cs('sub-tools-content')}>
                    <div className={cs('sub-tools-content__action')}>
                        <h2 style={{ margin: '5px 0', textTransform: 'capitalize' }}>{t('floor_settings')}</h2>
                    </div>

                    {/* --------------------------------------------- */}
                    {/* --------- Are There Relay Outputs? ---------- */}
                    {/* --------------------------------------------- */}
                    <>
                        <Row>
                            <Col span={24}>
                                <Divider className={cs('divider')}></Divider>
                                <Paragraph className={cs('floor-settings-title')}>
                                    {t('are_there_relay_outputs')}
                                </Paragraph>
                                <Radio.Group
                                    style={{ marginLeft: '8px' }}
                                    onChange={(e) => {
                                        const digit = e.target.value;
                                        if (digit === 0) {
                                            setQuestionRelayOutputs(0);
                                            setCheckboxGroupData(undefined);
                                        } else {
                                            setQuestionRelayOutputs(1);
                                        }
                                    }}
                                    value={questionRelayOutputs}
                                >
                                    <YesNoAnswer />
                                </Radio.Group>
                            </Col>

                            {/* --------------------------------------------- */}
                            {/* ----------- Render Checkbox Group ----------- */}
                            {/* --------------------------------------------- */}
                            <Col
                                span={24}
                                style={{ marginTop: '8px' }}
                                hidden={questionRelayOutputs === 0 ? true : false}
                            >
                                <CheckBoxGroupData
                                    defaultData={floorFileData?.checkboxGroupData || {}}
                                    onChangeCheckBoxGroupData={handleChangeCheckBoxGroupData}
                                />
                            </Col>
                        </Row>

                        {/* ------------------------------------------------------------- */}
                        {/* ----------- Render Floor Settings child container ------------*/}
                        {/* ------------------------------------------------------------- */}
                        {renderFloorSettingsChildContainer()}

                        {/* ---------------------------------------------- */}
                        {/* -------------- Render New fields --------------*/}
                        {/* ---------------------------------------------- */}
                        <Row style={{ marginTop: '18px', paddingBottom: '24px' }}>
                            <Col span={24}>
                                <Divider className={cs('divider')}></Divider>
                                <Paragraph className={cs('floor-settings-title')}>{t('optional_fields')}</Paragraph>

                                <NewFieldPopover
                                    onChangeNewFieldData={handleChangeNewFieldData}
                                    defaultNewFieldData={newFieldData}
                                    isHideContent={false}
                                    placement="right"
                                    actionSize="default"
                                />
                            </Col>
                        </Row>

                        {/* ------------------------------------------------------------------ */}
                        {/* ------------------ Render FloorSettingsChildModals ----------------*/}
                        {/* ------------------------------------------------------------------ */}
                        <>
                            {allowedAddNewFSChild && modalOpen && (
                                <FloorSettingsChildModal
                                    title={allowedAddNewFSChild?.title}
                                    isNew={allowedAddNewFSChild?.isNew}
                                    onChange={handleChangeFloorSettingsChildModal}
                                    modalOpen={modalOpen}
                                    modalData={modalData}
                                    dataRooms={roomsSelector}
                                    dataUnit={unit}
                                />
                            )}
                            {allowedEditCurrentFSChild && modalOpen && (
                                <FloorSettingsChildModal
                                    title={allowedEditCurrentFSChild?.title}
                                    defaultValues={allowedEditCurrentFSChild}
                                    onChange={handleChangeFloorSettingsChildModal}
                                    modalOpen={modalOpen}
                                    modalData={modalData}
                                    dataRooms={roomsSelector}
                                    dataUnit={unit}
                                />
                            )}
                        </>
                    </>
                </div>
            </div>
        </div>
    );
}

export default customMemo(FloorSettingsTool);

// Page size:
// Width: 594mm - 23.39inch
// Height: 420mmm - 16.53inch

export function draw_A2_FramePage(doc, leftMost, topMost) {
    leftMost += 20;
    topMost += 10;

    // // Horizontal line
    // drawTestLine(doc, {x: 564, y: 0}, {left: 20, top: 5})
    // // Vertical line
    // drawTestLine(doc, {x: 0, y: 400}, {left: 20, top: 5})

    // left: 594(pageWidth) -20(fromLeft) - 10(fromRight) = 564,
    // top: 420(pageHeight) -10(fromTop) - 10(fromBottom) = 400

    const framePageWidth = 564; // should be set based on page size

    const framePageHeight = 400; // should be set based on page size

    const drawingFrame = {
        left: leftMost, // mm
        top: topMost, // mm
        width: framePageWidth, // mm
        height: framePageHeight, // mm
    };
    doc.rect(drawingFrame.left, drawingFrame.top, drawingFrame.width, drawingFrame.height);

    const leftFramePage = leftMost;
    const topFramePage = topMost;

    return [leftFramePage, topFramePage];
}

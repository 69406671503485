/* eslint-disable prefer-template */

import styles from './index.module.scss';
import { Col, List, Popconfirm, Row, Typography } from 'antd';
import classNames from 'classnames/bind';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { makeViewerIsBackToMenuToolSelector } from '~/store/viewer/viewer.selectors';
import { setIsBackToMenuToolViewerAction } from '~/store/viewer/viewer.slice';

const cs = classNames.bind(styles);

const { Paragraph } = Typography;

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// EyeIcon component
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
function EyeIcon({ onClick, isPencilClick, onChange }) {
    const [isShown, setIsShown] = useState(false);

    // when user click Back button to turn back Menu tool setting,
    // turn all the eyes off
    const isBackToMenuToolSelector = useSelector(makeViewerIsBackToMenuToolSelector());
    const dispatch = useDispatch();

    const handleTurnEyeOff = () => {
        const eyeStatus = false;

        const data = {
            isShown: eyeStatus,
        };
        onChange(data);
    };

    useEffect(() => {
        if (!isPencilClick) {
            return;
        }

        handleTurnEyeOff();

        setIsShown(false);
    }, [isPencilClick]);

    useEffect(() => {
        if (!isBackToMenuToolSelector) {
            return;
        }

        handleTurnEyeOff();

        dispatch(setIsBackToMenuToolViewerAction(false));

        setIsShown(false);
    }, [isBackToMenuToolSelector]);

    const handleOnClick = useCallback(() => {
        const eyeStatus = !isShown;

        const data = {
            isShown: eyeStatus,
        };

        onClick(data);

        setIsShown(!isShown);
    }, [isShown]);

    return (
        <i
            className={cs('action', isShown ? 'fa-sharp fa-solid fa-eye' : 'fa-sharp fa-solid fa-eye-slash')}
            style={{ marginRight: 14, color: isShown ? 'var(--primary-color)' : '' }}
            onClick={handleOnClick}
        />
    );
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// PencilIcon component
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
function PencilIcon({ onClick }) {
    return <i className={cs('fa-solid fa-pencil', 'action')} style={{ marginRight: 14 }} onClick={onClick} />;
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// TrashIcon component
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
function TrashIcon({ onClick }) {
    // ===============================

    const { t } = useTranslation();

    // ===============================
    return (
        <Popconfirm title={t('are_you_sure')} onConfirm={onClick} okText={t('yes')} cancelText={t('no')}>
            <i className={cs('fa-solid fa-trash', 'action')} />
        </Popconfirm>
    );
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Actions
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
function Actions({ itemId, handleEyeClick, handlePencilClick, handleTrashClick }) {
    // ===============================

    const [isPencilClick, setIsPencilClick] = useState(false);

    // ===============================
    return (
        <>
            <EyeIcon
                isPencilClick={isPencilClick}
                onChange={(value) => {
                    handleEyeClick(itemId, value);
                }}
                onClick={(value) => {
                    handleEyeClick(itemId, value);
                    setIsPencilClick(false);
                }}
            />
            <PencilIcon
                onClick={() => {
                    handlePencilClick(itemId);
                    setIsPencilClick(true);
                }}
            />
            <TrashIcon
                onClick={() => {
                    handleTrashClick(itemId);
                }}
            />
        </>
    );
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// Empty component - guard when list is empty
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
function Empty() {
    return <span style={{ margin: 0, padding: 0 }} />;
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// FloorSettingsChildList component
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export default function FloorSettingsChildList({
    dataSource,
    modalName,
    onDeleteFSChild,
    onEditFSChild,
    onShowFSChild,
}) {
    const { t } = useTranslation();

    // ===========================================
    const handleEyeClick = (itemId, value) => {
        const data = {
            itemId,
            title: modalName,
            isShown: value.isShown,
        };

        onShowFSChild(data);
    };

    // ===========================================
    const handlePencilClick = (itemId) => {
        const data = {
            itemId,
            title: modalName,
        };

        onEditFSChild(data);
    };

    // ===========================================
    const handleTrashClick = (itemId) => {
        const data = {
            itemId,
            title: modalName,
        };

        onDeleteFSChild(data);
    };

    // ===========================================
    // Render FloorSettingsTool
    // ===========================================

    return (
        <List
            pagination={false}
            rowKey={(record) => record.id}
            dataSource={dataSource || []}
            split={false}
            renderItem={(item) => (
                <List.Item
                    key={'list-item' + item.id}
                    style={{ padding: 0, marginTop: '8px' }}
                    actions={[
                        <Actions
                            itemId={item.id}
                            handleEyeClick={handleEyeClick}
                            handlePencilClick={handlePencilClick}
                            handleTrashClick={handleTrashClick}
                        />,
                    ]}
                >
                    <List.Item.Meta
                        description={
                            <Row>
                                <Col>
                                    <div className={cs('color-circle-parent')}>
                                        <div
                                            className={cs('color-circle-child')}
                                            style={{
                                                backgroundColor: String(
                                                    `rgb(${item.rgbColor.r}, ${item.rgbColor.g}, ${item.rgbColor.b}`,
                                                ),
                                            }}
                                        ></div>
                                    </div>
                                </Col>
                                <Col flex={1} style={{ marginLeft: '12px' }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                        }}
                                    >
                                        <Paragraph
                                            style={{
                                                margin: 0,
                                                fontSize: '0.9rem',
                                                fontWeight: 600,
                                                color: '#666',
                                            }}
                                        >
                                            {item.subtitle || ''}
                                        </Paragraph>
                                        <Paragraph
                                            style={{
                                                margin: 0,
                                                fontSize: '0.75rem',
                                                color: '#666',
                                            }}
                                        >
                                            {t(modalName)}
                                        </Paragraph>
                                        <Paragraph
                                            style={{
                                                margin: 0,
                                                fontSize: '0.85rem',
                                                fontWeight: 400,
                                                color: '#666',
                                            }}
                                        >
                                            <span style={{ fontWeight: 600, marginRight: '6px' }}>
                                                {`${item.roomIdList.length}`}
                                            </span>
                                            {`${item.roomIdList.length >= 2 ? t('rooms') : t('room')}`}
                                        </Paragraph>
                                    </div>
                                </Col>
                            </Row>
                        }
                    />
                </List.Item>
            )}
        >
            <Empty description={t('no_data')} />
        </List>
    );
}

import { message } from 'antd';

import api from '~/api';
import { controlIsFetching } from '~/store/project/projectSlice';
import { uploadFailed, uploadStart, uploadProcessing, uploadPercent } from '~/store/files/filesSlice';

const uploadFile = async (listUpload, dispatch, id) => {
    const formData = new FormData();
    listUpload.forEach((f) => {
        formData.append('files', f.file);
        formData.append('names', f.name);
        formData.append('keys', f.id);
    });

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },

        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = ((loaded * 100) / total).toFixed(1);
            if (percent < 100) {
                dispatch(uploadPercent({ percent, id: listUpload[0].id }));
            }
        },
    };

    await dispatch(uploadStart({ listUpload, id }));

    api.post(`api/v1/user/File/upload/${id}`, formData, config)
        .then((res) => {
            dispatch(controlIsFetching(false));
            res.data.result.files.forEach((f) => {
                dispatch(uploadProcessing(f));
            });
        })
        .catch((err) => {
            dispatch(controlIsFetching(false));
            message.error({
                content: err.message,
            });
            listUpload.forEach((f) => {
                dispatch(uploadFailed(f));
            });
        });
};

export default uploadFile;

/* eslint-disable react/jsx-filename-extension */
/* eslint-disable global-require */
export const image = {
    logo: require('./images/logo.png'),
    logoSpeam: require('./images/logo_animation.gif'),
    pdf: require('./images/pdf.png'),
    helpTools: {
        select: require('./images/select.png'),
        markRoom: require('./images/mark-room.png'),
        editRoom: require('./images/edit-room.png'),
        editDevice: require('./images/edit-device.png'),
        listRoom: require('./images/list-room.png'),
        alarmDevice: require('./images/alarm-device.png'),
        listSymbol: require('./images/list-symbol.png'),
    },
};

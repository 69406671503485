import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '~/api';

export const loginAuthAction = createAsyncThunk('user/signin', async (payload, thunkAPI) => {
    try {
        const response = await api.post('/api/v1/Auth/signin', payload);
        if (response.status < 400) {
            return response.data;
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.errors[0]);
    }
});

export const signUpAuthAction = createAsyncThunk('user/signup', async (payload, thunkAPI) => {
    try {
        const response = await api.post('/api/v1/Auth/signup', payload);
        if (response.status < 400) {
            return response.data;
        }
        return thunkAPI.rejectWithValue(response.data);
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import styles from './UserDropdown.module.scss';

const cx = classNames.bind(styles);

const styleIcon = {
    padding: '12px 10px 12px 12px',
    borderRight: '1px solid var(--grey)',
};

export default function UserDropdown() {
    const { t } = useTranslation();

    const menuItem = (text, path) => (
        <div className={cx('menu-item')}>
            <Link to={path} onClick={() => sessionStorage.clear()} className={cx('menu-item__path')}>
                {t(text)}
            </Link>
        </div>
    );
    const menu = (
        <Menu
            className={cx('menu-custom')}
            items={[
                {
                    label: menuItem('account', '/account'),
                    key: '0',
                    icon: <i className="fa-solid fa-user" style={styleIcon} />,
                },
                {
                    label: menuItem('settings', '/settings'),
                    key: '1',
                    icon: <i className="fa-solid fa-gear" style={styleIcon} />,
                },
                {
                    type: 'divider',
                },
                {
                    label: menuItem('logout', '/logout'),
                    key: '3',
                    icon: <i className="fa-solid fa-right-from-bracket" style={styleIcon} />,
                },
            ]}
        />
    );

    const user = JSON.parse(cookies.get('user') || null);

    return (
        <Dropdown overlay={menu} trigger={['click']} className={cx('user-dropdown')} placement="bottom">
            <a onClick={(e) => e.preventDefault()}>
                <span className={cx('user-dropdown-name')}>{user?.name || 'User'}</span>
                <DownOutlined />
            </a>
        </Dropdown>
    );
}

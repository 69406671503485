export const toolsData = [
    {
        id: 'select',
        isActive: true,
        name: 'select',
        icon: 'fa-solid fa-arrow-pointer',
    },
    {
        id: 'markRoom',
        isActive: false,
        name: 'mark_room',
        icon: 'fa-sharp fa-solid fa-marker',
    },
    {
        id: 'editRoom',
        isActive: false,
        name: 'edit_room',
        icon: 'fa-solid fa-pen-to-square',
    },
    {
        id: 'editDevice',
        isActive: false,
        name: 'edit_device',
        icon: 'fa-solid fa-pen-to-square',
    },
    {
        id: 'listRoom',
        isActive: false,
        name: 'list_room',
        icon: 'fa-solid fa-list',
    },
    {
        id: 'listAlarmDevice',
        isActive: false,
        name: 'alarm_device',
        icon: 'fa-solid fa-album',
    },
    {
        id: 'listSymbol',
        isActive: false,
        name: 'list_symbol',
        icon: 'fa-sharp fa-solid fa-helicopter-symbol',
    },
    {
        id: 'floorSettings',
        isActive: false,
        name: 'floor_settings',
        icon: 'fa-solid fa-gear',
    },
    {
        id: 'distance',
        isActive: false,
        name: 'distance',
        icon: 'fa-solid fa-ruler',
    },
    {
        id: 'editUnit',
        isActive: false,
        name: 'edit_unit',
        icon: 'fa-regular fa-arrows-left-right-to-line',
    },
];

export const optionsUnit = [
    {
        value: 'in',
        label: 'Inches',
    },
    {
        value: 'm',
        label: 'Meters',
    },
    {
        value: 'dm',
        label: 'Decimeters',
    },
    {
        value: 'cm',
        label: 'Centimeters',
    },
    {
        value: 'mm',
        label: 'Millimeters',
    },
    {
        value: 'feet',
        label: 'Feet',
    },
    {
        value: 'miles',
        label: 'Miles',
    },
    {
        value: 'km',
        label: 'Kilometers',
    },
    {
        value: 'microinch',
        label: 'MicroInches',
    },
];

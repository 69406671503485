/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-const */
/* eslint-disable no-extra-boolean-cast */

import styles from '../ModalControll.module.scss';
import { Button, Input, Modal, Popconfirm, Popover, Select, Switch } from 'antd';
import classNames from 'classnames/bind';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    regexCheckPostCode,
    regexCheckTypeNum,
    regexEmail,
    regexNotSpecialCharacter,
    regexPhoneNum,
} from '~/helper/regex';
import { updateCustomer, uploadCustomer } from '~/store/generalData/generalAction';
import { generalSelector } from '~/store/generalData/generalSlice';
import { clearModalStateModalAction } from '~/store/modal/ModalSlice';

const cx = classNames.bind(styles);

const defaultInputData = [
    {
        id: 'name',
        label: 'name',
        show: true,
        type: 'text',
        value: '',
        error: false,
    },
    {
        id: 'street',
        label: 'street',
        show: true,
        type: 'text',
        value: '',
        error: false,
    },
    {
        id: 'postcode',
        label: 'postcode',
        show: true,
        type: 'postcode',
        value: '',
        error: false,
    },
    {
        id: 'city',
        label: 'city',
        show: true,
        type: 'text',
        value: '',
        error: false,
    },
    {
        id: 'telephone',
        label: 'telephone',
        show: true,
        type: 'tel',
        value: '',
        error: false,
    },
    {
        id: 'email',
        label: 'email',
        show: true,
        type: 'email',
        value: '',
        error: false,
    },
];

function GeneralForm({ dataRender, modalOpen, data = null }) {
    const [inputData, setInputData] = useState(defaultInputData);

    const [newFieldName, setNewFieldName] = useState('');
    const [newFieldErr, setNewFieldErr] = useState(null);
    const [checked, setCheked] = useState(true);
    const [newFieldOpen, setNewFieldOpen] = useState(false);
    const [searchList, setSearchList] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [errCustomer, setErrCustomer] = useState(false);

    const newFieldRef = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { customerList } = useSelector(generalSelector);

    useEffect(() => {
        if (modalOpen && customerList) {
            const resetList = defaultInputData.map((item) => ({ ...item, value: '', error: false }));
            setInputData(resetList);
            const newArr = [...customerList].map((item) => ({
                value: item.name,
                label: item.name,
                id: item.id,
                type: 0,
            }));
            setSearchList(newArr);
        }
    }, [modalOpen, customerList]);

    useEffect(() => {
        if (!data) return;
        let newList = [];
        newList = defaultInputData.map((input) => {
            const keys = Object.keys(data);
            keys.map((key) => {
                if (key === input.id) {
                    if (!data[key]) {
                        input.value = '';
                    } else {
                        input.value = data[key];
                    }
                    return { ...input };
                }
            });

            return { ...input };
        });
        if (!!data.dynamicFields && data.dynamicFields !== '') {
            const parseData = JSON.parse(data.dynamicFields);
            newList = [...newList, ...parseData];
        }

        if (data.type === 1) {
            const customerChoosed = searchList.find((item) => item.id === data?.customerId);
            if (!customerChoosed) {
                setCustomer(null);
            } else {
                setCustomer(customerChoosed);
            }
        }

        setInputData(newList);
    }, [data, searchList]);

    const handleSubmit = useCallback(() => {
        const errorField = [];
        const request = {
            type: 0,
            name: '',
            street: '',
            postcode: '',
            city: '',
            telephone: null,
            email: null,
            dynamicFields: '',
        };
        let newData = [];
        const newInputList = inputData.map((input) => {
            if (input.value !== '') {
                if (input.id === 'telephone') {
                    const validatePhone = regexPhoneNum.test(input.value);
                    if (validatePhone) {
                        input.error = false;
                        request[input.id] = input.value;
                    } else {
                        input.error = true;
                        errorField.push(input.label);
                    }
                }
                if (input.id === 'email') {
                    const validateEmail = regexEmail.test(input.value);
                    if (validateEmail) {
                        input.error = false;
                        request[input.id] = input.value;
                    } else {
                        input.error = true;
                        errorField.push(input.label);
                    }
                }

                if (input.new) {
                    newData.push(input);
                } else {
                    if (input.id !== 'email' && input.id !== 'telephone') {
                        input.error = false;
                        request[input.id] = input.value;
                    }
                }
            } else {
                if (input.id === 'telephone' || input.id === 'email') return { ...input, error: false };
                input.error = true;
                errorField.push(input.label);
            }
            return { ...input };
        });

        if (errorField.length > 0) {
            if (!customer && dataRender === 'locations') {
                setErrCustomer(true);
            }
            setInputData(newInputList);
            return;
        }
        if (newData.length > 0) {
            request.dynamicFields = JSON.stringify(newData);
        }

        if (dataRender === 'customers') {
            request.type = 0;
        }
        if (dataRender === 'locations') {
            if (!customer) {
                setErrCustomer(true);
                return;
            }
            request.customerId = customer.id;
            request.customer = customer.value;
            request.type = 1;
        }
        if (dataRender === 'operators') {
            request.type = 2;
        }
        handleCancel();
        if (data) {
            dispatch(updateCustomer({ id: data.id, data: request, t }));
        } else {
            dispatch(uploadCustomer({ request, t }));
        }
    }, [inputData, customer]);

    const handleChangeInput = useCallback(
        (id, value) => {
            setInputData(() =>
                inputData.map((input) => {
                    if (input.id === id) {
                        if (input.type === 'tel') {
                            if (!regexCheckTypeNum.test(value)) return { ...input };
                        }
                        if (input.type === 'postcode') {
                            if (!regexCheckPostCode.test(value)) return { ...input };
                        }
                        input.value = value;
                        input.error = false;
                    }
                    return { ...input };
                }),
            );
        },
        [inputData],
    );

    const handleShowContent = useCallback(
        (id) => {
            const newListInput = inputData.map((input) => {
                if (input.id === id) {
                    input.show = !input.show;
                }
                return { ...input };
            });
            setInputData(newListInput);
        },
        [inputData],
    );

    const handleAddNewField = useCallback(() => {
        if (newFieldName !== '') {
            const newId = newFieldName.split(' ').join('');
            let checkIdExist = inputData.find((input) => input.id.toLowerCase() === newId.toLowerCase());

            if (!!checkIdExist) {
                setNewFieldErr('field_name_is_exist');
                newFieldRef.current.focus();
            } else if (!regexNotSpecialCharacter.test(newId)) {
                setNewFieldErr('do_not_use_special_characters');
                newFieldRef.current.focus();
            } else {
                setNewFieldOpen(false);
                setNewFieldName('');
                if (checked) {
                    setInputData([
                        ...inputData,
                        {
                            id: newId,
                            label: newFieldName,
                            show: false,
                            type: 'text',
                            new: true,
                            value: '',
                            eye: true,
                        },
                    ]);
                } else {
                    setInputData([
                        ...inputData,
                        {
                            id: newId,
                            label: newFieldName,
                            show: true,
                            type: 'text',
                            new: true,
                            value: '',
                        },
                    ]);
                }
            }
        } else {
            setNewFieldErr(`${t('please_type_something')}!`);
        }
    }, [newFieldName, inputData, newFieldName]);

    const handleDeleteField = useCallback(
        (id) => {
            setInputData(() => inputData.filter((input) => input.id !== id));
        },
        [inputData],
    );

    const handleCancel = () => {
        dispatch(clearModalStateModalAction());
    };

    const handleChooseCustomer = useCallback(
        (value) => {
            const result = searchList.find((item) => item.label === value);
            if (!!result) {
                setErrCustomer(false);
                setCustomer(result);
            }
        },
        [searchList],
    );

    return (
        <Modal
            key={dataRender}
            title={t(`${data ? t('edit') : t('new')} ${t(dataRender)}`)}
            open={modalOpen}
            onCancel={handleCancel}
            footer={null}
            bodyStyle={{ padding: '10px 20px 0' }}
        >
            {dataRender === 'locations' && (
                <div className={cx('customer-container')}>
                    <Select
                        key="customer"
                        style={{ width: '100%', textTransform: 'none' }}
                        npm
                        showSearch
                        placeholder={`${t('customer')}*`}
                        optionFilterProp="children"
                        onChange={handleChooseCustomer}
                        className={cx('input-custom', 'input__field')}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={searchList}
                        value={customer}
                    />
                    {errCustomer && (
                        <span className={cx('err-mess')}>
                            <i className="fa-solid fa-triangle-exclamation" />{' '}
                            <span
                                style={{
                                    textTransform: 'math-auto',
                                }}
                            >
                                {t('this_field_is_invalid')}!
                            </span>
                        </span>
                    )}
                </div>
            )}
            {inputData.map((input, index) => (
                <div key={input.id} style={{ marginBottom: 14, textTransform: 'none' }} className={cx('form-item')}>
                    <label className={cx('input')} key={input.id}>
                        <Input
                            key={input.id}
                            className={cx('input-custom', 'input__field', input.value !== '' && 'input__has-value')}
                            placeholder={'  '}
                            value={input.value}
                            onChange={(e) => handleChangeInput(input.id, e.target.value)}
                            type={input.show ? input.type : 'password'}
                            status={input.error ? 'error' : ''}
                        />
                        <span className={cx('input__label')}>
                            {t(input.label)}
                            {index < 4 && '*'}
                        </span>
                    </label>

                    {input?.new && (
                        <div key={input.name} style={{ position: 'relative', textTransform: 'none' }}>
                            <div className={cx('actions-new-field')}>
                                {input?.eye && (
                                    <i
                                        className={input.show ? 'fa-sharp fa-solid fa-eye-slash' : 'fa-solid fa-eye'}
                                        style={{ marginRight: 14 }}
                                        onClick={() => handleShowContent(input.id)}
                                    />
                                )}
                                <Popconfirm
                                    title={t('are_you_sure')}
                                    onConfirm={() => handleDeleteField(input.id)}
                                    okText={t('yes')}
                                    cancelText={t('no')}
                                >
                                    <div>
                                        <i className="fa-solid fa-trash" />
                                    </div>
                                </Popconfirm>
                            </div>
                        </div>
                    )}
                    {input.error && (
                        <span className={cx('err-mess')}>
                            <i className="fa-solid fa-triangle-exclamation" />{' '}
                            <span>{t('this_field_is_invalid')}!</span>
                        </span>
                    )}
                </div>
            ))}

            <div className={cx('new-field')} key="newField">
                <Popover
                    key="popup"
                    content={
                        <div className={cx('popover-modal')}>
                            <Input
                                ref={newFieldRef}
                                style={{ textTransform: 'none' }}
                                className={cx('input-custom')}
                                placeholder={`${t('new_field')}*`}
                                value={newFieldName}
                                onChange={(e) => {
                                    setNewFieldName(e.target.value);
                                    setNewFieldErr(null);
                                }}
                            />
                            {newFieldErr && (
                                <span className={cx('err-mess')}>
                                    <i className="fa-solid fa-triangle-exclamation" /> <span>{t(newFieldErr)}</span>
                                </span>
                            )}
                            <div className={cx('switch-btn')}>
                                <Switch
                                    style={{ backgroundColor: checked ? 'var(--primary-color)' : '' }}
                                    size="large"
                                    onClick={() => {
                                        const current = checked;
                                        setCheked(!current);
                                    }}
                                    defaultChecked
                                />
                                <span style={{ marginLeft: 8, fontWeight: 500 }}>{t('hide_content')}</span>
                            </div>
                            <Button
                                className={cx('btn-custom_full-width', 'custom-btn')}
                                type="primary"
                                onClick={handleAddNewField}
                            >
                                {t('add')}
                            </Button>
                        </div>
                    }
                    title={<h3>{t('new_field')}</h3>}
                    placement="topLeft"
                    onOpenChange={(newOpen) => {
                        setNewFieldOpen(newOpen);
                        setNewFieldName('');
                    }}
                    open={newFieldOpen}
                    showArrow={false}
                    trigger="click"
                >
                    <Button key="add" className={cx('new-field__btn')} onClick={() => setNewFieldOpen(!newFieldOpen)}>
                        {t('new_field')}{' '}
                        <i
                            className={newFieldOpen ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down'}
                            style={{ marginLeft: 4 }}
                        />
                    </Button>
                </Popover>
            </div>

            <Button
                key="save"
                className="btn-custom btn-custom-large btn-custom_full-width mb-25"
                type="primary"
                size="large"
                onClick={handleSubmit}
            >
                {t('save')}
            </Button>
        </Modal>
    );
}

export default memo(GeneralForm);

/* eslint-disable no-unsafe-optional-chaining */
export function draw_A3_MainContent(doc, leftMost, topMost, mainContent, compressOption) {
    // --------------------------------------------------------
    // Draw a red rectangle that bounds the image for testing purposes
    // const width = 375
    // const height = width * 9 / 16

    // // Config drawing size
    // const drawingInfo = {
    //   left: leftMost + 7, // mm
    //   top: topMost + 5, // mm
    //   width: mainContent?.width + 2, // mm
    //   height: mainContent?.height + 2, // mm
    // };
    // // Set color of drawing to Red
    // doc.setDrawColor(255, 0, 0)
    // doc.rect(
    //   drawingInfo.left,
    //   drawingInfo.top,
    //   drawingInfo.width,
    //   drawingInfo.height
    // );
    // // Reset color of drawing
    // doc.setDrawColor(0, 0, 0)

    // console.log({leftMost, topMost})

    // --------------------------------------------------------

    doc.addImage(
        mainContent?.src,
        'WEBP',
        leftMost + 7,
        topMost + 5,
        mainContent?.width,
        mainContent?.height,
        undefined,
        compressOption || 'FAST', // compression of image
    );
}

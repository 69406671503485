import { call } from "redux-saga/effects";

/**
 * Helper for adding prefix to each action type
 *
 * @param prefix
 * @param actionTypes
 * @returns
 */
export function addPrefixActionType(prefix, actionTypes) {
    const expectedActionTypes = {};
    Object.entries(actionTypes).forEach(([key, value]) => {
        expectedActionTypes[key] = prefix + value;
    });
    return expectedActionTypes;
}

/**
 * Helper Generator for call api
 * @param request - Request Wrapper with default success/error actions
 * @param payload - JsonPayloadType
 * @returns
 */
export function* safeCall(request, payload) {
  try {
    return { response: yield call(request, payload) };
  } catch (error) {
    return { error };
  }
}

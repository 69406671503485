import {
    deleteRoom,
    detachLabelFromRoom,
    mapCoordinatesToModel,
    transformPolygonToRoom,
    turnOffSelectionInViewer,
} from '../../forge-viewer-tool';
import { reversePoints } from '../../radius-erase/radius-erase-function';
import { Room } from './room';
import { RoomStatic } from './roomStatic';
import { Box2 } from 'three-full';

export const RoomsStatic = {
    trackingIncrementOfRooms: 0,
    rooms: {}, // rooms object
    createRoom(
        roomId,
        currentPoints,
        boxPoints,
        scale,
        layerCurrentPoints,
        layerBoxPoints,
        polyId,
        name,
        width,
        widthLayer,
        height,
        heightLayer,
        minX,
        minY,
        maxX,
        maxY,
        scaleLength,
        isLayer,
        isOpen,
        roomSetting,
    ) {
        const room = new Room(
            roomId,
            currentPoints,
            boxPoints,
            scale,
            layerCurrentPoints,
            layerBoxPoints,
            polyId,
            name,
            width,
            widthLayer,
            height,
            heightLayer,
            minX,
            minY,
            maxX,
            maxY,
            scaleLength,
            isLayer,
            isOpen,
            roomSetting,
        );

        this.rooms[roomId] = room;
        return room;
    },

    /**
     * Get all rooms data from Hub
     *
     * @param {*} roomsDataFromHub
     * @returns
     */
    initializeRoomsStatic(roomsDataFromHub) {
        if (roomsDataFromHub === null || roomsDataFromHub === undefined) {
            return;
        }

        // reset first to avoid duplicated
        this.rooms = {};

        // Tracking incremented number of rooms, that supports for labeling room name
        this.trackingIncrementOfRooms = roomsDataFromHub?.length;

        roomsDataFromHub.forEach((room, index) => {
            let roomName = room.name;

            if (!roomName) {
                roomName = `Id: ${index + 1}`;
            }

            const roomBefore = {
                name: roomName,
                roomId: room.roomId,
                polyId: room.roomId,
                isOpen: false,
                isLayer: true,
                scale: room.scale,
                currentPoints: room.currentPoints,
                boxPoints: room.boxPoints,
                roomSetting: room?.roomSetting || {},
            };

            const roomAfter = transformPolygonToRoom(roomBefore);

            const roomId = roomAfter.roomId;
            const currentPoints = roomAfter.currentPoints;
            const boxPoints = roomAfter.boxPoints;
            const scale = roomAfter.scale;
            const layerCurrentPoints = roomAfter.layerCurrentPoints;
            const layerBoxPoints = roomAfter.layerBoxPoints;
            const polyId = roomAfter.polyId;
            const name = roomAfter.name;
            const width = roomAfter.width;
            const widthLayer = roomAfter.widthLayer;
            const height = roomAfter.height;
            const heightLayer = roomAfter.heightLayer;
            const minX = roomAfter.minX;
            const minY = roomAfter.minY;
            const maxX = roomAfter.maxX;
            const maxY = roomAfter.maxY;
            const scaleLength = roomAfter.scaleLength;
            const isLayer = true;
            const isOpen = false;
            const roomSetting = roomAfter.roomSetting;

            this.createRoom(
                roomId,
                currentPoints,
                boxPoints,
                scale,
                layerCurrentPoints,
                layerBoxPoints,
                polyId,
                name,
                width,
                widthLayer,
                height,
                heightLayer,
                minX,
                minY,
                maxX,
                maxY,
                scaleLength,
                isLayer,
                isOpen,
                roomSetting,
            );
        });
    },

    // =======================================================================
    // Get all room from rooms object
    // =======================================================================

    getRooms() {
        return this.rooms;
    },

    getRoomById(id) {
        const hasRoom = Boolean(this.rooms[id]);

        if (!hasRoom) {
            return undefined;
        }

        return this.rooms[id];
    },

    // =======================================================================
    // Rooms actions in viewer
    // =======================================================================

    toggleRoomsInViewer(isOpenAll) {
        // Turn off all selection in viewer
        turnOffSelectionInViewer();

        // toggle each room
        Object.keys(this.rooms).forEach((r) => this.rooms[r].toggleRoomInViewer(isOpenAll));
    },

    addRoomInViewer(newPolygon) {
        // Turn off all selection in viewer
        turnOffSelectionInViewer();

        let roomAfter = {};

        if (newPolygon.name === undefined) {
            // let count = 0;

            // Object.keys(this.rooms).forEach(() => {
            //     count += 1;
            // });

            const newCount = this.trackingIncrementOfRooms + 1;

            // increment the tracking counter
            this.trackingIncrementOfRooms += 1;

            const bbox = newPolygon.bbox;
            const boundingBox = [
                bbox.min,
                { x: bbox.min.x, y: bbox.max.y },
                bbox.max,
                { x: bbox.max.x, y: bbox.min.y },
            ];

            // Set room setting from RoomStatic
            const roomSetting = RoomStatic.roomSetting;

            const roomBefore = {
                name: `Id: ${newCount}`,
                roomId: newPolygon.id,
                polyId: newPolygon.id,
                isOpen: true,
                isLayer: true,
                scale: 1,
                currentPoints: newPolygon.points.map((point) => mapCoordinatesToModel(point)),
                boxPoints: boundingBox.map((point) => mapCoordinatesToModel(point)),
                layerCurrentPoints: newPolygon.points.map((point) => ({ x: point.x, y: point.y, z: 0 })),
                layerBoxPoints: boundingBox,
                roomSetting,
            };

            roomAfter = transformPolygonToRoom(roomBefore);
        } else {
            roomAfter = {
                roomId: newPolygon.roomId,
                currentPoints: newPolygon.currentPoints,
                boxPoints: newPolygon.boxPoints,
                scale: newPolygon.scale,
                layerCurrentPoints: newPolygon.layerCurrentPoints,
                layerBoxPoints: newPolygon.layerBoxPoints,
                polyId: newPolygon.polyId,
                name: newPolygon.name,
                width: newPolygon.width,
                widthLayer: newPolygon.widthLayer,
                height: newPolygon.height,
                heightLayer: newPolygon.heightLayer,
                minX: newPolygon.minX,
                minY: newPolygon.minY,
                maxX: newPolygon.maxX,
                maxY: newPolygon.maxY,
                scaleLength: newPolygon.scaleLength,
                isLayer: newPolygon.isLayer,
                isOpen: newPolygon.isOpen,
                roomSetting: newPolygon.roomSetting,
            };
        }

        const newRoom = new Room(
            roomAfter.roomId,
            roomAfter.currentPoints,
            roomAfter.boxPoints,
            roomAfter.scale,
            roomAfter.layerCurrentPoints,
            roomAfter.layerBoxPoints,
            roomAfter.polyId,
            roomAfter.name,
            roomAfter.width,
            roomAfter.widthLayer,
            roomAfter.height,
            roomAfter.heightLayer,
            roomAfter.minX,
            roomAfter.minY,
            roomAfter.maxX,
            roomAfter.maxY,
            roomAfter.scaleLength,
            roomAfter.isLayer,
            roomAfter.isOpen,
            roomAfter.roomSetting,
        );

        newRoom.toggleRoomInViewer(newRoom.isOpen);

        this.rooms[newRoom.roomId] = newRoom;
    },

    deleteRoomInViewer(roomId) {
        // Turn off all selection in viewer
        turnOffSelectionInViewer();

        const label = window?.roomLabel.find((l) => l.roomId === roomId);
        if (label) {
            detachLabelFromRoom(label);
        }
        deleteRoom(roomId);

        delete this.rooms[roomId];
    },

    changeRoomPointsInViewer(room) {
        if (room.name === undefined) {
            const { id /* points */ } = room;

            const roomNeedToChange = this.rooms[id];

            // Arrange points by clockwise direction
            const points = reversePoints(room.points);

            // Get bounding box from arranged points
            const bbox = new Box2();
            bbox.setFromPoints(points);

            const boundingBox = [
                bbox.min,
                { x: bbox.min.x, y: bbox.max.y },
                bbox.max,
                { x: bbox.max.x, y: bbox.min.y },
            ];

            const roomId = roomNeedToChange.roomId;
            const polyId = roomNeedToChange.polyId;
            const name = roomNeedToChange.name;
            const scale = roomNeedToChange.scale;
            const isOpen = roomNeedToChange.isOpen;
            const isLayer = roomNeedToChange.isLayer;
            const currentPoints = points.map((point) => mapCoordinatesToModel(point));
            const boxPoints = boundingBox.map((point) => mapCoordinatesToModel(point));
            const layerCurrentPoints = points.map((point) => ({ x: point.x, y: point.y, z: 0 }));
            const layerBoxPoints = boundingBox;
            const roomSetting = roomNeedToChange.roomSetting;

            const roomBefore = {
                roomId,
                polyId,
                name,
                scale,
                isOpen,
                isLayer,
                currentPoints,
                boxPoints,
                layerCurrentPoints,
                layerBoxPoints,
                roomSetting,
            };

            const roomAfter = transformPolygonToRoom(roomBefore);

            const newRoom = new Room(
                roomAfter.roomId,
                roomAfter.currentPoints,
                roomAfter.boxPoints,
                roomAfter.scale,
                roomAfter.layerCurrentPoints,
                roomAfter.layerBoxPoints,
                roomAfter.polyId,
                roomAfter.name,
                roomAfter.width,
                roomAfter.widthLayer,
                roomAfter.height,
                roomAfter.heightLayer,
                roomAfter.minX,
                roomAfter.minY,
                roomAfter.maxX,
                roomAfter.maxY,
                roomAfter.scaleLength,
                roomAfter.isLayer,
                roomAfter.isOpen,
                roomAfter.roomSetting,
            );

            newRoom.toggleRoomInViewer(newRoom.isOpen);

            this.rooms[newRoom.roomId] = newRoom;
        } else {
            turnOffSelectionInViewer();
            // Need to check
            this.rooms[room.roomId] = room;

            room.toggleRoomInViewer(room.isOpen);
        }
    },

    updateRoomSettingForAllRooms(setting) {
        RoomStatic.roomSetting = setting;

        if (this.rooms) {
            Object.values(this.rooms).forEach((room) => {
                this.rooms[room.roomId].roomSetting = setting;
            });
        }
    },

    updateRoomSettingByRoomId(payloadRoomSettingById) {
        const { roomSetting, roomId } = payloadRoomSettingById;

        const hasRoom = Boolean(this.rooms[roomId]);

        if (hasRoom) {
            this.rooms[roomId].roomSetting = roomSetting;
        }
    },

    // =======================================================================
    // Clear Cache
    // =======================================================================

    clearCache() {
        Object.values(this.rooms).forEach((room) => {
            room.clearCache();
        });
    },
};

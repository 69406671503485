// import { drawTestLine } from "../../utils";

// Page size:
// Width: 297mm - 11.69inch
// Height: 210mmm - 8.26inch

export function draw_A4_FramePage(doc, leftMost, topMost) {
    leftMost += 20;
    topMost += 10;

    // // Horizontal line
    // drawTestLine(doc, {x: 267, y: 0}, {left: 20, top: 10})
    // // Vertical line
    // drawTestLine(doc, {x: 0, y: 200}, {left: 20, top: 10})

    // left: 297(pageWidth) -20(fromLeft) - 10(fromRight) = 267,
    // top: 210(pageHeight) -10(fromTop) - 10(fromBottom) = 190

    const framePageWidth = 267; // should be set based on page size

    const framePageHeight = 190; // should be set based on page size

    const drawingFrame = {
        left: leftMost, // mm
        top: topMost, // mm
        width: framePageWidth, // mm
        height: framePageHeight, // mm
    };
    doc.rect(drawingFrame.left, drawingFrame.top, drawingFrame.width, drawingFrame.height);

    const leftFramePage = leftMost;
    const topFramePage = topMost;

    return [leftFramePage, topFramePage];
}

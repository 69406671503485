/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */

import DraggableFSChildModal from './DraggableFSChildModal';
import styles from './index.module.scss';
import { useDebounce } from 'ahooks';
import { Button, Col, Input, Modal, Row, Table, Typography } from 'antd';
import classNames from 'classnames/bind';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RgbColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import AppLoading from '~/components/AppLoading';
import {
    findShapeIdListByRegex,
    removeRoomWithColor,
    toggleRoomWithColor,
} from '~/helper/ForgeViewer/forge-viewer-tool';
import { RoomsStatic } from '~/helper/ForgeViewer/speam/rooms/roomsStatic';
import { filesList } from '~/store/files/filesSlice';
import { clearModalStateModalAction } from '~/store/modal/ModalSlice';

// set 'cx' as Css class names
const cs = classNames.bind(styles);

const { Paragraph, Text } = Typography;

// ==============================================================================
const clearAllColoredRooms = async () => {
    const regexFloorId = /^floor-preview/;
    const allRoomIdList = findShapeIdListByRegex(regexFloorId) ?? [];

    if (allRoomIdList && allRoomIdList.length > 0) {
        allRoomIdList.forEach((id) => {
            removeRoomWithColor(id);
        });
    }
};

// ==============================================================================

const NewNameInput = React.forwardRef((props, ref) => {
    const { t } = useTranslation();

    return (
        <div style={{ position: 'relative' }}>
            <Text
                style={{
                    color: 'var(--primary-color)',
                    fontWeight: 600,
                    fontSize: '0.8rem',
                    lineHeight: '1.4375em',
                    letterSpacing: '0.00938em',
                    paddingBlock: 0,
                    paddingInline: '4px',
                    position: 'absolute',
                    left: '12px',
                    top: '2px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 'calc(133% - 24px)',
                    display: 'block',
                    backgroundColor: '#ffffff',
                    borderRadius: '25px',
                    pointerEvents: 'none',
                    userSelect: 'none',
                    WebkitTransform: 'translate(-4px, -13px)',
                    msTransform: 'translate(-4px, -13px)',
                    transform: 'translate(-4px, -13px)',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                    zIndex: 1,
                    textTransform: 'none',
                }}
            >
                {props?.title}
            </Text>
            <Input {...props} ref={ref} placeholder={t('please_type_something')} />
        </div>
    );
});

// ==============================================================================

/**
 * Base component that renders FloorSettingsChildModal
 */
export default function FloorSettingsChildModal({
    title,
    isNew = undefined,
    onChange,
    defaultValues,
    modalOpen,
    modalData,
    dataRooms,
    dataUnit,
}) {
    // helper for Closing RoomItemSettingForm
    const { isFetchingSaveFile } = useSelector(filesList);

    // State for row key
    const [selectedRowKeys, setSelectedRowKeys] = useState(defaultValues?.roomIdList || []);
    const [rgbColor, setRgbColor] = useState(defaultValues?.rgbColor || { r: 0, g: 0, b: 0 });

    // State for Input new name setting
    const [newNameSetting, setNewNameSetting] = useState(defaultValues?.subtitle || '');
    const newNameInputRef = useRef();

    const newNameSettingDebounce = useDebounce(newNameSetting, { wait: 500 });

    const rgbColorDebounce = useDebounce(rgbColor, { wait: 500 });

    // State for Draggable
    const [disabled, setDisabled] = useState(true);

    // helper for translation
    const { t } = useTranslation();

    // dispatch action to redux store
    const dispatch = useDispatch();

    const roomNameList = useMemo(() => {
        const data = dataRooms?.map((room) => {
            return {
                text: room.name,
                value: room.name,
            };
        });

        return data;
    }, [dataRooms]);

    // ----------------------------------------------------------------
    // Helper for toggle room selection in table view
    // ----------------------------------------------------------------
    useEffect(() => {
        const roomIdList = [...selectedRowKeys];
        const regexFloorId = /^floor-preview/;

        if (roomIdList.length === 0) {
            clearAllColoredRooms();
        }

        if (roomIdList.length > 0) {
            const roomShapes = roomIdList
                .map((id) => {
                    return RoomsStatic.getRoomById(id);
                })
                .map((room) => {
                    const roomId = `floor-preview-${room.roomId}`;

                    const newRoom = {
                        roomId,
                        layerCurrentPoints: room.layerCurrentPoints,
                        name: room.name,
                        rgbColor: rgbColorDebounce,
                    };

                    return newRoom;
                });

            // Should create new classes for each room with new ID

            const acceptedIdList = [];
            const ignoredIdList = [];

            const handleShowRoom = async () => {
                roomShapes.forEach((room) => {
                    acceptedIdList.push(room.roomId);
                    toggleRoomWithColor(true, room.roomId, room.name, room.layerCurrentPoints, room.rgbColor);
                });
            };

            handleShowRoom();

            const allRoomIdList = findShapeIdListByRegex(regexFloorId) ?? [];

            if (allRoomIdList && allRoomIdList.length > 0) {
                allRoomIdList.forEach((id) => {
                    const sameId = acceptedIdList.includes(id);
                    if (!sameId) {
                        ignoredIdList.push(id);
                    }
                });
            }

            if (ignoredIdList && ignoredIdList.length > 0) {
                ignoredIdList.forEach((id) => {
                    removeRoomWithColor(id);
                });
            }
        }
    }, [selectedRowKeys.length, rgbColorDebounce]);

    /**
     * Confirm Modal...
     */
    const handleOnOkModal = useCallback(async () => {
        let data = {};

        if (defaultValues && defaultValues?.id?.length > 0) {
            data = {
                id: `${defaultValues?.id}`,
                title,
                subtitle: newNameSettingDebounce,
                roomIdList: selectedRowKeys,
                rgbColor,
            };
        } else if (isNew && newNameSettingDebounce) {
            data = {
                id: `${title}-${uuid()}`,
                title,
                subtitle: newNameSettingDebounce,
                roomIdList: selectedRowKeys,
                rgbColor,
            };
        } else if (!isNew && newNameSettingDebounce) {
            data = {
                id: `${title}-${uuid()}`,
                title,
                subtitle: newNameSettingDebounce,
                roomIdList: selectedRowKeys,
                rgbColor,
            };
        }

        onChange(data);

        clearAllColoredRooms();
        dispatch(clearModalStateModalAction());
    }, [defaultValues, selectedRowKeys, rgbColor, title, newNameSettingDebounce, isNew]);

    /**
     * Cancel the modal
     */
    const handleOnCancel = () => {
        clearAllColoredRooms();
        dispatch(clearModalStateModalAction());
    };
    /**
     * Render Modal Footer with Buttons
     */
    const renderModalFooter = () => {
        return (
            <Row key="fs-modal-footer-fi-r" justify="end">
                <Col key="fs-modal-footer-fi-r-c2" flex="70%">
                    <Button
                        key="fs-modal-footer-fi-r-c1-btn-apply-to-all-rooms"
                        htmlType="button"
                        className={cs('btn-custom-ghost')}
                        onClick={() => handleOnCancel()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        key="fs-modal-footer-fi-r-c1-btn-apply-to-this-room"
                        type="primary"
                        htmlType="submit"
                        className={cs('btn-custom')}
                        onClick={async () => {
                            if (isNew) {
                                if (!newNameSettingDebounce) {
                                    newNameInputRef?.current?.focus();
                                    return;
                                }
                                await handleOnOkModal();
                            } else {
                                if (!newNameSettingDebounce) {
                                    newNameInputRef?.current?.focus();
                                    return;
                                }
                                await handleOnOkModal();
                            }
                        }}
                    >
                        {t('confirm')}
                    </Button>
                </Col>
            </Row>
        );
    };

    // ----------------------------------------------------------------
    // Config Column for table
    // ----------------------------------------------------------------
    const columns = [
        {
            title: () => (
                <div style={{ fontSize: '0.85rem' }}>{`${selectedRowKeys.length >= 2 ? t('rooms') : t('room')}`}</div>
            ),
            dataIndex: 'name',
            filters: [...roomNameList],
            filterSearch: true,
            onFilter: (value, record) => record.name.startsWith(value),
            width: '30%',
            render: (name) => (
                <p style={{ fontSize: '0.8rem', padding: 0, margin: 0, minWidth: '10px', width: '100%' }}>{name}</p>
            ),
        },
        {
            title: () => <div style={{ fontSize: '0.85rem' }}>{`${t('width')} (${dataUnit})`}</div>,
            dataIndex: 'width',
            // width: 80,
            width: '35%',
            render: (num) => (
                <NumericFormat
                    thousandSeparator={t(',')}
                    decimalSeparator={t('.')}
                    displayType="text"
                    value={num}
                    style={{ fontSize: '0.8rem' }}
                />
            ),
        },
        {
            title: () => <div style={{ fontSize: '0.85rem' }}>{`${t('length')} (${dataUnit})`}</div>,
            dataIndex: 'height',
            width: '35%',
            render: (num) => (
                <NumericFormat
                    thousandSeparator={t(',')}
                    decimalSeparator={t('.')}
                    displayType="text"
                    value={num}
                    style={{ fontSize: '0.8rem' }}
                />
            ),
        },
    ];
    return (
        <div>
            <Modal
                key={`key-${title}`}
                mask={false}
                maskClosable={false}
                className={cs('fsc-modal')}
                data-id="fsc-modal"
                style={{ top: 0, right: 0 }}
                title={
                    <div
                        style={{
                            width: '100%',
                            cursor: 'move',
                        }}
                        onMouseOver={() => {
                            if (disabled) {
                                setDisabled(false);
                            }
                        }}
                        onMouseOut={() => {
                            setDisabled(true);
                        }}
                        onFocus={() => {}}
                        onBlur={() => {}}
                    >
                        <Paragraph
                            style={{
                                margin: 0,
                                display: 'inline-block',
                                fontSize: '1rem',
                                fontWeight: 400,
                                color: '#222020',
                                userSelect: 'none',
                                pointerEvents: 'none',
                            }}
                        >
                            <span style={{ fontWeight: 600, marginRight: '6px' }}>{`${t(title)}`}</span>
                            <span style={{ fontSize: '1rem' }}>
                                {' ( '}
                                <span style={{ fontWeight: 600, marginRight: '6px' }}>
                                    {`${selectedRowKeys.length} / ${roomNameList.length}`}
                                </span>
                                {`${t('rooms')}`}
                                {' ) '}
                            </span>
                        </Paragraph>
                    </div>
                }
                open={modalOpen && modalData?.title === title}
                onCancel={() => handleOnCancel()}
                onOk={async () => {
                    await handleOnOkModal();
                }}
                footer={[<div key="fs-modal-footer">{renderModalFooter()}</div>]}
                bodyStyle={{
                    padding: '14px 20px 0px 20px',
                    height: '100%',
                }}
                width="375px"
                modalRender={(modal) => <DraggableFSChildModal modal={modal} disabled={disabled} />}
            >
                {/* Show app loading after click Send button */}
                {isFetchingSaveFile && <AppLoading menuTool />}

                {/* =========================================================== */}
                {/* ================  Render Main Content here ================ */}
                {/* =========================================================== */}
                <Row align="center" gutter={[24, 24]}>
                    {isNew && (
                        <Col span={24}>
                            <NewNameInput
                                title={`${t('name_of_setting')}${
                                    newNameSetting && newNameSetting.length > 0 ? '' : '*'
                                }`}
                                autoFocus
                                placeholder={`${t('please_type_something')}...`}
                                newNameRef={newNameInputRef}
                                value={newNameSetting}
                                onChange={(event) => {
                                    const text = event.target.value;
                                    setNewNameSetting(text);
                                }}
                            />
                        </Col>
                    )}
                    {!isNew && (
                        <Col span={24}>
                            <NewNameInput
                                title={`${t('name_of_setting')}${
                                    newNameSetting && newNameSetting.length > 0 ? '' : '*'
                                }`}
                                placeholder={`${t('please_type_something')}...`}
                                ref={newNameInputRef}
                                value={newNameSetting}
                                onChange={(event) => {
                                    const text = event.target.value;
                                    setNewNameSetting(text);
                                }}
                                defaultValue={newNameSettingDebounce}
                            />
                        </Col>
                    )}
                    <Col span={24}>
                        <div key="table-data" className={cs('table-container')}>
                            {/* ---------------------------------------------------- */}
                            {/* -------------- Render Table room list ---------------*/}
                            {/* ---------------------------------------------------- */}
                            <Table
                                rowKey={(record) => {
                                    // return the desired data when row(s) is/are checked
                                    return record.roomId;
                                }}
                                pagination={false} // show full elements of dataSource
                                rowSelection={{
                                    onChange: (newSelectedRowKeys) => {
                                        setSelectedRowKeys(newSelectedRowKeys);
                                    },
                                    // default selected row keys that is got from api
                                    selectedRowKeys,
                                }}
                                dataSource={dataRooms || []}
                                columns={columns}
                                size="small"
                                className={cs('table-room-list')}
                                style={{ width: '100%' }}
                                locale={{
                                    filterConfirm: <div>{`${t('ok')}`}</div>,
                                    filterReset: <div>{`${t('reset')}`}</div>,
                                    filterSearchPlaceholder: `${t('search_in_filters')}`
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div key="color-data" className={cs('color-container')}>
                            {/* ------------------------------------------------- */}
                            {/* -------------- Render Color picker ---------------*/}
                            {/* ------------------------------------------------- */}
                            <RgbColorPicker color={rgbColor} onChange={setRgbColor} className={cs('color-picker')} />

                            {/* ------------------------------------------------- */}
                            {/* ---------------- Render Color name ---------------*/}
                            {/* ------------------------------------------------- */}
                            <Paragraph
                                className={cs('color-name')}
                                style={{
                                    color: `rgb(${rgbColorDebounce?.r}, ${rgbColorDebounce?.g}, ${rgbColorDebounce?.b})`,
                                }}
                            >
                                {`rgb(${rgbColorDebounce?.r}, ${rgbColorDebounce?.g}, ${rgbColorDebounce?.b})`}
                            </Paragraph>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}

/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import toolReducer from './tool/toolSlice';
import modalReducer from './modal/ModalSlice';

import projectReducer from './project/projectSlice';
import generalReducer from './generalData/generalSlice';
import filesReducer from './files/filesSlice';
import binListReducer from './bin-list/binListSlice';
import viewerReducer from './viewer/viewer.slice';
import alarmDeviceReducer from './alarm-device/alarmDeviceSlice';
import symbolReducer from './symbol/symbolSlice';
import wireAlarmReducer from './wire-alarm/wireAlarm.slice';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { viewerPdfReducer } from './viewer-pdf/viewer-pdf.reducer'

// Saga
// import { loggerSaga } from './logger/logger.saga';
import { viewerPdfSaga } from './viewer-pdf/viewer-pdf.saga';

// const customizedMiddleWare = getDefaultMiddleware({
//     serializableCheck: false,
// });

const rootReducer = {
    auth: authReducer,
    project: projectReducer,
    files: filesReducer,
    binList: binListReducer,
    general: generalReducer,
    modal: modalReducer,
    viewer: viewerReducer,
    alarm: alarmDeviceReducer,
    tool: toolReducer,
    wireAlarm: wireAlarmReducer,
    symbol: symbolReducer,
    viewerPdf: viewerPdfReducer,
};

// Create saga middleware
const sageMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    // middleware: customizedMiddleWare,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(sageMiddleware),
});

// Run middleware saga
function* rootSaga() {
    yield all([
        // fork(loggerSaga),
        fork(viewerPdfSaga),
    ]);
}
// Run saga
sageMiddleware.run(rootSaga);


export default store;

/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */

import {
    deleteFile,
    deleteManyFile,
    exportFile,
    getAllFile,
    saveDataFile,
    searchAction,
    updateFile,
} from './fileActions';
import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';

import { removeFromBinListByFileIdBinListAction } from '~/store/bin-list/binListSlice';

const filesSlice = createSlice({
    name: 'files',
    initialState: {
        fileModal: null,
        files: [],
        deleteFiles: [],
        isFetchingFile: false,
        isErrorFile: null,
        uploadSuccess: false,
        uploadFailed: false,
        isFetchingSaveFile: false,
        isSuccessSaveFile: false,
        isErrorSaveFile: false,
        isFetchingExportFile: false,
        urlExportFile: null,
        searchResult: [],
    },
    reducers: {
        setFiles: (state, { payload }) => {
            state.files = payload;
        },
        setEmtyFile(state) {
            state.files = [];
            state.deleteFiles = [];
            state.isFetchingFile = false;
        },
        resetSup(state) {
            state.deleteFiles = null;
            state.isFetchingFile = false;
            state.isErrorFile = null;
            state.uploadSuccess = false;
            state.uploadFailed = false;
            state.isFetchingSaveFile = false;
            state.isSuccessSaveFile = false;
            state.isErrorSaveFile = false;
        },

        restoreFiles(state, action) {
            state.files.unshift(action.payload.result);
            state.isFetchingFile = false;
        },

        removeFile(state, action) {
            state.files = [...state.files.filter((item) => item.id !== action.payload)];
        },

        uploadStart(state, { payload }) {
            payload.listUpload.forEach((f) => {
                const data = {
                    status: 'upLoading',
                    name: '',
                    id: f.id,
                    onEdit: f.onEdit,
                    file: f.file,
                    percent: 0,
                    projectId: payload.id,
                    thumbnailLoading: true,
                };
                state.files.unshift(data);
            });
        },

        uploadPercent(state, { payload }) {
            state?.files.forEach((file) => {
                if (file.id === payload.id) {
                    file.percent = payload.percent;
                }
                return { ...file };
            });
        },

        uploadProcessing(state, { payload }) {
            state.files.forEach((item, index) => {
                if (item?.id === payload.key) {
                    state.files.splice(index, 1);
                    state.files.unshift({ ...payload, thumbnailLoading: false, status: 2 });
                }
            });
        },

        uploadFailed(state, { payload }) {
            state.files.forEach((file, index) => {
                if (file.id === payload.id) {
                    state.files.splice(index, 1, { status: 'error', ...payload });
                }
            });
            state.uploadSuccess = false;
            state.uploadFailed = true;
        },

        updateFileInfo(state, { payload }) {
            state.files.filter((item, index) => {
                if (item?.id === payload.id) {
                    state.files.splice(index, 1, payload);
                }
            });

            if (payload.thumbnailLoading === false) {
                state.uploadFailed = false;
                state.uploadSuccess = true;
            }
        },

        addToDeleteFiles(state, { payload }) {
            state.deleteFiles.push(payload);
        },
        removeFromDeleteFiles(state, { payload }) {
            state.deleteFiles = [...state.deleteFiles.filter((item) => item !== payload)];
        },
        setDeleteFile(state, { payload }) {
            state.deleteFiles = payload;
        },
        setFileModal: (state, { payload }) => {
            state.fileModal = payload;
        },
        setIsFetchingExport: (state, { payload }) => {
            state.isFetchingExportFile = payload;
        },
        setUrlExport: (state, { payload }) => {
            state.urlExportFile = payload;
        },
        setSearchResult: (state, { payload }) => {
            state.searchResult = payload;
        },
        replaceFile: (state, { payload }) => {
            const index = state.files.findIndex((item) => item.id === payload.id);
            if (index < 0) return;
            state.files.splice(index, 1, payload);
        },
    },
    extraReducers: {
        [getAllFile.pending]: (state) => {
            state.isFetchingFile = true;
        },
        [getAllFile.fulfilled]: (state, { payload }) => {
            state.isFetchingFile = false;
            state.files = payload.result;
        },
        [getAllFile.rejected]: (state) => {
            state.isFetchingFile = false;
            state.isErrorFile = true;
        },
        [updateFile.fulfilled]: (state, { payload }) => {
            const { data, type, id, t } = payload;
            let index;
            switch (type) {
                // case 'restore':
                //     dispatch(removeFromBinListByFileIdBinListAction(id));
                //     dispatch(restoreFiles(data));
                //     message.success({
                //         content: `${t('restore_success')}!`,
                //         duration: 0.5,
                //     });
                //     break;
                case 'delete':
                    state.files = [...state.files.filter((item) => item.id !== id)];
                    message.success({
                        content: `${t('delete_success')}!`,
                        duration: 0.5,
                        maxCount: 3,
                    });
                    break;

                case 'rename':
                    index = state.files.findIndex((item) => item.id === id);
                    state.files.splice(index, 1, data);

                    if (state.files.length > 0) {
                        const indexAll = state.files.findIndex((item) => item.id === id);
                        state.files.splice(indexAll, 1, data);
                    }
                    state.isFetchingFile = false;
                    message.success({
                        content: `${t('rename_success')}!`,
                        duration: 0.5,
                    });
                    return;
                default:
                    return;
            }
        },
        [deleteFile.fulfilled]: (state, { payload }) => {
            state.files = [...state.files.filter((item) => item.id !== payload)];
        },
        [saveDataFile.pending]: (state) => {
            state.isFetchingSaveFile = true;
            state.isSuccessSaveFile = false;
            state.isErrorSaveFile = false;
        },
        [saveDataFile.fulfilled]: (state, { payload }) => {
            state.isFetchingSaveFile = false;
            state.isErrorSaveFile = false;
            state.isSuccessSaveFile = true;
            const fileChange = state.files.find((file) => file.id === payload.id);
            const otherFiles = state.files.filter((file) => file.id !== payload.id);
            state.files = [{ ...fileChange, fileData: payload.fileData }, ...otherFiles];
        },
        [saveDataFile.rejected]: (state) => {
            state.isFetchingSaveFile = false;
            state.isErrorSaveFile = true;
            state.isSuccessSaveFile = false;
        },
        [exportFile.pending]: (state) => {
            state.isFetchingExportFile = true;
        },
        [exportFile.rejected]: (state, { payload }) => {
            message.error({
                content: payload?.errors[0],
            });
            state.isFetchingExportFile = false;
        },
        [searchAction.fulfilled]: (state, { payload }) => {
            state.searchResult = payload;
        },
        [searchAction.rejected]: (state, { payload }) => {
            state.searchResult = [];
            message.error({
                content: payload.errors[0],
                duration: 2,
            });
        },
    },
});

const { actions, reducer } = filesSlice;
export const {
    setFiles,
    resetSup,
    uploadPercent,
    removeFile,
    restoreFiles,
    uploadStart,
    uploadProcessing,
    uploadFailed,
    updateFileInfo,
    addToDeleteFiles,
    removeFromDeleteFiles,
    setEmtyFile,
    setDeleteFile,
    setFileModal,
    setIsFetchingExport,
    setUrlExport,
    setSearchResult,
} = actions;
export const filesList = (state) => state.files;
export default reducer;

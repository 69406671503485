/* eslint-disable react/prop-types */
import { Col, Row } from 'antd';
import FormItemLHSRoomNumberMeasurement from './FormItemLHSRoomNumberMeasurement';
import FormItemLHSQuestionCheckbox from './FormItemLHSQuestionCheckbox';
import FormItemRHSQuestionBearers from './FormItemRHSQuestionBearers';
import FormItemRHSSlopingCeilings from './FormItemRHSSlopingCeilings';
import FormItemRHSQuestionVaults from './FormItemRHSQuestionVaults';
import FormItemRHSQuestionCheckbox from './FormItemRHSQuestionCheckbox';
import FormItemRHSDetectorTypeSelectBox from './FormItemRHSDetectorTypeSelectBox';

/**
 * Render FormItemBaseFields
 * @param {*} baseFields
 */
export default function FormItemBaseFields({ fields, errorMessages }) {
    return (
        <Row wrap={false}>
            {/* Form items on the left hand side */}
            <Col flex="50%">
                <Row>
                    <FormItemLHSRoomNumberMeasurement fields={fields} />
                </Row>
                <Row style={{ marginTop: '5px' }}>
                    <FormItemLHSQuestionCheckbox fields={fields} />
                </Row>
            </Col>

            {/* Form items on the right hand side */}
            <Col flex="50%" style={{ paddingLeft: '28px' }}>
                <Row style={{ marginBottom: '8px' }}>
                    <FormItemRHSQuestionBearers fields={fields} />
                </Row>
                <Row style={{ marginBottom: '60px' }}>
                    <FormItemRHSSlopingCeilings fields={fields} errorMessages={errorMessages} />
                </Row>
                <Row style={{ marginBottom: '60px' }}>
                    <FormItemRHSQuestionVaults fields={fields} errorMessages={errorMessages}/>
                </Row>
                <Row>
                    <FormItemRHSQuestionCheckbox fields={fields} />
                </Row>
                <Row>
                    <FormItemRHSDetectorTypeSelectBox fields={fields} />
                </Row>
            </Col>
        </Row>
    );
}

import { createSlice } from '@reduxjs/toolkit';

/**
 * Default state of modal slice
 */
export const initialState = {
    modalOpen: false,
    modalData: null,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        /**
         * ACTION: set the name of modal to modalData
         * @param {*} state   => base state
         * @param {*} payload  => name of modal
         */
        openModalModalAction(state, { payload }) {
            state.modalData = payload;
            state.modalOpen = true;
        },

        /**
         * ACTION: set the state of modal slice to default
         * @param {*} state   => base state
         * @param {*} payload  => name of modal
         */
        clearModalStateModalAction(state) {
            state.modalOpen = false;
            state.modalData = null;
        },
    },
});

/**
 * SELECTOR: current state of modal slice
 * @param {*} state
 * @returns
 */
export const selectModalState = (state) => state.modal;

// ============================================================================
// Export Actions
// ============================================================================
const { actions, reducer } = modalSlice;
export const { openModalModalAction, clearModalStateModalAction } = actions;

// ============================================================================
// Export Reducer
// ============================================================================
export default reducer;

// Page size:
// Width: 1189mm - 46.81inch
// Height: 841mm - 33.11inch

export function draw_A0_FramePage(doc, leftMost, topMost) {
    leftMost += 20;
    topMost += 10;

    // // Horizontal line
    // drawTestLine(doc, {x: 1159, y: 0}, {left: 20, top: 10})
    // // Vertical line
    // drawTestLine(doc, {x: 0, y: 821}, {left: 20, top: 10})

    // left: 1189(pageWidth) -20(fromLeft) - 10(fromRight) = 1159,
    // top: 841(pageHeight) -10(fromTop) - 10(fromBottom) = 821

    const framePageWidth = 1159; // should be set based on page size

    const framePageHeight = 821; // should be set based on page size

    const drawingFrame = {
        left: leftMost, // mm
        top: topMost, // mm
        width: framePageWidth, // mm
        height: framePageHeight, // mm
    };
    doc.rect(drawingFrame.left, drawingFrame.top, drawingFrame.width, drawingFrame.height);

    const leftFramePage = leftMost;
    const topFramePage = topMost;

    return [leftFramePage, topFramePage];
}
